import React, { useEffect, useState } from "react";
import Plus from "../../images/plus.svg";
import Delete from "../../images/delete.svg";
// import { business_register } from "../../../actions/auth";
import { Accordion, Card } from "react-bootstrap";
import { useRef } from "react";
import { connect } from "react-redux";

const AccordionComponent = ({
  services,
  handleToggle,
  setServices,
  activeIndex,
  fieldinitiaValues,
  editFields,
  setEditFields,
  setActiveIndex,
}) => {
  const accordionRef = useRef(null);

  const [onlineField, setOnlineField] = useState(
    services[activeIndex]?.intakeForm
      ? services[activeIndex]?.intakeForm
      : fieldinitiaValues
  );

  const onServiceEdit = (e, index) => {
    const updatedServiceList = [...services];

    updatedServiceList[index] = {
      ...updatedServiceList[index],
      [e.target.name]: e.target.value,
    };
    setServices(updatedServiceList);
  };

  const editField = (e) => {
    setOnlineField([
      ...onlineField,
      {
        field_category: "",
        field_name: "",
        is_required: false,
        field_label: "",
        field_type: "",
        online_fieldnames: [],
        selected: "",
        option: "",
        formfield: "",
      },
    ]);
    setEditFields([
      ...editFields,
      {
        field_category: "",
        field_name: "",
        is_required: false,
        field_label: "",
        field_type: "",
        online_fieldnames: [],
        selected: "",
        option: "",
        formfield: "",
      },
    ]);
  };

  const deleteEditField = (i) => {
    let formValues = onlineField;
    formValues.splice(i, 1);
    setOnlineField([...formValues]);
    setEditFields([...formValues]);
  };

  const editOnisReqChange = (e, i) => {
    let fields = [...editFields];
    let field = { ...fields[i] };

    field.is_required = !field.is_required;
    fields[i] = field;
    setEditFields(fields);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        accordionRef.current &&
        !accordionRef.current.contains(event.target)
      ) {
        setActiveIndex(null);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const onDeleteService = (index) => {
    const updatedServices = [...services];

    updatedServices.splice(index, 1);

    setServices(updatedServices);
  };
  return (
    <Accordion
      className="d-flex flex-column gap mt-2"
      activeKey={activeIndex?.toString()}
      ref={accordionRef}
    >
      {services.map((value, Index) => (
        <Card key={Index} className="border-10 ">
          <Accordion.Toggle
            variant="link"
            eventKey={Index.toString()}
            onClick={() => {
              handleToggle(Index);
            }}
            className="border-0 p-0"
          >
            <div className="form-row  first-input" key={Index}>
              <div className="col-sm-12 calendar-btn border-0 ">
                <button type="button" className="business-form-btn">
                  {value?.name}
                </button>
                <div
                  className="plusbtnclass"
                  onClick={() => onDeleteService(Index)}
                >
                  <img src={Delete} />
                </div>
              </div>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={Index.toString()}>
            <Card.Body>
              <div className="d-flex flex-column gap">
                <div className="form-row  first-input">
                  <div className="col-sm-12 ">
                    <input
                      className="form-control font-medium text-medium"
                      type="text"
                      placeholder="Service Name"
                      name="name"
                      maxLength="50"
                      value={value.name}
                      onChange={(e) => onServiceEdit(e, Index)}
                    />
                  </div>
                </div>
                <div className="form-row  first-input">
                  <div className="col-sm-12">
                    <textarea
                      className="form-control font-medium text-medium"
                      type="textarea"
                      placeholder="Service Description"
                      name="description"
                      value={value.description}
                      onChange={(e) => onServiceEdit(e, Index)}
                    />
                  </div>
                </div>
                <div className="form-row  first-input">
                  <div className="col-md-6 px-1 mb-2 mt-mb-0">
                    <input
                      className="form-control font-medium text-medium"
                      type="text"
                      placeholder="Service Price"
                      name="price"
                      value={value.price}
                      onChange={(e) => onServiceEdit(e, Index)}
                    />
                  </div>
                  <div className="col-md-6 px-1  ">
                    <select
                      className="form-control select font-medium text-medium drop-arrow "
                      name="service_duration"
                      placeholder="Duration"
                      id="service-service_duration"
                      onChange={(e) => onServiceEdit(e, Index)}
                    >
                      <option value="15">15 minutes</option>
                      <option value="30">30 minutes</option>
                      <option value="45">45 minutes</option>
                      <option value="60">60 minutes</option>
                      <option value="90">90 minutes</option>
                      <option value="120">120 minutes</option>
                    </select>
                  </div>
                </div>
                <p className="form-title m-0 text-left">
                  Booking Method
                </p>
                <div className="d-none flex-column gap-1">
                  <div className="contact-method-col ">
                    <label className="switch my-0">
                      <input
                        type="checkbox"
                        name="book_online"
                        id="service-book_online"
                        onChange={(e) => {
                          onServiceEdit(e, Index);
                        }}
                        checked={value.book_online}
                      />
                      <span className="slider round"></span>
                    </label>
                    <label
                      htmlFor="service-book_online"
                      className="text-grey"
                      style={{ margin: "6px 0px 0px 10px" }}
                    >
                      Book Through Integrated Calendar
                    </label>
                  </div>
                  {value.book_online && (
                    <>
                      <div className="bookonline-button-wrapper ">
                        <div className="ser_pop_create_intake_form">
                          <span>
                            Additional questions you want to ask the client:
                          </span>
                          <a onClick={(e) => editField(e)}>Add Field +</a>
                        </div>
                      </div>
                      {editFields !== null
                        ? editFields?.length > 0 &&
                          editFields?.map((element, index) => {
                            return (
                              <div
                                key={index}
                                className="card bookonlinemodule-wrapper"
                              >
                                <div className="card-header">
                                  <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 mb-sm-0 mb-2 ">
                                      <input
                                        className="form-control font-medium text-medium"
                                        id="field_label"
                                        type="text"
                                        placeholder="Field label"
                                        name="field_label"
                                        onChange={(e) => {
                                          var value = e.target.value.replace(
                                            " ",
                                            "_"
                                          );
                                          let fields = [...editFields];
                                          let field = { ...fields[index] };
                                          field.selected = "";
                                          field.field_name = value;
                                          field.field_label = e.target.value;
                                          field.field_type = "text";
                                          fields[index] = field;
                                          setEditFields(fields);
                                          onServiceEdit(e, Index);
                                        }}
                                        value={element.field_label}
                                      />
                                    </div>

                                    <div className="col-lg-5 col-md-4 col-sm-4 mt-2 mt-lg-0 col-4">
                                      <div className="check-box-control req-chk">
                                        <label
                                          className="form-check-label requiredInput"
                                          htmlFor={index}
                                        >
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id={index}
                                            onClick={(e) => {
                                              editOnisReqChange(e, index);
                                            }}
                                          />
                                          Required ?
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-lg-1 col-md-2 col-sm-2 mt-lg-0 mt-2 col-6">
                                      <div className="col-ms-2">
                                        <button
                                          type="button"
                                          className="btn btn-danger float-right deleteBtn"
                                          onClick={() => deleteEditField(index)}
                                        >
                                          -
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <ul className="list-group list-group-flush border-bottom">
                                  <li className="list-group-item online-fields">
                                    <div className="row flex_column">
                                      <div className="col-lg-12  col-md-6 com-sm-12 field-label">
                                        <label className="lable-service">
                                          {element.field_label}
                                        </label>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            );
                          })
                        : []}
                    </>
                  )}
                  <div className="contact-method-col">
                    <label className="switch my-0">
                      <input
                        type="checkbox"
                        name="call_to_book"
                        id="service-call_to_book"
                        onChange={(e) => {
                          onServiceEdit(e, Index);
                        }}
                        checked={value.call_to_book}
                      />
                      <span className="slider round"></span>
                    </label>
                    <label
                      htmlFor="service-call_to_book"
                      className="text-grey"
                      style={{ margin: "6px 0px 0px 10px" }}
                    >
                      Call to book
                    </label>
                  </div>

                  <div className="contact-method-col ">
                    <label className="switch my-0">
                      <input
                        type="checkbox"
                        name="book_site"
                        id="service-book_site"
                        onChange={(e) => {
                          //   setserviceData({
                          //     ...serviceData,
                          //     book_site: !value.book_site,
                          //     book_online: false,
                          //     call_to_book: false,
                          //   });
                          onServiceEdit(e, Index);
                        }}
                        checked={value.book_site}
                      />
                      <span className="slider round"></span>
                    </label>
                    <label
                      htmlFor="service-book_site"
                      className="text-grey"
                      style={{ margin: "6px 0px 0px 10px" }}
                    >
                      Book on another site
                    </label>
                  </div>
                  {value.book_site && (
                    <>
                      <div className="http_bg">
                        <div className="col-sm-12 text-muted">
                          <p className="htt_disc">
                            Enter the URL you would like to redirect users to
                            when
                            <br />
                            booking for this service.
                          </p>
                        </div>
                        <div className="col-sm-12">
                          {/* <label htmlFor="book-site-link"></label> */}
                          <input
                            type="text"
                            className="form-control http_field font-medium text-medium"
                            name="book_site_link"
                            placeholder="https://"
                            id="book_site_link"
                            checked={value.book_site_link}
                            required={value.book_site_link}
                            value={
                              value.book_site_link ? value.book_site_link : ""
                            }
                            // onChange={(e) => onChange(e)}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      ))}
    </Accordion>
  );
};

const CreateServices = ({
  clearNumber,
  onChange,
  setserviceData,
  serviceData,
  formData,
  // business_register,
  loading,
  setServices,
  services,
}) => {
  const [intakeField, setIntakeField] = useState([]);
  const [editFields, setEditFields] = useState([]);

  const [formError, setFormError] = useState("");

  const onServiceChange = (e) => {
    if (e.target.name === "price") {
      e.target.value = clearNumber(e.target.value);
    }

    setserviceData({ ...serviceData, [e.target.name]: e.target.value });
  };
  const [showForm, setShowForm] = useState(true);
  const [activeIndex, setActiveIndex] = useState(null);
  const handleToggle = (index) => {
    setActiveIndex(index);
  };

  const { intakeForm } = serviceData;
  let fieldinitiaValues = [
    {
      field_category: "",
      field_type: "",
      is_required: false,
      field_name: "",
      field_label: "",
      online_fieldnames: [],
      selected: "",
      option: "",
      formfield: "",
    },
  ];

  const [onlineField, setOnlineField] = useState(
    intakeForm ? intakeForm : fieldinitiaValues
  );
  const deleteField = (i) => {
    let formValues = onlineField;
    formValues.splice(i, 1);
    setOnlineField([...formValues]);
    setIntakeField([...formValues]);
  };

  const onisReqChange = (e, i) => {
    let fields = [...intakeField];
    let field = { ...fields[i] };

    field.is_required = !field.is_required;
    fields[i] = field;
    setIntakeField(fields);
  };

  const addField = (e) => {
    setOnlineField([
      ...onlineField,
      {
        field_category: "",
        field_name: "",
        is_required: false,
        field_label: "",
        field_type: "",
        online_fieldnames: [],
        selected: "",
        option: "",
        formfield: "",
      },
    ]);
    setIntakeField([
      ...intakeField,
      {
        field_category: "",
        field_name: "",
        is_required: false,
        field_label: "",
        field_type: "",
        online_fieldnames: [],
        selected: "",
        option: "",
        formfield: "",
      },
    ]);
  };

  const onCreateService = (e) => {
    setShowForm(false);

    if (
      serviceData.name &&
      serviceData.description &&
      serviceData.service_duration &&
      serviceData.price
    ) {
      setFormError("");
      setServices([...services, serviceData]);

      setserviceData({
        name: "",
        description: "",
        service_duration: 15,
        price: "",
        book_online: false,
        book_site: false,
        book_site_link: "",
        call_to_book: false,
      });
      setIntakeField([]);
      setOnlineField([]);
    } else {
      setFormError("Please fill out your first service fields.");
    }
    // }
  };
  const onAddService = () => {
    onCreateService();
    setShowForm(true);
    setIntakeField([]);
  };

  useEffect(() => {
    if (activeIndex >= 0 && activeIndex !== null) {
      setEditFields(services[activeIndex]?.intakeform);
    }
  }, [activeIndex]);

  const onCompleteSignup = () => {
    let submitFormData;
    if (serviceData?.name) {
      if (
        serviceData.description &&
        serviceData.service_duration &&
        serviceData.price
      ) {
        const newService = [...services, serviceData];
        submitFormData = { ...formData, services: newService };
        setFormError(null);
        // business_register(submitFormData);
      } else {
        setFormError("Please fill out the service fields.");
      }
    } else {
      setFormError(null);
      submitFormData = { ...formData, services: services };
      // business_register(submitFormData);
    }
  };

  return (
    <div className="bg-lightpeach px-10 border-20 form-row flex-column gap-10 w-full">
      <AccordionComponent
        services={services}
        addField={addField}
        activeIndex={activeIndex}
        setServices={setServices}
        handleToggle={handleToggle}
        fieldinitiaValues={fieldinitiaValues}
        editFields={editFields}
        setEditFields={setEditFields}
        setActiveIndex={setActiveIndex}
      />

      {showForm && (
        <>
          <div className="form-row  first-input">
            <div className="col-sm-12 ">
              <input
                className="form-control font-medium text-medium"
                type="text"
                placeholder="Service Name (ex: 30-minute Massage)"
                name="name"
                maxLength="50"
                value={serviceData.name}
                onChange={(e) => {
                  onServiceChange(e);
                }}
              />
            </div>
          </div>
          <div className="form-row  first-input">
            <div className="col-sm-12">
              <textarea
                className="form-control font-medium text-medium"
                type="textarea"
                placeholder="SHORT Service Description (ex: Book a 30-minute Massage)"
                name="description"
                value={serviceData.description}
                onChange={(e) => onServiceChange(e)}
              />
            </div>
          </div>
          <div className="form-row  first-input">
            <div className="col-sm-6 px-1">
              <input
                className="form-control font-medium text-medium"
                type="text"
                placeholder="Service Price"
                name="price"
                value={serviceData.price}
                onChange={(e) => onServiceChange(e)}
              />
            </div>
            <div className="col-sm-6 px-1 mt-2 mt-md-0">
              <select
                className="form-control select font-medium text-medium drop-arrow py-1"
                name="service_duration"
                placeholder="Duration"
                id="service-service_duration"
                onChange={(e) => onServiceChange(e)}
              >
                <option value="15">15 minutes</option>
                <option value="30">30 minutes</option>
                <option value="45">45 minutes</option>
                <option value="60">60 minutes</option>
                <option value="90">90 minutes</option>
                <option value="120">120 minutes</option>
              </select>
            </div>
          </div>
          <p className="form-title m-0 text-left">Booking Method</p>
          <div className=" flex-column gap-1">
            <div className="contact-method-col ">
              <label className="switch my-0">
                <input
                  type="checkbox"
                  name="typeof_booking"
                  id="service-book_online"
                  onChange={(e) => {
                    setserviceData({
                      ...serviceData,
                      book_site: false,
                      call_to_book: false,
                      book_online: !serviceData.book_online,
                    });
                  }}
                  checked={serviceData.book_online}
                />
                <span className="slider round"></span>
              </label>
              <label
                htmlFor="service-book_online"
                className="text-grey"
                style={{ margin: "6px 0px 0px 10px" }}
              >
                Book Through Integrated Calendar
              </label>
            </div>
            {serviceData.book_online && (
              <>
                {" "}
                <div className="bookonline-button-wrapper ">
                  <div className="ser_pop_create_intake_form">
                    <span>
                      Additional questions you want to ask the client:
                    </span>
                    <a onClick={(e) => addField(e)}>Add Field +</a>
                  </div>
                </div>
                {intakeField !== null
                  ? intakeField?.length > 0 &&
                    intakeField?.map((element, index) => {
                      return (
                        <div
                          key={index}
                          className="card bookonlinemodule-wrapper"
                        >
                          <div className="card-header">
                            <div className="row">
                              <div className="col-lg-6 col-md-6 col-sm-6 mb-sm-0 mb-2 ">
                                <input
                                  className="form-control font-medium text-medium"
                                  id="field_label"
                                  type="text"
                                  placeholder="Field label"
                                  name="field_label"
                                  onChange={(e) => {
                                    var value = e.target.value.replace(
                                      " ",
                                      "_"
                                    );
                                    let fields = [...intakeField];
                                    let field = { ...fields[index] };
                                    field.selected = "";
                                    field.field_name = value;
                                    field.field_label = e.target.value;
                                    field.field_type = "text";
                                    fields[index] = field;
                                    setIntakeField(fields);
                                  }}
                                  value={element.field_label}
                                />
                              </div>

                              <div className="col-lg-5 col-md-4 col-sm-4 mt-2 mt-lg-0 col-4">
                                <div className="check-box-control req-chk">
                                  <label
                                    className="form-check-label requiredInput"
                                    htmlFor={index}
                                  >
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id={index}
                                      onClick={(e) => {
                                        onisReqChange(e, index);
                                      }}
                                    />
                                    Required ?
                                  </label>
                                </div>
                              </div>
                              <div className="col-lg-1 col-md-2 col-sm-2 mt-lg-0 mt-2 col-6">
                                <div className="col-ms-2">
                                  <button
                                    type="button"
                                    className="btn btn-danger float-right deleteBtn"
                                    onClick={() => deleteField(index)}
                                  >
                                    -
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <ul className="list-group list-group-flush border-bottom">
                            <li className="list-group-item online-fields">
                              <div className="row flex_column">
                                <div className="col-lg-12  col-md-6 com-sm-12 field-label">
                                  <label className="lable-service">
                                    {element.field_label}
                                  </label>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      );
                    })
                  : []}
              </>
            )}
            <div className="contact-method-col">
              <label className="switch my-0">
                <input
                  type="checkbox"
                  name="typeof_booking"
                  id="service-call_to_book"
                  onChange={(e) => {
                    setserviceData({
                      ...serviceData,
                      book_online: false,
                      book_site: false,
                      call_to_book: !serviceData.call_to_book,
                    });
                  }}
                  checked={serviceData.call_to_book}
                />
                <span className="slider round"></span>
              </label>
              <label
                htmlFor="service-call_to_book"
                className="text-grey"
                style={{ margin: "6px 0px 0px 10px" }}
              >
                Call to book
              </label>
            </div>

            <div className="contact-method-col ">
              <label className="switch my-0">
                <input
                  type="checkbox"
                  name="typeof_booking"
                  id="service-book_site"
                  onChange={(e) => {
                    setserviceData({
                      ...serviceData,
                      book_site: !serviceData.book_site,
                      book_online: false,
                      call_to_book: false,
                    });
                  }}
                  checked={serviceData.book_site}
                />
                <span className="slider round"></span>
              </label>
              <label
                htmlFor="service-book_site"
                className="text-grey"
                style={{ margin: "6px 0px 0px 10px" }}
              >
                Book on another site
              </label>
            </div>
            {serviceData.book_site && (
              <>
                <div className="http_bg">
                  <div className="col-sm-12 text-muted">
                    <p className="htt_disc">
                      Enter the URL you would like to redirect users to when
                      <br />
                      booking for this service.
                    </p>
                  </div>
                  <div className="col-sm-12">
                    <input
                      type="text"
                      className="form-control http_field font-medium text-medium"
                      name="book_site_link"
                      placeholder="https://"
                      id="book_site_link"
                      checked={serviceData.book_site_link}
                      required={serviceData.book_site_link}
                      value={
                        serviceData.book_site_link
                          ? serviceData.book_site_link
                          : ""
                      }
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
      
      {formError && <p className="text-danger">{formError}</p>}

      <div className="first-input pb-10">
        <div
          className="col-sm-12 calendar-btn d-flex justify-content-between"
          onClick={onAddService}
        >
          <button type="button" className="business-form-btn p-0">
            Additional Service
          </button>
          <img src={Plus} className="mb-0" />
        </div>
      </div>
      <div className="d-none justify-content-between">
        <input
          type="button"
          className="btn btn-primary bg-white border-0 text-dark font-weight-bold"
          style={{ padding: "10px 20px", fontSize: "15px" }}
          value="Back"
          data-tut="register_button"
        />
        <button
          type="button"
          className="btn btn-primary text-dark font-weight-bold"
          style={{ padding: "10px 20px", fontSize: "15px" }}
          // value="Complete Sign Up"
          data-tut="register_button"
          onClick={onCompleteSignup}
          disabled={loading}
        >
          Complete Sign Up
          {loading && (
            <div class="spinner-border spinner-border-sm text-dark ml-2"></div>
          )}
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.company.loading,
});

export default connect(mapStateToProps, {
  // business_register,
})(CreateServices);
