import api from "../utils/api";
import { setAlert } from "./alert";
import { addMessage } from "./message";
// import * as emailjs from "emailjs-com";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  UPDATE_USER,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  RESET_PASSWORD,
  UPDATE_PASSWORD,
  BUSINESS_REGISTER_SUCCESS,
  START_LOADING,
  STOP_LOADING,
} from "./types";

// Load User
export const loadUser = () => async (dispatch) => {
  try {
    const res = await api.get("/auth");

    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Register User
export const register = (formData, history) => async (dispatch) => {
  try {
    const res = await api.post("/users", formData);
    // history.push("/signup-success");

    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data,
    });
    dispatch(
      addMessage({
        title: "Welcome to Appointment Cake",
        // text: 'Let\'s get you started, <a href="/guides">Getting Started Guide</a>',
        text: "You can find messages in this section.",
        type: "message",
        user: null,
        section: "user",
      })
    );
    dispatch(loadUser()).then((res) => {
      if (res) {
        if (history?.location?.state?.url)
          history.push(history?.location?.state?.url);
        else history.push("/dashboard");
      }
    });
  } catch (err) {
    const errors = err?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: REGISTER_FAIL,
    });
  }
};

// Business Register User
export const business_register = (formData, history) => async (dispatch) => {
  dispatch({
    type: START_LOADING,
  });
  try {
    const res = await api.post("/users/business-register", formData);
    // history.push("/signup-success");
    dispatch({
      type: BUSINESS_REGISTER_SUCCESS,
      payload: res.data,
    });
    dispatch({
      type: STOP_LOADING,
    });
    dispatch(
      addMessage({
        title: "Welcome to Appointment Cake",
        // text: 'Let\'s get you started, <a href="/guides">Getting Started Guide</a>',
        text: "You can find messages in this section.",
        type: "message",
        user: null,
        section: "business",
      })
    );
    dispatch(loadUser());
  } catch (err) {
    dispatch({
      type: STOP_LOADING,
    });
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger", 5000)));
    }

    dispatch({
      type: REGISTER_FAIL,
    });
  }
};

export const updateUser = (formData, history) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await api.post("/users/me", formData, config);

    dispatch({
      type: UPDATE_USER,
      payload: res.data,
    });

    dispatch(setAlert("Password Updated", "success"));

    history.push("/account"); // Can't use redirect tag in actions like in components
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: REGISTER_FAIL,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

// Login User
export const login = (email, password) => async (dispatch) => {
  const body = { email, password };

  try {
    const res = await api.post("/auth", body);
    localStorage.setItem("token", res.data.token);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
    window.lintrk("track", { conversion_id: 6507468 });
    dispatch(loadUser());
  } catch (err) {
    if (err) {
      const errors = err.response.data.errors;
      errors?.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

// Logout / Clear
export const logout = () => ({ type: LOGOUT });

// Reset Password
export const resetPassword = (email, history) => async (dispatch) => {
  try {
    const res = await api.post("/auth/reset", email);
    history.push("/success-page");
    dispatch({
      type: RESET_PASSWORD,
      payload: res.data,
    });
  } catch (err) {
    if (err) {
      const errors = err.response.data.errors;
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

export const updatePassword =
  (password, token, history) => async (dispatch) => {
    const body = { password, token };
    try {
      const res = await api.post("/auth/new-password", body);
      history.push("/success-page");
      dispatch({
        type: UPDATE_PASSWORD,
        payload: res.data,
      });
      dispatch(setAlert("Password Updated", "success"));
    } catch (err) {
      if (err) {
        const errors = err.response.data.errors;
        errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
      }
    }
  };

export const addGoogleAuthCode = (authCode) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    await api.post("/auth/gtoken", authCode, config);
    let userState = authCode.user;
    userState.googleRefreshToken = authCode.googleRefreshToken;
    userState.googleAuth = authCode.googleAuth;
    userState.googleUser = authCode.googleUser;
    dispatch({
      type: UPDATE_USER,
      payload: userState, //res.data
    });
    dispatch(setAlert("Calendar Connected", "success"));
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
      payload: { msg: err, status: 500 },
    });
  }
};

export const addGoogleAuthCodeBusiness = (authCode) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    await api.post("/auth/gtoken", authCode, config);
    let userState = authCode.user;
    userState.googleRefreshTokenBusiness = authCode.googleRefreshTokenBusiness;
    userState.googleAuthBusiness = authCode.googleAuthBusiness;
    userState.googleUserBusiness = authCode.googleUserBusiness;
    dispatch({
      type: UPDATE_USER,
      payload: userState, //res.data
    });
    dispatch(setAlert("Calendar Connected", "success"));
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
      payload: { msg: err, status: 500 },
    });
  }
};

export const removeGoogleAuthCode = (user) => async (dispatch) => {

  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const authCode = { googleAuth: {}, googleUser: {}, user: user };
    await api.put("/auth/gtoken", authCode, config);
    let userState = user;
    delete userState.googleRefreshToken;
    delete userState.googleAuth;
    delete userState.googleUser;
    dispatch({
      type: UPDATE_USER,
      payload: userState,
    });

    dispatch(setAlert("Calendar Disconnected", "success"));
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
      payload: { msg: err, status: 500 },
    });
  }
};
export const removeGoogleAuthCodeBusiness = (user) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const authCode = {
      googleAuthBusiness: {},
      googleUserBusiness: {},
      user: user,
    };
    await api.put("/auth/gtoken", authCode, config);
    let userState = user;
    delete userState.googleRefreshTokenBusiness;
    delete userState.googleAuthBusiness;
    delete userState.googleUserBusiness;
    dispatch({
      type: UPDATE_USER,
      payload: userState,
    });

    dispatch(setAlert("Calendar Disconnected", "success"));
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
      payload: { msg: err, status: 500 },
    });
  }
};
