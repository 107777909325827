import React from "react";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Moment from "react-moment";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMemoCircleInfo } from "@fortawesome/pro-regular-svg-icons";
const AppointmentHistory = ({
  booking: { _id, user, company, service, start_time, comments }
}) => {
  let appt_date = moment(start_time);

  // const history = useHistory();
  return (
    <>
      <tr className="booking-item-row appo_table appo_his" id={_id}>
        <td className="booking-date" style={{ width: "0%" }}>
          {/* <Link to={`/bookings/${_id}`} className="small"> */}
          <Moment
            className="upcom_date"
            format="ddd, MMM Do"
            date={appt_date}
          />
          {/* <br /> */}

          <Moment className="upcom_date" format="h:mm a" date={appt_date} />
          {/* </Link> */}
        </td>
        <td className="booking-service-name">
          <Link to={`/company/${company._id}`} className="small">
            {service.name}
          </Link>
          <br />
          <Link to={`/bookings/${_id}`} className="font-weight-bold ser">
            {company.name}
          </Link>
        </td>
        {/* <td>
        {user.firstName} {user.lastName}
      </td> */}
        <td className="booking-notes">
          <Link to={`/bookings/${_id}`} className="btn btn-sm">
            <FontAwesomeIcon
              icon={faMemoCircleInfo}
              style={{ color: "#25d2c3", fontSize: "27px" }}
            />{" "}
            {comments.length > 0 && (
              <span className="comments-length badge badge-pill badge-light">
                {comments.length}
              </span>
            )}
          </Link>
        </td>
        {/* <td className="service_info">
          <i>
            <p>[{company?.name}] - </p>
            <p> [{service?.name}]</p>
          </i>
          <p>
            <i class="fa-solid fa-location-dot"></i>1289 Lawrence Ave{" "}
            <a href="#">see map</a>
          </p>
          <p>
            <i class="fa-solid fa-mobile-button"></i>250-535-0609{" "}
            <a href="#">call</a>
          </p>
        </td> */}
        {/* <td className="tbl-info">
        <span>
          <i className="fa-solid fa-location-dot"></i>
          <p>{company?.location?.street_address}</p>
          <Link
            to={{
              pathname: "/find",
              search:
                "?" +
                new URLSearchParams({
                  // search: params.get("search"),
                  lat: company.location.lat,
                  lng: company?.location?.lng,
                  zoom: 15
                }).toString()
            }}
          >
            see map
          </Link>
        </span>
      </td> */}
        {/* <td className="tbl-info">
        <span>
          <i className="fa-solid fa-mobile-screen-button"></i>
          <p>{company?.phone}</p>
          <a href={`tel:${company?.phone}`}>call</a>
        </span>
      </td> */}

        {/* <td className="tbl-info app_history_icon">
          <a
            onClick={() => {
              history.push(`/bookings/${_id}`);
            }}
          >
            <FontAwesomeIcon
              icon={faMemoCircleInfo}
              style={{ color: "#25d2c3", fontSize: "27px" }}
            />
          </a>
        </td> */}
      </tr>
    </>
  );
};

AppointmentHistory.propTypes = {
  booking: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  null
)(AppointmentHistory);
