export  const options = [
    {
      label: "Select One",
      value: "",
    },
    {
      label: "Dental",
      value: "Dental",
    },
    {
      label: "General",
      value: "General",
    },
    {
      label: "Medical",
      value: "Medical",
    },
    {
      label: "Acupuncture",
      value: "Acupuncture",
    },
    {
      label: "Aesthetics",
      value: "Aesthetics",
    },
    {
      label: "Athletic Therapy",
      value: "Athletic Therapy",
    },
    {
      label: "Chiropractic",
      value: "Chiropractic",
    },
    {
      label: "Counselling",
      value: "Counselling",
    },
    {
      label: "Dietetics",
      value: "Dietetics",
    },
    {
      label: "Kinesiology",
      value: "Kinesiology",
    },

    {
      label: "Massage Therapy",
      value: "Massage Therapy",
    },
    {
      label: "Midwifery",
      value: "Midwifery",
    },
    {
      label: "Naturopathic Medicine",
      value: "Naturopathic Medicine",
    },
    {
      label: "Occupational Therapy",
      value: "Occupational Therapy",
    },
    {
      label: "Optometry",
      value: "Optometry",
    },

    {
      label: "Osteopathy",
      value: "Osteopathy",
    },
    {
      label: "Personal Training",
      value: "Personal Training",
    },
    {
      label: "Podiatry",
      value: "Podiatry",
    },
    {
      label: "Psychiatry",
      value: "Psychiatry",
    },
    {
      label: "Psychology",
      value: "Psychology",
    },
    {
      label: "Speech Therapy",
      value: "Speech Therapy",
    },
    {
      label: "Tattoo",
      value: "Tattoo",
    },
  ];