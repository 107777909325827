import React, { useState, useEffect, useForceUpdate } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addComment } from "../../actions/booking";

const CommentForm = ({ postId, company, addComment }) => {
  const widgetFunction = window.cloudinary.createUploadWidget(
    {
      cloudName: "vigilante-marketing-inc",
      uploadPreset: "ml_default",
      folder: `${company}/${postId}`
    },
    (error, result) => {
      if (!error && result && result.event === "success") {
        let uploadedImages = UploadImage;
        setUploadImage([]);
        uploadedImages.push(result.info.url);
        setUploadImage(uploadedImages);
        setTest(true);
      }
    }
  );
  const [text, setText] = useState("");
  const [UploadImage, setUploadImage] = useState([]);
  const [test, setTest] = useState(false);

  const showWidget = () => {
    widgetFunction.open();
  };
  const removeImageFromList = index => {
    let tempArray = [...UploadImage];

    tempArray.splice(index, 1);
    setUploadImage(tempArray);
  };
  useEffect(() => {}, [UploadImage]);

  return (
    <div className="post-form">
      <form
        className="form my-1"
        onSubmit={e => {
          e.preventDefault();
          addComment(postId, { text, UploadImage });
          setText("");
          setUploadImage([]);
        }}
      >
        <div className="form-comment">
          <textarea
            name="text"
            placeholder="Write a comment..."
            className="form-control mb-3"
            value={text}
            onChange={e => setText(e.target.value)}
            required
          ></textarea>
          <button
            type="button"
            className="attached-comment btn btn-info"
            onClick={showWidget}
          >
            <i className="fas fa-paperclip"></i>
          </button>
        </div>
        <div className="image-preview">
          {UploadImage && UploadImage.length > 0
            ? UploadImage.map((images, index) => {
                return (
                  <div className="images-block">
                    <img
                      className="commentImage-priview"
                      src={images}
                      style={{
                        width: "75px",
                        height: "75px"
                      }}
                    />
                    <button
                      type="button"
                      className="removeImage btn btn-danger"
                      onClick={() => {
                        removeImageFromList(index);
                      }}
                    >
                      <i className="far fa-times-circle"></i>
                    </button>
                  </div>
                );
              })
            : ""}
        </div>
        <button type="submit" className="btn btn-primary submit-comments">
          Post Comment
        </button>
      </form>
    </div>
  );
};

CommentForm.propTypes = {
  addComment: PropTypes.func.isRequired
};

export default connect(
  null,
  { addComment }
)(CommentForm);
