import { SHOW_MODAL, HIDE_MODAL, SET_CURRENT_COMPANY } from '../actions/waitlistModal';

const initialState = {
  showModal: false,
  currentCompany: null,
};

const waitlistModalReducer = (state = initialState, action) => {
  console.log("Reducer action: ", action); // Debugging line
  switch (action.type) {
    case SHOW_MODAL:
      console.log("Handling SHOW_MODAL"); // Debugging line
      return {
        ...state,
        showModal: true,
      };
    case HIDE_MODAL:
      return {
        ...state,
        showModal: false,
      };
    case SET_CURRENT_COMPANY:
      return {
        ...state,
        currentCompany: action.payload,
      };
    default:
      return state;
  }
};

export default waitlistModalReducer;
