// import config from '../config/default.json';
import api from "../utils/api";
import { setAlert } from "./alert";
import * as emailjs from "emailjs-com";
import { addMessage } from "./message";
import moment from "moment-timezone";
import nodemailer from "nodemailer";
import sendGridTransport from "nodemailer-sendgrid-transport";
import { waitlistDateEmailTemplate } from "../messageTemplates/waitlistDateEmailTemplate";
import { clientBookingEmailTemplate } from "../messageTemplates/clientBookingEmailTemplate";
import { ownerBookingEmailTemplate } from "../messageTemplates/ownerBookingEmailTemplate";
import { waitlistClientSignup } from "../messageTemplates/waitlistClientSignup";
import {waitlistOwnerSignup} from "../messageTemplates/waitlistOwnerSignup";


import {
  GET_BOOKING,
  GET_BOOKINGS,
  UPDATE_BOOKING,
  BOOKING_ERROR,
  ADD_COMMENT,
  REMOVE_COMMENT,
  GET_PAST_BOOKINGS,
  GET_FUTURE_BOOKINGS,
  CLEAR_BOOKING,
  UPDATE_USER,
  CLEAR_HISTORY
  // AUTH_ERROR
} from "./types";

// Get current users Booking
const CLIENT_ID = process.env.REACT_APP_GOOGLECLIENTID;
// const API_KEY = process.env.REACT_APP_GOOGLEAPIKEY;
// const DISCOVERY_DOCS = [
//   "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
// ];
const SCOPES =
  "profile email https://www.googleapis.com/auth/admin.directory.resource.calendar";
export const getCurrentBooking = () => async dispatch => {
  try {
    const res = await api.get("/bookings/mine");

    dispatch({
      type: GET_BOOKING,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: BOOKING_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status
      }
    });
  }
};



const transporter = nodemailer.createTransport(
  sendGridTransport({
    auth: {
      api_key: process.env.SEND_GRID_API
    }
  })
);

// Get Bookings
export const getBookings = () => async dispatch => {
  // clear any bookings in state before loading new bookings to state
  dispatch({ type: CLEAR_BOOKING });

  try {
    const res = await api.get("/bookings");

    dispatch({
      type: GET_BOOKINGS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: BOOKING_ERROR,
      payload: { msg: err, status: 500 }
    });
  }
};

// Get Past Bookings
export const getPastBookings = () => async dispatch => {
  dispatch({ type: CLEAR_BOOKING });

  try {
    const res = await api.get("/bookings/past");

    dispatch({
      type: GET_PAST_BOOKINGS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: BOOKING_ERROR,
      payload: { msg: "err", status: 500 }
    });
  }
};

// Get Past Bookings By Business
export const getPastBookingsByBusiness = BusinessId => async dispatch => {
  dispatch({ type: CLEAR_BOOKING });

  try {
    const res = await api.get(`/bookings/past/${BusinessId}`);

    dispatch({
      type: GET_PAST_BOOKINGS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: BOOKING_ERROR,
      payload: { msg: "err", status: 500 }
    });
  }
};

// Get Future Bookings
export const getFutureBookings = () => async dispatch => {
  try {
    const res = await api.get("/bookings/future");

    dispatch({
      type: GET_FUTURE_BOOKINGS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: BOOKING_ERROR,
      payload: { msg: err, status: 500 }
    });
  }
};

// Get Future Bookings By Business
export const getFutureBookingsByBusiness = BusinessId => async dispatch => {
  try {
    const res = await api.get(`/bookings/future/${BusinessId}`);

    console.log('the res by biz', res)

    dispatch({
      type: GET_FUTURE_BOOKINGS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: BOOKING_ERROR,
      payload: { msg: err, status: 500 }
    });
  }
};

// Get Booking by id
export const getBookingById = (bookingId, history) => async dispatch => {
  try {
    const res = await api.get(`/bookings/${bookingId}`);

    dispatch({
      type: GET_BOOKING,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: BOOKING_ERROR,
      payload: { msg: err, status: 500 }
    });
    dispatch(setAlert("Booking not found", "danger"));
    history.goBack();
  }
};

// Create or Update Booking
/*
// TODO:
    Get the current user
    Get current company, service id, start time from hidden form data
    create booking object in users->bookings
*/
export const createBooking = (serviceData, history) => async (
  dispatch,
  getState
) => {
  try {
    var gapi = window.gapi;
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    const res = await api.post("/bookings", serviceData, config);
    
    let userDetails = getState().auth.user;
    let updateBookingId = {
      user_calender: "",
      business_calender: ""
    };
    const new_booking_info = await api.get(`/bookings/${res.data._id}`);
    // console.log("res from service data", res)
    // console.log("booking data", new_booking_info)

    sendBookingEmails(new_booking_info);
    
    // connect user to google using access token
    if (
      userDetails.googleAuth !== undefined &&
      userDetails.googleAuth !== null
    ) {
      try {
        await gapi.load("client:auth2", async () => {
          await gapi.auth.authorize(
            {
              client_id: CLIENT_ID,
              prompt: "none",
              response_type: "permission", // Access Token.
              scope: SCOPES,
              login_hint: userDetails.googleUser.email
            },
            async function(result) {
              if (result) {
                await gapi.auth.setToken({
                  access_token: result.access_token
                });
                let googleCalenderEvent = {
                  summary: serviceData.service.name,
                  location:
                    serviceData.company.location.street_address +
                    ", " +
                    serviceData.company.location.city +
                    ", " +
                    serviceData.company.location.province +
                    " " +
                    serviceData.company.location.postal,
                  description:
                    "Booking: " +
                    serviceData.service.name +
                    " - " +
                    serviceData.company.name,
                  start: {
                    dateTime:
                      moment(serviceData.start_date).format("YYYY-MM-DD") +
                      "T" +
                      moment(serviceData.start_time).format("HH:mm:ss"),
                    timeZone: moment.tz.guess()
                  },
                  end: {
                    dateTime:
                      moment(serviceData.start_date).format("YYYY-MM-DD") +
                      "T" +
                      moment(serviceData.start_time)
                        .add(serviceData.duration, "minutes")
                        .format("HH:mm:ss"),
                    timeZone: moment.tz.guess()
                  },
                  reminders: {
                    useDefault: true
                  }
                };

                await gapi.client.calendar.events
                  .insert({
                    calendarId: "primary",
                    resource: googleCalenderEvent
                  })
                  .then(async function(response) {
                    await api.put(
                      `/bookings/${res.data._id}`,
                      { user_calender: response.result.id },
                      config
                    );
                    updateBookingId.user_calender = response.result.id;
                  });
              }
            }
          );
        });
      } catch (err) {
        console.log(err);
      }
    }

    if (
      serviceData.company.googleAuthBusiness !== undefined &&
      serviceData.company.is_admin === false &&
      serviceData.company.googleAuthBusiness !== null
    ) {
      try {
        await gapi.load("client:auth2", async () => {
          await gapi.auth.authorize(
            {
              client_id: CLIENT_ID,
              prompt: "none",
              response_type: "permission", // Access Token.
              scope: SCOPES,
              login_hint: serviceData.company.googleUserBusiness.email
            },
            async function(result) {
              // console.log("business result", result);

              if (result) {
                // console.log("result", result);
                await gapi.auth.setToken({
                  access_token: result.access_token
                });
                let googleCalenderEvent = {
                  summary: serviceData.service.name,
                  location:
                    serviceData.company.location.street_address +
                    ", " +
                    serviceData.company.location.city +
                    ", " +
                    serviceData.company.location.province +
                    " " +
                    serviceData.company.location.postal,
                  description:
                    "Booking: " +
                    serviceData.service.name +
                    " - " +
                    serviceData.company.name,
                  start: {
                    dateTime:
                      moment(serviceData.start_date).format("YYYY-MM-DD") +
                      "T" +
                      moment(serviceData.start_time).format("HH:mm:ss"),
                    timeZone: moment.tz.guess()
                  },
                  end: {
                    dateTime:
                      moment(serviceData.start_date).format("YYYY-MM-DD") +
                      "T" +
                      moment(serviceData.start_time)
                        .add(serviceData.duration, "minutes")
                        .format("HH:mm:ss"),
                    timeZone: moment.tz.guess()
                  },
                  reminders: {
                    useDefault: true
                  }
                };
                await gapi.client.calendar.events
                  .insert({
                    calendarId: "primary",
                    resource: googleCalenderEvent
                  })
                  .then(async function(response) {
                    // console.log("business_calender", response);
                    updateBookingId.business_calender = response.result.id;
                    await api.put(
                      `/bookings/${res.data._id}`,
                      { business_calender: response.result.id },
                      config
                    );
                  });
              }
            }
          );
        });
      } catch (err) {
        console.log(err);
      }
    }
    const new_booking = await api.get(`/bookings/${res.data._id}`);

    dispatch({
      type: GET_BOOKING,
      payload: res.data
    });

    let message_text =
      "You have a booking for " +
      serviceData.service.name +
      " with " +
      serviceData.company.name +
      " on " +
      moment(serviceData.start_date).format("ddd, MMM Do") +
      " at " +
      moment(serviceData.start_time).format("h:mm a");

    // Send Message to current User
    dispatch(
      addMessage({
        title:
          "New Booking: " +
          serviceData.service.name +
          " - " +
          serviceData.company.name,
        text: message_text,
        type: "booking",
        user: null,
        booking: res.data._id,
        section: "user"
      })
    );

    const url_text = {
      text: encodeURIComponent(new_booking.data.text),
      start: encodeURIComponent(new_booking.data.start_time),
      end: encodeURIComponent(
        moment(new_booking.data.start_time).add(
          new_booking.data.duration,
          "minutes"
        )
      ),
      location: encodeURIComponent(
        serviceData.company.location.street_address +
          ", " +
          serviceData.company.location.city +
          ", " +
          serviceData.company.location.province +
          " " +
          serviceData.company.location.postal
      ),
      subject: encodeURIComponent(
        "Booking: " +
          serviceData.service.name +
          " - " +
          serviceData.company.name
      )
    };

    let service_details = "";
    await serviceData.intakeForm.map((value, i) => {
      if (value && value.field_type === "file") {
        service_details += ` <strong> ${value.field_label} : </strong> `;
        value.value.map(images => {
          service_details += `<a href='${images}' target="_blank"> ${images
            .split("/")
            .pop()} </a><br>`;
        });
      } else {
        service_details += ` <p><strong> ${value.field_label} : </strong>  <span > ${value.value} </span></p><br>`;
      }
    });

    // company message
    const company_message_html =
      '<div style="font-family: sans-serif;"><div style="text-align: center;padding-top: 20px"><img src="https://appointmentcake.com/wp-content/uploads/2020/11/Appt-Cake-logo.png" width="150" style="width: 150px;margin: auto;"></div><div><p>Hello ' +
      serviceData.company.name +
      ",</p><p>You got a new booking for " +
      serviceData.service.name +
      " from " +
      new_booking.data.user.firstName +
      " " +
      new_booking.data.user.lastName +
      '.</p></div><div style="border:1px solid #ccc; border-radius: 16px;padding: 16px;"><p><small>Date</small><br><strong>' +
      moment(serviceData.start_date).format("ddd, MMM Do") +
      " <span >@ " +
      moment(serviceData.start_time).format("h:mm a") +
      "</span></strong></p><p><small>Service</small><br><strong >" +
      serviceData.service.name +
      "</p>" +
      service_details +
      '<p style="text-align: center"><span>Add to Calendar</span><br><a href="https://outlook.office.com/calendar/0/deeplink/compose?body=' +
      url_text.text +
      "&enddt=" +
      url_text.end +
      "&location=" +
      url_text.location +
      "&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=" +
      url_text.start +
      "&subject=" +
      url_text.subject +
      '" target="_blank" style="color:white; background-color: #27d7cf; text-decoration: none; padding: 10px; border-radius: 10px; margin: 10px; display: inline-block">Outlook.com</a><a href="https://outlook.office.com/calendar/0/deeplink/compose?body=' +
      url_text.text +
      "&enddt=" +
      url_text.end +
      "&location=" +
      url_text.location +
      "&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=" +
      url_text.start +
      "&subject=" +
      url_text.subject +
      '" target="_blank" style="color:white; background-color: #27d7cf; text-decoration: none; padding: 10px; border-radius: 10px; margin: 10px; display: inline-block">Office 365</a><a href="https://calendar.google.com/calendar/render?action=TEMPLATE&dates=' +
      url_text.start +
      "&details=" +
      url_text.text +
      "&location=" +
      url_text.location +
      "&text=" +
      url_text.subject +
      '" target="_blank" style="color:white; background-color: #27d7cf; text-decoration: none; padding: 10px; border-radius: 10px; margin: 10px; display: inline-block">Google</a></p></div><p style="text-align: center"><a href="https://app.appointmentcake.com/" style="color: #27d7cf; font-size: 12px;">Sent via Appointment Cake</a></p></div>' +
      serviceData.intakeForm;

    // Send Message to company owner
    dispatch(
      addMessage({
        title:
          "New Booking: " +
          serviceData.service.name +
          " - " +
          serviceData.company.name,
        text: message_text,
        type: "booking",
        user: serviceData.company.user,
        booking: res.data._id,
        section: "business"
      })
    );

    /*
     * EMAIL: Booking Added
     * To: Company
     */
    let from_name =
      new_booking.data.user.firstName + " " + new_booking.data.user.lastName;
    let to_email = new_booking.data.company.email;
    let reply_email = new_booking.data.user.email;

    await emailjs
      .send(
        "hGMuQSKsSZCPalW__xIOfw",
        "template_anf3l5p",
        {
          from_name: from_name,
          to_mail: to_email,
          subject: "Appointment Cake | New Booking for " + serviceData.service.name,
          message_html: company_message_html,
          type: "booking",
          reply_to: reply_email
        },
        "user_xcizJCbK5pvQjdlT9l1dj"
      )
      .then(
        response => {
          console.log("SUCCESS!", response.status, response.text);
        },
        error => {
          console.log("Failed...", error);
        }
      );

    // user/bookie message
    const user_message_html =
      '<div style="font-family: sans-serif;"><a href="https://app.appointmentcake.com/"><div style="text-align: center;padding-top: 20px"><img src="https://appointmentcake.com/wp-content/uploads/2020/11/Appt-Cake-logo.png" width="150" style="width: 150px;margin: auto;"></div></a><div><p>Hello ' +
      new_booking.data.user.firstName +
      " " +
      new_booking.data.user.lastName +
      ",</p><p>Here are the details of your booking for " +
      serviceData.service.name +
      " with " +
      serviceData.company.name +
      '.</p></div><div style="border:1px solid #ccc; border-radius: 16px;padding: 16px;"><p><small>Date</small><br><strong>' +
      moment(serviceData.start_date).format("ddd, MMM Do") +
      " <span >@ " +
      moment(serviceData.start_time).format("h:mm a") +
      "</span></strong></p><p><small>Service</small><br><strong >" +
      serviceData.service.name +
      "</p>" +
      service_details +
      '<p style="text-align: center"><span>Add to Calendar</span><br><a href="https://outlook.office.com/calendar/0/deeplink/compose?body=' +
      url_text.text +
      "&enddt=" +
      url_text.end +
      "&location=" +
      url_text.location +
      "&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=" +
      url_text.start +
      "&subject=" +
      url_text.subject +
      '" target="_blank" style="color:white; background-color: #27d7cf; text-decoration: none; padding: 10px; border-radius: 10px; margin: 10px; display: inline-block">Outlook.com</a><a href="https://outlook.office.com/calendar/0/deeplink/compose?body=' +
      url_text.text +
      "&enddt=" +
      url_text.end +
      "&location=" +
      url_text.location +
      "&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=" +
      url_text.start +
      "&subject=" +
      url_text.subject +
      '" target="_blank" style="color:white; background-color: #27d7cf; text-decoration: none; padding: 10px; border-radius: 10px; margin: 10px; display: inline-block">Office 365</a><a href="https://calendar.google.com/calendar/render?action=TEMPLATE&dates=' +
      url_text.start +
      "&details=" +
      url_text.text +
      "&location=" +
      url_text.location +
      "&text=" +
      url_text.subject +
      '" target="_blank" style="color:white; background-color: #27d7cf; text-decoration: none; padding: 10px; border-radius: 10px; margin: 10px; display: inline-block">Google</a></p></div><p style="text-align: center"><a href="https://app.appointmentcake.com/" style="color: #27d7cf; font-size: 12px;">Sent via Appointment Cake</a></p></div>';
    /*
     * EMAIL: Booking Added
     * To: User
     */
    from_name = new_booking.data.company.name;
    to_email = new_booking.data.user.email;
    reply_email = new_booking.data.company.email;

    await emailjs
      .send(
        "hGMuQSKsSZCPalW__xIOfw",
        "template_anf3l5p",
        {
          from_name: from_name,
          to_mail: to_email,
          subject: "Appointment Cake | Your Booking for " + serviceData.service.name,
          message_html: user_message_html,
          type: "booking",
          reply_to: reply_email
        },
        "user_xcizJCbK5pvQjdlT9l1dj"
      )
      .then(
        response => {
          console.log("SUCCESS!", response.status, response.text);
        },
        error => {
          console.log("Failed...", error);
        }
      );

    dispatch(setAlert("Booking Created", "success"));
    history.push("/dashboard");
  } catch (err) {
    console.log(err);
    /*const errors = err ? err.response.data.errors : false;

		if (errors) {
			errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
		}

		dispatch({
			type: BOOKING_ERROR,
			payload: {
				msg: err.response.statusText,
				status: err.response.status,
			},
		});*/
  }
};

const sendBookingEmails = async (bookingData) => {
  try {
    // Client Email
    let clientHtmlTemplate = clientBookingEmailTemplate;
    const booking = bookingData.data

    const clientAppointmentDate = moment(booking.start_time).format("dddd, MMMM D, YYYY");
    const clientAppointmentTime = moment(booking.start_time).format("h:mm a");
    const clientCompanyName = booking.company.name;
    const clientCompanyPhone = booking.company.phone || '';
    const clientCompanyAddress = booking.company.location?.street_address  || '';
    const clientUserName = booking.user.firstName || 'there';
    const clientBookingLink = `https://app.appointmentcake.com/company/${booking.company._id}`;

    clientHtmlTemplate = clientHtmlTemplate.replace(/{{appointmentDate}}/g, clientAppointmentDate);
    clientHtmlTemplate = clientHtmlTemplate.replace(/{{appointmentTime}}/g, clientAppointmentTime);
    clientHtmlTemplate = clientHtmlTemplate.replace(/{{companyName}}/g, clientCompanyName);
    clientHtmlTemplate = clientHtmlTemplate.replace(/{{companyPhone}}/g, clientCompanyPhone);
    clientHtmlTemplate = clientHtmlTemplate.replace(/{{companyAddress}}/g, clientCompanyAddress);
    clientHtmlTemplate = clientHtmlTemplate.replace(/{{userName}}/g, clientUserName);
    clientHtmlTemplate = clientHtmlTemplate.replace(/{{bookingLink}}/g, clientBookingLink);

    const clientEmailText = `
    Congratulations!
    
    You just booked an appointment with ${clientCompanyName} on ${clientAppointmentDate} at ${clientAppointmentTime}.
    
    Cancel or reschedule your appointment here: My Dashboard: https://app.appointmentcake.com/dashboard
    
    Stay Cool,
    The Appointment Cake Team 🧁
    
    Need some help? Email help@appointmentcake.com
    `;

    const clientEmailData = {
      to: booking.user.email,
      subject: `Your Booking for ${booking.service.name}`,
      html: clientHtmlTemplate,
      text: clientEmailText,
    };

    await api.post('/bookings/send-booking-email', clientEmailData);

    // Owner Email
    let ownerHtmlTemplate = ownerBookingEmailTemplate;

    const ownerAppointmentDate = moment(booking.start_time).format("dddd, MMMM D, YYYY");
    const ownerAppointmentTime = moment(booking.start_time).format("h:mm a");
    const ownerCompanyName = booking.company.name;
    const ownerCompanyPhone = booking.company.phone || '';
    const ownerCompanyAddress = booking.company.location?.street_address || '';
    const ownerUserName = booking.user.firstName || 'there';
    const ownerBookingLink = `https://app.appointmentcake.com/company/${booking.company._id}`;
    const clientPhone = booking.user.phone || '';
    const clientEmail = booking.user.email || '';

    const serviceNameText = booking.service?.name? booking.service.name : ownerCompanyName;
    ownerHtmlTemplate = ownerHtmlTemplate.replace(/{{appointmentDate}}/g, ownerAppointmentDate);
    ownerHtmlTemplate = ownerHtmlTemplate.replace(/{{appointmentTime}}/g, ownerAppointmentTime);
    ownerHtmlTemplate = ownerHtmlTemplate.replace(/{{companyName}}/g, ownerCompanyName);
    ownerHtmlTemplate = ownerHtmlTemplate.replace(/{{clientPhone}}/g, clientPhone );
    ownerHtmlTemplate = ownerHtmlTemplate.replace(/{{clientEmail}}/g, clientEmail );
    ownerHtmlTemplate = ownerHtmlTemplate.replace(/{{companyAddress}}/g, ownerCompanyAddress);
    ownerHtmlTemplate = ownerHtmlTemplate.replace(/{{userName}}/g, ownerUserName);
    ownerHtmlTemplate = ownerHtmlTemplate.replace(/{{bookingLink}}/g, ownerBookingLink);
    ownerHtmlTemplate = ownerHtmlTemplate.replace(/{{serviceName}}/g, serviceNameText);
    ownerHtmlTemplate = ownerHtmlTemplate.replace(/{{clientName}}/g, `${booking.user.firstName} ${booking.user.lastName}`);

      const ownerEmailText = `
    Congratulations!
    
    ${booking.user.firstName} ${booking.user.lastName} just booked an appointment with you for ${booking.service.name} on ${ownerAppointmentDate} at ${ownerAppointmentTime}!
    
    Stay Cool,
    The Appointment Cake Team 🧁
    
    Need some help? Email help@appointmentcake.com
    `;

    const ownerEmailData = {
      to: booking.company.email,
      subject: `New Booking for ${serviceNameText}`,
      html: ownerHtmlTemplate,
      text: ownerEmailText,
    };

    await api.post('/bookings/send-booking-email', ownerEmailData);

    console.log('Booking emails sent successfully');
  } catch (error) {
    console.error('Error sending booking emails:', error);
  }
};


// Check the waitlist for matching entries and notify users
const checkWaitlist = async (booking) => {
  try {
    const companyId = booking.company._id;
    const day = moment(booking.start_time).format('dddd');
    const period = getTimePeriod(booking.start_time);

    // Fetch waitlist entries that match the company, day, and period
    const response = await api.get(`/waitlist/company/${companyId}/day/${day}/period/${period}`);
    const waitlistEntries = response.data;

    // Notify users based on their preferences
    await notifyUsers(waitlistEntries, booking);
  } catch (error) {
    console.error('Error checking waitlist:', error);
  }
};

const notifyUsers = async (waitlistEntries, booking) => {
  try {
    const currentDate = new Date();

    for (const entry of waitlistEntries) {
      const createdAt = new Date(entry.createdAt);
      const duration = entry.duration;

      // Calculate the expiration date
      let expirationDate;
      if (duration === "1 Week") {
        expirationDate = new Date(createdAt.setDate(createdAt.getDate() + 7));
      } else if (duration === "2 Weeks") {
        expirationDate = new Date(createdAt.setDate(createdAt.getDate() + 14));
      } else if (duration === "1 Month") {
        expirationDate = new Date(createdAt.setMonth(createdAt.getMonth() + 1));
      }

      // Check if the waitlist entry is expired
      if (currentDate > expirationDate) {
        // Delete the expired waitlist entry
        await api.delete(`/waitlist/${entry._id}`);
        continue;
      }

      // Determine the booking time period
      const bookingTimePeriod = getTimePeriod(booking.start_time);

      // Send notifications if the entry is still valid and matches the booking time period
      if (entry.day === moment(booking.start_time).format('dddd') && entry.period === bookingTimePeriod) {
        if (entry.preferences.notifyByEmail && entry.email) {
          await sendEmailNotification(entry, booking);
        }

        if (entry.preferences.notifyByText && entry.phone) {
          await sendTextNotification(entry, booking);
        }
      }
    }
  } catch (error) {
    console.error('Error notifying users:', error);
  }
};




const sendEmailNotification = async (entry, booking) => {
  console.log('EMAIL ENTRY ', entry);
  console.log('EMAIL BOOKING Booking:', booking);

  // HTML template as a string
  let htmlTemplate = waitlistDateEmailTemplate;

  // Replace placeholders with actual data
  const appointmentDate = moment(booking.start_time).format("dddd, MMMM D, YYYY");
  const appointmentTime = moment(booking.start_time).format("h:mm a");
  const companyName = booking.company.name;
  const companyPhone = booking.company.phone || '';
  const companyAddress = booking.company.address || '';
  const userName = entry.firstName || 'there';
  const bookingLink = `https://app.appointmentcake.com/company/${booking.company._id}`;

  htmlTemplate = htmlTemplate.replace(/{{appointmentDate}}/g, appointmentDate);
  htmlTemplate = htmlTemplate.replace(/{{appointmentTime}}/g, appointmentTime);
  htmlTemplate = htmlTemplate.replace(/{{companyName}}/g, companyName);
  htmlTemplate = htmlTemplate.replace(/{{companyPhone}}/g, companyPhone);
  htmlTemplate = htmlTemplate.replace(/{{companyAddress}}/g, companyAddress);
  htmlTemplate = htmlTemplate.replace(/{{userName}}/g, userName);
  htmlTemplate = htmlTemplate.replace(/{{bookingLink}}/g, bookingLink);

  const emailData = {
    to: entry.email,
    subject: `Waitlisted ${companyName} has availability`,
    text: `Hi there, ${companyName} has availability on ${appointmentDate} at ${appointmentTime}, and you are on the waitlist! Book now: ${bookingLink}`,
    html: htmlTemplate,
  };

  try {
    await api.post('/bookings/send-waitlist-email', emailData);
    // console.log('Email notification sent successfully');
  } catch (error) {
    console.error('Error sending email notification:', error);
  }
};



// Send text notification
const sendTextNotification = async (entry, booking) => {
  const phone = entry.phone.startsWith('1') ? `+${entry.phone}` : `+1${entry.phone}`;
  const body = `A business on your Appointment Cake waitlist has a new availability! ${booking.company.name} has availability on ${moment(booking.start_time).format("ddd, MMM Do")} at ${moment(booking.start_time).format("h:mm a")}`;
  // console.log(`Sending text to ${phone}: ${booking.company.name} has availability on ${moment(booking.start_time).format("ddd, MMM Do")} at ${moment(booking.start_time).format("h:mm a")}.`);
  try {
    await api.post('/messages/sendsms', { phone, body }); // Corrected this line
  } catch (error) {
    console.error('Error sending text notification:', error);
  }
};



const getTimePeriod = (startTime) => {
  const hour = moment(startTime).hour();
  if (hour < 12) return 'morning';
  if (hour < 17) return 'afternoon';
  return 'evening';
};



// Function to check waitlist entries and notify users based on hours change
export const checkWaitlistOnHoursChange = async (availWaitlistTimeSlots) => {
  try {
    const notifications = {};

    for (const slot of availWaitlistTimeSlots) {
      const { day, timeSlot, companyId } = slot;

      // Fetch the company name by the company ID
      const companyResponse = await api.get(`/company/${companyId}`);
      const companyName = companyResponse.data.name;

      // Fetch waitlist entries that match the company, day, and time slot
      const response = await api.get(`/waitlist/company/${companyId}/day/${day}/period/${timeSlot}`);
      const waitlistEntries = response.data;

      for (const entry of waitlistEntries) {
        const key = `${entry.email}-${entry.phone}`;

        if (!notifications[key]) {
          notifications[key] = {
            email: entry.email,
            phone: entry.phone,
            companyId,
            companyName,
            slots: [],
          };
        }

        notifications[key].slots.push({ day, timeSlot });
      }
    }

    await notifyUsersOnHoursChange(Object.values(notifications));
  } catch (error) {
    console.error('Error checking waitlist on hours change:', error);
  }
};

// Function to notify users based on their preferences on hours change
const notifyUsersOnHoursChange = async (notifications) => {
  try {
    for (const notification of notifications) {
      const { email, phone, companyId, companyName, slots } = notification;
      const timeSlotsText = slots
        .map((slot) => `${slot.day} ${slot.timeSlot}`)
        .join('; ');

      if (email) {
        await sendEmailNotificationOnHoursChange(email, companyName, timeSlotsText, companyId);
        // console.log("sending email:", email, companyName, timeSlotsText, companyId)
      }

      if (phone) {
        await sendTextNotificationOnHoursChange(phone, companyName, timeSlotsText, companyId);
        // console.log("sending phone:", phone, companyName, timeSlotsText, companyId)
      }
    }
  } catch (error) {
    console.error('Error notifying users on hours change:', error);
  }
};

// Send email notification on hours change
const sendEmailNotificationOnHoursChange = async (email, companyName, timeSlotsText, companyId) => {
  const emailData = {
    to: email,
    subject: `Updated hours for ${companyName}`,
    text: `Good news! A business you have waitlisted, ${companyName}, has updated their hours of operation. There are new time slots available: ${timeSlotsText}.`,
    html: `
      <p>Good news!</p>
      <p>A business you have waitlisted, ${companyName}, has updated their hours of operation. There are new time slots available: ${timeSlotsText}.</p>
    `,
  };

  try {
    await api.post('/bookings/send-waitlist-email', emailData);
  } catch (error) {
    console.error('Error sending email notification:', error);
  }
};

// Send text notification on hours change
const sendTextNotificationOnHoursChange = async (phone, companyName, timeSlotsText, companyId) => {
  const formattedPhone = phone.startsWith('1') ? `+${phone}` : `+1${phone}`;
  const body = `Good news! A business you have waitlisted, ${companyName}, has updated their hours of operation. There are new time slots available: ${timeSlotsText}.`;

  try {
    await api.post('/messages/sendsms', { phone: formattedPhone, body });
  } catch (error) {
    console.error('Error sending text notification:', error);
  }
};


// Function to send waitlist signup emails
export const sendWaitlistSignupEmails = async ({
  firstName,
  lastName,
  userEmail,
  companyName,
  companyEmail,
}) => {
  console.log('Sending emails for user:', firstName, lastName);
  console.log('Company:', companyName);

  // Prepare the client email template
  let clientEmailHtml = waitlistClientSignup;
  clientEmailHtml = clientEmailHtml.replace(/{{companyName}}/g, companyName);

  // Prepare the owner email template
  let ownerEmailHtml = waitlistOwnerSignup;
  ownerEmailHtml = ownerEmailHtml.replace(/{{clientName}}/g, `${firstName} ${lastName}`);

  // Client email data
  const clientEmailData = {
    to: userEmail,
    subject: `You have joined the waitlist at ${companyName}`,
    text: `Hi ${firstName}, you have successfully signed up for the waitlist at ${companyName}. We will notify you when an appointment becomes available.`,
    html: clientEmailHtml,
  };

  // Owner email data
  const ownerEmailData = {
    to: companyEmail,
    subject: `${firstName} ${lastName} joined your waitlist!`,
    text: `${firstName} ${lastName} has successfully signed up for the waitlist at your company, ${companyName}.`,
    html: ownerEmailHtml,
  };

  try {
    // Send email to the client
    await api.post('/bookings/send-waitlist-email', clientEmailData);
    console.log('Client email sent successfully');

    // Send email to the owner
    await api.post('/bookings/send-waitlist-email', ownerEmailData);
    console.log('Owner email sent successfully');
  } catch (error) {
    console.error('Error sending waitlist signup emails:', error);
  }
};

// deleteBooking
export const deleteBooking = (id, history, text) => async dispatch => {
  // if (window.confirm('Are you sure you want to cancel your appointment?')) {
  try {
    // Store booking before deletion to use in messages
    const prev_booking = await api.get(`/bookings/${id}`);

    // Delete booking
    const res = await api.delete(`/bookings/${id}`);

     // Check the waitlist for any matching entries
     await checkWaitlist(prev_booking.data);

    // console.log(JSON.stringify(res));
    history.push("/dashboard");
    // Go back to the dashboard before updating state to avoid error on single booking page
    dispatch({
      type: UPDATE_BOOKING,
      payload: res.data
    });
    var gapi = window.gapi;

    if (
      prev_booking.data.company.user.googleAuthBusiness !== undefined &&
      prev_booking.data.company.user.googleAuthBusiness !== null
    ) {
      try {
        await gapi.load("client:auth2", async () => {
          await gapi.auth.authorize(
            {
              client_id: CLIENT_ID,
              prompt: "none",
              response_type: "permission", // Access Token.
              scope: SCOPES,
              login_hint:
                prev_booking.data.company.user.googleUserBusiness.email
            },
            async function(result) {
              if (result) {
                // console.log("result", result);
                await gapi.auth.setToken({
                  access_token: result.access_token
                });
                await gapi.client.calendar.events
                  .delete({
                    calendarId: "primary",
                    eventId: prev_booking.data.business_calender
                  })
                  .then(async function(response) {});
              }
            }
          );
        });
      } catch (err) {
        console.log(err);
      }
    }
    if (
      prev_booking.data.user.googleAuth !== undefined &&
      prev_booking.data.user.googleAuth !== null
    ) {
      try {
        await gapi.load("client:auth2", async () => {
          await gapi.auth.authorize(
            {
              client_id: CLIENT_ID,
              prompt: "none",
              response_type: "permission", // Access Token.
              scope: SCOPES,
              login_hint: prev_booking.data.user.googleUser.email
            },
            async function(result) {
              if (result) {
                // console.log("resultresult", result);
                await gapi.auth.setToken({
                  access_token: result.access_token
                });

                await gapi.client.calendar.events
                  .delete({
                    calendarId: "primary",
                    eventId: prev_booking.data.user_calender
                  })
                  .then(async function(response) {});
              }
            }
          );
        });
      } catch (err) {
        console.log(err);
      }
    }
    /*
     * EMAIL: Booking Deleted
     * To: Company Owner
     */
    const from_name =
      prev_booking.data.user.firstName + " " + prev_booking.data.user.lastName;
    const to_email = prev_booking.data.company.email;
    const reply_email = prev_booking.data.user.email;
    const user_message_html =
      '<div style="font-family: sans-serif;"><div style="text-align: center;padding-top: 20px"><img src="https://appointmentcake.com/wp-content/uploads/2020/11/Appt-Cake-logo.png" width="150" style="width: 150px;margin: auto;"></div><div><p>Hello ' +
      prev_booking.data.user.firstName +
      " " +
      prev_booking.data.user.lastName +
      ",</p><p>Here are the details of your booking cancel for " +
      prev_booking.data.service.name +
      " with " +
      prev_booking.data.company.name +
      '.</p></div><div style="border:1px solid #ccc; border-radius: 16px;padding: 16px;"><p><small>Date</small><br><strong>' +
      moment(prev_booking.data.start_date).format("ddd, MMM Do") +
      " <span >@ " +
      moment(prev_booking.data.start_time).format("h:mm a") +
      "</span></strong></p><p><small>Service</small><br><strong >" +
      prev_booking.data.service.name +
      "</strong></p><small>Notes</small><br><p>" +
      prev_booking.data.text +
      "</p><small>Cancel Reason</small><br><p>" +
      text +
      '</p></div><p style="text-align: center"><a href="https://app.appointmentcake.com/" style="color: #27d7cf; font-size: 12px;">Sent via Appointment Cake</a></p></div>';
    emailjs
      .send(
        "hGMuQSKsSZCPalW__xIOfw",
        "template_anf3l5p",
        {
          from_name: from_name,
          to_mail: to_email,
          subject: "Appointment Cake | " + from_name + " canceled their booking",
          message_html: user_message_html,
          type: "cancelation",
          reply_to: reply_email
        },
        "user_xcizJCbK5pvQjdlT9l1dj"
      )
      .then(
        response => {
          console.log("SUCCESS!", response.status, response.text);
        },
        error => {
          console.log("Failed...", error);
        }
      );

    dispatch(setAlert("Booking Canceled", "success"));
  } catch (err) {
    console.log(err);

    // dispatch({
    // 	type: BOOKING_ERROR,
    // 	payload: {
    // 		msg: err.response.statusText,
    // 		status: err.response.status,
    // 	},
    // });
  }
  // }
};

// add user intake form value
export const addIntakeForm = intakeForm => async (dispatch, getState) => {
  try {
    // const config = {
    //   headers: {
    //     "Content-Type": "application/json"
    //   }
    // };
    let userState = getState().auth.user;
    let fieldname = new Set(intakeForm.intakeForm.map(d => d.field_name));
    let new_intakeform = [
      ...intakeForm.intakeForm,
      ...userState.intakeFormFields.filter(d => !fieldname.has(d.field_name))
    ];
    intakeForm.intakeForm = new_intakeform;
    userState.intakeFormFields = new_intakeform;
    intakeForm.user = getState().auth.user;
    intakeForm.intakeForm = new_intakeform;
    await api.put("/auth/intakeForm", intakeForm);

    dispatch({
      type: UPDATE_USER,
      payload: userState //res.data
    });
  } catch (err) {
    console.log("----", err);
  }
};
// Add Comment
export const addComment = (postId, formData) => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    const res = await api.post(`/bookings/comment/${postId}`, formData, config);

    const auth = await api.get("/auth");

    // console.log("res.data : ", res.data);
    /*
     * EMAIL: Comment Added
     * To: Either Owner or Booker
     */
    const comments_length =
      res.data.comments.length > 0 ? res.data.comments.length - 1 : 0;

    const send_user =
      res.data.company.user?._id !== auth.data?._id
        ? res.data.company
        : res.data.user;
    const from_name = res.data.comments[comments_length].name;
    const to_email = send_user.email;
    const reply_email =
      res.data.company.user?._id !== auth.data?._id
        ? res.data.user.email
        : res.data.company.email;
    let message_text = res.data.comments[comments_length].text;
    if (formData.UploadImage && formData.UploadImage.length > 0) {
      message_text += "<br>";
      formData.UploadImage.map(images => {
        message_text += `<a href='${images}' target="_blank">${images
          .split("/")
          .pop()} </a><br>`;
      });
    }
    // console.log("===", {
    //   from_name: from_name,
    //   to_mail: to_email,
    //   subject: "Appointment Cake | New Message from " + from_name,
    //   message_html: message_text,
    //   type: "message",
    //   reply_to: reply_email
    // });
    emailjs
      .send(
        "hGMuQSKsSZCPalW__xIOfw",
        "template_anf3l5p",
        {
          from_name: from_name,
          to_mail: to_email,
          subject: "Appointment Cake | New Message from " + from_name,
          message_html: message_text,
          type: "message",
          reply_to: reply_email
        },
        "user_xcizJCbK5pvQjdlT9l1dj"
      )
      .then(
        response => {
          console.log("SUCCESS!", response.status, response.text);
        },
        error => {
          console.log("Failed...", error);
        }
      );

    dispatch(
      addMessage({
        title: "New Comment from: " + from_name,
        text: message_text,
        type: "comment",
        user: res.data.user._id,
        booking: res.data._id,
        section: "user"
      })
    );

    dispatch(
      addMessage({
        title: "New Comment from: " + from_name,
        text: message_text,
        type: "comment",
        user: res.data.company.user._id,
        booking: res.data._id,
        section: "business"
      })
    );
    dispatch({
      type: ADD_COMMENT,
      payload: res.data.comments
    });

    dispatch(setAlert("Booking Comment Added", "success"));
  } catch (err) {
    // console.log("error : ", err);
    dispatch({
      type: BOOKING_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status
      }
    });
  }
};

// Delete Comment
export const deleteComment = (bookingId, commentId) => async dispatch => {
  try {
    await api.delete(`/bookings/comment/${bookingId}/${commentId}`);

    dispatch({
      type: REMOVE_COMMENT,
      payload: commentId
    });
    dispatch(setAlert("Booking Comment Removed", "success"));
  } catch (err) {
    console.log(err);
    dispatch({
      type: BOOKING_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status
      }
    });
  }
};

export const clearHistory = () => async dispatch => {
  try {
    const res = await api.delete(`/bookings`);
    dispatch({
      type: CLEAR_HISTORY,
      payload: []
    });
    dispatch(setAlert(res.data.msg, "success"));
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
    }
    // console.log("err : ", err);
    dispatch({
      type: BOOKING_ERROR,
      payload: {
        msg: err.response.msg,
        status: err.response.status
      }
    });
  }
};

export const clearHistoryByBusiness = BusinessId => async dispatch => {
  try {
    const res = await api.post(`/bookings/${BusinessId}`);

    dispatch({
      type: CLEAR_HISTORY,
      payload: []
    });
    dispatch(setAlert(res.data.msg, "success"));
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
    }
    // console.log("err : ", err);
    dispatch({
      type: BOOKING_ERROR,
      payload: {
        msg: err.response.msg,
        status: err.response.status
      }
    });
  }
};
