import React, { Fragment, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navbar from "./components/layout/Navbar";
import Landing from "./components/layout/Landing";
import Routes from "./components/routing/Routes";
import { PersistGate } from "redux-persist/integration/react";
import { LOGOUT } from "./actions/types";

// Redux
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import { loadUser } from "./actions/auth";
import setAuthToken from "./utils/setAuthToken";

import "./App.css";
import "./media.css";
import "./global.css";
// import SignupSuccess from './components/auth/SignupSuccess';
// import DeleteAccountSuccess from './components/auth/DeleteAccountSuccess';
// import SuccessPage from "./components/auth/ForgotSuccess";

const App = () => {
  useEffect(() => {
    // check for token in LS
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    store.dispatch(loadUser());

    // log user out from all tabs if they log out in one tab
    window.addEventListener("storage", () => {
      if (!localStorage.token) store.dispatch({ type: LOGOUT });
    });
  }, []);

  const [tour, setTour] = useState(false);
  return (
    <Provider store={store}>
      <Router>
        <PersistGate persistor={persistor}>
          {/* <Route exact path="/signup-success" component={SignupSuccess} />
          <Route exact path="/delete-account" component={DeleteAccountSuccess} />
          <Route exact path="/success-page" component={SuccessPage} /> */}
          <div className="flex ">
        
              <Navbar />
            
              <Switch>
                <Route exact path="/" component={Landing} />
                <Route
                  component={() => <Routes tour={tour} setTour={setTour} />}
                />
              </Switch>
            </div>
        </PersistGate>
      </Router>
    </Provider>
  );
};

export default App;
