import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import CompanyItem from "../companies/CompanyItem";
import BlueMarker from "../../images/pin-icon-png-9.png";
import WhiteMarker from "../../images/pin-icon-png-white.png";

const markerStyle = {
  position: "absolute",
  width: "48px",
  height: "47px",
  backgroundImage: `url(${BlueMarker})`,
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  transform: "translate(-50%,-50%)",
  cursor: "grab"
};

const defaultMarkerStyle = {
  position: "absolute",
  width: "38px",
  height: "37px",
  backgroundImage: `url(${WhiteMarker})`,
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  transform: "translate(-50%,-50%)",
  cursor: "grab"
};

const disableMarkerStyle = {
  position: "absolute",
  width: "15px",
  height: "15px",
  backgroundColor: "rgb(94 91 91)",
  borderRadius: "100%",
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  transform: "translate(-50%,-50%)",
  // cursor: 'grab'
  "&:hover": {
    backgroundColor: "red"
  }
};

// const disableMarkerStyle:hover = {
//   border: 2px,solid,red;
// }

const Marker = ({
  show,
  company,
  isdefault,
  text,
  onClick,
  isFiltered,
  $hover,
  onMarkerHover,
  onMarkerHoverLeave
}) => {
  const MarkerHover = () => {
    if (company != undefined) {
      company.isMarkerHover = true;
      onMarkerHover(company);
    }
  };

  const MarkerHoverLeave = () => {
    if (company != undefined) {
      company.isMarkerHover = false;
      onMarkerHoverLeave(company);
    }
  };

  return (
    <Fragment>
      <div
        className={
          !isdefault && !isFiltered && company?.isHover == true
            ? "markerHover"
            : ""
        }
        onMouseEnter={MarkerHover}
        onMouseLeave={MarkerHoverLeave}
        style={
          isdefault
            ? defaultMarkerStyle
            : isFiltered
            ? disableMarkerStyle
            : markerStyle
        }
        alt={text}
        onClick={onClick}
      ></div>
      {!isFiltered && show && (
        <div
          style={{
            backgroundColor: "#fff",
            width: "30em",
            transform: "translate(-50%,-110%)",
            zIndex: 100,
            position: "relative"
          }}
        >
          {company && <CompanyItem key={company} company={company} />}
        </div>
      )}
    </Fragment>
  );
};

Marker.defaultProps = {
  onClick: null,
  show: false
};

Marker.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  show: PropTypes.bool.isRequired
};

export default Marker;
