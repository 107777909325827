import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
// import Moment from "react-moment";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faMobileScreenButton
} from "@fortawesome/pro-regular-svg-icons";
const BookingItem = ({
  booking: { _id, user, company, service, start_time, comments }
}) => {
  const [phoneNumber, setPhoneNumber] = useState();
  let appt_date = moment(start_time);

  let date =
    moment(appt_date).format("MMM Do") +
    " @ " +
    moment(appt_date).format("h:mm a");
  // const history = useHistory();

  // const str = company?.phone;

  let formatPhoneNumber = str => {
    //Filter only numbers from the input
    let cleaned = ("" + str)?.replace(/\D/g, "");

    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match == null || undefined) {
      setPhoneNumber(company?.phone);
    } else {
      let FormatedNumber = +match[1] + "-" + match[2] + "-" + match[3];
      setPhoneNumber(FormatedNumber);
      if (match) {
        return FormatedNumber;
      }
      return null;
    }
  };

  useEffect(() => {
    formatPhoneNumber(company?.phone);
  }, []);

  return (
    <>
      <tr className="booking-item-row appo_table flex_column" id={_id}>
        <td className="booking-date">
          <Link to={`/bookings/${_id}`} className="small">
            <div className="upcom_date">{date}</div>
          </Link>
        </td>
        {/* <td className="booking-service-name">
        <Link to={`/bookings/${_id}`} className="font-weight-bold">
          {service.name}
        </Link>
        <br />
        <Link to={`/company/${company._id}`} className="small">
          {company.name}
        </Link>
      </td> */}
        {/* <td>
        {user.firstName} {user.lastName}
      </td> */}
        {/* <td className="booking-notes">
        <Link to={`/bookings/${_id}`} className="btn btn-sm	 btn-primary">
          <i className="far fa-comments"></i>{" "}
          {comments.length > 0 && (
            <span className="comments-length badge badge-pill badge-light">
              {comments.length}
            </span>
          )}
        </Link>
      </td> */}
        <td className="service_info">
          <i>
            <Link to={`/bookings/${_id}`} className="small">
              <p>{company?.name}&nbsp;-&nbsp;</p>
            </Link>
            <Link to={`/bookings/${_id}`} className="small">
              <p>{' '} {service?.name}</p>
            </Link>
          </i>
          <p>
            <FontAwesomeIcon icon={faLocationDot} />{" "}
            {/* <i className="fa-solid fa-location-dot"></i> */}
            {company?.location.street_address}
            <Link
              to={{
                pathname: "/find",
                search:
                  "?" +
                  new URLSearchParams({
                    // search: params.get("search"),
                    lat: company?.location.lat,
                    lng: company?.location?.lng,
                    zoom: 13
                  }).toString()
              }}
            >
              {" "}
              see map
            </Link>
          </p>
          <p>
            <FontAwesomeIcon icon={faMobileScreenButton} />{" "}
            {/* <i class="fa-solid fa-mobile-screen-button"></i> */}
            {phoneNumber} <a href={`tel:${phoneNumber}`}>call</a>
          </p>
        </td>
        {/* <td className="tbl-info">
        <span>
          <i className="fa-solid fa-location-dot"></i>
          <p>{company?.location?.street_address}</p>
          <Link
            to={{
              pathname: "/find",
              search:
                "?" +
                new URLSearchParams({
                  // search: params.get("search"),
                  lat: company.location.lat,
                  lng: company?.location?.lng,
                  zoom: 13
                }).toString()
            }}
          >
            see map
          </Link>
        </span>
      </td> */}
        {/* <td className="tbl-info">
        <span>
          <i className="fa-solid fa-mobile-screen-button"></i>
          <p>{company?.phone}</p>
          <a href={`tel:${company?.phone}`}>call</a>
        </span>
      </td> */}

        {/* <td className="tbl-info app_history_icon">
          <a
            onClick={() => {
              history.push(`/bookings/${_id}`);
            }}
          >
            <FontAwesomeIcon
              icon={faMemoCircleInfo}
              style={{ color: "#25d2c3", fontSize: "27px" }}
            />
          </a>
        </td> */}
      </tr>
    </>
  );
};

BookingItem.propTypes = {
  booking: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  null
)(BookingItem);
