import React, { useEffect, useState, useRef } from "react";
import Banner from "../../../images/step4.png";
import Plus from "../../../images/plus.svg";
import Delete from "../../../images/delete.svg";
import { business_register } from "../../../actions/auth";
import { Accordion, Card } from "react-bootstrap";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

const AccordionComponent = ({
  services,
  handleToggle,
  setServices,
  activeIndex,
  fieldinitiaValues,
  editFields,
  setEditFields,
  setActiveIndex,
}) => {
  const accordionRef = useRef(null);

  const [onlineField, setOnlineField] = useState(
    services[activeIndex]?.intakeForm
      ? services[activeIndex]?.intakeForm
      : fieldinitiaValues
  );

  const onServiceEdit = (e, index) => {
    const updatedServiceList = [...services];

    updatedServiceList[index] = {
      ...updatedServiceList[index],
      [e.target.name]: e.target.value,
    };
    setServices(updatedServiceList);
  };

  const editField = (e) => {
    setOnlineField([
      ...onlineField,
      {
        field_category: "",
        field_name: "",
        is_required: false,
        field_label: "",
        field_type: "",
        online_fieldnames: [],
        selected: "",
        option: "",
        formfield: "",
      },
    ]);
    setEditFields([
      ...editFields,
      {
        field_category: "",
        field_name: "",
        is_required: false,
        field_label: "",
        field_type: "",
        online_fieldnames: [],
        selected: "",
        option: "",
        formfield: "",
      },
    ]);
  };

  const deleteEditField = (i) => {
    let formValues = onlineField;
    formValues.splice(i, 1);
    setOnlineField([...formValues]);
    setEditFields([...formValues]);
  };

  const editOnisReqChange = (e, i) => {
    let fields = [...editFields];
    let field = { ...fields[i] };

    field.is_required = !field.is_required;
    fields[i] = field;
    setEditFields(fields);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        accordionRef.current &&
        !accordionRef.current.contains(event.target)
      ) {
        setActiveIndex(null);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const onDeleteService = (index) => {
    const updatedServices = [...services];

    updatedServices.splice(index, 1);

    setServices(updatedServices);
  };
  return (
    <Accordion
      className="d-flex flex-column gap"
      activeKey={activeIndex?.toString()}
      ref={accordionRef}
    >
      {services.map((value, Index) => (
        <Card key={Index} className="border-10 mb-3">
          <Accordion.Toggle
            variant="link"
            eventKey={Index.toString()}
            onClick={() => {
              handleToggle(Index);
            }}
            className="border-0 p-0"
          >
            <div className="form-row first-input mb-3">
              <div className="col-sm-12 calendar-btn border-0">
                <button type="button" className="business-form-btn">
                  {value?.name}
                </button>
                <div
                  className="plusbtnclass"
                  onClick={() => onDeleteService(Index)}
                >
                  <img src={Delete} alt="delete icon" />
                </div>
              </div>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={Index.toString()}>
            <Card.Body>
              <div className="d-flex flex-column gap">
                <div className="form-row first-input mb-3">
                  <div className="col-sm-12">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Service Name"
                      name="name"
                      maxLength="50"
                      value={value.name}
                      onChange={(e) => onServiceEdit(e, Index)}
                      required
                    />
                  </div>
                </div>
                <div className="form-row first-input mb-3">
                  <div className="col-sm-12">
                    <textarea
                      className="form-control"
                      type="textarea"
                      placeholder="Service Description"
                      name="description"
                      value={value.description}
                      onChange={(e) => onServiceEdit(e, Index)}
                    />
                  </div>
                </div>
                <div className="form-row first-input mb-3">
                  <div className="col-sm-6 mb-3 mb-md-0">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Service Price"
                      name="price"
                      value={value.price}
                      onChange={(e) => onServiceEdit(e, Index)}
                    />
                  </div>
                  <div className="col-sm-6">
                    <select
                      className="form-control"
                      name="service_duration"
                      placeholder="Duration"
                      id="service-service_duration"
                      onChange={(e) => onServiceEdit(e, Index)}
                    >
                      <option value="15">15 minutes</option>
                      <option value="30">30 minutes</option>
                      <option value="45">45 minutes</option>
                      <option value="60">60 minutes</option>
                      <option value="90">90 minutes</option>
                      <option value="120">120 minutes</option>
                    </select>
                  </div>
                </div>

                <p className="form-title m-0 text-left">Booking Method</p>
                <div className="flex-column gap-1">
                  <div className="contact-method-col">
                    <label className="switch my-0">
                      <input
                        type="checkbox"
                        name="book_online"
                        id="service-book_online"
                        onChange={(e) => {
                          onServiceEdit(e, Index);
                        }}
                        checked={value.book_online}
                      />
                      <span className="slider round"></span>
                    </label>
                    <label
                      htmlFor="service-book_online"
                      className="text-grey"
                      style={{ margin: "6px 0px 0px 10px" }}
                    >
                      Book Through Integrated Calendar
                    </label>
                  </div>
                  {value.book_online && (
                    <>
                      <div className="bookonline-button-wrapper">
                        <div className="ser_pop_create_intake_form">
                          <span>
                            Additional questions you want to ask the client:
                          </span>
                          <a onClick={(e) => editField(e)}>Add Field +</a>
                        </div>
                      </div>
                      {editFields !== null
                        ? editFields?.length > 0 &&
                          editFields?.map((element, index) => {
                            return (
                              <div
                                key={index}
                                className="card bookonlinemodule-wrapper"
                              >
                                <div className="card-header">
                                  <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 mb-sm-0 mb-2">
                                      <input
                                        className="form-control"
                                        id="field_label"
                                        type="text"
                                        placeholder="Field label"
                                        name="field_label"
                                        required
                                        onChange={(e) => {
                                          var value = e.target.value.replace(
                                            " ",
                                            "_"
                                          );
                                          let fields = [...editFields];
                                          let field = { ...fields[index] };
                                          field.selected = "";
                                          field.field_name = value;
                                          field.field_label = e.target.value;
                                          field.field_type = "text";
                                          fields[index] = field;
                                          setEditFields(fields);
                                        }}
                                        value={element.field_label}
                                      />
                                    </div>

                                    <div className="col-lg-5 col-md-4 col-sm-4 mt-2 mt-lg-0 col-4">
                                      <div className="check-box-control req-chk">
                                        <label
                                          className="form-check-label requiredInput"
                                          htmlFor={index}
                                        >
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id={index}
                                            onClick={(e) => {
                                              editOnisReqChange(e, index);
                                            }}
                                          />
                                          Required ?
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-lg-1 col-md-2 col-sm-2 mt-lg-0 mt-2 col-6">
                                      <div className="col-ms-2">
                                        <button
                                          type="button"
                                          className="btn btn-danger float-right deleteBtn"
                                          onClick={() => deleteEditField(index)}
                                        >
                                          -
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <ul className="list-group list-group-flush border-bottom">
                                  <li className="list-group-item online-fields">
                                    <div className="row flex_column">
                                      <div className="col-lg-12 col-md-6 com-sm-12 field-label">
                                        <label className="lable-service">
                                          {element.field_label}
                                        </label>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            );
                          })
                        : []}
                    </>
                  )}
                  <div className="contact-method-col">
                    <label className="switch my-0">
                      <input
                        type="checkbox"
                        name="call_to_book"
                        id="service-call_to_book"
                        onChange={(e) => {
                          onServiceEdit(e, Index);
                        }}
                        checked={value.call_to_book}
                      />
                      <span className="slider round"></span>
                    </label>
                    <label
                      htmlFor="service-call_to_book"
                      className="text-grey"
                      style={{ margin: "6px 0px 0px 10px" }}
                    >
                      Call to book
                    </label>
                  </div>

                  <div className="contact-method-col">
                    <label className="switch my-0">
                      <input
                        type="checkbox"
                        name="book_site"
                        id="service-book_site"
                        onChange={(e) => {
                          onServiceEdit(e, Index);
                        }}
                        checked={value.book_site}
                      />
                      <span className="slider round"></span>
                    </label>
                    <label
                      htmlFor="service-book_site"
                      className="text-grey"
                      style={{ margin: "6px 0px 0px 10px" }}
                    >
                      Book on another site
                    </label>
                  </div>
                  {value.book_site && (
                    <>
                      <div className="http_bg">
                        <div className="col-sm-12 text-muted">
                          <p className="htt_disc">
                            Enter the URL you would like to redirect users to
                            when booking for this service.
                          </p>
                        </div>
                        <div className="col-sm-12">
                          <input
                            type="text"
                            className="form-control http_field"
                            name="book_site_link"
                            placeholder="https://"
                            id="book_site_link"
                            checked={value.book_site_link}
                            required={value.book_site_link}
                            value={
                              value.book_site_link
                                ? value.book_site_link
                                : ""
                            }
                            onChange={(e) => onServiceEdit(e, Index)}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      ))}
    </Accordion>
  );
};

const Step4 = ({
  setStep,
  clearNumber,
  onChange,
  setserviceData,
  serviceData,
  formData,
  business_register,
  isAuthenticated,
  loading,
  setServices,
  services,
}) => {
  const [intakeField, setIntakeField] = useState([]);
  const [editFields, setEditFields] = useState([]);

  const [formError, setFormError] = useState("");

  const onServiceChange = (e) => {
    if (e.target.name === "price") {
      e.target.value = clearNumber(e.target.value);
    }

    setserviceData({ ...serviceData, [e.target.name]: e.target.value });
  };
  const [showForm, setShowForm] = useState(true);
  const [activeIndex, setActiveIndex] = useState(null);
  const handleToggle = (index) => {
    setActiveIndex(index);
  };

  const { intakeForm } = serviceData;
  let fieldinitiaValues = [
    {
      field_category: "",
      field_type: "",
      is_required: false,
      field_name: "",
      field_label: "",
      online_fieldnames: [],
      selected: "",
      option: "",
      formfield: "",
    },
  ];

  const [onlineField, setOnlineField] = useState(
    intakeForm ? intakeForm : fieldinitiaValues
  );
  const deleteField = (i) => {
    let formValues = onlineField;
    formValues.splice(i, 1);
    setOnlineField([...formValues]);
    setIntakeField([...formValues]);
  };

  const onisReqChange = (e, i) => {
    let fields = [...intakeField];
    let field = { ...fields[i] };

    field.is_required = !field.is_required;
    fields[i] = field;
    setIntakeField(fields);
  };

  const addField = (e) => {
    setOnlineField([
      ...onlineField,
      {
        field_category: "",
        field_name: "",
        is_required: false,
        field_label: "",
        field_type: "",
        online_fieldnames: [],
        selected: "",
        option: "",
        formfield: "",
      },
    ]);
    setIntakeField([
      ...intakeField,
      {
        field_category: "",
        field_name: "",
        is_required: false,
        field_label: "",
        field_type: "",
        online_fieldnames: [],
        selected: "",
        option: "",
        formfield: "",
      },
    ]);
  };

  const onCreateService = (e) => {
    setShowForm(false);

    if (
      serviceData.name &&
      serviceData.description &&
      serviceData.service_duration &&
      serviceData.price
    ) {
      setFormError("");
      setServices([...services, serviceData]);

      setserviceData({
        name: "",
        description: "",
        service_duration: 15,
        price: "",
        book_online: false,
        book_site: false,
        book_site_link: "",
        call_to_book: false,
      });
      setIntakeField([]);
      setOnlineField([]);
    } else {
      setFormError("Please fill out your first service fields.");
    }
  };
  const onAddService = () => {
    onCreateService();
    setShowForm(true);
    setIntakeField([]);
  };

  useEffect(() => {
    if (activeIndex >= 0 && activeIndex !== null) {
      setEditFields(services[activeIndex]?.intakeform);
    }
  }, [activeIndex]);

  const onCompleteSignup = () => {
    let submitFormData;
    if (serviceData?.name) {
      if (
        serviceData.description &&
        serviceData.service_duration &&
        serviceData.price
      ) {
        const newService = [...services, serviceData];
        submitFormData = { ...formData, services: newService };
        setFormError(null);
        business_register(submitFormData);
      } else {
        setFormError("Please fill out the service fields.");
      }
    } else {
      setFormError(null);
      submitFormData = { ...formData, services: services };
      business_register(submitFormData);
    }
  };

  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <div className="container d-flex flex-column flex-lg-row align-items-center mb-3">
      <div className="flex-grow-1 d-flex flex-column justify-content-center p-3">
        <p className="title mb-4">What services do you provide?</p>
        <div className="business-dialog mx-auto mt-3 w-100" style={{ maxWidth: "620px" }}>
          <div className="d-flex justify-content-between align-items-center w-100">
            <p className="form-title m-0">Services</p>
            <p className="form-subtitle m-0">last step</p>
          </div>
          <div className="progress-bar-outer w-100 mb-3">
            <span className="progress-baar bg-aqua"></span>
            <span className="progress-baar bg-aqua"></span>
            <span className="progress-baar bg-aqua"></span>
            <span className="progress-baar bg-aqua"></span>
          </div>
          <div className="d-flex flex-column buisness-information mt-0 p-1">
            <AccordionComponent
              services={services}
              addField={addField}
              activeIndex={activeIndex}
              setServices={setServices}
              handleToggle={handleToggle}
              fieldinitiaValues={fieldinitiaValues}
              editFields={editFields}
              setEditFields={setEditFields}
              setActiveIndex={setActiveIndex}
            />
            <p className={`${services.length > 0 ? "d-block" : "d-none"} form-title m-0 text-left`}>
              Add Service(s)
            </p>
            {showForm && (
              <>
                <div className="form-row first-input mb-3">
                  <div className="col-sm-12">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Service Name (ex: 30-minute Massage)"
                      name="name"
                      maxLength="50"
                      value={serviceData.name}
                      onChange={(e) => {
                        onServiceChange(e);
                      }}
                      required
                    />
                  </div>
                </div>
                <div className="form-row first-input mb-3">
                  <div className="col-sm-12">
                    <textarea
                      className="form-control"
                      type="textarea"
                      placeholder="SHORT Service Description (ex: Book a 30-minute Massage)"
                      name="description"
                      value={serviceData.description}
                      onChange={(e) => onServiceChange(e)}
                    />
                  </div>
                </div>
                <div className="form-row first-input mb-3">
                  <div className="col-sm-6 mb-3 mb-md-0">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Service Price"
                      name="price"
                      value={serviceData.price}
                      onChange={(e) => onServiceChange(e)}
                    />
                  </div>
                  <div className="col-sm-6">
                    <select
                      className="form-control"
                      name="service_duration"
                      placeholder="Duration"
                      id="service-service_duration"
                      onChange={(e) => onServiceChange(e)}
                    >
                      <option value="15">15 minutes</option>
                      <option value="30">30 minutes</option>
                      <option value="45">45 minutes</option>
                      <option value="60">60 minutes</option>
                      <option value="90">90 minutes</option>
                      <option value="120">120 minutes</option>
                    </select>
                  </div>
                </div>
                <>
                  <p className="form-title m-0 text-left">How would you like to be booked?</p>
                  <div className="flex-column gap-1">
                    <div className="contact-method-col">
                      <select
                        id="booking-method-select"
                        className="form-control drop-arrow-booking h-43"
                        name="booking_method"
                        value={serviceData.booking_method}
                        onChange={(e) => {
                          const selectedMethod = e.target.value;
                          if (selectedMethod === "call_to_book") {
                            setserviceData({
                              ...serviceData,
                              booking_method: selectedMethod,
                              book_online: false,
                              book_site: false,
                              call_to_book: true,
                              book_site_link: "",
                            });
                          } else if (selectedMethod === "book_site") {
                            setserviceData({
                              ...serviceData,
                              booking_method: selectedMethod,
                              book_online: false,
                              call_to_book: false,
                              book_site: true,
                            });
                          }
                        }}
                      >
                        <option value="">Select Booking Method</option>
                        <option value="call_to_book">Call to book</option>
                        <option value="book_site">Book on another site</option>
                      </select>
                    </div>

                    {serviceData.booking_method === "book_site" && (
                      <>
                        <div className="http_bg mt-3">
                          <div className="col-sm-12 text-muted">
                            <p className="htt_disc">
                              Enter the URL you would like to redirect users to
                              when booking for this service.
                            </p>
                          </div>
                          <div className="col-sm-12">
                            <input
                              type="text"
                              className="form-control http_field"
                              name="book_site_link"
                              placeholder="https://"
                              id="book_site_link"
                              checked={serviceData.book_site_link}
                              required={serviceData.book_site_link}
                              value={
                                serviceData.book_site_link
                                  ? serviceData.book_site_link
                                  : ""
                              }
                              onChange={(e) => onServiceChange(e)}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </>
                {formError && <p className="text-danger">{formError}</p>}
                <p
                  className={`${services.length > 0 ? "d-none" : "d-block"} form-title m-0 text-left`}
                >
                  Add Service(s)
                </p>
                <div className="form-row first-input mb-3">
                  <div className="col-sm-12 calendar-btn" onClick={onAddService}>
                    <button type="button" className="business-form-btn">
                      Add Service
                    </button>
                    <div className="plusbtnclass">
                      <img src={Plus} alt="plus icon" />
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <input
                    type="button"
                    className="btn btn-primary bg-white border-0 text-dark font-weight-bold"
                    style={{ padding: "10px 20px", fontSize: "15px" }}
                    value="Back"
                    data-tut="register_button"
                    onClick={() => setStep(3)}
                  />
                  <button
                    type="button"
                    className="btn btn-primary text-dark font-weight-bold"
                    style={{ padding: "10px 20px", fontSize: "15px" }}
                    data-tut="register_button"
                    onClick={onCompleteSignup}
                    disabled={loading}
                  >
                    Complete Sign Up
                    {loading && (
                      <div className="spinner-border spinner-border-sm text-dark ml-2"></div>
                    )}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="d-none d-lg-block" style={{ width: "30%" }}>
        <img src={Banner} className="img-fluid" alt="Banner" />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.company.loading,
});

export default connect(mapStateToProps, {
  business_register,
})(Step4);
