export const clientBookingEmailTemplate = 
`
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Appointment Confirmation</title>
    <style>
        body {
            font-family: Arial, sans-serif;
            background-color: #f7f7f7;
            margin: 0;
            padding: 0;
        }
        .email-container {
            background-color: #ffffff;
            width: 600px;
            margin: 20px auto;
            border: 1px solid #e0e0e0;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }
        .header {
            text-align: center;
            padding-bottom: 20px;
            padding: 20px;
        }
        .header img {
            width: 150px;
        }
        .info-header{
            display: flex;
            align-items: center;
            justify-content: start;
            padding: 20px;
        }
        .company-info{
            margin-left: 50px;
        }
        .appointment-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #e0f7fa;
            padding: 20px;
            border-radius: 8px;
            margin-bottom: 20px;
        }
        .appointment-info .icon {
            font-size: 50px;
            color: #26c6da;
        }
        .appointment-info .details {
            text-align: left;
        }
        .appointment-info .details h3 {
            margin: 0;
            font-size: 18px;
            color: #333333;
        }
        .appointment-info .details p {
            margin: 5px 0;
            font-size: 16px;
            color: #666666;
        }
        .content {
            padding: 20px;
            font-size: 16px;
            color: #333333;
            line-height: 1.5;
        }
        .content p {
            margin: 20px 0;
        }
        .button {
            display: block;
            width: 200px;
            margin: 20px auto;
            text-align: center;
            padding: 10px;
            background-color: #ff9800;
            color: #ffffff;
            text-decoration: none;
            border-radius: 5px;
            font-size: 16px;
        }
        .footer {
            background-color: #68D4CE;
            text-align: center;
            padding: 20px;
            font-size: 14px;
            color: white;
        }
        .footer a {
            color: white;
            text-decoration: none;
        }
        .footer .social-media {
            margin-top: 10px;
        }
        .footer .social-media img {
            width: 20px;
            margin: 0 10px;
        }
        .main-image-container{
            padding: 0 100px;
        }
        .visit-btn {
            display: block;
            width: 200px;
            margin: 20px auto;
            text-align: center;
            padding: 10px;
            background-color: #FFAD68;
            color: #ffffff!important;
            text-decoration: none;
            border-radius: 25px;
            font-size: 16px;
        }
    </style>
</head>
<body>
    <div class="email-container">
        <div class="header">
            <img src="https://appointmentcake.com/wp-content/uploads/2020/11/Appt-Cake-logo.png" alt="ApptCake Logo">
        </div>
        <div class="info-header">
            <div class="appointment-info">
                <img src="https://appointmentcake.com/wp-content/uploads/2024/07/mdi_calendar.png" alt="Calendar Logo">
                <div class="details">
                    <h3>{{appointmentDate}}</h3>
                    <p>{{appointmentTime}}</p>
                </div>
            </div>
            <div class="company-info">
                <h3>{{companyName}}</h3>
                <p>{{companyPhone}}</p>
                <p>{{companyAddress}}</p>
            </div>
        </div>
        <div class="content">
            
            <p>Congratulations!</p>
            <p>
                You just booked an appointment with {{companyName}}.
            <br>
                Cancel or reschedule your appointment here! <a href="https://app.appointmentcake.com/dashboard">My Dashboard</a>
            </p>
            <p>
                Stay Cool,<br>
                The Appointment Cake Team 🧁
            </p>
            <p>
                Need some help? Email <a href="mailto:help@appointmentcake.com">help@appointmentcake.com</a>
            </p>
        </div>
        <div class="main-image-container">
            <img src="https://appointmentcake.com/wp-content/uploads/2024/07/Book.png" alt="Booking Illustration" style="width: 100%;">
            <a href="https://app.appointmentcake.com/" class="visit-btn">Visit Appointment Cake</a>
        </div>
        <div class="footer">
            © All rights reserved
            <div class="social-media">
                <a href="https://www.linkedin.com/company/appointment-cake-app/"><img src="https://appointmentcake.com/wp-content/uploads/2024/07/mdi_linkedin.png" alt="LinkedIn"></a>
                <a href="https://www.instagram.com/appointmentcake/"><img src="https://appointmentcake.com/wp-content/uploads/2024/07/mdi_instagram.png" alt="Instagram"></a>
                <a href="https://www.facebook.com/AppointmentCake"><img src="https://appointmentcake.com/wp-content/uploads/2024/07/ic_baseline-facebook.png" alt="Facebook"></a>
            </div>
        </div>
    </div>
</body>
</html>
`