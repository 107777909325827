import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Moment from "react-moment";
import { deleteComment } from "../../actions/booking";
// import UserIcon from '../../images/user-icon.png';

const CommentItem = ({
  bookingId,
  comment: { _id, text, name, avatar, user, date, attachments },
  auth,
  deleteComment
}) => {
  return (
    <div
      className={
        !auth.loading && (user === auth.user._id || user._id === auth.user._id)
          ? "booking-comment d-flex justify-content-start"
          : "booking-comment d-flex "
      }
    >
      <div
        className={
          !auth.loading &&
          (user === auth.user._id || user._id === auth.user._id)
            ? "comment-avatar float-right ml-2"
            : "comment-avatar float-left mr-2"
        }
      >
        <img
          alt="Avatar"
          src={avatar}
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50px"
          }}
        />
      </div>
      <div className="card bg-light mb-3">
        <div className="card-body px-3 py-2">
          <strong className="small">{name}</strong>
          {/* <p>{_id}</p> */}
          <br />
          <div>{text}</div>
          <div>
            {attachments && attachments.length > 0
              ? attachments.map((value, index) => {
                  return (
                    <img
                      alt="Avatar"
                      className="commentImage"
                      src={value}
                      style={{
                        width: "50px",
                        height: "50px"
                      }}
                    />
                  );
                })
              : ""}
          </div>
          <div className="text-muted">
            <span className="mr-4 small comment-date">
              <Moment format="MMM Do">{date}</Moment>
            </span>
            {!auth.loading &&
              (user === auth.user._id || user._id === auth.user._id) && (
                <button
                  type="button"
                  className="btn btn-text btn-sm"
                  onClick={e => deleteComment(bookingId, _id)}
                >
                  Remove
                </button>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

CommentItem.propTypes = {
  bookingId: PropTypes.number.isRequired,
  comment: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  deleteComment: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { deleteComment }
)(CommentItem);
