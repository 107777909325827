import React, { Fragment, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { withRouter, Link, useHistory } from "react-router-dom";
import moment from "moment-timezone";
import { connect } from "react-redux";
import { getAllPlans } from "../../actions/feature";
import AddBusinessService from "./AddBusinessService";
import {
  deleteAdminService,
  getAdminCompanyBookings,
} from "../../actions/company";
import UpdateBusiness from "./UpdateBusiness";
import { Helmet } from "react-helmet";

const BusinessService = ({
  auth,
  deleteAdminService,
  companyBusiness,
  getAdminCompanyBookings,
  getAllPlans,
}) => {
  const currentCompany = useLocation();
  let history = useHistory();
  const [allService, setAllService] = useState([]);
  const [currentTab, setCurrentTab] = useState("services");
  const [company, setCompany] = useState(currentCompany?.state?.companyDetails);

  const [serviceData, setService] = useState({
    service: {
      _id: null,
      name: null,
      book_online: null,
      book_site: null,
      book_site_link: null,
      call_to_book: null,
      description: null,
      price: null,
      service_duration: null,
      intakeForm: [],
    },
  });
  const { service } = serviceData;
  const clearService = () => {
    const serviceData = {
      service: {
        _id: null,
        namae: "",
        book_online: false,
        call_to_book: false,
        book_site: false,
        book_site_link: "",
        description: "",
        price: "",
        service_duration: "",
        intakeForm: [],
      },
    };
    setService(serviceData);
  };
  const d_names = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const date = new Date();
  const today = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    0,
    0,
    0,
    0
  );
  const d_key = d_names[date.getDay()];
  const [selectedService, setSelectedService] = useState();
  const [isEdit, SetIsEdit] = useState(false);
  useEffect(() => {
    company
      ? companyBusiness &&
        company &&
        companyBusiness?.find((item) => item._id == company?._id) != undefined
        ? setAllService(
            companyBusiness?.find((item) => item._id == company?._id)
          )
        : setAllService(company)
      : history.push("/admin");
  }, [companyBusiness]);
  useEffect(() => {
    getAdminCompanyBookings(currentCompany?.state?.currentPage, "");
    getAllPlans();
    companyBusiness.map((comp) => {
      if (comp._id === currentCompany?.state?.companyDetails._id) {
        setCompany(comp);
      }
    });
  }, [currentTab]);
  const Title = company?.services[0]?.name
    ? `${company?.name} | ${company?.services[0]?.name} | Appointment Cake`
    : `${company?.name} | Appointment Cake`;

  return (
    // Header
    // currentCompany?.state ?
    <>
      <Helmet>
        <title>{Title}</title>
        <meta
          name="description"
          content={
            company?.description
              ? `${company?.description} | ${company?.name} | Appointment Cake`
              : `${company?.name} | Appointment Cake`
          }
        />
      </Helmet>
      {company && (
        <>
          <div className="row p-4 business-service-header">
            <div className="px-4">
              {auth.isAuthenticated && auth.user._id && (
                <Link
                  to={{
                    pathname: "/superadmin",
                    state: { CurrentTab: currentCompany?.state?.currentTab },
                  }}
                  className="btn btn-blue rounded-circle mobile-top-arrow d-flex justify-content-center align-items-center"
                >
                  <i className="fas fa-arrow-left"></i>
                </Link>
              )}
            </div>
            <div className="col">
              <h3 className="company-title" style={{ fontSize: "20px" }}>
                {company.name}
                {company.social.facebook ? (
                  <a
                    className="marginLeft btn"
                    target="_blank"
                    href={company.social.facebook}
                  >
                    <i className="fab fa-facebook-f text-primary"></i>
                  </a>
                ) : (
                  ""
                )}
                {company.social.instagram ? (
                  <a
                    className="marginLeft btn"
                    target="_blank"
                    href={company.social.instagram}
                  >
                    <i className="fab fa-instagram text-primary"></i>
                  </a>
                ) : (
                  ""
                )}
                {company.social.linkedin ? (
                  <a
                    className="marginLeft btn"
                    target="_blank"
                    href={company.social.linkedin}
                  >
                    <i className="fab fa-linkedin-in text-primary"></i>
                  </a>
                ) : (
                  ""
                )}
              </h3>
              <p>
                {company.description && (
                  <Fragment>{company.description}</Fragment>
                )}
                <br />
                <span className="">
                  {company.store_hours[d_key].open ? (
                    <Fragment>
                      <span className="text-success">
                        Open{" "}
                        {moment(new Date(today))
                          .add(company.store_hours[d_key].start_time, "minutes")
                          .format("h:mm a")}{" "}
                        -{" "}
                        {moment(new Date(today))
                          .add(company.store_hours[d_key].end_time, "minutes")
                          .format("h:mm a")}
                      </span>
                    </Fragment>
                  ) : (
                    <span className="badge badge-danger">Closed today</span>
                  )}
                </span>{" "}
                | {company.location.street_address} | {company.phone}{" "}
              </p>
            </div>
          </div>

          {/* service listing  */}
          <div className="row p-4">
            <div className="col-md-3 mb-3">
              <div className="card" style={{ position: "sticky", top: "1em" }}>
                <div
                  className="list-group list-group-flush border border-dark"
                  role="tablist"
                >
                  <a
                    href="#services"
                    id="services"
                    data-toggle="pill"
                    role="tab"
                    className="list-group-item active"
                    data-tut="business_list"
                    onClick={() => setCurrentTab("services")}
                  >
                    Services
                  </a>
                  <a
                    href="#settings"
                    id="settings"
                    data-toggle="pill"
                    role="tab"
                    className="list-group-item"
                    data-tut="appointment_tab"
                    onClick={() => setCurrentTab("settings")}
                  >
                    Settings
                  </a>
                </div>
              </div>
            </div>
            {currentTab == "services" && (
              <div className="col-md-9 mb-3">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card mb-4">
                      <div className="card-header">
                        <a
                          href="#add-service"
                          className="btn btn-primary float-right"
                          onClick={() => {
                            clearService();
                            SetIsEdit(false);
                          }}
                        >
                          Add Service
                        </a>
                        <h4 className="card-title">Services</h4>
                      </div>
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        {allService.services?.length > 0 ? (
                          <tbody>
                            {allService &&
                              allService.services?.map((serv, i) => (
                                <tr key={serv._id}>
                                  <td>{serv.name}</td>
                                  <td className="text-right">
                                    <div className="btn-group" role="group">
                                      <a
                                        href="#add-service"
                                        className="btn"
                                        onClick={() => {
                                          setSelectedService(serv);
                                          SetIsEdit(true);
                                        }}
                                      >
                                        <i
                                          className="fa-solid fa-pen-to-square"
                                          style={{ color: "#25d2c3" }}
                                        ></i>
                                      </a>
                                      <button
                                        className="btn"
                                        onClick={() =>
                                          deleteAdminService(
                                            serv._id,
                                            company._id
                                          )
                                        }
                                      >
                                        <i
                                          className="fa-solid fa-trash-can edit_del_btn"
                                          style={{ color: "red" }}
                                        ></i>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td colSpan="2" style={{ textAlign: "center" }}>
                                No Services Available
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <AddBusinessService
                      selectedService={selectedService}
                      clearService={clearService}
                      data={company}
                      isEdit={isEdit}
                    />
                  </div>
                </div>
              </div>
            )}
            {currentTab == "settings" && (
              <div className="col-md-9 mb-3">
                <div className="row">
                  <div className="col-lg-12">
                    <UpdateBusiness comp={company} />
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </> /*: <SuperAdmin />*/ /*: <SuperAdmin />*/ /*: <SuperAdmin />*/ /*: <SuperAdmin />*/ /*: <SuperAdmin />*/ /*: <SuperAdmin />*/ /*: <SuperAdmin />*/ /*: <SuperAdmin />*/ /*: <SuperAdmin />*/ /*: <SuperAdmin />*/ /*: <SuperAdmin />*/ /*: <SuperAdmin />*/ /*: <SuperAdmin />*/ /*: <SuperAdmin />*/ /*: <SuperAdmin />*/ /*: <SuperAdmin />*/ /*: <SuperAdmin />*/ /*: <SuperAdmin />*/ /*: <SuperAdmin />*/ /*: <SuperAdmin />*/ /*: <SuperAdmin />*/ /*: <SuperAdmin />*/ /*: <SuperAdmin />*/ /*: <SuperAdmin />*/ /*: <SuperAdmin />*/ /*: <SuperAdmin />*/ /*: <SuperAdmin />*/ /*: <SuperAdmin />*/ /*: <SuperAdmin />*/ /*: <SuperAdmin />*/ /*: <SuperAdmin />*/ /*: <SuperAdmin />*/ /*: <SuperAdmin />*/ /*: <SuperAdmin />*/ /*: <SuperAdmin />*/ /*: <SuperAdmin />*/ /*: <SuperAdmin />*/ /*: <SuperAdmin />*/ /*: <SuperAdmin />*/ /*: <SuperAdmin />*/ /*: <SuperAdmin />*/ /*: <SuperAdmin />*/ /*: <SuperAdmin />*/ /*: <SuperAdmin />*/
    /*: <SuperAdmin />*/
    /*: <SuperAdmin />*/
    /*: <SuperAdmin />*/
    /*: <SuperAdmin />*/
    /*: <SuperAdmin />*/
   /*: <SuperAdmin />*/);
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  companyBusiness: state.company.adminBookings,
});

export default connect(mapStateToProps, {
  deleteAdminService,
  getAdminCompanyBookings,
  getAllPlans,
})(withRouter(BusinessService));
