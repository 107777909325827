import React, { Fragment, useEffect, useState } from "react";
import { getLikedCompanies } from "../../actions/company";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CompanyItem from "../companies/CompanyItem";
import { useHistory } from "react-router-dom";
import UserIcon from "../../images/user-icon.png";
import { Helmet } from "react-helmet";

const Favorite = ({ getLikedCompanies, company: { liked } }) => {
  useEffect(() => {
    getLikedCompanies();
  }, [getLikedCompanies]);

  const history = useHistory();

  return (
    <>
      <Helmet>
        <title>Appointment Cake</title>
        <meta
          name="description"
          content="Whatever or whoever you’re looking for,
                   Appointment Cake has you covered."
        />
      </Helmet>
      <div className="card-header">
        <h4 className="none_text card-title">Favorites</h4>
        {/* <img src={LogoImage} alt='' /> */}
        <a
          onClick={() => history.push("/account")}
          className="user-icon desktop-show btn btn-info max_width side_gap d-none"
        >
          <i
            className="fa-solid fa-user"
            onClick={() => history.push("/account")}
          ></i>
          User
        </a>
        <div className="col mobile-dashboard-header">
          <a
            onClick={() => {
              history.push("/account");
            }}
            className="user-icon"
          >
            <img
              src={UserIcon}
              alt="User Icon"
              style={{ marginLeft: "-18px", marginTop: "-5px" }}
            />
          </a>
        </div>
        <div className="hidden">
          {/* <ToolTip text={"A list of Businesses"} /> */}
        </div>
      </div>
      <div className="mid_hed">
        <p>Favorites</p>
      </div>
      <div
        className="widget mb-4 favorite-page favorite_bg"
        data-tut="fav_place"
      >
        <div className="card">
          {/* <div className="card-header bg-aquafavorite_hed">
            <h4>Favorites</h4>
          </div> */}
          <div
            className="card-body company-list fav_company_list"
            style={{ maxHeight: "90vh", overflow: "auto" }}
          >
            {liked.length > 0 ? (
              <Fragment>
                {liked.map((company, i) => (
                  <CompanyItem
                    key={company._id}
                    company={company}
                    page="favorite"
                  />
                ))}
              </Fragment>
            ) : (
              <Fragment>
                <h5 className="text-muted"> No Places liked</h5>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

Favorite.protoTypes = {
  getLikedCompanies: PropTypes.func.isRequired,
  liked: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  company: state.company
});

export default connect(
  mapStateToProps,
  {
    getLikedCompanies
  }
)(Favorite);
