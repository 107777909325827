import { LOAD_MAP, MAP_ERROR, GET_COOR } from '../actions/types';

const initialState = {
	map: null,
	companies: [],
	loading: true,
	error: {},
};

export default function map(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case LOAD_MAP:
			return {
				...state,
				companies: payload,
				loading: false,
			};
		case MAP_ERROR:
			return {
				...state,
				error: payload,
				loading: false,
			};
		case GET_COOR:
			return {
				...state,
				map: payload,
				loading: false,
			};
		default:
			return state;
	}
}
