import React, { useState, useEffect , useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import { getCoordinates } from '../../actions/map';
import GoogleMapReact from 'google-map-react';
import AutoComplete from './Autocomplete';
import PropTypes from 'prop-types';
import { getCompanies } from '../../actions/company';
import Marker from './Marker';

const MapContainer = ({ company: { companies, loading } , mapMarkers, newMarker,addressupdate, currentLat,currentLng ,centerData,setFilter}) => {
  const [mapData, setMapData] = useState({
    mapApiLoaded: false,
    mapInstance: null,
    mapApi: null,
    geoCoder: null,
    places: [],
    center: {
    },
    zoom: 13,
    address: '',
    draggable: true,
    markers: [],
    lat: null,
    lng: null,
  });
  let customMapCenter;
  const [isMapApiLoaded, setIsMapApiLoaded] = useState(false)
  const [isMapIndtance, setIsMapIndtance] = useState()
  const [isMapApi, setIsMapApi] = useState()
  const refMap = useRef(null)
  let customMap
  let customMapApi
  let {
    mapApiLoaded,
    mapInstance,
    mapApi,
    geoCoder,
    places,
    center,
    zoom,
    address,
    draggable,
    markers,
    lat,
    lng,
  } = mapData;

  // const { companies  } = useSelector((state) => state.company)
  useEffect(() => {
    getCoordinates(lat, lng, markers);

    const newMarkers = markers.length === 0 ? mapMarkers : markers;
    
    setMapData({
      mapApiLoaded: !mapInstance ? false : true ,
      mapInstance: !mapInstance ? null : mapInstance,
      mapApi: !mapApi ? null : mapApi,
      geoCoder: !geoCoder ? null : geoCoder,
      places: !places ? [] : places,
      center: !center ? [0, 0] : center,
      zoom: !zoom ? 13 : zoom,
      address: !address ? '' : address,
      draggable: !draggable ? true : draggable,
      markers: !newMarkers ? [] : newMarkers,
      lat: !lat ? null : lat,
      lng: !lng ? null : lng,
    });
  }, [
    address,
    center,
    draggable,
    geoCoder,
    markers,
    mapApi,
    mapApiLoaded,
    mapInstance,
    places,
    zoom,
    lat,
    lng,
    mapMarkers,
  ]);

  const handleBoundsChanged = () => {
    const mapCenter = refMap.current.getCenter(); //get map center
    // mapData.center = mapCenter
    
};

const setCurrentLocation = () => {
		
  if ('geolocation' in navigator) {
    navigator.geolocation.getCurrentPosition(function(position) {
      setMapData({
      ...mapData,
      lat: position.coords.latitude,
      lng: position.coords.longitude,
      center: [position.coords.latitude, position.coords.longitude],
      miles: 15000/1000
    });
    });
  }
  };

  useEffect(() => {
    setTimeout(() => {
      if(currentLat === ""){
        setCurrentLocation()
      }
      setMapData({
			  ...mapData,
			  center: [currentLat, currentLng],
			  lat: currentLat,
			  lng: currentLng,
      });
    }, 1000);
  },[currentLng])


  useEffect(() => {
     mapData.markers = [];
     companies?.map(companyValue=>{
      mapData.markers.push({lat:companyValue.location.lat,lng:companyValue.location.lng,company:companyValue,show:false,text:companyValue.name});
      setMapData({
        ...mapData,
        markers:[...mapData.markers]
      })
    })
	}, [companies]);
  
  const onMarkerInteraction = (childKey, childProps, mouse) => {
    setMapData({
      ...mapData,
      draggable: false,
      lat: mouse.lat,
      lng: mouse.lng,
    });
  };

  const onMarkerInteractionMouseUp = (childKey, childProps, mouse) => {
    setMapData({ ...mapData, draggable: true });
    _generateAddress();
  };

  const onChildClickCallback = (key) => {
    markers[key].show = !markers[key].show;
    for (let marker in markers) {
      if (marker !== key) {
        markers[marker].show = false;
      }
    }
  };

  const _onChange = ({ center, zoom }) => {
    setMapData({
      ...mapData,
      center: center,
      zoom: 13,
    });
    getCompanies({latitude:center.lat ,longitude: center.lng})
  };

  const _onClick = (value) => {
    setMapData({
      ...mapData,
      lat: value.lat,
      lng: value.lng,
      markers: [
        {
          lat: value.lat,
          lng: value.lng,
        },
      ],
    });
    if (typeof newMarker === 'function' && newMarker !== false) {
      newMarker({
        lat: value.lat,
        lng: value.lng,
      });
    }
  };

  const addPlace = (place) => {
    setMapData({
      ...mapData,
      places: [place],
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
      markers: [{lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()}],
      center :{
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      }
    });
    addressupdate && addressupdate(place.address_components,place.geometry.location.lat() ,place.geometry.location.lng())
     // Central City, Kelowna, BC V1Y 6H7, Canada
  };
  
  // const [center, setCenter] = useState(null);


  const _generateAddress = () => {
    if (mapApi !== null) {
      const geocoder = new mapApi.Geocoder();
      
      geocoder.geocode(
        { location: { lat: lat, lng: lng } },
        (results, status) => {
          if (status === 'OK') {
            if (results[0]) {
              setMapData({
                ...mapData,
                zoom: 13,
                address: results[0].formatted_address,
              });
            } else {
              window.alert('No results found');
            }
          } else {
            window.alert('Geocoder failed due to: ' + status);
          }
        }
      );
    }
  };

  const apiHasLoaded = (map, maps) => {
    customMap =  map
    customMapApi = maps
    setIsMapIndtance(customMap)
    setIsMapApi(customMapApi)
    setIsMapApiLoaded(true)
    setMapData({
      ...mapData,
      mapApiLoaded: true,
      mapInstance: map,
      mapApi: maps,
    });
    _generateAddress();
  };

  return (
    <div style={{ position: 'relative' }}>
      {isMapApiLoaded && (
        <div>
          <AutoComplete map={isMapIndtance} mapApi={isMapApi} addPlace={addPlace} setFilter={setFilter} isFilter={false}/>
        </div>
       )} 
      <div
        style={{
          height: '400px',
          minHeight: '400px',
          width: '100%',
          position: 'relative',
        }}
        id='MapContainer'
      >
        <GoogleMapReact
        ref={refMap}
          bootstrapURLKeys={{
            key: 'AIzaSyDMrbBYCBv7WyV77RaI6OBbfK4gDNYNiCw',
            libraries: ['places', 'geometry'],
          }}
          center={center}
          defaultCenter={center}
          defaultZoom={zoom}
          zoom={zoom}
          draggable={draggable}
          onChange={_onChange}
          onChildMouseDown={onMarkerInteraction}
          onChildMouseUp={onMarkerInteractionMouseUp}
          onChildMouseMove={onMarkerInteraction}
          onChildClick={onChildClickCallback}
          onBoundsChanged={handleBoundsChanged}
          onClick={newMarker !== false ? _onClick : null}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => apiHasLoaded(map, maps)}
        >
            <Marker            
              key={0}
              lat={center.lat}
              lng={center.lng}
              isdefault={true}
              show={false}
              disableDoubleClickZoo = {true}
            />
          {/* {markers.map((marker, index) => {return (
            index != 0 ?
              <Marker     
                isdefault={false}
                key={index+1}
                company={marker.company}
                lat={marker.lat}
                lng={marker.lng}
                text={marker.text}
                show={marker.show}
              /> : ""
          )})} */}
        </GoogleMapReact>
      </div>
    </div>
  );
};

MapContainer.propTypes = {
  getCoordinates: PropTypes.func,
  markers: PropTypes.array,
  // getCompanies: PropTypes.func.isRequired,
  company: PropTypes.object,
  setFilter:PropTypes?.setFilter
};

const mapStateToProps = (state) => ({
  markers: state.markers,
  company: state.company,
});
export default connect(mapStateToProps, { getCoordinates  })(MapContainer);