import React, { Fragment, useState, useRef, useEffect } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addGoogleAuthCode, removeGoogleAuthCode } from "../../actions/auth";
// import ToolTip from "../layout/ToolTip";
import { useGoogleLogin } from "react-google-login";
import Calendar from "../../asssets/icons/googlecal.png";
import Trash from "../../asssets/icons/trash.svg";
// import { useEffect } from "react";

const AddIntegration = ({
  addGoogleAuthCode,
  auth: { user },
  removeGoogleAuthCode,
}) => {
  const closeRef = useRef(null);
  var GoogleAuth;
  var gapi = window.gapi;
  const CLIENT_ID = process.env.REACT_APP_GOOGLECLIENTID;
  const API_KEY = process.env.REACT_APP_GOOGLEAPIKEY;
  const DISCOVERY_DOCS = [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
  ];
  const SCOPES = "https://www.googleapis.com/auth/calendar.events";

  // calendar connection
  const clientId = CLIENT_ID;

  const [googleUser, setGoogleUser] = useState(
    user?.googleUser && user?.googleUser?.email
  );
  // const onSuccess = res => {
  //   // companyDetail.googleAuthBusiness = res.tokenObj;
  //   // companyDetail.googleRefreshTokenBusiness = '';
  //   // companyDetail.googleUserBusiness = {
  //   //   name: res.Du.tf,
  //   //   email: res.Du.tv
  //   // };

  //   // user details
  //   addGoogleAuthCode({
  //     googleRefreshToken: "",
  //     googleAuth: res.tokenObj,
  //     googleUser: {
  //       name: res.profileObj.name,
  //       email: res.profileObj.email
  //     },
  //     user: user
  //   });
  //   closeRef.current.click();
  //   listUpcomingEvents();
  //   console.log("listUpcomingEvents : ", listUpcomingEvents());
  // };
  // const onFailure = res => {
  //   console.log("Login failed: res:", res);
  // };

  // const { signIn } = useGoogleLogin({
  //   onSuccess,
  //   onFailure,
  //   clientId,
  //   issignedIn: true,
  //   accessType: "offline",
  //   scope: "https://www.googleapis.com/auth/calendar.events"
  // });

  // const onConnect = () => {
  //   console.log("hi============");
  //   gapi.load("client:auth2", () => {
  //     gapi.client.init({
  //       apiKey: API_KEY,
  //       clientId: CLIENT_ID,
  //       discoveryDocs: DISCOVERY_DOCS,
  //       scope: SCOPES,
  //     });

  //     gapi.client.load("calendar", "v3", () => {
  //       closeRef.current.click();
  //     });

  //     GoogleAuth = gapi.auth2.getAuthInstance();
  //     GoogleAuth.grantOfflineAccess({
  //       access_type: "offline",
  //       prompt: "consent",
  //     })
  //       .then((authResult) => {
  //         if (authResult["code"]) {
  //           let googleLoginUser = gapi.auth2
  //             .getAuthInstance()
  //             .currentUser.get()
  //             .getBasicProfile(true);
  //           addGoogleAuthCode({
  //             googleRefreshToken: authResult["code"],
  //             googleAuth: gapi.auth2
  //               .getAuthInstance()
  //               .currentUser.get()
  //               .getAuthResponse(true),
  //             googleUser: {
  //               name: googleLoginUser.getName(),
  //               email: googleLoginUser.getEmail(),
  //             },
  //             user: user,
  //           });
  //         }
  //         listUpcomingEvents();
  //         closeRef.current.click();
  //       })
  //       .catch((err) => {
  //         console.log("err--", err);
  //       });
  //   });
  // };
  const onConnect = () => {
    gapi.load("client:auth2", () => {
      gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      });

      gapi.client.load("calendar", "v3", () => {
        GoogleAuth = gapi.auth2.getAuthInstance();

        GoogleAuth.grantOfflineAccess({
          access_type: "offline",
          prompt: "consent",
        })
          .then((authResult) => {
            if (authResult["code"]) {
              let googleLoginUser = gapi.auth2
                .getAuthInstance()
                .currentUser.get()
                .getBasicProfile(true);
              addGoogleAuthCode({
                googleRefreshToken: authResult["code"],
                googleAuth: gapi.auth2
                  .getAuthInstance()
                  .currentUser.get()
                  .getAuthResponse(true),
                googleUser: {
                  name: googleLoginUser.getName(),
                  email: googleLoginUser.getEmail(),
                },
                user: user,
              });
            }
            listUpcomingEvents();
            closeRef.current.click();
          })
          .catch((err) => {
            console.log("err--", err);
          });
      });
    });
  };
  const listUpcomingEvents = () => {
    gapi.client.calendar.events
      .list({
        calendarId: "primary",
        timeMin: new Date().toISOString(),
        showDeleted: false,
        singleEvents: true,
        maxResults: 10,
        orderBy: "startTime",
      })
      .then(function (response) {
        var events = response.result.items;
      });
  };

  return user === null ? (
    <Redirect to="/" />
  ) : (
    <Fragment>
      <div className="d-flex flex-column" id="add-integration">
        <div>
          <p className="text-lg w-fit font-weight-bold mb-10 mx-auto">
            Personal Calendar
          </p>
          <p className="text-medium font-medium text-center mb-4">
            Sync your personal calendar to find appointments faster
          </p>
          <div className="border border-dark p-4 d-flex w-fit border-8 mx-auto">
            <img src={Calendar} alt="Calendar" width={60} height={60} />
            <div className="d-flex flex-column ml-3">
              <p className="text-medium font-medium mb-1">Google Calendar</p>
              {user?.googleUser !== undefined ||
              user?.googleUser?.email !== undefined ? (
                <div className="d-flex mr-1">
                  <p>{user?.googleUser?.email}</p>
                  <img
                    src={Trash}
                    alt="Trash"
                    width={24}
                    height={24}
                    onClick={() => {
                      removeGoogleAuthCode(user);
                    }}
                  />
                </div>
              ) : (
                <input
                  type="submit"
                  className="btn bg-orange text-white text-xs font-semibold py-0"
                  value="Connect"
                  data-tut="register_button"
                  onClick={onConnect}
                />
              )}
            </div>
          </div>
          {/* <div className="btn">
            {user.googleAuth !== undefined && user.googleAuth !== null ? (
              <>
                <i
                  className="fa-solid fa-link calender_icon"
                  onClick={() => {
                    removeGoogleAuthCode(user);
                  }}
                ></i>
              </>
            ) : (
              <></>
            )}
          </div> */}

          {/* Calendar Pop */}
          <div id="calendar-pop" className="overlay">
            <div className="popup margin-top-pop">
              <a className="close" href="#" ref={closeRef}>
                &times;
              </a>
              <h2>Sync Calendar</h2>

              <div className="calender_pop_btn_container">
                <input
                  type="submit"
                  className="btn btn-primary btn-lg create-service-btn"
                  value="Google Calendar"
                  data-tut="register_button"
                  onClick={onConnect}
                />

                {/* <input
              type="submit"
              className="btn btn-primary btn-lg create-service-btn"
              value="Calandly"
              data-tut="register_button"
            />

            <input
              type="submit"
              className="btn btn-primary btn-lg create-service-btn"
              value="Outlook"
              data-tut="register_button"
            /> */}
              </div>
            </div>
          </div>
          {/* Calendar Pop */}
        </div>
     
      </div>

      <a href="#calendar-pop d-none">
        <button className="position btn btn-primary add_btn d-none">
          Add +
        </button>
      </a>
    </Fragment>
  );
};

AddIntegration.propTypes = {
  addGoogleAuthCode: PropTypes.func.isRequired,
  removeGoogleAuthCode: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  addGoogleAuthCode,
  removeGoogleAuthCode,
})(AddIntegration);
