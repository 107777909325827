import React, { useState, useEffect } from "react";
import Banner from "../../../images/step1.png";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import { connect, useDispatch } from "react-redux";
import { getCategories } from "../../../actions/company";

const Step1 = ({ getCategories }) => {
  const initialState = {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    password: "",
    password2: "",
    refferalUserId: "",
    name: "",
    website: "",
    description: "",
    street_address: "",
    street_address_2: "",
    city: "",
    province: "",
    country: "",
    postal: "",
    companyEmail: "",
    companyPhone: "",
    fax: "",
    lat: "",
    lng: "",
    facebook: "",
    twitter: "",
    linkedin: "",
    instagram: "",
    Sunday_open: true,
    Sunday_start_time: 540,
    Sunday_end_time: 1020,
    Monday_open: true,
    Monday_start_time: 540,
    Monday_end_time: 1020,
    Tuesday_open: true,
    Tuesday_start_time: 540,
    Tuesday_end_time: 1020,
    Wednesday_open: true,
    Wednesday_start_time: 540,
    Wednesday_end_time: 1020,
    Thursday_open: true,
    Thursday_start_time: 540,
    Thursday_end_time: 1020,
    Friday_open: true,
    Friday_start_time: 540,
    Friday_end_time: 1020,
    Saturday_open: true,
    Saturday_start_time: 540,
    Saturday_end_time: 1020,
    markers: [],
    geoLocation: "",
    googleRefreshTokenBusiness: null,
    googleAuthBusiness: null,
    googleUserBusiness: null,
    markers: [],
    category: "",
    subcategory: "",
    calendartype: "",
    calendarurl: "",
    reference: "wordofmouth",
    reason: "",
  };

  const [calenderEmail, setCalendarEmail] = useState(null);
  const [services, setServices] = useState([]);
  const [serviceData, setserviceData] = useState({
    name: "",
    description: "",
    service_duration: 15,
    price: "",
    book_online: false,
    book_site: false,
    book_site_link: "",
    call_to_book: true,
    intakeform: [],
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);
  const [formData, setFormData] = useState(initialState);
  const [formError, setFormError] = useState("");
  const [step, setStep] = useState(1);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.password !== formData.password2) {
      setFormError("Passwords don't match!");
    } else {
      setStep(2);
      setFormError(null);
    }
  };

  function clearPhoneNumber(value = "") {
    return value.replace(/\D/g, "");
  }

  function clearNumber(value = "") {
    return value.replace(/\D/g, "");
  }

  const onChange = (e) => {
    if (e.target.name === "phone" || e.target.name === "companyPhone") {
      e.target.value = clearPhoneNumber(e.target.value);
    }

    if (e.target.name === "fax") {
      e.target.value = clearNumber(e.target.value);
    }

    if (e.target.name === "firstName" || e.target.name === "lastName") {
      let reference = /^[A-Za-z]+$/;
      if (reference.test(e.target.value) || e.target.value === "") {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      }
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <>
      <div className={`container ${step === 1 ? "d-flex" : "d-none"} flex-column flex-lg-row align-items-center mb-3`}>
        <div className="flex-grow-1 d-flex flex-column justify-content-center p-3">
          <p className="title mb-2">Welcome to Appointment Cake!</p>
          <p className="subtitle mb-4">
            Complete these simple steps and start growing your business effortlessly
          </p>
          <div className="business-dialog mx-auto mt-3 w-100" style={{ maxWidth: "620px" }}>
            <div className="d-flex justify-content-between align-items-center w-100">
              <p className="form-title m-0">Get Started</p>
              <p className="form-subtitle m-0">3 steps left</p>
            </div>
            <div className="progress-bar-outer w-100">
              <span className="progress-baar bg-aqua"></span>
              <span className="progress-baar bg-gray"></span>
              <span className="progress-baar bg-gray"></span>
              <span className="progress-baar bg-gray"></span>
            </div>
            <form className="form w-100" onSubmit={handleSubmit} data-tut="register_detail">
              <div className="d-flex flex-column gap">
                <div className="form-row form-group my-0">
                  <div className="col-sm-6">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="First Name *"
                      name="firstName"
                      maxLength="50"
                      value={formData.firstName}
                      onChange={onChange}
                      required
                    />
                  </div>
                  <div className="col-sm-6">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Last Name *"
                      name="lastName"
                      maxLength="50"
                      value={formData.lastName}
                      onChange={onChange}
                      required
                    />
                  </div>
                </div>

                <div className="form-row form-group my-0">
                  <div className="col-sm-6">
                    <input
                      className="form-control"
                      type="email"
                      placeholder="Email Address *"
                      name="email"
                      pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
                      value={formData.email}
                      onChange={onChange}
                      required
                    />
                  </div>
                  <div className="col-sm-6">
                    <input
                      className="form-control"
                      type="tel"
                      placeholder="Phone Number*"
                      name="phone"
                      pattern=".{0}|.{10,}"
                      maxLength="15"
                      minLength="10"
                      title="Must be at least 10 numbers and maximum 15 numbers."
                      value={formData.phone}
                      onChange={onChange}
                      required
                    />
                  </div>
                </div>

                <div className="form-row form-group my-0">
                  <div className="col-sm-12">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Company Name *"
                      name="name"
                      maxLength="50"
                      value={formData.name}
                      onChange={onChange}
                      required
                    />
                  </div>
                </div>

                <div className="form-row form-group my-0">
                  <div className="col-sm-6">
                    <input
                      className="form-control"
                      type="email"
                      placeholder="Company Email *"
                      name="companyEmail"
                      pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
                      value={formData.companyEmail}
                      onChange={onChange}
                      required
                    />
                  </div>
                  <div className="col-sm-6">
                    <input
                      className="form-control"
                      type="tel"
                      placeholder="Business Phone Number *"
                      name="companyPhone"
                      pattern=".{0}|.{10,}"
                      maxLength="15"
                      minLength="10"
                      title="Must be at least 10 numbers and maximum 15 numbers."
                      value={formData.companyPhone}
                      onChange={onChange}
                      required
                    />
                  </div>
                </div>

                <div className="form-row form-group my-0">
                  <div className="col-sm-6">
                    <div className="input-box">
                      <input
                        className="form-control"
                        type={showPassword ? "text" : "password"}
                        placeholder="Password *"
                        name="password"
                        minLength="6"
                        maxLength="50"
                        value={formData.password}
                        onChange={onChange}
                        required
                      />
                      <i
                        className={`far ${showPassword ? "fa-eye" : "fa-eye-slash"}`}
                        onClick={() => setShowPassword(!showPassword)}
                      ></i>
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="input-box">
                      <input
                        className="form-control"
                        type={showConfPassword ? "text" : "password"}
                        placeholder="Confirm Password *"
                        name="password2"
                        minLength="6"
                        maxLength="50"
                        value={formData.password2}
                        onChange={onChange}
                        required
                      />
                      <i
                        className={`far ${showConfPassword ? "fa-eye" : "fa-eye-slash"}`}
                        onClick={() => setShowConfPassword(!showConfPassword)}
                      ></i>
                    </div>
                  </div>
                  {formError && <p className="text-danger p-2">{formError}</p>}
                </div>

                <p className="form-title m-0 text-left">How did you hear about us</p>
                <select
                  className="form-control"
                  name="reference"
                  placeholder="Word of Mouth"
                  onChange={onChange}
                  value={formData.reference}
                >
                  <option value="word of mouth">Word of Mouth</option>
                  <option value="email">Email</option>
                  <option value="socialmedia">Social Media</option>
                  <option value="radio">Radio</option>
                  <option value="podcast">Podcast</option>
                  <option value="search">Search engine (Google, Yahoo, etc.)</option>
                  <option value="other">Other</option>
                </select>
                {formData.reference === "other" && (
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Please specify"
                    name="reason"
                    value={formData.reason}
                    onChange={onChange}
                    required
                  />
                )}

                <div className="mb-5 mb-md-0 d-flex justify-content-end">
                  <input
                    type="submit"
                    className="btn btn-primary text-dark font-weight-bold"
                    style={{ padding: "10px 20px", fontSize: "15px" }}
                    value="NEXT STEP"
                    data-tut="register_button"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="d-none d-lg-block" style={{ width: "30%" }}>
          <img src={Banner} className="img-fluid" alt="Banner" />
        </div>
      </div>

      {step === 2 && (
        <Step2
          step={step}
          setStep={setStep}
          formData={formData}
          setFormData={setFormData}
          onChange={onChange}
          calenderEmail={calenderEmail}
          setCalendarEmail={setCalendarEmail}
        />
      )}

      {step === 3 && (
        <Step3
          formData={formData}
          setFormData={setFormData}
          onChange={onChange}
          setStep={setStep}
        />
      )}

      {step === 4 && (
        <Step4
          setStep={setStep}
          formData={formData}
          setFormData={setFormData}
          clearNumber={clearNumber}
          onChange={onChange}
          setserviceData={setserviceData}
          serviceData={serviceData}
          setServices={setServices}
          services={services}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  getCategories,
})(Step1);
