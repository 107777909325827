import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addClaim, setClaim } from "../../actions/claim";
import { useState } from "react";
import { getCurrentProfile } from "../../actions/profile";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faMobileScreen,
} from "@fortawesome/pro-regular-svg-icons";
import moment from "moment-timezone";

const CompanyItem = ({
  company,
  isAuthenticated,
  onHover,
  onLeave,
  addClaim,
  company: {
    _id,
    name,
    phone,
    location,
    services,
    description,
    store_hours,
    is_featured,
  },
  auth,
  setClaim,
  myclaims,
  claimed,
  getCurrentProfile,
  showService,
  page,
}) => {
  const history = useHistory();
  const d_names = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const date = new Date();
  const d_key = d_names[date.getDay()];
  const onHoverSingle = () => {
    onHover(company);
  };
  const [claimList, setClaimList] = useState(claimed);
  const [phoneNumber, setPhoneNumber] = useState();
  const onLeaveSingle = () => {
    onLeave(company);
  };
  const claimCkick = (id) => {
    addClaim(_id, auth.user._id);
    setClaimList(true);
    getCurrentProfile();
  };

  const today = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    0,
    0,
    0,
    0
  );
  const Day = moment(new Date(today))
    .add(company.store_hours[d_key]?.end_time, "minutes")
    .format("HH:MM");

  const currentTime = new Date();
  const formatCurrentTime = moment(currentTime).format("HH:MM");
  // console.log("store_hours[d_key].end : ", store_hours[d_key].end_time);
  // console.log("formatCurrentTime : ", formatCurrentTime);

  let formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ("" + str).replace(/\D/g, "");

    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match == null || undefined) {
      setPhoneNumber(company?.phone);
    } else {
      let FormatedNumber = +match[1] + "-" + match[2] + "-" + match[3];
      setPhoneNumber(FormatedNumber);
      if (match) {
        return FormatedNumber;
      }
      return null;
    }
  };
  useEffect(() => {
    formatPhoneNumber(company?.phone);
  }, []);

  return (
    <div
      className={
        company.isHover && company.isMarkerHover
          ? "card bg-white mb-3 company-card hoverClass isMarkerHover"
          : company.isHover
          ? "card bg-white mb-3 company-card hoverClass"
          : company.is_featured
          ? "card bg-white mb-3 company-card box-card"
          : "card bg-white grey mb-3 company-card"
      }
      onMouseEnter={onHover ? onHoverSingle : ""}
      onMouseLeave={onLeave ? onLeaveSingle : ""}
    >
      <div className="card-body">
        <div className={company.is_featured ? "box-header" : ""}>
          <h5
            className={
              !is_featured
                ? "card-title listComp favourite"
                : "card-title listComp favriot"
            }
          >
            <Link
              className="full-width"
              to={`/company/${_id}`}
              style={{ marginBottom: "-5px" }}
            >
              {name}
            </Link>

            {company.is_admin && isAuthenticated ? (
              claimList ? (
                <button
                  className="disable-claim-btn floatright full-height"
                  disabled
                >
                  Claimed
                </button>
              ) : (
                <button
                  className="claim-btn floatright full-height"
                  onClick={() => claimCkick(_id)}
                >
                  Claim
                </button>
              )
            ) : (
              ""
            )}
            {company.is_admin && !isAuthenticated ? (
              claimList ? (
                <button
                  className="disable-claim-btn floatright full-height"
                  disabled
                >
                  Claimed
                </button>
              ) : (
                <button
                  className="claim-btn floatright full-height"
                  onClick={() => history.push("/login")}
                >
                  Claim
                </button>
              )
            ) : (
              ""
            )}
            {/* {
            company.is_featured ?  <i className="fa-solid fa-star starComp"></i> : ''
          } */}
          </h5>

          {!is_featured && (
            <div className="tbl-info">
              <span className="font">
                <p>{description}</p>
              </span>
            </div>
          )}

          <h6 className="card-subtitle text-muted mb-3 bg  mt-1">
            {store_hours &&
            store_hours[d_key] &&
            store_hours[d_key].open &&
            formatCurrentTime < Day ? (
              <span className="badge badge-pill badge-success fr ">Open</span>
            ) : (
              <span className="badge badge-pill badge-danger fr">Closed</span>
            )}
          </h6>
        </div>
        <Link
          title={company?.name}
          to={`/company/${_id}`}
          className="btn btn-primary float-right rounded-circle"
        >
          <i className="fas fa-arrow-right"></i>
        </Link>

        <small className="text-muted">
          {is_featured && (
            <div className="tbl-info">
              <span className="font">
                <p>{description}</p>
              </span>
            </div>
          )}

          {/* {description && <div>{description}</div>} */}
          {location && (
            <span>
              {page == "favorite" && (
                <i>
                  <FontAwesomeIcon icon={faLocationDot} />
                </i>
              )}
              {"  "}
              {location.street_address}
              {page == "favorite" && (
                <Link
                  to={{
                    pathname: "/find",
                    search:
                      "?" +
                      new URLSearchParams({
                        // search: params.get("search"),
                        lat: location?.lat,
                        lng: location?.lng,
                        zoom: 15,
                      }).toString(),
                  }}
                  style={{ textDecoration: "underline", marginLeft: "5px" }}
                >
                  see map
                </Link>
              )}
            </span>
          )}

          {page == "favorite" && (
            <div className="tbl-info">
              <span>
                <i>
                  <FontAwesomeIcon icon={faMobileScreen} />
                </i>
                <p>{phoneNumber}</p> <a href={`tel:${phoneNumber}`}>call</a>
              </span>
            </div>
          )}

          {/* {phone && <span>{phone}</span>} */}
        </small>
        {company.category ? (
          <div className="mt-2 badge-listing">
            <span className="badge badge-pill badge-secondary mr-2">
              {company.category}
            </span>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

CompanyItem.propTypes = {
  company: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool,
  addClaim: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  setClaim: PropTypes.object.isRequired,
  myclaims: PropTypes.object.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  auth: state.auth,
  myclaims: state.company.myclaims,
});

export default connect(mapStateToProps, {
  addClaim,
  setClaim,
  getCurrentProfile,
})(CompanyItem);
