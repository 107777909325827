import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getAllPlans,
  cancelPlan,
  renewPlan,
  getAllInactivePlans,
} from "../../actions/feature";
import Moment from "react-moment";
import Swal from "sweetalert2";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Search from "../../asssets/icons/SearchL.svg";

const FeaturedLisitng = ({
  getAllPlans,
  plans,
  cancelPlan,
  renewPlan,
  getAllInactivePlans,
  inactivePlans,
}) => {
  useEffect(() => {
    getAllPlans();
  }, []);
  const [tab, setTab] = useState(0);
  const cancelCurrentPlan = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#27d7cf",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        cancelPlan(JSON.stringify({ id: id })).then((res) => getAllPlans());
        Swal.fire({
          confirmButtonColor: "#27d7cf",
          title: "Deleted!",
          text: "Plans are deleted.",
          icon: "success",
        });
      }
    });
  };
  const [show, setShow] = useState(false);
  const [currentId, setCurrentId] = useState();
  const [payableAmount, setPayableAmount] = useState("");
  const [plan, setPlan] = useState();
  const [isSearch, setIsSearch] = useState([]);
  const [searchString, setIsSearchString] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setShow(true);
    setCurrentId(id);
  };
  useEffect(() => {
    getAllInactivePlans();
    generatePlan();
  }, []);

  useEffect(() => {
    generatePlan();
  }, [payableAmount]);

  const generatePlan = () => {
    if (payableAmount == 10) {
      setPlan("1m");
    } else if (payableAmount == 20) {
      setPlan("2m");
    } else if (payableAmount == 28) {
      setPlan("3m");
    } else if (payableAmount == 50) {
      setPlan("6m");
    } else if (payableAmount == 100) {
      setPlan("1year");
    }
    // return plan;
  };

  const renewThePlan = (id) => {
    setShow(false);
    const response = renewPlan({
      amount: payableAmount,
      id: id,
      companyID: currentId,
      plan: plan,
    });
  };

  const onSearch = (e) => {
    const searchWord = e.target.value;
    if (searchWord == "") {
      setIsSearch([]);
      setIsSearchString("");
    } else {
      setIsSearch([searchWord]);
      setIsSearchString(searchWord);
      getAllPlans(searchString);
      getAllInactivePlans(searchString);
    }
  };
  const onSearchSubmit = () => {
    getAllPlans(searchString);
    getAllInactivePlans(searchString);
  };
  const clearSearchBar = () => {
    setIsSearch([]);
    setIsSearchString("");
    getAllPlans("");
    getAllInactivePlans("");
  };
  return (
    <>
      <div className="search-bar col-md-6 mx-auto my-4 min-w-678">
        <form action="#" onSubmit={onSearchSubmit}>
          <input
            className="search-input form-control pl-30"
            id="autocomplete"
            placeholder="Search"
            type="text"
            value={searchString}
            onChange={onSearch}
          ></input>
        </form>
        <div className="search-bar-clear">
          <button>
            {isSearch.length === 0 ? (
              ""
            ) : (
              <i className="fas fa-close mr-4" onClick={clearSearchBar}></i>
            )}
          </button>
        </div>

        <button>
          <img src={Search} alt="Search" onClick={onSearchSubmit} />
        </button>
      </div>
      <div className="d-flex">
        <p
          className={`text-sm font-bold px-5 py-10 cursor-pointer ${
            tab === 0 ? "border-b-orange" : null
          }`}
          onClick={() => setTab(0)}
        >
          Active
        </p>
        <p
          className={`text-sm font-bold px-5 py-10 cursor-pointer ${
            tab === 1 ? "border-b-orange" : null
          }`}
          onClick={() => setTab(1)}
        >
          InActive
        </p>
      </div>
      <div style={{ overflow: "auto" }}>
        <table className="table table-striped featured-table">
          <thead>
            <tr>
              <th>Company Info</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Duration</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {tab == 0 ? (
              plans && plans.length > 0 ? (
                plans.map((plan, i) => (
                  <tr className="booking-item-row" id={i}>
                    <td className="booking-date text-xs font-normal">
                      {plan.name}
                    </td>
                    <td className="booking-date text-xs font-normal">
                      {plan.featuredPlan.length > 0 &&
                        plan.featuredPlan.map((feature, i) => (
                          <>
                            <Moment
                              className="activate-table"
                              format="Do MMM yyyy"
                              date={feature.start_date}
                            />
                          </>
                        ))}
                    </td>
                    <td className="booking-date">
                      {plan.featuredPlan.length > 0 &&
                        plan.featuredPlan.map((feature, i) => (
                          <>
                            <Moment
                              className="activate-table"
                              format="Do MMM yyyy"
                              date={feature.end_date}
                            />
                          </>
                        ))}
                    </td>
                    <td className="booking-date">
                      {plan.featuredPlan.length > 0 &&
                        plan.featuredPlan.map((feature, i) => (
                          <>
                            <span className="small">
                              {feature.plan_name
                                ? feature.plan_name.includes("m")
                                  ? feature.plan_name
                                      .slice(0, -1)
                                      .concat(" Month")
                                  : "1 Year"
                                : "1 Year"}
                            </span>{" "}
                            <br />{" "}
                          </>
                        ))}
                    </td>
                    <td className="booking-date">
                      {plan.featuredPlan.length > 0 &&
                        plan.featuredPlan.map((feature, i) => (
                          <>
                            {feature.status == "active" ? (
                              <span className="booking-date badge badge-success status ml-0 capitalize">
                                {feature.status}
                              </span>
                            ) : (
                              <span className="booking-date badge badge-warning status ml-0 capitalize">
                                {feature.status}
                              </span>
                            )}
                            <br />{" "}
                          </>
                        ))}
                    </td>
                    <td>
                      <div
                        onClick={() => cancelCurrentPlan(plan._id)}
                        className="bg-red text-white badge badge-warning cursor-pointer"
                      >
                        Cancel
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="booking-item-row">
                  <td
                    className="booking-date"
                    colSpan="6"
                    style={{ textAlign: "center" }}
                  >
                    No active plans found
                  </td>
                </tr>
              )
            ) : inactivePlans && inactivePlans.length > 0 ? (
              inactivePlans.map((plan, i) => (
                <tr className="booking-item-row" id={i}>
                  <td className="booking-date activate-table text-xs font-normal pl-0">
                    {plan.name}
                  </td>
                  <td className="booking-date text-xs font-normal">
                    {plan.featuredPlan.length > 0 &&
                      plan.featuredPlan.map((feature, i) => (
                        <>
                          <Moment
                            className="activate-table"
                            format="Do MMM yyyy"
                            date={feature.start_date}
                          />
                        </>
                      ))}
                  </td>
                  <td className="booking-date text-xs font-normal">
                    {plan.featuredPlan.length > 0 &&
                      plan.featuredPlan.map((feature, i) => (
                        <>
                          <Moment
                            className="activate-table"
                            format="Do MMM yyyy"
                            date={feature.end_date}
                          />
                        </>
                      ))}
                  </td>
                  <td className="booking-date text-xs font-normal">
                    {plan.featuredPlan.length > 0 &&
                      plan.featuredPlan.map((feature, i) => (
                        <>
                          <span className=" activate-table">
                            {feature.plan_name
                              ? feature.plan_name.includes("m")
                                ? feature.plan_name
                                    .slice(0, -1)
                                    .concat(" Month")
                                : "1 Year"
                              : "1 Year"}
                          </span>{" "}
                        </>
                      ))}
                  </td>
                  <td className="booking-date ">
                    {plan.featuredPlan.length > 0 &&
                      plan.featuredPlan.map((feature, i) => (
                        <>
                          <span className=" badge badge-danger bg-red capitalize">
                            {feature.status}
                          </span>{" "}
                          <br />
                        </>
                      ))}
                  </td>
                  <td>
                    <div
                      // onClick={() => renewPlan(plan._id)}
                      onClick={() => handleShow(plan._id)}
                      className="badge badge-warning bg-aqua text-white cursor-pointer"
                      data-toggle="modal"
                      data-target="#exampleModal"
                    >
                      Renew
                    </div>
                    <Modal show={show} onHide={handleClose}>
                      <Modal.Header closeButton>
                        <Modal.Title>Choose a plan to Renew</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="card">
                          <div className="card-body">
                            <div className="purchaseRadio">
                              <div
                                className="form-check"
                                style={{ flex: "50%" }}
                              >
                                <label className="form-check-label lable-sepxrvice">
                                  <input
                                    type="radio"
                                    style={{ marginLeft: "8.75rem" }}
                                    className="form-check-input"
                                    name="feature"
                                    value="10"
                                    onChange={() => setPayableAmount(10)}
                                  />
                                  10$ / Month
                                </label>
                              </div>
                              <div
                                className="form-check"
                                style={{ flex: "50%" }}
                              >
                                <label className="form-check-label lable-service">
                                  <input
                                    type="radio"
                                    style={{ marginLeft: "8.75rem" }}
                                    className="form-check-input"
                                    name="feature"
                                    value="20"
                                    onChange={() => setPayableAmount(20)}
                                  />
                                  20$ / 2 months
                                </label>
                              </div>
                              <div
                                className="form-check"
                                style={{ flex: "50%" }}
                              >
                                <label className="form-check-label lable-service">
                                  <input
                                    type="radio"
                                    style={{ marginLeft: "8.75rem" }}
                                    className="form-check-input"
                                    name="feature"
                                    onChange={() => setPayableAmount(28)}
                                  />
                                  28$ / 3 months
                                </label>
                              </div>
                              <div
                                className="form-check"
                                style={{ flex: "50%" }}
                              >
                                <label className="form-check-label lable-service">
                                  <input
                                    type="radio"
                                    style={{ marginLeft: "8.75rem" }}
                                    className="form-check-input"
                                    name="feature"
                                    value="50"
                                    onChange={() => setPayableAmount(50)}
                                  />
                                  50$ / 6 months
                                </label>
                              </div>
                              <div
                                className="form-check"
                                style={{ flex: "50%" }}
                              >
                                <label className="form-check-label lable-service">
                                  <input
                                    type="radio"
                                    style={{ marginLeft: "8.75rem" }}
                                    className="form-check-input"
                                    name="feature"
                                    value="100"
                                    onChange={() => setPayableAmount(100)}
                                  />
                                  100$ / year
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                          Close
                        </Button>
                        <Button
                          variant="primary"
                          onClick={() => renewThePlan(plan._id)}
                        >
                          Save Changes
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </td>
                </tr>
              ))
            ) : (
              <tr className="booking-item-row">
                <td
                  className="booking-date"
                  colSpan="6"
                  style={{ textAlign: "center" }}
                >
                  No Inactive Plans found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

FeaturedLisitng.propTypes = {
  getAllPlans: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  plans: state.plans.featuredPlans,
  getAllInactivePlans: PropTypes.func.isRequired,
  renewPlan: PropTypes.func.isRequired,
  cancelPlan: state.plans.cancelPlan,
  inactivePlans: state.plans.inactivePlans,
});

export default connect(mapStateToProps, {
  getAllPlans,
  cancelPlan,
  getAllInactivePlans,
  renewPlan,
})(FeaturedLisitng);
