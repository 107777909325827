import React, { Fragment, useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getCompanyById, addLike, removeLike } from "../../actions/company";
import { createBooking } from "../../actions/booking";
import Spinner from "../layout/Spinner";
import CompanyServices from "./CompanyServices";
import moment from "moment";
import { Helmet } from "react-helmet";
// import config from '../../config/default.json';

const Company = ({
  addLike,
  removeLike,
  createBooking,
  getCompanyById,
  company: { company, loading },
  history,
  match
}) => {
  const d_names = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ];
  const date = new Date();
  const today = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    0,
    0,
    0,
    0
  );
  const d_key = d_names[date.getDay()];
  const providerGapi = window.gapi;
  /* 
	  Update with your own Client Id and Api key 
	*/

  const [serviceData, setService] = useState({
    service: {
      name: null,
      book_online: null,
      call_to_book: null,
      description: null,
      price: null,
      service_duration: null,
      start_time: null,
      date: today
    }
  });

  const [selectedDate, setDate] = useState({
    selectedDate: today
  });
  const { service } = serviceData;

  const [activeIndex, setItem] = useState(0);

  const setCurrentDate = selectedDate => {
    setService({ service: { ...serviceData, date: selectedDate } });
    setDate({ selectedDate: selectedDate });
    if (monthFilter) {
      getAvailableDaysByMonth(monthFilter.format("MMMM"));
    }
  };

  const setCurrentService = selectedService => {
    setService({
      service: { ...selectedService, date: serviceData["date"] }
    });
  };

  const onChange = e =>
    setService({ ...serviceData, [e.target.name]: e.target.value });

  useEffect(() => {
    getCompanyById(match.params.id);

    serviceData["company"] = company;
    serviceData["start_time"] = service.start_time;
    serviceData["start_date"] = service.date;
    serviceData["duration"] = service.service_duration;
    serviceData["service"] = service;
    serviceData["date"] = service.date;
    setService(serviceData);
  }, [getCompanyById, match.params.id, serviceData, selectedDate]);

  const onBookAppointment = e => {
    e.preventDefault();
    createBooking(serviceData, history);
  };
  const [providerBooking, setProviderBooking] = useState([]);

  // This is for the filtering system/feature -- jays code

  const [availableDays, setAvailableDays] = useState([]);
  const [availableDaysByMonth, setAvailableDaysByMonth] = useState([]);

  const [monthFilter, setMonthFilter] = useState(null);

  useEffect(() => {
    const getAvailableDays = async () => {
      if (company !== null) {
        const mToday = moment();
        const mEnd = moment().day(90);
        let available_dates = [];
        // If you want an exclusive end date (half-open interval)
        for (var m = moment(mToday); m.isBefore(mEnd); m.add(1, "days")) {
          if (!Array.isArray(available_dates[m.format("M")]))
            available_dates[m.format("M")] = [];
          if (company.store_hours[m.format("dddd")].open)
            available_dates[m.format("M")][m.format("D")] = moment(m);
        }

        setAvailableDays(available_dates);
      }
    };

    getAvailableDays();
  }, [company, serviceData]);

  useEffect(() => {
    const getAvailableDaysByMonth = month => {
      if (company !== null && !monthFilter) {
        const mToday = moment();
        const mEnd = moment().day(90);
        let available_dates = [];
        // If you want an exclusive end date (half-open interval)
        for (var m = moment(mToday); m.isBefore(mEnd); m.add(1, "days")) {
          if (month && m.format("MMMM") === month) {
            if (!Array.isArray(available_dates[m.format("M")]))
              available_dates[m.format("M")] = [];
            if (company.store_hours[m.format("dddd")].open)
              available_dates[m.format("M")][m.format("D")] = moment(m);
          } else {
            if (!Array.isArray(available_dates[m.format("M")]))
              available_dates[m.format("M")] = [];
            if (company.store_hours[m.format("dddd")].open)
              available_dates[m.format("M")][m.format("D")] = moment(m);
          }
        }

        setAvailableDaysByMonth(available_dates);
      }
    };
    getAvailableDaysByMonth();
  }, [company, monthFilter]);

  const getAvailableDaysByMonth = month => {
    if (company !== null) {
      const mToday = moment();
      const mEnd = moment().day(90);
      let available_dates = [];

      // If you want an exclusive end date (half-open interval)
      for (var m = moment(mToday); m.isBefore(mEnd); m.add(1, "days")) {
        if (month && m.format("MMMM") === month) {
          if (!Array.isArray(available_dates[m.format("M")]))
            available_dates[m.format("M")] = [];
          if (company.store_hours[m.format("dddd")].open)
            available_dates[m.format("M")][m.format("D")] = moment(m);
        }
      }

      setAvailableDaysByMonth(available_dates);
    }
  };

  const handleMonthFilter = async (e, monthFilter) => {
    setMonthFilter(moment().month(e.target.value));
    await getAvailableDaysByMonth(e.target.value);
  };

  // const incrementMonth
  const incrementMonth = () => {
    if (monthFilter === null) {
      setMonthFilter(moment().add(1, "M"));
      getAvailableDaysByMonth(
        moment()
          .add(1, "M")
          .format("MMMM")
      );
    } else {
      setMonthFilter(monthFilter.add(1, "M"));
      getAvailableDaysByMonth(monthFilter.format("MMMM"));
    }
  };

  const decrementMonth = () => {
    if (monthFilter === null) {
      setMonthFilter(moment().subtract(1, "M"));
      getAvailableDaysByMonth(
        moment()
          .subtract(1, "M")
          .format("MMMM")
      );
    } else {
      setMonthFilter(monthFilter.subtract(1, "M"));
      getAvailableDaysByMonth(monthFilter.format("MMMM"));
    }
  };

  return loading || company === null ? (
    <Spinner />
  ) : (
    <Fragment>
      <Helmet>
        <title>Appointment Cake</title>
        <meta
          name="description"
          content="Whatever or whoever you’re looking for,
                   Appointment Cake has you covered."
        />
      </Helmet>
      <div className="row p-4">
        <div className="px-4"></div>
        <div className="col">
          <h3>{company.name}</h3>
          <p>
            {company.description && <Fragment>{company.description}</Fragment>}
            <br />
            <span className="text-muted">
              {company.store_hours[d_key].open ? (
                <Fragment>
                  <span className="text-success">
                    Open{" "}
                    {moment(new Date(today))
                      .add(company.store_hours[d_key].start_time, "minutes")
                      .format("h:mm a")}{" "}
                    -{" "}
                    {moment(new Date(today))
                      .add(company.store_hours[d_key].end_time, "minutes")
                      .format("h:mm a")}
                  </span>
                </Fragment>
              ) : (
                <span className="badge badge-danger">Closed today</span>
              )}
            </span>{" "}
            | {company.location.street_address} | {company.phone}{" "}
          </p>
        </div>
      </div>

      <div className="row px-5">
        <div className="col-md-2 p-2 d-flex flex-column">
          <div className="input-group mb-4">
            <div className="input-group-prepend">
              <button
                onClick={decrementMonth}
                className="btn btn-outline-secondary"
              >
                <i className="fas fa-chevron-left"></i>
              </button>
            </div>
            <select
              className="form-control"
              value={monthFilter?.format("MMMM")}
              onChange={e => handleMonthFilter(e)}
            >
              {availableDays?.map((month, i) => (
                <option
                  key={i}
                  value={moment()
                    .month(i - 1)
                    .format("MMMM")}
                >
                  {moment()
                    .month(i - 1)
                    .format("MMMM")}
                </option>
              ))}
            </select>

            <div className="input-group-append">
              <button
                onClick={incrementMonth}
                className="btn btn-outline-secondary"
              >
                <i className="fas fa-chevron-right"></i>
              </button>
            </div>
          </div>

          <div
            className="card flex-grow-1"
            style={{
              maxHeight: "calc(100vh - 15em)",
              overflow: "auto"
            }}
          >
            <div className="card-body d-grid gap-2">
              {availableDaysByMonth?.map((month, i) => {
                return (
                  <div key={i}>
                    {moment()
                      .month(i - 1)
                      .format("MMMM")}
                    {month.map((day, j) => {
                      const itemIndex = moment(day).format("MMDD");
                      const activeItem =
                        activeIndex === itemIndex
                          ? "bg-primary text-white"
                          : "";
                      return (
                        <button
                          onClick={() => {
                            setCurrentDate(
                              moment(day)
                                .hours(0)
                                .minutes(0)
                                .seconds(0)._d
                            );
                            setItem(itemIndex);
                          }}
                          className={`btn btn-light btn-block mb-1 ${activeItem}`}
                          key={itemIndex}
                        >
                          <h3>{moment(day).format("D")}</h3>
                          {moment(day).format("dddd")}
                        </button>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="col-md-4 p-2">
          {selectedDate && (
            <CompanyServices
              company={company}
              selectedService={setCurrentService}
              selectedDate={selectedDate}
              calendarEvent={providerBooking}
              isSharing={true}
            />
          )}
        </div>
        <div
          className="col-md-6 p-2 d-flex flex-column"
          style={{
            maxHeight: "calc(100vh - 10em)",
            overflow: "auto"
          }}
        >
          {service && service.hasOwnProperty("name") && service.name !== null && (
            <form
              className="card bg-white flex-grow-1"
              onSubmit={onBookAppointment}
            >
              <div className="card-header">
                <h4 className="card-title mt-3">{service.name}</h4>
              </div>
              <div className="card-body">
                <p>
                  <strong>{service.service_duration} Minutes</strong>
                </p>
                <p>
                  {moment(service.start_time).format("dddd, D, MMMM")}
                  <br />
                  <strong>
                    {moment(service.start_time).format("h:mm a")}
                  </strong>{" "}
                  to{" "}
                  <strong>
                    {moment(service.start_time)
                      .add(service.service_duration, "minutes")
                      .format("h:mm a")}
                  </strong>
                </p>
                <p className="text-muted">
                  <small>{service.description}</small>
                </p>
                <input
                  name="service_start"
                  value={service.start_time}
                  type="hidden"
                />
              </div>
              <div className="card-footer"></div>
            </form>
          )}
        </div>
      </div>
    </Fragment>
  );
};

Company.protoTypes = {
  createBooking: PropTypes.func.isRequired,
  getCompanyById: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  serviceData: PropTypes.object
};

const mapStateToProps = state => ({
  company: state.company,
  serviceData: state.serviceData
});

export default connect(
  mapStateToProps,
  {
    createBooking,
    getCompanyById,
    addLike,
    removeLike
  }
)(withRouter(Company));
