import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import { setAlert } from "../../actions/alert";
import { register } from "../../actions/auth";
import PropTypes from "prop-types";
import Tour from "reactour";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import BuisnessIcon from "../../images/giftbox.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGiftCard } from "@fortawesome/pro-regular-svg-icons";
import { faFaceParty } from "@fortawesome/pro-regular-svg-icons";
import { Helmet } from "react-helmet";

//import axios from 'axios';
// import ReactPhoneInput from "react-phone-input-2";
// import 'react-phone-number-input/style.css'
// import PhoneInput from 'react-phone-number-input'

const RegisterComp = ({ setAlert, register, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    password2: "",
  });
  // const [isTourOpen, setisTourOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);
  const refferar = useLocation();
  const search = refferar.search;
  const refferalUserId = new URLSearchParams(search).get("referrer");
  // const [phone, setPhone] = useState()
  // const closeTour = () => {
  //   setisTourOpen(false);
  // };
  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);
  const history = useHistory();

  const { firstName, lastName, email, phone, password, password2 } = formData;
  const onChange = (e) => {
    if (e.target.name === "firstName" || e.target.name === "lastName") {
      let reference = /^[A-Za-z]+$/;
      if (reference.test(e.target.value) || e.target.value === "") {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        }); // can be used for the onChange of all fields, automatically pulling the name of the field as the key
      }
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      }); // can be used for the onChange of all fields, automatically pulling the name of the field as the key
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password !== password2) {
      // brought in with the /actions/alert, but then must export it with 'connect' at the bottom of the file
      // Would be props.setAlert, but we destructured at the top
      setAlert("Passwords do not match", "danger");
    } else {
      register({ firstName, lastName, email, phone, password });
    }
  };

  // Redirect is authenticated
  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <>
      <Helmet>
        <title>Appointment Cake</title>
        <meta
          name="description"
          content="Whatever or whoever you’re looking for,
                   Appointment Cake has you covered."
        />
      </Helmet>
      <div className="edit-service">
        <button>
          <i
            className="fas fa-arrow-left"
            onClick={() => history.push("/login")}
          ></i>
        </button>
      </div>
      <section
        className="landing text-center d-flex justify-content-center align-items-center white-bg header-bg signup-page"
        id="bottom-wave"
      >
        {/* <Tour
          steps={steps}
          isOpen={isTourOpen}
          onRequestClose={closeTour}
          onAfterOpen={disableBody}
          onBeforeClose={enableBody}
        /> */}
        {/* <button
          id="change-log"
          type="button"
          onClick={() => setisTourOpen(true)}
          className="btn btn-info"
        >
          <i className="fas fa-edit"></i> <span> Guide </span>{" "}
        </button> */}
        {/* <div className="mobile-dahboard-header">
        <a
          className="btn btn-secondary rounded-circle mobile-top-arrow mobile-show"
          href="/admin"
        >
          <i className="fas fa-arrow-left" aria-hidden="true"></i>
        </a>
      </div> */}

        <div className="form-outer">
          <h1 className="text-primary">Sign Up</h1>
          <p className="lead">
            What type of Account Are You Trying to Create ?
          </p>

          <div className="signup-row">
            <Link
              to={{
                pathname: `/business-register`,
                state: { refferalUserId, url: history?.location?.state?.url },
              }}
              className="business"
            >
              <div>
                <i>
                  <FontAwesomeIcon
                    icon={faGiftCard}
                    className="fa-4x"
                    style={{ color: "#ffab68" }}
                  />
                </i>

                <span>Business</span>
              </div>
            </Link>

            <Link
              to={{
                pathname: `/create-account`,
                state: { refferalUserId,url: history?.location?.state?.url },
              }}
            >
              <div className="business">
                <i>
                  <FontAwesomeIcon
                    icon={faFaceParty}
                    className="fa-4x"
                    style={{ color: "#ffab68" }}
                  />

                  {/* <img src={BuisnessIcon} /> */}
                </i>
                <span>Personal</span>
              </div>
            </Link>
          </div>

          <p className="my-3 signup-bottom-txt" data-tut="signup_link">
            Already have an account?{" "}
            <Link to="/login" className="font-weight">
              Sign In
            </Link>
          </p>
        </div>
      </section>
    </>
  );
};

// const steps = [
//   {
//     selector: '[data-tut="register_detail"]',
//     content: `Add your personal details to create a new account.`
//   },
//   {
//     selector: '[data-tut="register_button"]',
//     content: `Click on register button to confirm registration.`
//   },
//   {
//     selector: '[data-tut="signup_link"]',
//     content: `If you already have an account click on "Sign up" link.`
//   }
// ];

RegisterComp.propTypes = {
  setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

// Use the connect function to pass our setAlert into props
export default connect(mapStateToProps, { setAlert, register })(RegisterComp);
