import React, { Fragment, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Spinner from '../layout/Spinner';
import ProfileItem from '../profiles/ProfileItem';
import { getProfiles } from '../../actions/profile';
import { Helmet } from 'react-helmet';

const Profiles = ({ getProfiles, profile: {profiles, loading }}) => {

    useEffect(() => {
        getProfiles();
    }, [getProfiles])

    return (
      <Fragment>
        <Helmet>
          <title>Appointment Cake</title>
          <meta
            name="description"
            content="Whatever or whoever you’re looking for,
                   Appointment Cake has you covered."
          />
        </Helmet>
        {loading ? (
          <Spinner />
        ) : (
          <Fragment>
            <h1 className="text-primary">Profiles</h1>
            <p className="lead">
              <i className="fab fa-connectdevelop"></i> Browse Profiles
            </p>
            <div className="profiles">
              {profiles.length > 0 ? (
                profiles.map(profile => (
                  <ProfileItem key={profile._id} profile={profile} />
                ))
              ) : (
                <h4>No Profiles found...</h4>
              )}
            </div>
          </Fragment>
        )}
      </Fragment>
    );
}

Profiles.propTypes = {
    getProfiles: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    profile: state.profile
})
export default connect(mapStateToProps, {getProfiles})(Profiles);
