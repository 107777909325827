import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../actions/auth";
import Spinner from "../layout/Spinner";
import LogoImage from "../../images/Apptt-Cake-logo-white.png";
import { Helmet } from "react-helmet";
const Login = ({ login, isAuthenticated, loading }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: ""
  });
  const [showPassword, setShowPassword] = useState(false);
  const token = localStorage.getItem("token");
  const { email, password } = formData;

  const onChange = e =>
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    }); // can be used for the onChange of all fields, automatically pulling the name of the field as the key

  const onSubmit = async e => {
    e.preventDefault();
    // linkedin track
    login(email, password);
  };

  // Redirect if logged in
  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }
  return loading ? (
    <Spinner />
  ) : (
    <section className="login_bg landing text-center d-flex justify-content-center align-items-center">
      <Helmet>
        <title>Appointment Cake</title>
        <meta
          name="description"
          content="Whatever or whoever you’re looking for,
                   Appointment Cake has you covered."
        />
      </Helmet>
      <div className="form_width">
        <img className="sigin_logo" src={LogoImage} alt="logo" />
        <h1 className="signin_text large text-primary">Sign In</h1>
        <p className="lead none_text">Login to Your Account</p>
        <form
          className="form"
          onSubmit={e => {
            onSubmit(e);
          }}
        >
          <div className="form-group">
            <input
              className="form-control"
              id="email"
              type="email"
              placeholder="Email Address"
              name="email"
              value={email}
              onChange={e => onChange(e)}
              required
            />
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-12 input-box">
                {showPassword ? (
                  <input
                    className="form-control"
                    type="text"
                    id="password"
                    placeholder="Password"
                    name="password"
                    minLength="6"
                    maxLength="50"
                    value={password}
                    onChange={e => onChange(e)}
                    required
                  />
                ) : (
                  <input
                    className="form-control"
                    type="password"
                    id="password"
                    placeholder="Password"
                    name="password"
                    minLength="6"
                    maxLength="50"
                    value={password}
                    onChange={e => onChange(e)}
                    required
                  />
                )}
                {showPassword ? (
                  <i
                    className="far fa-eye"
                    onClick={() => setShowPassword(false)}
                  ></i>
                ) : (
                  <i
                    className="far fa-eye-slash"
                    onClick={() => setShowPassword(true)}
                  ></i>
                )}
              </div>
            </div>
          </div>
          <input
            type="submit"
            className="btn btn-primary btn-block submit_btn"
            value="Login"
          />
        </form>
        <p className="forget_signin my-1 h6">
          Forgot Password?{" "}
          <Link to="/forgot" className="font-weight">
            Reset
          </Link>
        </p>
        <p className="dont_account my-4">
          Don't have an account?{" "}
          <Link to="/register" className="font-weight">
            Sign Up
          </Link>
        </p>

        <div className="in-center">
          <a
            href="https://www.facebook.com/AppointmentCake"
            className="youtube social"
          >
            <i className="fa-brands fa-facebook fa-lg social-icon-color"></i>
          </a>

          <a
            href="https://www.linkedin.com/company/appointment-cake-app/"
            className="youtube social"
          >
            <i
              className="fa-brands fa-linkedin-in fa-lg social-icon-color"
              style={{ padding: "15px" }}
            ></i>
          </a>

          <a
            href="https://www.instagram.com/appointmentcake/"
            className="youtube social"
          >
            <i className="fa-brands fa-instagram fa-lg social-icon-color"></i>
          </a>
        </div>
      </div>
    </section>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(
  mapStateToProps,
  { login }
)(Login);
