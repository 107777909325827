import {
	GET_MESSAGE,
	MESSAGE_ERROR,
	CLEAR_MESSAGE,
	UPDATE_MESSAGE,
	GET_MESSAGES,
	ADD_MESSAGE,
	REMOVE_MESSAGE,
} from '../actions/types';

const initialState = {
	message: null,
	messages: [],
	loading_messages: true,
	error: {},
};

export default function message(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case GET_MESSAGE:
			return {
				...state,
				message: payload,
				loading_messages: false,
			};
		case MESSAGE_ERROR:
			return {
				...state,
				error: payload,
				loading_messages: false,
				message: null,
			};
		case CLEAR_MESSAGE:
			return {
				...state,
				message: null,
				messages: [],
				loading_messages: false,
			};
		case UPDATE_MESSAGE:
			return {
				...state,
				message: payload,
				loading_messages: false,
			};
		case GET_MESSAGES:
			return {
				...state,
				messages: payload,
				loading_messages: false,
			};
		case ADD_MESSAGE:
			return {
				...state,
				message: payload,
				loading_messages: false,
			};
		case REMOVE_MESSAGE:
			return {
				...state,
				message: payload,
				loading_messages: false,
			};
		default:
			return state;
	}
}
