import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getAllInactivePlans } from "../../actions/feature";
import { Link } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { renewPlan } from "../../actions/feature";
import Moment from "react-moment";

const InactiveFeaturedLisitng = ({ getAllInactivePlans, plans, renewPlan }) => {
  const [show, setShow] = useState(false);
  const [currentId, setCurrentId] = useState();
  const [payableAmount, setPayableAmount] = useState("");
  const [plan, setPlan] = useState();

  const handleClose = () => setShow(false);
  const handleShow = id => {
    setShow(true);
    setCurrentId(id);
  };
  useEffect(() => {
    getAllInactivePlans();
    generatePlan();
  }, []);

  useEffect(() => {
    generatePlan();
  }, [payableAmount]);

  const generatePlan = () => {
    if (payableAmount == 10) {
      setPlan("1m");
    } else if (payableAmount == 20) {
      setPlan("2m");
    } else if (payableAmount == 28) {
      setPlan("3m");
    } else if (payableAmount == 50) {
      setPlan("6m");
    } else if (payableAmount == 100) {
      setPlan("1year");
    }
    // return plan;
  };

  const renewThePlan = id => {
    setShow(false);
    const response = renewPlan({
      amount: payableAmount,
      id: id,
      companyID: currentId,
      plan: plan
    });
  };

  return (
    <div style={{ overflow: "auto" }}>
      <table className="table table-striped featured-table">
        <thead>
          <tr>
            <th>Company Info</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Duration</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {plans && plans.length > 0 ? (
            plans.map((plan, i) => (
              <tr className="booking-item-row" id={i}>
                <td className="booking-date activate-table">{plan.name}</td>
                <td className="booking-date">
                  {plan.featuredPlan.length > 0 &&
                    plan.featuredPlan.map((feature, i) => (
                      <>
                        <Moment
                          className="activate-table"
                          format="Do MMM yyyy"
                          date={feature.start_date}
                        />
                      </>
                    ))}
                </td>
                <td className="booking-date">
                  {plan.featuredPlan.length > 0 &&
                    plan.featuredPlan.map((feature, i) => (
                      <>
                        <Moment
                          className="activate-table"
                          format="Do MMM yyyy"
                          date={feature.end_date}
                        />
                      </>
                    ))}
                </td>
                <td className="booking-date">
                  {plan.featuredPlan.length > 0 &&
                    plan.featuredPlan.map((feature, i) => (
                      <>
                        <span className=" activate-table">
                          {feature.plan_name
                            ? feature.plan_name.includes("m")
                              ? feature.plan_name.slice(0, -1).concat(" Month")
                              : "1 Year"
                            : "1 Year"}
                        </span>{" "}
                      </>
                    ))}
                </td>
                <td className="booking-date">
                  {plan.featuredPlan.length > 0 &&
                    plan.featuredPlan.map((feature, i) => (
                      <>
                        <span className="booking-date badge badge-danger">
                          {feature.status}
                        </span>{" "}
                        <br />
                      </>
                    ))}
                </td>
                <td>
                  <button
                    type="button"
                    // onClick={() => renewPlan(plan._id)}
                    onClick={() => handleShow(plan._id)}
                    className="btn btn-sm btn-primary"
                    data-toggle="modal"
                    data-target="#exampleModal"
                  >
                    Renew
                  </button>
                  <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Choose a plan to Renew</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="card">
                        <div className="card-body">
                          <div className="purchaseRadio">
                            <div className="form-check" style={{ flex: "50%" }}>
                              <label className="form-check-label lable-sepxrvice">
                                <input
                                  type="radio"
                                  style={{ marginLeft: "8.75rem" }}
                                  className="form-check-input"
                                  name="feature"
                                  value="10"
                                  onChange={() => setPayableAmount(10)}
                                />
                                10$ / Month
                              </label>
                            </div>
                            <div className="form-check" style={{ flex: "50%" }}>
                              <label className="form-check-label lable-service">
                                <input
                                  type="radio"
                                  style={{ marginLeft: "8.75rem" }}
                                  className="form-check-input"
                                  name="feature"
                                  value="20"
                                  onChange={() => setPayableAmount(20)}
                                />
                                20$ / 2 months
                              </label>
                            </div>
                            <div className="form-check" style={{ flex: "50%" }}>
                              <label className="form-check-label lable-service">
                                <input
                                  type="radio"
                                  style={{ marginLeft: "8.75rem" }}
                                  className="form-check-input"
                                  name="feature"
                                  onChange={() => setPayableAmount(28)}
                                />
                                28$ / 3 months
                              </label>
                            </div>
                            <div className="form-check" style={{ flex: "50%" }}>
                              <label className="form-check-label lable-service">
                                <input
                                  type="radio"
                                  style={{ marginLeft: "8.75rem" }}
                                  className="form-check-input"
                                  name="feature"
                                  value="50"
                                  onChange={() => setPayableAmount(50)}
                                />
                                50$ / 6 months
                              </label>
                            </div>
                            <div className="form-check" style={{ flex: "50%" }}>
                              <label className="form-check-label lable-service">
                                <input
                                  type="radio"
                                  style={{ marginLeft: "8.75rem" }}
                                  className="form-check-input"
                                  name="feature"
                                  value="100"
                                  onChange={() => setPayableAmount(100)}
                                />
                                100$ / year
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose}>
                        Close
                      </Button>
                      <Button
                        variant="primary"
                        onClick={() => renewThePlan(plan._id)}
                      >
                        Save Changes
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </td>
              </tr>
            ))
          ) : (
            <tr className="booking-item-row">
              <td
                className="booking-date"
                colSpan="6"
                style={{ textAlign: "center" }}
              >
                No Inactive Plans found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

InactiveFeaturedLisitng.propTypes = {
  getAllInactivePlans: PropTypes.func.isRequired,
  renewPlan: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  plans: state.plans.inactivePlans
  // auth: state.auth,
});

export default connect(
  mapStateToProps,
  { getAllInactivePlans, renewPlan }
)(InactiveFeaturedLisitng);
