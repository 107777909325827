import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const Alert = ({ alerts }) =>
  alerts !== null &&
  alerts.length > 0 &&
  alerts.map(alert => (
    <div
      style={{
        // display: "block",
        opacity: 1,
        position: "fixed",
        top: "50px",
        right: "15px",
        minWidth: "250px",
        zIndex: "10"
      }}
      key={alert.id}
      className="toast"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div className={`toast-header`}>
        <strong className="mr-auto">
          {alert.alertType === "danger" ? "Error" : "Success"}
        </strong>
      </div>
      <div className="toast-body">
        {alert.alertType === "danger" ? (
          <i style={{ color: "red" }} className="fas fa-times-circle"></i>
        ) : (
          <i style={{ color: "green" }} className="fas fa-times-circle"></i>
        )}{" "}
        {alert.msg}
      </div>
    </div>
  ));

Alert.propTypes = {
  alerts: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  alerts: state.alert // this is defined in the reducers/index.js in the combineReducers func
});

export default connect(mapStateToProps)(Alert);
