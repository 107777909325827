import React, { useState, useEffect, Fragment } from "react";
import { withRouter } from "react-router-dom";
import MapContainer from "../maps/MapContainer";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import BusinessFormAutocomplete from "../maps/BusinessFormAutocomplete";
import { editBusiness } from "../../actions/company";
import { Helmet } from "react-helmet";
import Select from "react-select";
import AutoComplete from "../maps/Autocomplete";
import { checkWaitlistOnHoursChange } from "../../actions/booking";

const initialState = {
  name: "",
  website: "",
  description: "",
  street_address: "",
  street_address_2: "",
  city: "",
  province: "",
  country: "",
  postal: "",
  email: "",
  phone: "",
  fax: "",
  lat: "",
  lng: "",
  facebook: "",
  twitter: "",
  linkedin: "",
  instagram: "",
  Sunday_open: false,
  Sunday_start_time: 540,
  Sunday_end_time: 1020,
  Monday_open: false,
  Monday_start_time: 540,
  Monday_end_time: 1020,
  Tuesday_open: false,
  Tuesday_start_time: 540,
  Tuesday_end_time: 1020,
  Wednesday_open: false,
  Wednesday_start_time: 540,
  Wednesday_end_time: 1020,
  Thursday_open: false,
  Thursday_start_time: 540,
  Thursday_end_time: 1020,
  Friday_open: false,
  Friday_start_time: 540,
  Friday_end_time: 1020,
  Saturday_open: false,
  Saturday_start_time: 540,
  Saturday_end_time: 1020,
  markers: [],
  geoLocation: "",
  category: "",
  subcategory: "",
  calendarurl: "",
  calendartype: "",
  googleAuthBusiness: "",
  googleRefreshTokenBusiness: "",
  googleUserBusiness: "",
};

const EditUserBusiness = ({
  history,
  editBusiness,
  setCompany,
  categoryList,
  companyList,
}) => {
  // const company = useLocation();
  const [weekDay, setWeekDay] = useState([0, 1, 2, 3, 4, 5, 6]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [isSelected, setIsSelected] = useState({
    category: false,
    subcategory: false,
    location: false,
  });
  const [currentCompany, setCurrentCompany] = useState();
  const [formData, setFormData] = useState(initialState);
  const [facebookError, setfacebookError] = useState("");
  const [linkedinError, setlinkedinError] = useState("");
  const [instagramError, setinstagramError] = useState("");

  const [formError, setFormError] = useState("");
  const [oldStoreHours, setOldStoreHours] = useState();
  const d_names = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  useEffect(() => {
    const cid = history.location.pathname.split("/")[3];
    const company = companyList?.filter((e) => e?._id === cid);

    setCurrentCompany(company[0]);
    setCompany(company[0]?.name);
  }, [history.location.pathname]);
  useEffect(() => {
    if (currentCompany !== null && currentCompany !== undefined) {
      const companyData = { ...initialState };
      const storeHours = {};
      for (const key in currentCompany) {
        if (key in companyData) companyData[key] = currentCompany[key];
      }

      for (const key in currentCompany?.location) {
        if (key in companyData)
          companyData[key] = currentCompany?.location[key];
      }

      for (const key in currentCompany?.social) {
        if (key in companyData) companyData[key] = currentCompany?.social[key];
      }

      for (const key in currentCompany?.store_hours) {
        companyData[key + "_open"] = currentCompany?.store_hours[key].open;
        companyData[key + "_start_time"] =
          currentCompany?.store_hours[key].start_time;
        companyData[key + "_end_time"] =
          currentCompany?.store_hours[key].end_time;

        storeHours[key + "_open"] = currentCompany?.store_hours[key].open;
        storeHours[key + "_start_time"] =
          currentCompany?.store_hours[key].start_time;
        storeHours[key + "_end_time"] =
          currentCompany?.store_hours[key].end_time;

        companyData["markers"][0] = {
          text: currentCompany?.name,
          lat: companyData["lat"],
          lng: companyData["lng"],
          company: currentCompany,
        };

        companyData["geoLocation"] = {
          type: "Point",
          coordinates: [companyData["lng"] * 1, companyData["lat"] * 1],
        };
      }

      // Set the initial store hours state
      setOldStoreHours(storeHours);
      console.log('store hours', storeHours)

      // Check if the properties exist before assigning them
      if (currentCompany["calendartype"] !== undefined) {
        companyData["calendartype"] = currentCompany["calendartype"];
      }
      if (currentCompany["calendarurl"] !== undefined) {
        companyData["calendarurl"] = currentCompany["calendarurl"];
      }
      if (currentCompany["googleAuthBusiness"] !== undefined) {
        companyData["googleAuthBusiness"] =
          currentCompany["googleAuthBusiness"];
      }
      if (currentCompany["googleRefreshTokenBusiness"] !== undefined) {
        companyData["googleRefreshTokenBusiness"] =
          currentCompany["googleRefreshTokenBusiness"];
      }
      if (currentCompany["googleUserBusiness"] !== undefined) {
        companyData["googleUserBusiness"] =
          currentCompany["googleUserBusiness"];
      }

      // console.log("companyData innn", companyData);
      setFormData(companyData);
    }
  }, [currentCompany]);

  const {
    name,
    website,
    description,
    street_address,
    street_address_2,
    city,
    province,
    country,
    postal,
    email,
    phone,
    fax,
    lat,
    lng,
    facebook,
    instagram,
    linkedin,
    /*Sunday_open,
    Sunday_start_time,
    Sunday_end_time,
    Monday_open,
    Monday_start_time,
    Monday_end_time,
    Tuesday_open,
    Tuesday_start_time,
    Tuesday_end_time,
    Wednesday_open,
    Wednesday_start_time,
    Wednesday_end_time,
    Thursday__open,
    Thursday_start_time,
    Thursday_end_time,
    Friday_open,
    Friday_start_time,
    Friday_end_time,
    Saturday_open,
    Saturday_start_time,
    Saturday_end_time,*/
    markers,
    category,
    subcategory,
  } = formData;
  let tempData;

  const timeDropDown = () => {
    var hours, minutes, ampm;
    const options = [];
    for (var i = 0; i <= 1440; i += 30) {
      hours = Math.floor(i / 60);
      minutes = i % 60;
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      ampm = hours % 24 < 12 ? "AM" : "PM";
      hours = hours % 12;
      if (hours === 0) {
        hours = 12;
      }
      options.push(
        <option key={i} value={i}>
          {hours + ":" + minutes + " " + ampm}
        </option>
      );
    }
    return options;
  };
  const timeDropDownOptions = timeDropDown();
  timeDropDownOptions.map((time) => {});

  function clearNumber(value = "") {
    return value.replace(/\D+/g, "");
  }
  function clearPhoneNumber(value) {
    return (value = value.replace(/[^\d]/g, "")); // remove all non-numeric characters
    // if (value.length === 10) { // only format if it's a 10-digit number
    //   value = value.replace(/(\d{2})(\d{3})(\d{4})/, '($1)-$2-$3');
    // }
    // return value;
  }

  // const handleToggle = (i) => {
  //   console.log("i", i);
  //   if (weekDay.includes(i)) {
  //     setWeekDay(weekDay.filter((elem) => elem !== i));
  //   } else {
  //     setWeekDay([...weekDay, i]);
  //   }
  // };
  const handleToggle = (i) => {
    const day = d_names[i];
    const isOpen = !formData[`${day}_open`];
    setFormData({
      ...formData,
      [`${day}_open`]: isOpen,
    });
  };

  const onChange = (e, lat) => {
    if (e.target.name === "facebook") {
      if (e.target.name === "fax") {
        e.target.value = clearNumber(e.target.value);
      }
      let reference = /^(https?:\/\/)?((w{3}\.)?)facebook.com\/.*/i;
      if (reference.test(e.target.value) || e.target.value === "") {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setfacebookError("");
      } else {
        setfacebookError("This is not a valid facebook link.");
      }
    }

    if (e.target.name === "linkedin") {
      let reference = /^(https?:\/\/)?((w{3}\.)?)linkedin.com\/.*/i;
      if (reference.test(e.target.value) || e.target.value === "") {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setlinkedinError("");
      } else {
        setlinkedinError("This is not a valid linkedin link.");
      }
    }

    if (e.target.name === "instagram") {
      let reference = /^(https?:\/\/)?((w{3}\.)?)instagram.com\/.*/i;
      if (reference.test(e.target.value) || e.target.value === "") {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setinstagramError("");
      } else {
        setinstagramError("This is not a valid instagram link.");
      }
    }
    if (e.target.name === "name") {
      // let reference = /^[A-Za-z]+$/;
      // if (reference.test(e.target.value) || e.target.value === "") {
      setFormData({ ...formData, [e.target.name]: e.target.value });
      // }

      // }else{
      //   setFormData({ ...formData, [e.target.name]: e.target.value });
      // }
    } else if (e.target.name === "phone") {
      setFormData({
        ...formData,
        [e.target.name]: clearPhoneNumber(e.target.value),
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    if (lat != null) {
      setFormData({ ...formData, lat: lat });
    }
  };

  const addressUpdate = (address, latitiude, lng) => {
    tempData = { ...formData };
    setIsSelected({
      ...isSelected,
      location: false,
    });
    setFormData({
      ...formData,
      street_address: address[0] ? `${address[0].long_name}` : "",
      street_address_2: address[1] ? ` ${address[1].long_name}` : "",
      city: address[4] ? `${address[4].long_name}` : "",
      province: address[6] ? `${address[6].short_name}` : "",
      country: address[5] ? `${address[5].long_name}` : "",
      postal: address[8] ? `${address[8].short_name}` : "",
      lat: latitiude,
      lng: lng,
      geoLocation: {
        type: "Point",
        coordinates: [lng * 1, latitiude * 1],
      },
    });
  };
  const onCategoryChange = (values) => {
    let dropdownfield = [{ label: "Subcategory", value: "" }];
    categoryList?.map((element) => {
      if (element.category_name === values) {
        element?.sub_category_name.map((value) => {
          dropdownfield.push({
            label: value.text,
            value: value.text,
          });
        });
      }
    });

    setSubCategories(dropdownfield);
    setFormData({
      ...formData,
      category: values,
      subcategory: "",
    });
  };

  const onTimeChange = (e) => {
    const { name, value } = e.target;
    const day = name.split('_')[0];
    const field = name.split('_')[1];

    console.log(`Changed field: ${name}, value: ${value}`);
  
    setFormData({
      ...formData,
      [name]: value,
    });
  
    if (field === 'start_time' || field === 'end_time') {
      const startTime = field === 'start_time' ? value : formData[`${day}_start_time`];
      const endTime = field === 'end_time' ? value : formData[`${day}_end_time`];
      handleHoursChange(day, startTime, endTime);
    }
  };
  

  const onSubmit = (e) => {
    e.preventDefault();
    if (
      !formData.category ||
      !formData.subcategory ||
      !formData.lat ||
      !formData.lng
    ) {
      setIsSelected({
        ...isSelected,
        category: !formData.category ? true : false,
        subcategory: !formData.subcategory ? true : false,
        location: !formData.lat || !formData.lng ? true : false,
      });
    }
    if (
      formData.subcategory &&
      formData.category
      // formData.lat &&
      // formData.lng
    ) {
      setFormError("");
      const availWaitlistTimeSlots = compareHoursSetting();
      console.log('availWaitlistTimeSlots', availWaitlistTimeSlots); 
      checkWaitlistOnHoursChange(availWaitlistTimeSlots);
      editBusiness(formData, history, false, currentCompany?._id, true).then(
        (res) => {
          // history.push('/admin')
          window.scrollTo(0, 0);
          setCompany(res.name);
        }
      );
    } else {
      setFormError(
        "There is some errors with the form values, please check the form once."
      );
    }
  };

  const compareHoursSetting = () => {
    const changedTimeSlots = [];
  
    const getTimeSlots = (startTime, endTime) => {
      const slots = [];
      if (startTime < 720) slots.push("morning");
      if (endTime > 720 && startTime < 1080) slots.push("afternoon");
      if (endTime > 1080) slots.push("evening");
      return slots;
    };
  
    for (const day of d_names) {
      const oldOpen = oldStoreHours[`${day}_open`];
      const newOpen = formData[`${day}_open`];
      const oldStartTime = oldStoreHours[`${day}_start_time`];
      const newStartTime = formData[`${day}_start_time`];
      const oldEndTime = oldStoreHours[`${day}_end_time`];
      const newEndTime = formData[`${day}_end_time`];
  
      if (oldOpen !== newOpen || oldStartTime !== newStartTime || oldEndTime !== newEndTime) {
        if (!oldOpen && newOpen) {
          // Store opening, calculate new time slots
          const slots = getTimeSlots(newStartTime, newEndTime);
          slots.forEach((slot) =>
            changedTimeSlots.push({
              day,
              timeSlot: slot,
              companyId: currentCompany._id,
            })
          );
        } else if (oldOpen && newOpen) {
          // Store was open, check for expanded hours
          if (newStartTime < oldStartTime) {
            const newSlots = getTimeSlots(newStartTime, oldStartTime);
            newSlots.forEach((slot) => {
              if (!changedTimeSlots.some(ts => ts.day === day && ts.timeSlot === slot)) {
                changedTimeSlots.push({
                  day,
                  timeSlot: slot,
                  companyId: currentCompany._id,
                });
              }
            });
            // Also add old start time slot if it's not already in the list
            const oldStartSlot = getTimeSlots(oldStartTime, oldStartTime);
            oldStartSlot.forEach((slot) => {
              if (!changedTimeSlots.some(ts => ts.day === day && ts.timeSlot === slot)) {
                changedTimeSlots.push({
                  day,
                  timeSlot: slot,
                  companyId: currentCompany._id,
                });
              }
            });
          }
          if (newEndTime > oldEndTime) {
            const newSlots = getTimeSlots(oldEndTime, newEndTime);
            newSlots.forEach((slot) => {
              if (!changedTimeSlots.some(ts => ts.day === day && ts.timeSlot === slot)) {
                changedTimeSlots.push({
                  day,
                  timeSlot: slot,
                  companyId: currentCompany._id,
                });
              }
            });
            // Also add old end time slot if it's not already in the list
            const oldEndSlot = getTimeSlots(oldEndTime, oldEndTime);
            oldEndSlot.forEach((slot) => {
              if (!changedTimeSlots.some(ts => ts.day === day && ts.timeSlot === slot)) {
                changedTimeSlots.push({
                  day,
                  timeSlot: slot,
                  companyId: currentCompany._id,
                });
              }
            });
          }
        }
      }
    }
  
    console.log("Changed time slots:", changedTimeSlots);
    return changedTimeSlots;
  };
  
  
  
  
  
  let subcategoryValue, filterValue;
  filterValue = categories.filter((i) => i.value === formData?.category);
  if (formData.category) {
    subcategoryValue = subCategories?.filter(
      (i) => i.value === formData?.subcategory
    );
  }

  useEffect(() => {
    let CategoryList = [{ label: "Category", value: "" }];

    categoryList?.map((element) => {
      CategoryList.push({
        label: element.category_name,
        value: element.category_name,
      });
    });

    setCategories(CategoryList);
    filterValue = categories.filter((i) => i.value === formData?.category);
    if (formData.category) {
      subcategoryValue = subCategories?.filter(
        (i) => i.value === formData?.subcategory
      );

      let dropdownfield = [{ label: "Subcategory", value: "" }];
      categoryList?.map((element) => {
        if (element.category_name === formData.category) {
          element?.sub_category_name.map((value) => {
            dropdownfield.push({
              label: value.text,
              value: value.text,
            });
          });
        }
      });
      setSubCategories(dropdownfield);
    }
  }, [formData, categoryList]);
  const addPlace = (place) => {
    const addressArray = {};
    place.address_components.length > 0 &&
      place.address_components.forEach((add) => {
        if (add.types[0] == "street_number" || add.types[0] == "route") {
          addressArray["street_address"] =
            (addressArray["street_address"] || "") + " " + add.long_name;
        } else if (add.types[0] == "locality") {
          addressArray["city"] = add.long_name;
        } else if (
          add.types[0] == "administrative_area_level_2" &&
          !addressArray["city"]
        ) {
          addressArray["city"] = add.long_name;
        } else if (add.types[0] == "administrative_area_level_1") {
          addressArray["province"] = add.long_name;
        } else if (add.types[0] == "country") {
          addressArray["country"] = add.long_name;
        } else if (add.types[0] == "postal_code_prefix") {
          addressArray["postal"] = add.long_name;
        }
      });
    setFormData({
      ...formData,
      street_address: addressArray.street_address
        ? addressArray.street_address
        : "",
      street_address_2: addressArray.street_address_2
        ? addressArray.street_address_2
        : "",
      city: addressArray.city ? addressArray.city : "",
      province: addressArray.province ? addressArray.province : "",
      country: addressArray.country ? addressArray.country : "",
      postal: addressArray.postal ? addressArray.postal : "",
      lng: place.geometry.location.lng(),
      lat: place.geometry.location.lat(),
    });
  };

  //waitlist util functions
  const handleHoursChange = (day, startTime, endTime) => {
    setFormData({
      ...formData,
      [`${day}_start_time`]: startTime,
      [`${day}_end_time`]: endTime,
    });
  
    const timePeriods = getTimePeriods(startTime, endTime);
    checkWaitlistOnHoursChange(currentCompany._id, day, timePeriods);
  };
  
  const getTimePeriods = (startTime, endTime) => {
    const periods = [];
    if (startTime <= 720 && endTime >= 720) periods.push("morning");
    if (startTime <= 1020 && endTime >= 1020) periods.push("afternoon");
    if (startTime <= 1440 && endTime >= 1440) periods.push("evening");
    return periods;
  };
  

  return (
    <>
      <Helmet>
        <title>Appointment Cake</title>
        <meta
          name="description"
          content="Whatever or whoever you’re looking for,
                   Appointment Cake has you covered."
        />
      </Helmet>
      {/* -------------------------- */}
      <section className="white-bg header-bg-edit bottom-wave-edit">
        <p className="text-lg w-fit font-weight-bold mb-0 mx-auto mb-4">
          Business Information
        </p>
        <div className=" text-center d-flex justify-content-center align-items-center">
          <div className="buisness-information-edit w-100">
            <div className="buisness-information-edit row">
              <div className="col-md-6">
                <p className="font-semibold text-m m-0 text-center text-md-left mb-1 ml-2">
                  Information
                </p>
                <div className="bg-lightpeach p-10 border-20 w-100 mt-2">
                  <div className="col-md-12 px-0 pb-10">
                    <input
                      className="form-control font-medium text-medium"
                      type="text"
                      placeholder="Company Name "
                      name="name"
                      onChange={(e) => onChange(e)}
                      value={name}
                      required
                    />
                  </div>
                  <div className="d-flex col-sm-12 px-0 pb-10">
                    <input
                      className="form-control font-medium text-medium"
                      type="text"
                      placeholder="Brief Description / tagline"
                      name="description"
                      value={description}
                      onChange={onChange}
                    />
                  </div>
                  <div className="d-flex col-sm-12 px-0 pb-10">
                    <div className="col-sm-6 pl-0 pr-1">
                      <input
                        className="form-control font-medium text-medium"
                        type="email"
                        placeholder="Company Email "
                        name="email"
                        value={email}
                        onChange={onChange}
                        required
                      />
                    </div>

                    <div className="col-sm-6 pl-1 pr-0">
                      <input
                        className="form-control font-medium text-medium"
                        type="tel"
                        placeholder="Business Phone Number "
                        name="phone"
                        // minLength="10"
                        // maxLength="10"
                        value={clearPhoneNumber(phone)}
                        onChange={onChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="d-flex col-sm-12 px-0 pb-10">
                    <input
                      className="form-control font-medium text-medium"
                      type="text"
                      placeholder="Website"
                      name="website"
                      value={website}
                      onChange={onChange}
                    />
                  </div>
                  <div className="col-sm-6 d-none">
                    <input
                      className="form-control font-medium text-medium"
                      type="text"
                      placeholder="Fax Number"
                      name="fax"
                      minLength="10"
                      maxLength="10"
                      value={fax}
                      onChange={onChange}
                    />
                  </div>
                  <div className="form-heading d-none">
                    <span>Social Media Links</span>
                  </div>
                  <div className="col-sm-6 d-none">
                    <input
                      className="form-control font-medium text-medium"
                      type="text"
                      placeholder="facebook.com/"
                      name="facebook"
                      value={facebook}
                      onChange={onChange}
                    />
                    <p style={{ color: "red" }}>
                      {facebookError ? facebookError : ""}
                    </p>
                  </div>

                  <div className="col-sm-6 d-none">
                    <input
                      className="form-control font-medium text-medium"
                      type="text"
                      placeholder="linkedin.com/in/"
                      name="linkedin"
                      value={linkedin}
                      onChange={onChange}
                    />
                    <p style={{ color: "red" }}>
                      {linkedinError ? linkedinError : ""}
                    </p>
                  </div>

                  <div className="col-sm-6 d-none">
                    <input
                      className="form-control font-medium text-medium"
                      type="text"
                      placeholder="instagram.com/"
                      name="instagram"
                      value={instagram}
                      onChange={onChange}
                    />
                    <p style={{ color: "red" }}>
                      {instagramError ? instagramError : ""}
                    </p>
                  </div>

                  <div className="col-md-12  row p-0 m-0">
                    <div className="col-md-6 pl-0 pr-0 pr-md-1 ">
                      <Select
                        options={categories}
                        styles={{
                          menu: (base) => ({ ...base, zIndex: 99999999 }),
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderRadius: "8px",
                          }),
                        }}
                        name="field_category"
                        defaultValue={filterValue && filterValue[0]}
                        value={filterValue && filterValue[0]}
                        onChange={(value) => {
                          onCategoryChange(value.value);
                          setIsSelected({
                            ...isSelected,
                            category: false,
                          });
                        }}
                        className="font-medium text-medium"
                      />
                      {isSelected.category && (
                        <label className="text-danger d-flex">
                          Please select category.
                        </label>
                      )}
                    </div>
                    {subCategories?.length > 0 && (
                      <div className="col-md-6 mt-1 mt-md-0 pl-0 pl-md-1  pr-0">
                        <Select
                          options={subCategories}
                          styles={{
                            menu: (base) => ({ ...base, zIndex: 99999999 }),
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderRadius: "8px",
                            }),
                          }}
                          className="font-medium text-medium"
                          defaultValue={subcategoryValue && subcategoryValue[0]}
                          value={subcategoryValue && subcategoryValue[0]}
                          onChange={(value) => {
                            setFormData({
                              ...formData,
                              subcategory: value.value,
                            });
                            setIsSelected({
                              ...isSelected,
                              subcategory: false,
                            });
                          }}
                        />
                        {isSelected.subcategory && (
                          <label className="text-danger d-flex">
                            Please select subcategory.
                          </label>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6 mt-3 mt-md-0">
                <p className="font-semibold text-m m-0 text-center text-md-left mb-1 ml-2">
                  Address
                </p>
                <div className="bg-lightpeach p-10 border-20 h-fit mt-2">
                  <div className="map-location d-none">
                    <MapContainer
                      mapMarkers={markers}
                      newMarker={false}
                      currentLat={currentCompany?.location?.lat}
                      currentLng={currentCompany?.location?.lng}
                      addressupdate={addressUpdate}
                      onChange={onChange}
                    />
                    {isSelected.location && (
                      <label className="text-danger d-flex">
                        Please select valid location.
                      </label>
                    )}
                  </div>
                  {/* <div className="map-location col-sm-6">
                  <BusinessFormAutocomplete addressupdate={addressUpdate} />
                </div> */}

                  <div className="col-md-12  row px-0 m-0 pb-10">
                    <div
                      className="col-sm-12 business-registration px-0"
                      style={{ marginBottom: "38px" }}
                    >
                      <AutoComplete
                        addPlace={addPlace}
                        isFilter={false}
                        placeholder="Search Address"
                        className="font-medium text-medium"
                      />
                    </div>
                  </div>
                  <div className="col-md-12  row px-0 m-0 mt-2 mt-md-0 pb-10">
                    <div className="col-md-6 pl-0 pr-0 pr-md-1">
                      <input
                        className="form-control font-medium text-medium"
                        type="text"
                        placeholder="Street Address "
                        name="street_address"
                        value={street_address}
                        onChange={onChange}
                        required
                      />
                    </div>
                    {/* <div className="col-sm-6 business-registration px-0 ">
                      <AutoComplete
                        addPlace={addPlace}
                        isFilter={false}
                        placeholder="Street Address"
                        defaultAddress={street_address}
                      />
                    </div> */}
                    <div className="col-md-6 pl-0 pl-md-1 pr-0 mt-2 mt-md-0">
                      <input
                        className="form-control font-medium text-medium"
                        type="text"
                        placeholder="Street Address 2"
                        name="street_address_2"
                        value={street_address_2}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-12  row px-0 m-0 pb-10 ">
                    <div className="col-md-6 pl-0 pr-0 pr-md-1">
                      <input
                        className="form-control font-medium text-medium"
                        type="text"
                        placeholder="City "
                        name="city"
                        value={city}
                        onChange={onChange}
                        required
                        disabled
                      />
                    </div>
                    <div className="col-md-6 pl-0 pl-md-1 pr-0 mt-2 mt-md-0">
                      <input
                        className="form-control font-medium text-medium"
                        type="text"
                        placeholder="Province "
                        name="province"
                        value={province}
                        onChange={onChange}
                        required
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-md-12  row px-0 m-0">
                    <div className="col-md-6 pl-0 pr-0 pr-md-1">
                      <input
                        className="form-control font-medium text-medium"
                        type="text"
                        placeholder="Company Country "
                        name="country"
                        value={country}
                        onChange={onChange}
                        required
                        disabled
                      />
                    </div>
                    <div className="col-md-6 pl-0 pl-md-1 pr-0 mt-2 mt-md-0">
                      <input
                        className="form-control font-medium text-medium"
                        type="text"
                        placeholder="Postal Code"
                        name="postal"
                        maxLength="50"
                        value={postal}
                        onChange={onChange}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-6"
                style={{
                  marginTop: "20px",
                  // marginLeft: "5px",
                }}
              >
                <p
                  className="font-semibold text-m text-center text-md-left"
                  style={{
                    marginBottom: "3px",
                    marginLeft: "5px",
                  }}
                >
                  Hours of Operation
                </p>

                <div className="form-row form-group bg-lightpeach p-10 border-20">
                  {d_names.map((day, i) => {
                    return (
                      <div className="hours-grid-container" key={i}>
                        <div className="hours-grid-container-section-1">
                          <label
                            className="font-semibold text-medium"
                            style={{
                              color: "#4E4E4E",
                            }}
                          >
                            {day}
                          </label>
                          <div
                            className="hours-grid-container-switch-container"
                            style={{
                              display: "flex",
                              alignItems: "baseline",
                              paddingRight: "20px",
                              width: "50%",
                            }}
                          >
                            <label className="switch hours-grid-container-switch">
                              <input
                                type="checkbox"
                                name={`${day}_open`}
                                id={`${day}_open`}
                                onChange={(e) => {
                                  // console.log(
                                  //   "e.target.name",
                                  //   e.target.checked
                                  // );
                                  // console.log("day", day);
                                  // console.log(
                                  //   "!formData",
                                  //   !formData[day + "_open"]
                                  // );
                                  setFormData({
                                    ...formData,
                                    [day + "_open"]: !formData[day + "_open"],
                                  });
                                }}
                                onClick={() => handleToggle(i)}
                                checked={formData[`${day}_open`]}
                              />
                              <span className="slider round"></span>
                            </label>
                            <span
                              className="font-medium text-medium hours-grid-container-switch-text"
                              style={{
                                color: "#4E4E4E",
                              }}
                            >
                              {formData[`${day}_open`] ? "Open" : "Closed"}
                            </span>
                          </div>
                        </div>
                        <div className="hours-grid-container-section-2 mb-2 mb-md-0">
                          <div
                            className="grid-col"
                            style={{
                              width: "100%",
                            }}
                          >
                            <div className="grid-item">
                            <select
  className="form-control font-semibold text-medium"
  style={{ color: "#4E4E4E" }}
  name={`${day}_start_time`}
  placeholder="Closes at"
  onChange={onTimeChange}
  value={formData[day + "_start_time"]}
>
  {timeDropDownOptions.map((time_option, i) => (
    <Fragment key={i}>{time_option}</Fragment>
  ))}
</select>

<select
  className="form-control font-semibold text-medium"
  style={{ color: "#4E4E4E" }}
  name={`${day}_end_time`}
  placeholder="Closes at"
  onChange={onTimeChange}
  value={formData[day + "_end_time"]}
>
  {timeDropDownOptions
    .filter(
      (time_option) =>
        parseInt(time_option.props.value) >
        parseInt(formData[day + "_start_time"])
    )
    .map((time_option, i) => (
      <Fragment key={i}>{time_option}</Fragment>
    ))}
</select>

                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="card-body mb-5 pb-5 pb-md-0 mb-md-0">
              <button
                type="button"
                className={`btn btn-primary text-xs font-semibold w-fit w-md-25 px-5 px-md-4 py-3 py-md-2 my-3 my-md-0 ${
                  !formData.name ||
                  !formData.phone ||
                  !formData.email ||
                  !formData.phone ||
                  !formData.country ||
                  !formData.province ||
                  !formData.city
                    ? "disabled-btn border-0"
                    : ""
                }`}
                onClick={onSubmit}
                disabled={
                  !formData.name ||
                  !formData.phone ||
                  !formData.email ||
                  !formData.phone ||
                  !formData.country ||
                  !formData.province ||
                  !formData.city
                }
              >
                Save Company Info
              </button>
              <p
                style={{
                  color: "red",
                  marginLeft: "10px",
                  marginTop: "10px",
                }}
              >
                {formError ? formError : ""}
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* ---------------------------- */}
    </>
  );
};

EditUserBusiness.propTypes = {
  editBusiness: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  company: state.company.company,
  formfields: state.formfields,
  categoryList: state.company.category,
  companyList: state.company.companylist,
});

export default connect(mapStateToProps, { editBusiness })(
  withRouter(EditUserBusiness)
); 
