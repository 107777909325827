import React from "react";
import PropTypes from "prop-types";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const DetailTooltip = ({ text }) => {
  return (
    <OverlayTrigger
      key={"left"}
      placement={"bottom"}
      overlay={<Tooltip id={`tooltip-${"bottom"}`}>{text}</Tooltip>}
    >
      <i className="fa-solid fa-circle-info"></i>
    </OverlayTrigger>
  );
};

DetailTooltip.propTypes = {
  text: PropTypes.string.isRequired
};

export default DetailTooltip;
