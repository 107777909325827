import { SET_MODAL, REMOVE_MODAL } from '../actions/types';

const initialState = [];

export default function modal(state = initialState, action) {
	const { type, payload } = action; // destructor for action so you don't need to type action.type or action.payload

	switch (type) {
		case SET_MODAL:
			return [...state, payload];
		case REMOVE_MODAL:
			return state.filter(modal => modal.id !== payload);
		default:
			return state;
	}
}
