import React, { useEffect, useState } from "react";
import { setTransferredClaim, transferClaim } from "../../actions/claim";
import Tooltip from "../layout/ToolTip";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Pagination from "react-bootstrap/Pagination";
import Spinner from "../layout/Spinner";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";

const TransferredClaim = ({
  setTransferredClaim,
  TransferredClaimList,
  transferClaim,
  totalTransferredClaims,
  CT,
}) => {
  const [currentPage, setcurrentPage] = useState(0);
  const [loadingDynamic, setloadingDynamic] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  useEffect(() => {
    setloadingDynamic(true);
    setTimeout(() => {
      setloadingDynamic(false);
    }, 1000);
  }, []);
  useEffect(() => {
    setTransferredClaim(currentPage);
  }, []);
  useEffect(() => {
    setPageCount(Math.ceil(totalTransferredClaims / 10));
    setcurrentPage(0);
  }, []);
  const onNext = () => {
    setloadingDynamic(true);
    setTimeout(() => {
      setloadingDynamic(false);
    }, 1000);
    window.scrollTo(0, 0);
    setcurrentPage(currentPage + 1);
  };

  const onPrev = () => {
    setloadingDynamic(true);
    setTimeout(() => {
      setloadingDynamic(false);
    }, 1000);
    window.scrollTo(0, 0);
    setcurrentPage(currentPage - 1);
  };

  const pageNoClick = (i) => {
    setloadingDynamic(true);
    setTimeout(() => {
      setloadingDynamic(false);
    }, 1000);
    window.scrollTo(0, 0);
    setcurrentPage(i);
  };
  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setcurrentPage(selectedPage);
    setTransferredClaim(selectedPage);
  };
  return (
    <div>
      {loadingDynamic ? (
        <Spinner />
      ) : (
        <div className="appointment-tbl ssfdf">
          <table className="table table-striped">
            <thead>
              <tr>
                <th className=" py-10 text-sm font-bold">Company Info</th>
                <th className=" py-10 text-sm font-bold">Claimer Info</th>
                {/* <th>Action</th> */}
              </tr>
            </thead>
            <tbody>
              {TransferredClaimList && TransferredClaimList.length > 0 ? (
                TransferredClaimList.map((claim, i) =>
                  claim?.company || claim?.user ? (
                    <tr className="booking-item-row" id={i}>
                      <td className="rounded-s-5">
                        {/* <Link
                          to={{
                            pathname: `/superadmin/service/${claim ?.company ?._id}`,
                            state: { companyDetails: claim ?.company, currentTab: CT }
                          }}
                          className="font-weight-bold"
                        > */}
                        <span className="font-weight-bold text-sm py-10 text-aqua">
                          {claim?.company?.name}
                        </span>
                        {/* </Link> */}
                        <br />
                        <span className="text-xs text-dark font-normal">
                          {claim?.company?.email}
                        </span>
                        <br />
                        <span className="text-xs text-dark font-normal">
                          {claim?.company?.phone}
                        </span>
                      </td>
                      <td className="rounded-e-5">
                        <span className="font-weight-bold text-sm py-10 text-aqua">
                          {claim?.user?.firstName} {claim?.user?.lastName}
                        </span>
                        <br />
                        <span className="text-xs text-dark font-normal">
                          {claim?.user?.email}
                        </span>
                        <br />
                        <span className="text-xs text-dark font-normal">
                          {claim?.user?.phone}
                        </span>
                      </td>
                    </tr>
                  ) : null
                )
              ) : (
                <tr className="booking-item-row">
                  <td className="booking-date">No transferred claims found</td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="App mt-4">
            <ReactPaginate
              previousLabel={"prev"}
              nextLabel={"next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              forcePage={currentPage}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
          {/* <Pagination
              style={{
                marginTop: "10px",
                paddingBottom: "50px",
                marginLeft: "10px"
              }}
            >
              <Pagination.Prev
                disabled={currentPage == 0 || TransferredClaimList ?.length == 0}
                onClick={onPrev}
              />
              {TransferredClaimList &&
                [...Array(totalTransferredClaims)].map((data, i) => {
                  if (i < Math.ceil(totalTransferredClaims / 10)) {
                    return (
                      <Pagination.Item
                        active={currentPage == i}
                        onClick={() => pageNoClick(i)}
                      >
                        {i + 1}
                      </Pagination.Item>
                    );
                  }
                })}
              <Pagination.Next
                disabled={
                  currentPage == Math.ceil(totalTransferredClaims / 10) - 1 ||
                    TransferredClaimList ?.length == 0
              }
                onClick={onNext}
              />
            </Pagination> */}
        </div>
      )}
    </div>
  );
};

TransferredClaim.propTypes = {
  setTransferredClaim: PropTypes.object.isRequired,
  TransferredClaimList: PropTypes.object.isRequired,
  transferClaim: PropTypes.object.isRequired,
  totalTransferredClaims: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  TransferredClaimList: state.company.TransferredClaimList,
  totalTransferredClaims: state.company.totalTransferredClaims,
  // auth: state.auth,
});

export default connect(mapStateToProps, { setTransferredClaim, transferClaim })(
  TransferredClaim
);
