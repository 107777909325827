import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { useHistory,Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const ForgotSuccess = () => {
  let history = useHistory();

  return (
    <>
      <Helmet>
        <title>Appointment Cake</title>
        <meta
          name="description"
          content="Whatever or whoever you’re looking for,
                   Appointment Cake has you covered."
        />
      </Helmet>
      <div className="edit-service">
        <button>
          <i className="fas fa-arrow-left" onClick={() => history.goBack()}></i>
        </button>
        <div className="signup_header">
          <Link
            className="btn btn-secondary rounded-circle mobile-top-arrow"
            to="/login"
          >
            <i className="fas fa-arrow-left" aria-hidden="true"></i>
          </Link>
        </div>
      </div>
      <SuccessPageContainer>
        <div className="success_page_items">
          <h3>Forgot Password</h3>
          <h5>Success</h5>
          <div className="check_container">
            <i className="fa-solid fa-check"></i>
          </div>
          <p>
            An email will be sent soon to <br />
            reset your password
          </p>

          <input
            type="button"
            className="btn btn-primary btn-block back_login_btn"
            value="Back to Login"
            onClick={() => {
              history.push("/login");
            }}
          />
        </div>
      </SuccessPageContainer>
    </>
  );
};

const SuccessPageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  & .success_page_items {
    text-align: center;
  }

  & h3 {
    color: #ffab68;
    margin-bottom: 23px;
  }

  & h5 {
    margin-top: 6px;
    color: #ffab68;
    font-weight: 400;
    font-size: 24px;
    margin-bottom: 22px;
  }

  & p {
    margin-bottom: 38px;
  }

  & .back_login_btn {
    padding: 12px 59px;
    border: 0;
    transition: 0.5s ease-in-out;
    background-color: #25d2c3;
  }

  & .back_login_btn:hover {
    background-color: #ffab68;
  }

  @media (max-width: 767px) {
    & h3 {
      font-size: 28px;
    }

    & p {
      font-size: 12px;
    }
  }
`;

ForgotSuccess.propTypes = {};

const mapStateToProps = state => ({});

// Use the connect function to pass our setAlert into props
export default connect(mapStateToProps)(ForgotSuccess);
