import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
//replace with new action we make
// import { login } from '../../actions/auth';
import Spinner from "../layout/Spinner";
// import Alert from "../layout/Alert";
import { setAlert } from "../../actions/alert";
import { updatePassword } from "../../actions/auth";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

const ResetPassword = ({ setAlert, updatePassword, loading, history }) => {
  const token = useParams();
  const [formData, setFormData] = useState({
    password: "",
    password2: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);
  const { password, password2 } = formData;

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    }); // can be used for the onChange of all fields, automatically pulling the name of the field as the key
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password !== password2) {
      // brought in with the /actions/alert, but then must export it with 'connect' at the bottom of the file
      // Would be props.setAlert, but we destructured at the top
      setAlert("Passwords do not match", "danger");
      setFormData({
        password: "",
        password2: "",
      });
    } else {
      updatePassword(formData.password, token, history);
    }
  };

  return loading ? (
    <Spinner />
  ) : (
    <section className="landing text-center d-flex justify-content-center align-items-center">
      <Helmet>
        <title>Appointment Cake</title>
        <meta
          name="description"
          content="Whatever or whoever you’re looking for,
                   Appointment Cake has you covered."
        />
      </Helmet>
      <div>
        <h1 className="large text-primary">Reset Password</h1>
        <p className="lead">Remember To Set A Strong Password</p>
        <form
          className="form"
          onSubmit={(e) => {
            onSubmit(e);
          }}
        >
          <div className="form-group">
            <div className="row">
              <div className="col-12 input-box">
                {showPassword ? (
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Password"
                    name="password"
                    minLength="6"
                    maxLength="50"
                    value={password}
                    onChange={(e) => onChange(e)}
                    required
                  />
                ) : (
                  <input
                    className="form-control"
                    type="password"
                    placeholder="Password"
                    name="password"
                    minLength="6"
                    maxLength="10"
                    value={password}
                    onChange={(e) => onChange(e)}
                    required
                  />
                )}
                {showPassword ? (
                  <i
                    className="far fa-eye"
                    onClick={() => setShowPassword(false)}
                  ></i>
                ) : (
                  <i
                    className="far fa-eye-slash"
                    onClick={() => setShowPassword(true)}
                  ></i>
                )}
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-12 input-box">
                {showConfPassword ? (
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Confirm Password"
                    name="password2"
                    minLength="6"
                    maxLength="50"
                    value={password2}
                    onChange={(e) => onChange(e)}
                    required
                  />
                ) : (
                  <input
                    className="form-control"
                    type="password"
                    placeholder="Confirm Password"
                    name="password2"
                    minLength="6"
                    value={password2}
                    onChange={(e) => onChange(e)}
                    required
                  />
                )}
                {showConfPassword ? (
                  <i
                    className="far fa-eye"
                    onClick={() => setShowConfPassword(false)}
                  ></i>
                ) : (
                  <i
                    className="far fa-eye-slash"
                    onClick={() => setShowConfPassword(true)}
                  ></i>
                )}
              </div>
            </div>
          </div>
          <input
            type="submit"
            className="btn btn-primary btn-block"
            value="Reset Password"
          />
        </form>
        <p className="my-4">
          Don't Want To Change Your Password? <Link to="/login">Login</Link>
        </p>
      </div>
    </section>
  );
};

ResetPassword.propTypes = {
  //login: PropTypes.func.isRequired,
  history: PropTypes.object,
  setAlert: PropTypes.func,
  updatePassword: PropTypes.func,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { setAlert, updatePassword })(
  ResetPassword
);
