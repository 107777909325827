import React, { Fragment, useState, useEffect, useRef } from "react";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import {
  getCompanyById,
  addLike,
  removeLike,
  linkCopied,
  notifyOn,
  notifyOff,
} from "../../actions/company";
import { createBooking, addIntakeForm } from "../../actions/booking";
import Spinner from "../layout/Spinner";
import Bell_White from '../../asssets/icons/Bell_White.svg';
import CompanyServices from "./CompanyServices";
import Tour from "reactour";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import moment from "moment-timezone";
import { addClaim } from "../../actions/claim";
import { helmetJsonLdProp } from "react-schemaorg";
import { Helmet } from "react-helmet";
import { GET_APPOINTMENTDETAIL } from "../../actions/types";
import { faTimesToSlot } from "@fortawesome/pro-regular-svg-icons";
import { showModal, hideModal, setCurrentCompany } from '../../actions/waitlistModal';
import WaitlistModal from '../waitlist/WaitlistModal';

const Company = ({
  addLike,
  removeLike,
  linkCopied,
  createBooking,
  addIntakeForm,
  getCompanyById,
  company: { company, loading },
  history,
  match,
  auth,
  tour,
  setTour,
  notifyOn,
  notifyOff,
  addClaim,
  myclaims,
  appointmentDetail,
  waitlistModal,
}) => {
  const dispatch = useDispatch();
  const title = company?.services[0]?.name
    ? `${company?.name} | ${company?.services[0]?.name} | Appointment Cake`
    : `${company?.name} | Appointment Cake`;



  const d_names = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const serviceReference = useRef(null);
  const executeScrollService = () => serviceReference.current.scrollIntoView();
  const formReference = useRef(null);
  const executeScrollForm = () => {
    setTimeout(() => {
      formReference?.current?.scrollIntoView();
    }, 1000);
  };

  const [isTourOpen, setisTourOpen] = useState(tour);
  const closeTour = () => {
    setisTourOpen(false);
    setTour(false);
  };
  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);

  const date = new Date();
  const today = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    0,
    0,
    0,
    0
  );
  let d_key = d_names[date.getDay()];
  const providerGapi = window.gapi;
  /*
    Update with your own Client Id and Api key
  */
  var GoogleAuth;
  // const CLIENT_ID = process.env.REACT_APP_GOOGLECLIENTID;
  const CLIENT_ID="560365648108-5dhcireuma8a3ggnag0cglfbamsh140p.apps.googleusercontent.com"
  const API_KEY = process.env.REACT_APP_GOOGLEAPIKEY;
  const DISCOVERY_DOCS = [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
  ];
  // const SCOPES = "https://www.googleapis.com/auth/calendar.events";
  const SCOPES = "email profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/admin.directory.resource.calendar openid https://www.googleapis.com/auth/calendar.events";

  // console.log(SCOPES, "SCOPES")

  let form_value = [
    {
      field_category: "",
      field_label: "",
      field_name: "",
      field_type: "",
      value: "",
      formfield: "",
    },
  ];

  const [serviceData, setService] = useState(
    appointmentDetail?.service
      ? {
          service: {
            name: appointmentDetail?.service?.name,
            book_online: appointmentDetail?.service?.book_online,
            book_site: appointmentDetail?.service?.book_site,
            book_site_link: appointmentDetail?.service?.book_site_link,
            call_to_book: appointmentDetail?.service?.call_to_book,
            description: appointmentDetail?.service?.description,
            price: appointmentDetail?.service?.price,
            service_duration: appointmentDetail?.service?.service_duration,
            start_time: appointmentDetail?.service?.start_time,
            date: appointmentDetail?.service?.date,
            intakeForm: appointmentDetail?.service?.intakeForm,
            formfield: appointmentDetail?.service?.formfield,
          },
        }
      : {
          service: {
            name: null,
            book_online: null,
            call_to_book: null,
            book_site_link: null,
            description: null,
            price: null,
            service_duration: null,
            start_time: null,
            date: today,
            intakeForm: [form_value],
            formfield: null,
          },
        }
  );

  const [selectedDate, setDate] = useState({
    selectedDate: today,
  });
  const { service } = serviceData;
  const [activeIndex, setItem] = useState(
    appointmentDetail?.selectedDateIndex
      ? appointmentDetail?.selectedDateIndex
      : moment().format("MMDD")
  );
  const [selectedServiceIndex, setSelectedServiceIndex] = useState(-1);
  const [UploadImage, setUploadImage] = useState([]);
  const [variablehours, setVariableHours] = useState([]);
  const [editComapny, setEditCompany] = useState(false);
  const [selectedDay, setSelectedDay] = useState(d_names[date.getDay()]);

  const setCurrentDate = (selectedDate) => {
    d_key = d_names[selectedDate.getDay()];
    setSelectedDay(d_key);
    setService({ service: { ...serviceData, date: selectedDate } });
    setDate({ selectedDate: selectedDate });
    if (monthFilter) {
      getAvailableDaysByMonth(monthFilter.format("MMMM"));
    } else {
      getAvailableDaysByMonth(moment().format("MMMM"));
    }
  };

  const currentDay = date.getDate();
  const selecteDay = selectedDate.selectedDate.getDate();

  const setCurrentService = (selectedService) => {
    setService({
      service: { ...selectedService, date: serviceData["date"] },
    });
  };

  const onChange = (e) =>
    setService({ ...serviceData, [e.target.name]: e.target.value });

  const onFieldValueChange = (e, i, formfield, index) => {
    let fields = { ...serviceData };
    let field = { ...fields.intakeForm[i] };
    if (field.field_type === "checkbox") {
      field.formfield = formfield;
      field.value = field.value ? field.value : [];
      if (e.target.checked) {
        field.value.push(e.target.value);
        field.option[index].is_Checked = true;
      } else {
        const found = field.value.indexOf(e.target.value);
        field.value.splice(found, 1);
        field.option[index].isChecked = false;
      }
    } else {
      // field.value = e.target.value
      field.value = e.target.value;
      field.formfield = formfield;
    }
    fields.intakeForm[i] = field;
    setService(fields);
  };

  const showWidget = (i, formfield) => {
    widgetFunction(i, formfield).open();
  };

  const widgetFunction = (i) =>
    window.cloudinary.createUploadWidget(
      {
        cloudName: "vigilante-marketing-inc",
        uploadPreset: "ml_default",
        folder: `${company}/${company._id}/images`,
      },
      (error, result) => {
        if (!error && result && result.event === "success") {
          let uploadedImages = UploadImage;
          setUploadImage([]);
          uploadedImages.push(result.info.url);
          setUploadImage(uploadedImages);
          let fields = { ...serviceData };
          let field = { ...fields.intakeForm };
          let intakeform = { ...field[i] };
          intakeform.value = intakeform.value ? intakeform.value : [];
          intakeform.value.push(result.info.url);
          fields.intakeForm[i] = intakeform;
          setService(fields);
        }
      }
    );

  const removeImageFromList = (index, i) => {
    let tempArray = [...UploadImage];
    tempArray.splice(index, 1);
    let fields = { ...serviceData };
    let field = { ...fields.intakeForm[index] };
    field.value = field.value ? field.value : [];
    field.value.splice(i, 1);
    fields.intakeForm[index] = field;
    setService(fields);
    setUploadImage(tempArray);
  };

  const getPrevData = () => {
    if (auth && auth.user) {
      let authFields = [...auth?.user?.intakeFormFields];
      let currentFields = [...service?.intakeForm];

      currentFields.map((field, i) => {
        let result = authFields.find((obj) => obj.formfield == field.formfield);
        if (result !== undefined) {
          if (currentFields[i].formfield === result.formfield) {
            if (!currentFields[i].value) {
              currentFields[i].value = result.value;
              if (currentFields[i].field_type === "radio") {
                currentFields[i].option.map((opt) =>
                  opt.lable === result.value
                    ? (opt.isChecked = true)
                    : (opt.isChecked = false)
                );
              }
            }
            if (currentFields[i]?.field_type === "checkbox") {
              currentFields[i]?.option.map((opt) =>
                result?.value?.map((val) =>
                  opt.lable === val ? (opt.is_Checked = true) : ""
                )
              );
            }
          }
        }
      });
      setService({ ...serviceData, intakeForm: currentFields });
    }
  };

  useEffect(() => {
    getCompanyById(match.params.id);
    if (service && service.hasOwnProperty("name") && service.name !== null) {
      getPrevData();
    }

    serviceData["company"] = company;
    serviceData["start_time"] = service?.start_time;
    serviceData["start_date"] = service?.start_time;
    serviceData["duration"] = service?.service_duration;
    serviceData["service"] = service;
    serviceData["date"] = service.date;
    serviceData["intakeForm"] = service.intakeForm;
    setService(serviceData);
  }, [getCompanyById, match.params.id, serviceData, selectedDate]);
  const [claimList, setClaimList] = useState();
  const onBookAppointment = (e) => {
    e.preventDefault();
    createBooking(serviceData, history);
    addIntakeForm({ intakeForm: service.intakeForm });
  };
  const [providerBooking, setProviderBooking] = useState([]);

  const handleCloseModal = () => {
    hideModal();
  };

  const handleOpenModal = (timeslot, company) => {
    console.log("opening modal with company", company);
    dispatch(setCurrentCompany(company));
    dispatch(showModal());
  };

  // This is for the filtering system/feature -- jays code

  const [availableDays, setAvailableDays] = useState([moment().format("MMDD")]);
  const [availableDaysByMonth, setAvailableDaysByMonth] = useState([]);

  const [monthFilter, setMonthFilter] = useState(null);

  //test code
  // const [gapiLoaded, setGapiLoaded] = useState(false);


  // const loadGapi = () => {
  //   if (providerGapi) {
  //     providerGapi.load('client:auth2', initClient);
  //   } else {
  //     logerror("GAPI not loaded");
  //   }
  // };

  // const initClient = () => {
  //   providerGapi.client.init({
  //     apiKey: API_KEY,
  //     clientId: CLIENT_ID,
  //     discoveryDocs: DISCOVERY_DOCS,
  //     scope: SCOPES,
  //   }).then(() => {
  //     setGapiLoaded(true);
  //   }).catch((error) => {
  //     console.error("Error initializing GAPI client", error);
  //   });
  // };

  // const fetchGoogleCalendarEvents = async (date) => {
  //   if (!gapiLoaded) return;

  //   try {
  //     const response = await providerGapi.client.calendar.events.list({
  //       calendarId: 'primary',
  //       timeMin: moment(date).startOf('day').toISOString(),
  //       timeMax: moment(date).endOf('day').toISOString(),
  //       showDeleted: false,
  //       singleEvents: true,
  //       orderBy: 'startTime',
  //     });
  //     console.log("Google Calendar Events: ", response.result.items);
  //     setProviderBooking(response.result.items);
  //   } catch (error) {
  //     console.error("Error fetching Google Calendar events", error);
  //   }
  // };

  // useEffect(() => {
  //   const getAvailableDays = async () => {
  //     if (company !== null) {
  //       const mToday = moment();
  //       const mEnd = moment().add(90, 'days');
  //       let available_dates = [];
  //       for (let m = moment(mToday); m.isBefore(mEnd); m.add(1, "days")) {
  //         if (!Array.isArray(available_dates[m.format("M")]))
  //           available_dates[m.format("M")] = [];
  //         if (company.store_hours[m.format("dddd")].open)
  //           available_dates[m.format("M")][m.format("D")] = moment(m);
  //       }
  //       setAvailableDays(available_dates);

  //       if (company?.user?.googleAuthBusiness) {
  //         console.log("googleAuthBusiness", company?.user?.googleAuthBusiness);
  //         loadGapi();
  //       }
  //     }
  //   };
  //   getAvailableDays();
  // }, []);

  // useEffect(() => {
  //   console.log('tried to fetch google calendar events', gapiLoaded, company?.user?.googleAuthBusiness)
  //   if (gapiLoaded && company?.user?.googleAuthBusiness) {
  //     console.log('fetching google calendar events')
  //     const loginAndFetchEvents = async () => {
  //       await providerGapi.auth.authorize(
  //         {
  //           client_id: CLIENT_ID,
  //           prompt: "none",
  //           response_type: "permission",
  //           scope: SCOPES,
  //           login_hint: company?.user?.googleUserBusiness.email,
  //         },
  //         async function (result) {
  //           if (result) {
  //             if (!result.access_token) {
  //               await providerGapi.auth.setToken({
  //                 access_token: result.access_token,
  //               });
  //             }
  //             await providerGapi.client.load("calendar", "v3", () => {
  //               fetchGoogleCalendarEvents(new Date());
  //             });
  //           }
  //         }
  //       );
  //     };
  //     loginAndFetchEvents();
  //   }
  // }, [gapiLoaded, company]);


  useEffect(() => {
    const getAvailableDays = async () => {
      if (company !== null) {
        const mToday = moment();
        const mEnd = moment().day(90);
        let available_dates = [];
        // If you want an exclusive end date (half-open interval)
        for (var m = moment(mToday); m.isBefore(mEnd); m.add(1, "days")) {
          if (!Array.isArray(available_dates[m.format("M")]))
            available_dates[m.format("M")] = [];
          if (company.store_hours[m.format("dddd")].open)
            available_dates[m.format("M")][m.format("D")] = moment(m);
        }
        setAvailableDays(available_dates);
        if (company !== null && company?.googleAuthBusiness) {
          // console.log("googleAuthBusiness", company?.googleAuthBusiness);
          await providerGapi?.load("client:auth2", async () => {
            // console.log('clent id', CLIENT_ID, company?.googleAuthBusiness?.email)
            await providerGapi.auth.authorize(
              {
                client_id: CLIENT_ID,
                prompt: "none",
                response_type: "permission", // Access Token.
                scope: SCOPES,
                login_hint: company?.googleUserBusiness.email,
              },
              async function (result) {
                if (result) {
                  if (!result.access_token) {
                    await providerGapi.auth.setToken({
                      access_token: result.access_token,
                    });
                  }

                  // if (result.isAccessTokenExpired(tokenInfo)) {
                  //   return(
                  //     this.refreshAccessToken(key, tokenInfo),
                  //   )
                  //  }

                  //   if(providerGapi.auth.isAccessTokenExpired()){
                  //     await providerGapi.auth.setToken({
                  //       access_token: result.access_token

                  //     // refresh the token
                  //     // $client->refreshToken($refreshToken);
                  //     // await providerGapi.auth.setToken({
                  //     //   access_token: result.access_token
                  //     });
                  // }
                  await providerGapi.client.load("calendar", "v3", () => {
                    providerGapi.client.calendar.events
                      .list({
                        calendarId: "primary",
                        timeMax:
                          moment(selectedDate.selectedDate).format(
                            "YYYY-MM-DD"
                          ) +
                          "T" +
                          moment("1999-10-05 23:59:59").format("HH:mm:ss") +
                          "Z",
                        timeMin:
                          moment(selectedDate.selectedDate).format(
                            "YYYY-MM-DD"
                          ) +
                          "T" +
                          moment("1999-10-05 00:00:00").format("HH:mm:ss") +
                          "Z",
                        showDeleted: false,
                        singleEvents: true,
                        orderBy: "startTime",
                      })
                      .then(function (response) {
                        var events = response.result.items;
                        setProviderBooking(events);
                        console.log("Provider Booking", events);
                      });
                  });
                }
              }
            );
          });
        }
      }
    };
    getAvailableDays();
  }, [company]);

  useEffect(() => {
    const getAvailableDaysByMonth = (month) => {
      if (company !== null && !monthFilter) {
        const mToday = moment();
        const mEnd = moment().day(90);
        let available_dates = [];
        // If you want an exclusive end date (half-open interval)

        for (var m = moment(mToday); m.isBefore(mEnd); m.add(1, "days")) {
          if (month && m.format("MMMM") === month) {
            if (!Array.isArray(available_dates[m.format("M")]))
              available_dates[m.format("M")] = [];
            if (company.store_hours[m.format("dddd")].open)
              available_dates[m.format("M")][m.format("D")] = moment(m);
          }
          // else {
          //   if (!Array.isArray(available_dates[m.format("M")]))
          //     available_dates[m.format("M")] = [];
          //   if (company.store_hours[m.format("dddd")].open)
          //     available_dates[m.format("M")][m.format("D")] = moment(m);
          // }
        }
        setAvailableDaysByMonth(available_dates);
      }
    };
    getAvailableDaysByMonth(moment().format("MMMM"));
  }, [company, monthFilter]);

  const getAvailableDaysByMonth = (month) => {
    if (company !== null) {
      const mToday = moment();
      const mEnd = moment().day(90);
      let available_dates = [];

      // If you want an exclusive end date (half-open interval)
      for (var m = moment(mToday); m.isBefore(mEnd); m.add(1, "days")) {
        if (month && m.format("MMMM") === month) {
          if (!Array.isArray(available_dates[m.format("M")]))
            available_dates[m.format("M")] = [];
          if (company.store_hours[m.format("dddd")].open)
            available_dates[m.format("M")][m.format("D")] = moment(m);
        }
      }

      setAvailableDaysByMonth(available_dates);
    }
  };

  const handleMonthFilter = async (e, monthFilter) => {
    setMonthFilter(moment().month(e.target.value));
    await getAvailableDaysByMonth(e.target.value);
  };

  // const incrementMonth
  const incrementMonth = () => {
    if (monthFilter === null) {
      setMonthFilter(moment().add(1, "M"));
      getAvailableDaysByMonth(moment().add(1, "M").format("MMMM"));
    } else {
      setMonthFilter(monthFilter.add(1, "M"));
      getAvailableDaysByMonth(monthFilter.format("MMMM"));
    }
  };

  const decrementMonth = () => {
    if (monthFilter === null) {
      setMonthFilter(moment().subtract(1, "M"));
      getAvailableDaysByMonth(moment().subtract(1, "M").format("MMMM"));
    } else {
      setMonthFilter(monthFilter.subtract(1, "M"));
      getAvailableDaysByMonth(monthFilter.format("MMMM"));
    }
  };

  const claimCkick = (id) => {
    addClaim(company._id, auth.user._id);
  };

  useEffect(() => {
    setClaimList(
      myclaims &&
        myclaims?.some((element) => {
          if (element.company == company?._id) {
            return true;
          }
        })
    );
  }, [company]);

  const onEditCompany = () => {
    history.push({
      pathname: `/business/service/${company._id}`,
      state: {
        companyyy: company,
        settingsProp: "settings",
      },
    });
  };

  useEffect(() => {
    if (company?.store_hours?.Sunday?.open) {
      variablehours.push({
        // "@type": "OpeningHoursSpecification",
        dayOfWeek: "Sunday",
        opens: moment(new Date(today))
          .add(company?.store_hours?.Sunday?.start_time, "minutes")
          .format("H:mm"),
        closes: moment(new Date(today))
          .add(company?.store_hours?.Sunday?.end_time, "minutes")
          .format("H:mm"),
      });
    }
    if (company?.store_hours?.Monday?.open) {
      variablehours.push({
        // "@type": "OpeningHoursSpecification",
        dayOfWeek: "Monday",
        opens: moment(new Date(today))
          .add(company?.store_hours?.Monday?.start_time, "minutes")
          .format("H:mm"),
        closes: moment(new Date(today))
          .add(company?.store_hours?.Monday?.end_time, "minutes")
          .format("H:mm"),
      });
    }
    if (company?.store_hours?.Tuesday?.open) {
      variablehours.push({
        // "@type": "OpeningHoursSpecification",
        dayOfWeek: "Tuesday",
        opens: moment(new Date(today))
          .add(company?.store_hours?.Tuesday?.start_time, "minutes")
          .format("H:mm"),
        closes: moment(new Date(today))
          .add(company?.store_hours?.Tuesday?.end_time, "minutes")
          .format("H:mm"),
      });
    }
    if (company?.store_hours?.Wednesday?.open) {
      variablehours.push({
        // "@type": "OpeningHoursSpecification",
        dayOfWeek: "Wednesday",
        opens: moment(new Date(today))
          .add(company?.store_hours?.Wednesday?.start_time, "minutes")
          .format("H:mm"),
        closes: moment(new Date(today))
          .add(company?.store_hours?.Wednesday?.end_time, "minutes")
          .format("H:mm"),
      });
    }
    if (company?.store_hours?.Thursday?.open) {
      variablehours.push({
        // "@type": "OpeningHoursSpecification",
        dayOfWeek: "Thursday",
        opens: moment(new Date(today))
          .add(company?.store_hours?.Thursday?.start_time, "minutes")
          .format("H:mm"),
        closes: moment(new Date(today))
          .add(company?.store_hours?.Thursday?.end_time, "minutes")
          .format("H:mm"),
      });
    }
    if (company?.store_hours?.Friday?.open) {
      variablehours.push({
        // "@type": "OpeningHoursSpecification",
        dayOfWeek: "Friday",
        opens: moment(new Date(today))
          .add(company?.store_hours?.Friday?.start_time, "minutes")
          .format("H:mm"),
        closes: moment(new Date(today))
          .add(company?.store_hours?.Friday?.end_time, "minutes")
          .format("H:mm"),
      });
    }
    if (company?.store_hours?.Saturday?.open) {
      variablehours.push({
        // "@type": "OpeningHoursSpecification",
        dayOfWeek: "Saturday",
        opens: moment(new Date(today))
          .add(company?.store_hours?.Saturday?.start_time, "minutes")
          .format("H:mm"),
        closes: moment(new Date(today))
          .add(company?.store_hours?.Saturday?.end_time, "minutes")
          .format("H:mm"),
      });
    }
  }, []);

  const endTime = moment(new Date(today))
    .add(company?.store_hours[selectedDay]?.end_time, "minutes")
    .format("HH:mm");

  const currentTime = new Date();
  const formatCurrentTime = moment(currentTime).format("HH:MM");

  const handleAddToWaitlist = (waitlistObj) => {
    console.log("timeslot", waitlistObj);
    console.log("company", company);
    console.log("selectedDate", selectedDate.selectedDate);
    handleOpenModal(waitlistObj, company);
  }

  

  return loading || company === null ? (
    <Spinner />
  ) : (
    <Fragment>
      <Helmet>
        <title>{title}</title>
        <meta
          name="description"
          content={
            company?.description
              ? `${company?.description} | ${company?.name} | Appointment Cake`
              : `${company?.name} | Appointment Cake`
          }
        />
      </Helmet>
      <Helmet
        script={[
          helmetJsonLdProp({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            name: `${company?.name}`,
            image: "",
            "@id": company?._id,
            url: window.location.href,
            telephone: `${company?.phone}`,
            address: {
              "@type": "PostalAddress",
              streetAddress: company?.location?.street_address,
              // addressLocality: "company?.location?.postal",
              addressRegion: company?.location?.province,
              postalCode: company?.location?.postal,
              addressCountry: company?.location?.country,
            },
            geo: {
              "@type": "GeoCoordinates",
              latitude: company?.location?.lat,
              longitude: company?.location?.lng,
            },
            openingHoursSpecification: variablehours,
          }),
        ]}
      />
      <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={closeTour}
        onAfterOpen={disableBody}
        onBeforeClose={enableBody}
      />
      <a
        onClick={() => {
          history.push("/account");
        }}
        className="user-icon desktop-show btn btn-info max_width d-none"
      >
        <i className="fa-solid fa-user"></i> User{" "}
      </a>
      <button
        id="change-log"
        type="button"
        onClick={() => setisTourOpen(true)}
        className="btn btn-info max_width d-none"
      >
        <i className="fas fa-edit"></i>
        <span> Guide </span>
      </button>
      <div className="row p-4">
        <div className="px-4">
          {/* {auth.isAuthenticated && auth.user._id && ( */}
          <div
            onClick={history.goBack}
            className="btn btn-secondary rounded-circle d-flex justify-content-center align-items-center"
          >
            <i className="fas fa-arrow-left"></i>
          </div>
          {/* )} */}
        </div>
        <div className="col" data-tut="service_header">
          <h3 className="company-title edit_comp_heading">
            {company?.name}

            {/* {auth?.isAuthenticated &&
            company?.user !== null &&
            auth.user._id &&
            auth.user._id != company?.user?._id ? (
              company.likes.filter(
                like => like.user.toString() === auth.user._id
              ).length > 0 ? (
                company?.notified?.filter(
                  notify => notify.user.toString() === auth.user._id
                ).length > 0 ? (
                  <>
                    <button
                      className="marginLeft btn btn-primary btn-notify"
                      onClick={e => {
                        notifyOff(company._id);
                      }}
                      style={{ marginTop: "8px" }}
                    >
                      <i className="fas fa-bell" aria-hidden="true"></i>{" "}
                      Notified
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="marginLeft btn btn-primary btn-notify"
                      onClick={e => {
                        notifyOn(company._id);
                      }}
                      style={{ width: "137px", marginTop: "5px" }}
                    >
                      <i className="fas fa-bell" aria-hidden="true"></i> Notify
                      Me!
                    </button>
                  </>
                )
              ) : (
                ""
              )
            ) : (
              ""
            )} */}
            <button 
              onClick={() => handleAddToWaitlist( {timeslot: 'morning', serviceName: "All"})}
              className="ml-3 service-waitlist-btn"><img style={{color:"white"}} src={Bell_White} className="mr-1" />
              Join Waitlist
              </button>

            <div className="social_icons set-social-icon">
              {auth?.isAuthenticated &&
              company?.user !== null &&
              auth.user._id &&
              auth.user._id != company?.user?._id ? (
                company.likes.filter(
                  (like) => like.user.toString() === auth.user._id
                ).length > 0 ? (
                  company?.notified?.filter(
                    (notify) => notify.user.toString() === auth.user._id
                  ).length > 0 ? (
                    <>
                      <button
                        className="marginLeft btn btn-primary btn-notify"
                        onClick={(e) => {
                          notifyOff(company._id);
                        }}
                        style={{ marginTop: "-6px" }}
                      >
                        <i className="fas fa-bell" aria-hidden="true"></i>{" "}
                        Notified
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="marginLeft btn btn-primary btn-notify"
                        onClick={(e) => {
                          notifyOn(company._id);
                        }}
                        style={{ width: "137px", marginTop: "-6px" }}
                      >
                        <i className="fas fa-bell" aria-hidden="true"></i>{" "}
                        Notify Me!
                      </button>
                    </>
                  )
                ) : (
                  ""
                )
              ) : (
                ""
              )}

              {auth?.isAuthenticated &&
              auth?.user?._id &&
              company?.user !== null &&
              auth?.user?._id != company?.user?._id ? (
                company?.likes.filter(
                  (like) => like?.user?.toString() === auth?.user?._id
                ).length > 0 ? (
                  <>
                    <button
                      onClick={(e) => {
                        removeLike(company._id);
                      }}
                      className="marginLeft btn btn-text like-button"
                    >
                      <i className="fas fa-heart text-lg text-primary"></i>
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      onClick={(e) => {
                        addLike(company._id);
                      }}
                      className="marginLeft btn btn-text like-button"
                      test
                      service
                      Booking
                    >
                      <i className="far fa-heart text-lg text-primary"></i>
                    </button>
                  </>
                )
              ) : (
                ""
              )}
              {auth.isAuthenticated && (
                <button
                  onClick={(e) => {
                    navigator.clipboard.writeText(`${window.location.href}`);
                    linkCopied();
                  }}
                  className="marginLeft btn btn-text"
                >
                  <i
                    class="fa fa-share-alt text-primary fa-2x"
                    aria-hidden="true"
                  ></i>
                </button>
              )}
              {company?.social?.facebook ? (
                <a
                  className="marginLeft btn"
                  target="_blank"
                  href={company?.social?.facebook}
                  style={{ fontSize: "20px" }}
                >
                  <i className="fab fa-facebook-f text-primary"></i>
                </a>
              ) : (
                ""
              )}
              {company?.social?.instagram ? (
                <a
                  className="marginLeft btn"
                  target="_blank"
                  href={company?.social?.instagram}
                  style={{ fontSize: "20px" }}
                >
                  <i className="fab fa-instagram text-primary"></i>
                </a>
              ) : (
                ""
              )}
              {company?.social?.linkedin ? (
                <a
                  className="marginLeft btn"
                  target="_blank"
                  href={company?.social?.linkedin}
                  style={{ fontSize: "20px" }}
                >
                  <i className="fab fa-linkedin-in text-primary"></i>
                </a>
              ) : (
                ""
              )}
            </div>
            {
              company.is_admin && auth.isAuthenticated ? (
                claimList ? (
                  <button className="disable-claim-btn marginLeft" disabled>
                    Claimed
                  </button>
                ) : (
                  <button
                    className="claim-btn marginLeft"
                    onClick={() => claimCkick(company._id)}
                  >
                    Claim
                  </button>
                )
              ) : (
                ""
              )
              // :""
            }
            {auth.isAuthenticated &&
              auth.loading === false &&
              company?.user !== null &&
              auth.user._id === company?.user?._id && (
                <Link
                  to={{
                    pathname: `/business/service/${company._id}`,
                    state: { companyDetails: company, settingProp: "settings" },
                  }}
                  className="btn edit-company-btn btn-dark marginLeft h-fit"
                >
                  <i className="far fa-edit"></i> Edit Company
                </Link>
              )}
          </h3>
          <p>
            {company.description && <Fragment>{company.description}</Fragment>}
            <br />
            <span className="text-muted">
              {company.store_hours[selectedDay].open ? (
                <Fragment>
                  {endTime < formatCurrentTime && currentDay >= selecteDay ? (
                    <>
                      <span className="text-danger">
                        Closed{" "}
                        {moment(new Date(today))
                          .add(
                            company.store_hours[selectedDay].start_time,
                            "minutes"
                          )
                          .format("h:mm a")}{" "}
                        -{" "}
                        {moment(new Date(today))
                          .add(
                            company.store_hours[selectedDay].end_time,
                            "minutes"
                          )
                          .format("h:mm a")}
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="text-success">
                        Open{" "}
                        {moment(new Date(today))
                          .add(
                            company.store_hours[selectedDay].start_time,
                            "minutes"
                          )
                          .format("h:mm a")}{" "}
                        -{" "}
                        {moment(new Date(today))
                          .add(
                            company.store_hours[selectedDay].end_time,
                            "minutes"
                          )
                          .format("h:mm a")}
                      </span>
                    </>
                  )}
                </Fragment>
              ) : (
                <span className="badge badge-danger">Closed today</span>
              )}
            </span>{" "}
            | {company.location.street_address} | {company.phone}{" "}
          </p>
        </div>
      </div>
      <div className="row px-5">
        <div className="col-lg-3 col-md-12  p-2 d-flex flex-column">
          <div className="input-group mb-4">
            <div className="input-group-prepend">
              <button
                onClick={decrementMonth}
                className="btn btn-outline-secondary"
              >
                <i className="fas fa-chevron-left"></i>
              </button>
            </div>
            <label className="form-control monthCalendar">
              {monthFilter
                ? monthFilter.format("MMMM")
                : moment().format("MMMM")}
            </label>

            <div className="input-group-append">
              <button
                onClick={incrementMonth}
                className="btn btn-outline-secondary"
              >
                <i className="fas fa-chevron-right"></i>
              </button>
            </div>
          </div>

          <div
            className="card flex-grow-1"
            style={{
              maxHeight: "calc(100vh - 15em)",
              overflow: "auto",
            }}
          >
            <div className="card-body d-grid gap-2" data-tut="date_bar">
              {availableDaysByMonth?.map((month, i) => {
                return (
                  <div key={i}>
                    {moment()
                      .month(i - 1)
                      .format("MMMM")}
                    {month.map((day, j) => {
                      const itemIndex = moment(day).format("MMDD");
                      const activeItem =
                        activeIndex === itemIndex
                          ? "bg-primary text-white"
                          : "";
                      return (
                        <button
                          onClick={() => {
                            executeScrollService();
                            setCurrentDate(
                              moment(day).hours(0).minutes(0).seconds(0)._d
                            );
                            setItem(itemIndex);
                          }}
                          className={`btn btn-light btn-block mb-1 ${activeItem}`}
                          key={itemIndex}
                        >
                          <h3>{moment(day).format("D")} </h3>
                          {moment(day).format("dddd")}
                        </button>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 p-2" data-tut="service_list">
          {selectedDate && (
            <CompanyServices
              handleAddToWaitlist={handleAddToWaitlist}
              company={company}
              selectedService={setCurrentService}
              selectedDate={selectedDate}
              calendarEvent={providerBooking}
              reference={serviceReference}
              executeScrollForm={executeScrollForm}
              activeIndexdefault={-1}
              setSelectedServiceIndex={setSelectedServiceIndex}
              selectedDay={selectedDay}
            />
          )}
        </div>
        <div
  data-tut="service_form"
  ref={formReference}
  className="col-lg-5 col-md-6 p-2 d-flex flex-column btm-col-in"
  style={{
    maxHeight: "calc(100vh - 10em)",
    overflow: "auto",
  }}
>
  {service &&
    service.hasOwnProperty("name") &&
    service.name !== null && (
      <form
        className="card bg-white flex-grow-1"
        onSubmit={onBookAppointment}
      >
        <div className="card-header services-name">
          <h4 className="card-title mt-3">{service.name}</h4>
        </div>
        <div className="card-body">
          <p>
            <strong>{service.service_duration} Minutes</strong>
          </p>
          <p>
            {moment(service.start_time).format("dddd, D MMMM")}
            <br />
            <strong>
              {moment(service.start_time).format("h:mm a")}
            </strong>{" "}
            to{" "}
            <strong>
              {moment(service.start_time)
                .add(service.service_duration, "minutes")
                .format("h:mm a")}
            </strong>
          </p>
          <div
            className="text-muted"
            style={{ whiteSpace: "pre-line" }}
          >
            <small>{service.description}</small>
          </div>
          <input
            name="service_start"
            value={service.start_time}
            type="hidden"
          />
          {auth.isAuthenticated &&
            service.book_online === true &&
            service.intakeForm.length > 0 && (
              <div className="card set-company-label">
                {service.intakeForm.length > 0 &&
                  service.intakeForm.map((element, index) => (
                    <ul className="list-group field_list list-group-flush" key={index}>
                      <li className="list-group-item online-fields">
                        <div className="row">
                          <div className="col-md-12 field-label">
                            <label>{element.field_label}</label>
                          </div>
                          <div className="col-md-10">
                            {element.field_type === "text" ? (
                              <input
                                id={element.field_name + element._id}
                                required={element.is_required}
                                defaultValue={element.value}
                                type={element.field_type}
                                placeholder={element.field_label}
                                className="form-control"
                                onChange={(e) =>
                                  onFieldValueChange(
                                    e,
                                    index,
                                    element.formfield
                                  )
                                }
                              ></input>
                            ) : element.field_type === "checkbox" ? (
                              <ul className="company-checkbox">
                                <li>
                                  {element &&
                                    element.option &&
                                    element.option.map((ele, i) => (
                                      <div className="form-check" key={i}>
                                        <label className="form-check-label lable-service">
                                          <input
                                            type="checkbox"
                                            id={element.field_name + ele._id}
                                            className="form-check-input"
                                            name={ele.value}
                                            defaultChecked={ele.is_Checked}
                                            onClick={(e) =>
                                              onFieldValueChange(
                                                e,
                                                index,
                                                element.formfield,
                                                i
                                              )
                                            }
                                            value={ele.value}
                                          ></input>
                                          {ele.lable}
                                        </label>
                                      </div>
                                    ))}
                                </li>
                              </ul>
                            ) : element.field_type === "radio" ? (
                              element &&
                              element.option &&
                              element.option.map((option, i) => (
                                <div className="form-check" key={i}>
                                  <label className="form-check-label lable-service">
                                    <input
                                      required={element.is_required}
                                      id={element.field_name + element._id}
                                      type="radio"
                                      defaultChecked={option.isChecked}
                                      className="form-check-input"
                                      name={index}
                                      value={option.value}
                                      onChange={(e) =>
                                        onFieldValueChange(
                                          e,
                                          index,
                                          element.formfield,
                                          i
                                        )
                                      }
                                    ></input>
                                    {option.lable}
                                  </label>
                                </div>
                              ))
                            ) : element.field_type === "file" ? (
                              <>
                                <button
                                  type="button"
                                  className="attached-comment btn btn-info"
                                  onClick={() =>
                                    showWidget(
                                      index,
                                      element.formfield
                                    )
                                  }
                                >
                                  <i className="fas fa-paperclip"></i>{" "}
                                  Upload
                                </button>
                                <div className="image-preview">
                                  {element.value &&
                                    element.value.length > 0 &&
                                    element.value.map((images, i) => (
                                      <div className="images-block" key={i}>
                                        <img
                                          className="commentImage-priview"
                                          src={images}
                                          style={{
                                            width: "75px",
                                            height: "75px",
                                          }}
                                        />
                                        <button
                                          type="button"
                                          className="removeImage btn btn-danger"
                                          onClick={() => {
                                            removeImageFromList(
                                              index,
                                              i
                                            );
                                          }}
                                        >
                                          <i className="far fa-times-circle"></i>
                                        </button>
                                      </div>
                                    ))}
                                </div>
                              </>
                            ) : element.field_type === "textarea" ? (
                              <textarea
                                name="text"
                                id={element.field_name + element._id}
                                className="form-control"
                                placeholder={element.field_label}
                                onChange={(e) =>
                                  onFieldValueChange(
                                    e,
                                    index,
                                    element.formfield
                                  )
                                }
                              ></textarea>
                            ) : element.field_type === "slider" ? (
                              <div className="form-check-label">
                                <input
                                  className="slider"
                                  type="range"
                                  required={element.is_required}
                                  id={element.field_name + element._id}
                                  step={100 / element.option.length}
                                  min="10"
                                  value={
                                    element.value
                                      ? element.value
                                      : "0".toString()
                                  }
                                  list="tickmarks"
                                  onChange={(e) =>
                                    onFieldValueChange(
                                      e,
                                      index,
                                      element.formfield
                                    )
                                  }
                                />
                                <datalist id="tickmarks">
                                  {element &&
                                    element.option &&
                                    element.option.map((el, i) => (
                                      <option value={i + 1} key={i}>
                                        {i + 1}
                                      </option>
                                    ))}
                                </datalist>
                                {element.lable}
                              </div>
                            ) : element.field_type === "dropdown" ? (
                              <select
                                className="form-control"
                                name="field_category"
                                value={element.field_category}
                                option={element.option}
                              ></select>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </li>
                    </ul>
                  ))}
              </div>
            )}
          {auth.isAuthenticated &&
            service.intakeForm.length < 1 &&
            service.book_online === true && (
              <div className="form-group">
                <label htmlFor="bookingNotes">Notes</label>
                <textarea
                  id="bookingNotes"
                  name="text"
                  className="form-control"
                  value={serviceData["text"]}
                  onChange={(e) => onChange(e)}
                ></textarea>
              </div>
            )}
        </div>
        <div className="card-footer">
          {service.book_online === true && auth.isAuthenticated && (
            <button
              type="submit"
              className="btn btn-primary btn-block btn-lg"
              data-toggle="modal"
              date-target=""
            >
              Book {moment(service.start_time).format("h:mm a")} Now
            </button>
          )}
          {service.book_site === true &&
            service.book_site_link &&
            auth.isAuthenticated && (
              <a
                href={service.book_site_link}
                target="_blank"
                className="btn btn-primary btn-block btn-lg"
              >
                Book From Site
              </a>
            )}
          {service.call_to_book === true &&
            auth.isAuthenticated &&
            auth.user && (
              <a
                className="btn btn-primary btn-block btn-lg"
                href={`tel:${company.phone}`}
              >
                Call to Book
              </a>
            )}
          {!auth.isAuthenticated && (
            <a
              className="btn btn-primary btn-block btn-lg"
              onClick={() => {
                const payload = {
                  selectedServiceIndex: selectedServiceIndex,
                  selectedDateIndex: activeIndex,
                  service: service,
                };
                dispatch({
                  type: GET_APPOINTMENTDETAIL,
                  payload: payload,
                });
                history.push({
                  pathname: `/register`,
                  state: {
                    url: `/company/${company._id}`,
                  },
                });
              }}
            >
              Login / Registration
            </a>
          )}
        </div>
      </form>
    )}
    <WaitlistModal
        show={waitlistModal.showModal}
        onHide={handleCloseModal}
        currentCompany={waitlistModal.currentCompany}
      />
</div>


      </div>
    </Fragment>
  );
};

const steps = [
  {
    selector: '[data-tut="service_header"]',
    content: `Details about the company.`,
  },
  {
    selector: '[data-tut="date_bar"]',
    content: `Section for dates available to book the service in particular company.`,
  },
  {
    selector: '[data-tut="service_list"]',
    content: `List of services available in the company.`,
  },
  {
    selector: '[data-tut="service_form"]',
    content: `Service form and service details will be displayed here.`,
  },
];

Company.protoTypes = {
  createBooking: PropTypes.func.isRequired,
  getCompanyById: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  serviceData: PropTypes.object,
  addIntakeForm: PropTypes.object.serviceData,
  addClaim: PropTypes.func.isRequired,
  myclaims: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  setCurrentCompany: PropTypes.func.isRequired,
  waitlistModal: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  company: state.company,
  appointmentDetail: state.booking.appointmentDetail,
  serviceData: state.serviceData,
  auth: state.auth,
  myclaims: state.company.myclaims,
  hideModal: PropTypes.func.isRequired,
  setCurrentCompany: PropTypes.func.isRequired,
  waitlistModal: state.waitlistModal,
});

export default connect(mapStateToProps, {
  createBooking,
  getCompanyById,
  addLike,
  removeLike,
  linkCopied,
  addIntakeForm,
  notifyOn,
  notifyOff,
  addClaim,
  showModal, 
  hideModal, 
  setCurrentCompany, 
})(withRouter(Company));
