import api from "../utils/api";
import { setAlert } from "./alert";
import { $ } from "react-jquery-plugin";
import { addMessage } from "./message";

import {
  GET_COMPANY,
  GET_COMPANIES,
  COMPANY_BOOKINGS,
  COMPANY_ERROR,
  UPDATE_COMPANY,
  CLEAR_COMPANY,
  UPDATE_LIKES,
  GET_LIKED_COMPANIES,
  FORM_FIELDS,
  ADMIN_BUSINESS,
  UPDATE_BSERVICE,
  DELETE_BSERVICE,
  ADD_BSERVICE,
  GET_COMPANY_LIST,
  UPDATE_USERSERVICE,
  GET_COMPANY_LIST_UPDATE,
  UPDATE_NOTIFICATION,
  DELETE_SASERVICE,
  ALLADMINBUSINESS,
  CATEGORIES,
  START_LOADING,
  STOP_LOADING,
  PAGINATION_DATA,
  ALLUSER,
  USERERROR,
  DELETEUSER,
  EDITUSER,
} from "./types";

// Get current users Company
export const getCurrentCompany = () => async (dispatch) => {
  try {
    const res = await api.get("/company/mine");

    dispatch({
      type: GET_COMPANY,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: COMPANY_ERROR,
      payload: { msg: err, status: 500 },
    });
  }
};

// Get list of current users Company
export const getCurrentCompanyList =
  (currentPage, search) => async (dispatch) => {
    try {
      const res = await api.get(
        `/company/lists?limit=10&page=${currentPage}&search=${search}`
      );
      dispatch({
        type: GET_COMPANY_LIST,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: COMPANY_ERROR,
        payload: { msg: err, status: 500 },
      });
    }
  };

// All Admin Business
export const allBusiness = (currentPage, search) => async (dispatch) => {
  dispatch({
    type: START_LOADING,
  });
  dispatch({
    type: PAGINATION_DATA,
    payload: currentPage,
  });

  try {
    const res = await api.get(
      `/company/admin/companies?limit=10&page=${currentPage}&search=${search}`
    );
    dispatch({
      type: ALLADMINBUSINESS,
      payload: res.data,
    });
    if (res.data)
      dispatch({
        type: STOP_LOADING,
      });
  } catch (err) {
    console.log(err);
    dispatch({
      type: STOP_LOADING,
    });
  }
};

//all user list
export const allUser =
  (currentPage, search, startDate, endDate) => async (dispatch) => {
    dispatch({
      type: START_LOADING,
    });

    try {
      const res = await api.get(
        `/users/userlist?limit=10&page=${currentPage}&search=${search}&startDate=${
          startDate ? startDate : ""
        }&endDate=${endDate ? endDate : ""}`
      );
      dispatch({
        type: ALLUSER,
        payload: res.data,
      });

      if (res.data)
        dispatch({
          type: STOP_LOADING,
        });
    } catch (err) {
      console.log(err);
      dispatch({
        type: STOP_LOADING,
      });
    }
  };

//delete user
export const deleteUser = (id) => async (dispatch) => {

  try {
    const res = await api.delete(`/users/userlist/${id}`);

    dispatch({
      type: DELETEUSER,
    });
    if (res.status === 200) dispatch(setAlert("User Deleted", "success"));
  } catch (err) {
    console.log(err);
    dispatch({
      type: USERERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};
//edit user
export const editUser = (formData) => async (dispatch) => {
  try {
    const res = await api.put(`/users/userlist/${formData.id}`, formData);

    dispatch({
      type: EDITUSER,
    });
    if (res.status === 200) dispatch(setAlert("User Updated", "success"));
  } catch (err) {
    console.log(err);
    dispatch({
      type: USERERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};
export const getFormFields = () => async (dispatch) => {
  try {
    const res = await api.get("/company/formFields");

    dispatch({
      type: FORM_FIELDS,
      payload: res.data,
    });
  } catch (err) {}
};

export const getCategories = () => async (dispatch) => {
  try {
    const res = await api.get("/company/categories-list");

    dispatch({
      type: CATEGORIES,
      payload: res.data,
    });
  } catch (err) {}
};
export const linkCopied = () => async (dispatch) => {
  dispatch(setAlert("Link Copied", "success", 1000));
};
// Get users liked Company
export const getLikedCompanies = () => async (dispatch) => {
  try {
    const res = await api.get("/company/liked");

    dispatch({
      type: GET_LIKED_COMPANIES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: COMPANY_ERROR,
      payload: { msg: err, status: 500 },
    });
  }
};

// Get Companies
export const getCompanies = (mapData) => async (dispatch) => {
  dispatch({ type: CLEAR_COMPANY });

  try {
    const res = await api.get(
      `/company?lat=${mapData.latitude}&lng=${mapData.longitude}&miles=${mapData.miles}`
    );

    dispatch({
      type: GET_COMPANIES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: COMPANY_ERROR,
      payload: { msg: err, status: 500 },
    });
  }
};

// Get Company by id
export const getCompanyById = (companyId) => async (dispatch) => {
  try {
    const res = await api.get(`/company/${companyId}`);

    dispatch({
      type: GET_COMPANY,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: COMPANY_ERROR,
      payload: { msg: err, status: 500 },
    });
  }
};

// Get Company Bookings
export const getCompanyBookings = (id) => async (dispatch) => {
  // dispatch({ type: CLEAR_COMPANY });

  try {
    const res = await api.get(`/company/${id}/bookings`);
    dispatch({
      type: COMPANY_BOOKINGS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

// Get Company User
export const getCompanyOwner = (company) => async (dispatch) => {
  try {
    const user = company.user;
    const res = await api.get(`/user/${user._id}`);
  } catch (err) {
    console.log(err);
  }
};

// Create or Update Company

export const createCompany =
  (formData, history, edit = false) =>
  async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
console.log("formData inside API",formData)
      const res = await api.post("/company", formData, config);

      dispatch({
        type: GET_COMPANY,
        payload: res.data,
      });

      dispatch(
        setAlert(edit ? "Company Updated" : "Company Created", "success")
      );
      history.push("/admin");
    } catch (err) {
      const errors =
        typeof err === "object" && err !== null
          ? err.response.data.errors
          : false;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
      }

      dispatch({
        type: COMPANY_ERROR,
        payload: {
          msg: err.response.statusText,
          status: err.response.status,
        },
      });
    }
  };

// Add Service
export const addService = (formData, history) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await api.put("/company/service", formData, config);

    dispatch({
      type: UPDATE_COMPANY,
      payload: res.data,
    });

    dispatch(setAlert("Service Added", "success"));

    history.push("/company"); // Can't use redirect tag in actions like in components
  } catch (err) {
    console.log(err);
  }
};

// Add Service
export const editService = (formData, history) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await api.put(
      `/company/service/${formData._id}`,
      formData,
      config
    );

    dispatch({
      type: UPDATE_COMPANY,
      payload: res.data,
    });

    dispatch(setAlert("Service Added", "success"));

    // history.push('/company'); // Can't use redirect tag in actions like in components
  } catch (err) {
    console.log(err);
  }
};

// Delete Services
export const deleteService = (id) => async (dispatch) => {
  try {
    const res = await api.delete(`/company/service/${id}`);

    dispatch({
      type: UPDATE_COMPANY,
      payload: res.data,
    });

    dispatch(setAlert("Service Removed", "success"));
  } catch (err) {
    console.log(err);
    dispatch({
      type: COMPANY_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

// Add Like
export const addLike = (id) => async (dispatch, getState) => {
  try {
    const res = await api.put(`/company/like/${id}`);
    if ($(".like-button i").hasClass("far")) {
      $(".like-button i").removeClass("far").addClass("fas");
    } else {
      $(".like-button i").removeClass("fas").addClass("far");
    }

    dispatch({
      type: UPDATE_LIKES,
      payload: res.data,
    });

    dispatch(
      addMessage({
        title: `${getState().auth.user.firstName} ${
          getState().auth.user.lastName
        } has favourited your company, ${res.data.name}`,
        // text: 'Let\'s get you started, <a href="/guides">Getting Started Guide</a>',
        text: "You can find messages in this section.",
        type: "company",
        user: res.data.user,
        section: "business",
      })
    );

    dispatch(setAlert("Company added to your favorites", "success"));
  } catch (err) {
    console.log(err);
    /*dispatch({
      type: COMPANY_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });*/
  }
};

// Remove Like
export const removeLike = (id) => async (dispatch) => {
  // console.log('remove');
  try {
    const res = await api.put(`/company/unlike/${id}`);

    if ($(".like-button i").hasClass("far")) {
      $(".like-button i").removeClass("far").addClass("fas");
    } else {
      $(".like-button i").removeClass("fas").addClass("far");
    }

    dispatch({
      type: UPDATE_LIKES,
      payload: res.data,
    });

    dispatch(setAlert("Company no longer favorited", "success"));
  } catch (err) {
    console.log(err);
    /*dispatch({
      type: COMPANY_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });*/
  }
};

// notifyOn
export const notifyOn = (id) => async (dispatch) => {
  try {
    const res = await api.put(`/company/notify/${id}`);

    dispatch({
      type: UPDATE_NOTIFICATION,
      payload: res.data,
    });

    dispatch(setAlert("Notification setting turned on.", "success"));
  } catch (err) {
    console.log(err);
    /*dispatch({
      type: COMPANY_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });*/
  }
};

// notifyOff
export const notifyOff = (id) => async (dispatch) => {
  // console.log('remove');
  try {
    const res = await api.put(`/company/unnotify/${id}`);
    dispatch({
      type: UPDATE_NOTIFICATION,
      payload: res.data,
    });

    dispatch(setAlert("Notification setting turned off.", "success"));
  } catch (err) {
    console.log(err);
    /*dispatch({
      type: COMPANY_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });*/
  }
};

// create business for super admin

export const createBusiness =
  (formData, history, edit = false) =>
  async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await api.post("/company/business", formData, config);

      dispatch({
        type: GET_COMPANY,
        payload: res.data,
      });

      dispatch(setAlert("Company Created"));

      if (!edit) {
        history.push("/superadmin"); // Can't use redirect tag in actions like in components
      }
    } catch (err) {
      const errors =
        typeof err === "object" && err !== null
          ? err.response.data.errors
          : false;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
      }

      dispatch({
        type: COMPANY_ERROR,
        payload: {
          msg: err.response.statusText,
          status: err.response.status,
        },
      });
    }
  };

// get all business of superAdmin
export const getAdminCompanyBookings =
  (currentPage, search) => async (dispatch) => {
    dispatch({
      type: START_LOADING,
    });
    dispatch({
      type: PAGINATION_DATA,
      payload: currentPage,
    });

    try {
      const res = await api.get(
        `/company/business?limit=10&page=${currentPage}&search=${search}`
      );
      dispatch({
        type: ADMIN_BUSINESS,
        payload: res.data,
      });
      if (res.data)
        dispatch({
          type: STOP_LOADING,
        });
    } catch (err) {
      console.log(err);
      dispatch({
        type: STOP_LOADING,
      });
    }
  };

// Delete Admin Business
export const deleteAdminBusiness = (id) => async (dispatch) => {
  if (window.confirm("Are you sure? This can NOT be undone!")) {
    try {
      const res = await api.delete(`/company/business/${id}`);

      dispatch({
        type: ADMIN_BUSINESS,
        payload: res.data,
      });

      dispatch(setAlert("Business Removed", "success"));
    } catch (err) {
      console.log(err);
      dispatch({
        type: COMPANY_ERROR,
        payload: {
          msg: err.response.statusText,
          status: err.response.status,
        },
      });
    }
  }
};

//Delete admin company
export const deleteAdminCompany = (id) => async (dispatch) => {
  if (window.confirm("Are you sure? This can NOT be undone!")) {
    try {
      const res = await api.delete(`/company/admin/companies/${id}`);

      dispatch(setAlert("Business removed successfully", "success"));
    } catch (err) {
      console.log(err);
      dispatch({
        type: COMPANY_ERROR,
        payload: {
          msg: err.response.statusText,
          status: err.response.status,
        },
      });
    }
  }
};

// Delete user Business
export const deleteUserBusiness = (id, history) => async (dispatch) => {
  // if (window.confirm("Are you sure? This can NOT be undone!")) {
  try {
    await api.delete(`/company/${id}`);
    history.push("/admin");
    dispatch(setAlert("Business Removed", "success"));
  } catch (err) {
    console.log(err);
    dispatch({
      type: COMPANY_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
  // }
};

// edit business for super admin

export const editBusiness =
  (formData, history, edit = false, id, alert) =>
  async (dispatch, getState) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await api.put(`/company/business/${id}`, formData, config);
      let userState = getState().company.companylist;

      userState.map((x, i) =>
        x._id === id
          ? ((x.googleRefreshTokenBusiness =
              formData.googleRefreshTokenBusiness),
            (x.googleAuthBusiness = formData.googleAuthBusiness),
            (x.googleUserBusiness = formData.googleUserBusiness),
            (x.store_hours.Friday.open = formData.Friday_open),
            (x.store_hours.Thursday.open = formData.Thursday_open),
            (x.store_hours.Monday.open = formData.Monday_open),
            (x.store_hours.Saturday.open = formData.Saturday_open),
            (x.store_hours.Sunday.open = formData.Sunday_open),
            (x.store_hours.Tuesday.open = formData.Tuesday_open),
            (x.store_hours.Wednesday.open = formData.Wednesday_open),
            (x.store_hours.Sunday.start_time = parseInt(
              formData.Sunday_start_time
            )),
            (x.store_hours.Sunday.end_time = parseInt(
              formData.Sunday_end_time
            )),
            (x.store_hours.Monday.start_time = parseInt(
              formData.Monday_start_time
            )),
            (x.store_hours.Monday.end_time = parseInt(
              formData.Monday_end_time
            )),
            (x.store_hours.Tuesday.start_time = parseInt(
              formData.Tuesday_start_time
            )),
            (x.store_hours.Tuesday.end_time = parseInt(
              formData.Tuesday_end_time
            )),
            (x.store_hours.Wednesday.start_time = parseInt(
              formData.Wednesday_start_time
            )),
            (x.store_hours.Wednesday.end_time = parseInt(
              formData.Wednesday_end_time
            )),
            (x.store_hours.Thursday.start_time = parseInt(
              formData.Thursday_start_time
            )),
            (x.store_hours.Thursday.end_time = parseInt(
              formData.Thursday_end_time
            )),
            (x.store_hours.Friday.start_time = parseInt(
              formData.Friday_start_time
            )),
            (x.store_hours.Friday.end_time = parseInt(
              formData.Friday_end_time
            )),
            (x.store_hours.Saturday.start_time = parseInt(
              formData.Saturday_start_time
            )),
            (x.store_hours.Saturday.end_time = parseInt(
              formData.Saturday_end_time
            )),
            (x.name = formData.name),
            (x.website = formData.website),
            (x.description = formData.description),
            (x.email = formData.email),
            (x.phone = formData.phone),
            (x.fax = formData.fax),
            (x.social.facebook = formData.facebook),
            (x.social.instagram = formData.instagram),
            (x.social.linkedin = formData.linkedin),
            (x.location.street_address = formData.street_address),
            (x.location.street_address_2 = formData.street_address_2),
            (x.location.city = formData.city),
            (x.location.province = formData.province),
            (x.location.country = formData.country),
            (x.location.postal = formData.postal),
            (x.location.lat = formData.lat),
            (x.location.lng = formData.lng),
            (x.calendarurl = formData.calendarurl),
            (x.calendartype = formData.calendartype),
            (x.category = formData.category),
            (x.subcategory = formData.subcategory),
            (x.geoLocation = formData.geoLocation))
          : (x = x)
      );
      let data = userState;
      let companyData = { data };
      dispatch({
        type: GET_COMPANY_LIST_UPDATE,
        payload: formData,
      });
      dispatch({
        type: GET_COMPANY_LIST,
        payload: companyData,
      });

      // dispatch({
      //   type: GET_COMPANY_LIST,
      //   payload: res.data
      // });

      if (alert) dispatch(setAlert("Company Updated"));

      return res.data;

      // if (!edit) {
      //   // history.push("/superadmin/business"); // Can't use redirect tag in actions like in components
      //   history.goBack()
      // }
    } catch (err) {
      // console.log("err : ", err);
      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach((error) =>
          dispatch(setAlert("Something went wrong", "danger"))
        );
      }

      dispatch({
        type: COMPANY_ERROR,
        payload: {
          msg: err.response.statusText,
          status: err.response.status,
        },
      });
    }
  };

// add business service

export const addBusinessService = (formData, history) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await api.put("/company/business/service", formData, config);

    dispatch({
      type: ADD_BSERVICE,
      payload: res.data,
    });

    dispatch(setAlert("Service Added", "success"));

    history.push("/company"); // Can't use redirect tag in actions like in components
  } catch (err) {
    console.log(err);
  }
};

// Edit business Service
export const editSBusinesservice = (formData, history) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await api.put(
      `/company/business/service/${formData._id}`,
      formData,
      config
    );

    dispatch({
      type: UPDATE_BSERVICE,
      payload: res.data,
    });

    dispatch(setAlert("Service Updated", "success"));

    // history.push('/company'); // Can't use redirect tag in actions like in components
  } catch (err) {
    console.log(err);
  }
};

// remove business service
// Delete Services
export const deleteBusinessService = (id, companyid) => async (dispatch) => {
  try {
    const res = await api.delete(
      `/company/business/${companyid}/service/${id}`
    );
    // business/:company_id/service/:serviceID
    dispatch({
      type: DELETE_BSERVICE,
      payload: res.data,
    });

    dispatch(setAlert("Service Removed", "success"));
  } catch (err) {
    console.log(err);
    dispatch({
      type: COMPANY_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

// remove superadmin service
// Delete Services
export const deleteAdminService = (id, companyid) => async (dispatch) => {
  try {
    const res = await api.delete(
      `/company/business/${companyid}/service/${id}`
    );
    // business/:company_id/service/:serviceID
    dispatch({
      type: DELETE_SASERVICE,
      payload: res.data,
    });

    dispatch(setAlert("Service Removed", "success"));
  } catch (err) {
    console.log(err);
    dispatch({
      type: COMPANY_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

// Edit user Service
export const editUserServices = (formData, history) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await api.put(
      `/company/business/service/${formData._id}`,
      formData,
      config
    );

    // console.log("res : ", res.data.isBooked);
    if (res.data.isBooked?.length) {
      dispatch(setAlert(res.data.msg, "danger"));
    } else {
      dispatch({
        type: UPDATE_USERSERVICE,
        payload: res.data,
      });

      dispatch(setAlert("Service Updated", "success"));
    }

    // history.push('/company'); // Can't use redirect tag in actions like in components
  } catch (err) {
    console.log(err);
  }
};

// add user Service
export const addUserService = (formData, history) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await api.put("/company/business/service", formData, config);

    dispatch({
      type: UPDATE_USERSERVICE,
      payload: res.data,
    });

    dispatch(setAlert("Service Added", "success"));

    // history.push('/company'); // Can't use redirect tag in actions like in components
  } catch (err) {
    console.log(err);
  }
};
