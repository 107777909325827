import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateUser } from "../../actions/auth";
import { setAlert } from "../../actions/alert";

const initialState = {
  email: "",
  password: "",
  password2: "",
};

const EmailPasswordForm = ({
  updateUser,
  auth: { user },
  history,
  setAlert,
}) => {
  const [formData, setFormData] = useState(initialState);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);

  useEffect(() => {
    if (user) {
      const formData = { ...initialState };
      for (const key in user) {
        if (key in formData) formData[key] = user[key];
      }

      setFormData(formData);
    }
  }, [updateUser, user]);

  const { email, password, password2 } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (formData.password != formData.password2) {
      // alert("Password do not match!")
      setAlert("Passwords do not match", "danger");
    } else {
      updateUser(formData, history);
      setFormData(...formData, { password: "", password2: "" });
    }
  };

  return (
    <Fragment>
      <div className="mx-auto">
        <p className="text-lg w-fit font-weight-bold mb-0 mx-auto mb-4">
          Email / Password
        </p>

        <form className="form w-585" onSubmit={onSubmit} autoComplete="off">
          <div className="bg-lightpeach border-20 p-10">
            <div className=" pt-10">
              <input
                type="email"
                className="form-control py-10h-43 text-medium font-medium"
                name="email"
                placeholder="Email Address"
                value={user && email}
                required
                readOnly
                disabled
              />
            </div>
            <div className="py-10 ">
              <div className="row">
                <div className="col-12 input-box">
                  {showPassword ? (
                    <input
                      type="text"
                      className="form-control py-10h-43 text-medium font-medium"
                      name="password"
                      placeholder="Password*"
                      value={formData.password}
                      onChange={onChange}
                      required
                      minLength="6"
                      maxLength="50"
                      autoComplete="off"
                    />
                  ) : (
                    <input
                      type="password"
                      className="form-control py-10h-43 text-medium font-medium"
                      name="password"
                      placeholder="Password*"
                      value={formData.password}
                      onChange={onChange}
                      required
                      minLength="6"
                      maxLength="50"
                      autoComplete="off"
                    />
                  )}
                  {showPassword ? (
                    <i
                      className="far fa-eye eye_icon"
                      onClick={() => setShowPassword(false)}
                    ></i>
                  ) : (
                    <i
                      className="far fa-eye-slash eye_icon"
                      onClick={() => setShowPassword(true)}
                    ></i>
                  )}
                </div>
              </div>
            </div>
            <div className="pb-10">
              {/* <label htmlFor='password2'>Confirm New Password</label> */}
              <div className="row">
                <div className="col-12 input-box">
                  {showConfPassword ? (
                    <input
                      type="text"
                      className="form-control py-10h-43 text-medium font-medium"
                      name="password2"
                      placeholder="Confirm New Password*"
                      value={formData.password2}
                      onChange={onChange}
                      required
                      minLength="6"
                      maxLength="50"
                      autoComplete="off"
                    />
                  ) : (
                    <input
                      type="password"
                      className="form-control py-10h-43 text-medium font-medium"
                      name="password2"
                      placeholder="Confirm New Password*"
                      value={formData.password2}
                      onChange={onChange}
                      required
                      minLength="6"
                      maxLength="50"
                      autoComplete="off"
                    />
                  )}
                  {showConfPassword ? (
                    <i
                      className="far fa-eye eye_icon"
                      onClick={() => setShowConfPassword(false)}
                    ></i>
                  ) : (
                    <i
                      className="far fa-eye-slash eye_icon"
                      onClick={() => setShowConfPassword(true)}
                    ></i>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="text-center mt-1">
            <button
              type="submit"
              className="btn btn-primary w-50 mt-2 text-xs font-semibold py-1"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

EmailPasswordForm.propTypes = {
  updateUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { updateUser, setAlert })(
  withRouter(EmailPasswordForm)
); // need withRouter to pass history object
