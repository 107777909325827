import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { deleteService } from "../../actions/company";

const Service = ({ service, selectedService, deleteService, clearService }) => {
  const [serviceData, setService] = useState({
    service: {
      name: null,
      book_online: null,
      call_to_book: null,
      description: null,
      price: null,
      service_duration: null,
      intakeForm: []
    }
  });

  useEffect(() => {
    setService(serviceData);
  }, [serviceData, selectedService, clearService]);

  const services =
    service &&
    service?.map(serv => (
      <tr key={serv._id}>
        <td>{serv.name}</td>
        <td className="text-right">
          <div className="btn-group" role="group">
            <a
              href="#add-service"
              className="btn btn-secondary"
              onClick={() => selectedService(serv)}
            >
              Edit
            </a>
            <button
              className="btn btn-danger"
              onClick={() => deleteService(serv._id)}
            >
              Delete
            </button>
          </div>
        </td>
      </tr>
    ));

  return (
    <Fragment>
      <div className="card mb-4">
        <div className="card-header">
          <a
            href="#add-service"
            className="btn btn-primary float-right"
            onClick={() => clearService()}
          >
            Additional Service
          </a>
          <h4 className="card-title">Services</h4>
        </div>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Service</th>
              <th></th>
            </tr>
          </thead>
          {services.length > 0 ? (
            <tbody>{services}</tbody>
          ) : (
            <tbody>
              <tr>
                <td>No Services Available</td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
    </Fragment>
  );
};

Service.propTypes = {
  service: PropTypes.array.isRequired,
  serviceData: PropTypes.object
};

const mapStateToProps = state => ({
  serviceData: state.serviceData
});

export default connect(
  mapStateToProps,
  { deleteService }
)(Service);
