import React, { useState, useEffect } from "react";
// import Tooltip from "../layout/ToolTip";
import "./Feature.css";
import { generatePrivateKey, getPlans } from "../../actions/feature";
import { withRouter, useHistory, useLocation, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import SplitForm from "./SplitForm";
import Moment from "react-moment";
import { renewPlan } from "../../actions/feature";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import './featuredForm.css';

const stripePromise = loadStripe(process.env.REACT_APP_STRPIE_PUBLIC_KEY);

const FeaturedForm = ({
  generatePrivateKey,
  company,
  getPlans,
  CompanyList,
  User,
  profile: { profile },
  renewPlan,
  FreeMonths,
  UsedFree,
}) => {
  const [payableAmount, setPayableAmount] = useState("");
  const [showCheckoutForm, setShowCheckoutForm] = useState(true);
  const [clientSecret, setClientSecret] = useState("");
  const [copied, setCopied] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [tab, setTab] = useState(0);
  // const [activePlans, setActivePlans] = useState(company.featuredPlan);
  // console.log("company?._id : ", company?._id);
  const FeaturedList = CompanyList?.find((obj) => obj?._id == company?._id);
  const activePlans = FeaturedList?.featuredPlan.filter(
    (element) => element.status == "active" || element.status == "pending"
  );
  const inActivePlans = FeaturedList?.featuredPlan.filter(
    (element) => element.status == "inactive"
  );
  // console.log("FeaturedList : ", FeaturedList);

  const Months = FreeMonths?.free_months
    ? FreeMonths?.free_months
    : User.free_months;

  const c_id = FeaturedList?._id;
  useEffect(() => {
    getPlans(company?._id);
  }, []);

  // function isInActive(plan) {
  //   return plan.status == "inactive";
  // }

  // const [inActivePlans, setInActivePlans] = useState(
  //   company.featuredPlan.find(isInActive)
  // );
  const onSubmit = () => {
    if (payableAmount == 1) {
      const response = renewPlan({
        amount: 0,
        userId: User._id,
        companyID: c_id,
        plan: "1m",
        isReferalMonth: true,
      });
    } else {
      generatePrivateKey(JSON.stringify({ amount: payableAmount })).then(
        (data) => setClientSecret(data.client_secret)
      );
    }
  };

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  // const ManagePaymentForm = data => {
  //   setClientSecret(data);
  // };

  const successModal = (data) => {
    setModalShow(data);
  };

  const onRefferalLinkClick = (async) => {
    var copyText;
    process.env.NODE_ENV === "production"
      ? (copyText = `https://app.appointmentcake.com/register?referrer=${profile?.user?._id}`)
      : (copyText = `http://localhost:3000/register?referrer=${profile?.user?._id}`);
    navigator.clipboard.writeText(copyText);
    setCopied(true);
    // setAlert("Link Copied", "success");
  };

  setTimeout(() => {
    setCopied(false);
  }, 3000);

  function FeatureListingPlanSuccess(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <div className="success_popup">
          <Modal.Body className="success_popup_items">
            <div className="check_container">
              <i class="fa-solid fa-check"></i>
            </div>
            <h4>Success</h4>
            <p>
              You have successfully signed up <br />
              for a Feature Listing plan.{" "}
            </p>
            <p>Thank you for your business!</p>
            <button
              className="btn top btn-primary"
              onClick={() => setModalShow(false)}
            >
              <i className="fa-solid"></i> Back to Business
            </button>
          </Modal.Body>
        </div>
      </Modal>
    );
  }

  const PlanCard = ({ plan, statusClass, index }) => (
    <div className={`card plan-card mb-3 ${index % 2 === 0 ? 'bg-light' : ''}`}>
    <div className="card-body plan-card-body">
      <div className="row">
        <div className="col-6 mb-2">
          <div className="font-weight-bold">Plan Duration</div>
          <div className="plan-value">
            {plan.plan_name
              ? plan.plan_name.includes('m')
                ? plan.plan_name === '1m'
                  ? plan.plan_name.slice(0, -1).concat(' Month')
                  : plan.plan_name.slice(0, -1).concat(' Months')
                : '1 Year'
              : '1 Year'}
          </div>
        </div>
        <div className="col-6 mb-2">
          <div className="font-weight-bold">Amount</div>
          <div className="plan-value">${plan.amount}</div>
        </div>
        <div className="col-6 mb-2">
          <div className="font-weight-bold">Start Date</div>
          <div className="plan-value">
            <Moment format="MMM DD, yyyy" date={plan.start_date} />
          </div>
        </div>
        <div className="col-6 mb-2">
          <div className="font-weight-bold">End Date</div>
          <div className="plan-value">
            <Moment format="MMM DD, yyyy" date={plan.end_date} />
          </div>
        </div>
        
        <div className="col-6 mb-2">
          <div className="font-weight-bold">Status</div>
          <div className={`badge ${statusClass}`}>{plan.status}</div>
        </div>
      </div>
    </div>
  </div>

  );

  return (
    <div className="feature-listing new-feature-list button feature_main_box">
      <p className="text-lg w-fit font-weight-bold mb-0 mx-auto mb-10">
        Feature Listing
      </p>
      <p className="text-medium w-fit font-medium mb-0 mx-auto mb-4">
        We have the perfect pricing plan for you
      </p>
      <div className=" plan-card p-0">
        <div className="card-body plan-card-body p-0">
          {/* Active plan */}
          <div className="card plan-card mb-4 future_listing_card">
            <div className="mt-4">
              <h3 className="h4 text-center text-lg-left font-semibold mb-10">
                Add A Feature Listing
              </h3>
              <p className="text-sm font-normal mb-4">
                Feature Listings will promote your services and increase
                visibility to our users
              </p>
            </div>
            <div className="card-body plan-card-body custome-card-body">
              <div className="purchaseRadio">
                {Months > 0 && FeaturedList?.used_free == false ? (
                  <div className="form-check" style={{ flex: "50%" }}>
                    <label className="form-check-label lable-sepxrvice">
                      <input
                        type="radio"
                        style={{ marginLeft: "-20px", marginTop: "0.4rem" }}
                        className="form-check-input"
                        name="feature"
                        value="0"
                        onChange={() => setPayableAmount(1)}
                      />
                      1-Month Free
                    </label>
                  </div>
                ) : (
                  ""
                )}
                <div className="d-flex flex-wrap w-100 justify-content-center gap-24 mb-4 mb-md-0">
                  <div
                    className={`p-4 border cursor-pointer col-5 col-lg-2 ${
                      payableAmount === 10 ? "border-aqua" : "border-dark"
                    } border-8 d-flex flex-column w-17`}
                    onClick={() => setPayableAmount(10)}
                  >
                    <p className="text-medium font-semibold m-0">1 Month</p>
                    <p className="text-sm font-medium m-0">$10.00</p>
                  </div>
                  <div
                    className={`p-4 border cursor-pointer col-5 col-lg-2 ${
                      payableAmount === 20 ? "border-aqua" : "border-dark"
                    } border-8 d-flex flex-column w-17`}
                    onClick={() => setPayableAmount(20)}
                  >
                    <p className="text-medium font-semibold m-0 ">2 Month</p>
                    <p className="text-sm font-medium m-0">$20.00</p>
                  </div>
                  <div
                    className={`p-4 border cursor-pointer col-5 col-lg-2 ${
                      payableAmount === 28 ? "border-aqua" : "border-dark"
                    } border-8 d-flex flex-column w-17`}
                    onClick={() => setPayableAmount(28)}
                  >
                    <p className="text-medium font-semibold m-0">3 Month</p>
                    <p className="text-sm font-medium m-0">$28.00</p>
                  </div>
                  <div
                    className={`p-4 border cursor-pointer col-5 col-lg-2 ${
                      payableAmount === 50 ? "border-aqua" : "border-dark"
                    } border-8 d-flex flex-column w-17`}
                    onClick={() => setPayableAmount(50)}
                  >
                    <p className="text-medium font-semibold m-0">6 Month</p>
                    <p className="text-sm font-medium m-0">$50.00</p>
                  </div>
                  <div
                    className={`p-4 border cursor-pointer col-10 col-lg-2 ${
                      payableAmount === 100 ? "border-aqua" : "border-dark"
                    } border-8 d-flex flex-column w-17`}
                    onClick={() => setPayableAmount(100)}
                  >
                    <p className="text-medium font-semibold m-0 ">1 Year</p>
                    <p className="text-sm font-medium m-0">$100.00</p>
                  </div>
                </div>
              </div>
              <div className="w-100 text-center">
                <button
                  onClick={() => onSubmit()}
                  className="btn btn-aqua text-white text-xs font-semibold border-r-4 mt-5 mt-md-4 col-6 col-md-3 cursor-pointer mx-auto"
                  style={{ padding: "5px 0px" }}
                  disabled={payableAmount == ""}
                >
                  Purchase Feature Listing
                </button>
                {clientSecret && (
                  <Elements options={options} stripe={stripePromise}>
                    {/* <CheckoutForm clientSecret={clientSecret} /> */}
                    <div className="card mb-4 mt-4 border-lightgrey">
                      <div className="card-header bg-aqua">
                        <h4 className="card-title mx-2">
                          Make Payment of {payableAmount}${" "}
                        </h4>
                      </div>
                      <div className="card-body px-2">
                        <SplitForm
                          payableamount={payableAmount}
                          showCheckoutForm={showCheckoutForm}
                          company={company}
                          // func={ManagePaymentForm}
                          success={successModal}
                        />
                      </div>
                    </div>
                  </Elements>
                )}
              </div>
            </div>
          </div>
          <div className="card plan-card">
            <div className="d-flex justify-content-between align-items-center flex-column flex-md-row">
              <h3 className="h3 font-semibold">Active Plans</h3>
              <div className="refferal-btn">
                <button
                  onClick={() => onRefferalLinkClick()}
                  className="btn btn-light-aqua border-aqua-1 text-aqua border-r-42 text-m font-semibold py-10 px-20"
                >
                  Referral Link
                </button>

                {copied && (
                    <div className="header-right referral-popover">
                      <p>Link Copied</p>
                    </div>
                )}
              </div>
            </div>
            <div className="d-flex">
              <p
                className={`text-sm font-bold px-5 py-10 cursor-pointer ${
                  tab === 0 ? "border-b-orange" : null
                }`}
                onClick={() => setTab(0)}
              >
                Active
              </p>
              <p
                className={`text-sm font-bold px-5 py-10 cursor-pointer ${
                  tab === 1 ? "border-b-orange" : null
                }`}
                onClick={() => setTab(1)}
              >
                InActive
              </p>
            </div>

            <div className="container-fluid" style={{ overflowX: 'auto' }}>
    {tab === 0 ? (
      activePlans?.length > 0 ? (
        activePlans.map((plan, id) => (
          <PlanCard
            key={id}
            plan={plan}
            statusClass={plan.status === 'pending' ? 'badge-warning' : 'badge-success'}
            index={id}
          />
        ))
      ) : (
        <div className="text-center py-3 no-plans">No Active Plans Available</div>
      )
    ) : inActivePlans?.length > 0 ? (
      inActivePlans.map((plan, id) => (
        <PlanCard
          key={id}
          plan={plan}
          statusClass="badge-danger"
          index={id}
        />
      ))
    ) : (
      <div className="text-center py-3 no-plans">No Inactive Plans Available</div>
    )}
  </div>


          
          </div>
        </div>
      </div>
    </div>
  );
};

FeaturedForm.protoTypes = {
  generatePrivateKey: PropTypes.func.isRequired,
  getPlans: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  CompanyList: state.company.companylist,
  User: state.auth.user,
  profile: state.profile,
  FreeMonths: state.feature.userFreeMonths,
  UsedFree: state.feature.updateFreeStatus,
});

export default connect(mapStateToProps, {
  generatePrivateKey,
  getPlans,
  renewPlan,
})(withRouter(FeaturedForm));
