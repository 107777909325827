// formfields
import {
	PLANS, INACTIVEPLANS
} from '../actions/types';

const initialState = {
	featuredPlans: [],
};

export default function plans(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case PLANS:
        return {
            ...state,
            featuredPlans: payload,
            loading: false
		  };
		  case INACTIVEPLANS:
        return {
            ...state,
            inactivePlans: payload,
            loading: false
          };
		default:
			return state;
	}
}
