import React, { Fragment, useState } from "react";
import Banner from "../../../images/step3.png";
import AutoComplete from "../../maps/Autocomplete";

const Step3 = ({ setFormData, formData, onChange, setStep }) => {
  const d_names = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const [weekDay, setWeekDay] = useState([0, 1, 2, 3, 4, 5, 6]);

  const timeDropDown = () => {
    var hours, minutes, ampm;
    const options = [];
    for (var i = 0; i <= 1440; i += 30) {
      hours = Math.floor(i / 60);
      minutes = i % 60;
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      ampm = hours % 24 < 12 ? "AM" : "PM";
      hours = hours % 12;
      if (hours === 0) {
        hours = 12;
      }
      options.push(
        <option key={i} value={i}>
          {hours + ":" + minutes + " " + ampm}
        </option>
      );
    }
    return options;
  };

  const timeDropDownOptions = timeDropDown();
  const handleToggle = (i) => {
    if (weekDay.includes(i)) {
      setWeekDay(weekDay.filter((elem) => elem !== i));
    } else {
      setWeekDay([...weekDay, i]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setStep(4);
  };

  const addPlace = (place) => {
    const addressArray = {};
    place.address_components.length > 0 &&
      place.address_components.forEach((add) => {
        if (add.types[0] === "street_number" || add.types[0] === "route") {
          addressArray["street_address"] =
            (addressArray["street_address"] || "") + " " + add.long_name;
        } else if (add.types[0] === "locality") {
          addressArray["city"] = add.long_name;
        } else if (
          add.types[0] === "administrative_area_level_2" &&
          !addressArray["city"]
        ) {
          addressArray["city"] = add.long_name;
        } else if (add.types[0] === "administrative_area_level_1") {
          addressArray["province"] = add.long_name;
        } else if (add.types[0] === "country") {
          addressArray["country"] = add.long_name;
        } else if (add.types[0] === "postal_code_prefix") {
          addressArray["postal"] = add.long_name;
        }
      });

    setFormData({
      ...formData,
      street_address: place.formatted_address,
      city: addressArray.city ?? "",
      country: addressArray.country ?? "",
      province: addressArray.province ?? "",
      postal: addressArray.postal ? addressArray.postal : formData.postal,
      lng: place.geometry.location.lng(),
      lat: place.geometry.location.lat(),
    });
  };

  return (
    <div className="container d-flex flex-column flex-lg-row align-items-center mb-3">
      <div className="flex-grow-1 d-flex flex-column justify-content-center p-3">
        <p className="title mb-2">Welcome to Appointment Cake!</p>
        <p className="subtitle mb-4">
          Complete these simple steps and start growing your business effortlessly
        </p>
        <div className="business-dialog mx-auto mt-3 w-100" style={{ maxWidth: "620px" }}>
          <div className="d-flex justify-content-between align-items-center w-100">
            <p className="form-title m-0">Hours of Operation</p>
            <p className="form-subtitle m-0">1 step left </p>
          </div>
          <div className="progress-bar-outer w-100">
            <span className="progress-baar bg-aqua"></span>
            <span className="progress-baar bg-aqua"></span>
            <span className="progress-baar bg-aqua"></span>
            <span className="progress-baar bg-gray"></span>
          </div>
          <form className="form w-100" onSubmit={handleSubmit} data-tut="register_detail">
            <div className="form-row form-group bg-white p-10 border-20">
              {d_names.map((day, i) => (
                <div className="hours-grid-container" key={i}>
                  <div className="hours-grid-container-section-1">
                    <label className="font-semibold text-medium" style={{ color: "#4E4E4E" }}>
                      {day}
                    </label>
                    <div style={{ display: "flex", alignItems: "baseline", paddingRight: "20px", width: "55%" }}>
                      <label className="switch hours-grid-container-switch">
                        <input
                          type="checkbox"
                          name={`${day}_open`}
                          id={`${day}_open`}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              [day + "_open"]: !formData[day + "_open"],
                            });
                          }}
                          onClick={() => handleToggle(i)}
                          checked={formData[`${day}_open`]}
                        />
                        <span className="slider round"></span>
                      </label>
                      <span className="font-medium text-medium hours-grid-container-switch-text" style={{ color: "#4E4E4E" }}>
                        {formData[`${day}_open`] ? "Open" : "Closed"}
                      </span>
                    </div>
                  </div>
                  <div className="hours-grid-container-section-2">
                    <div className="grid-col" style={{ width: "100%" }}>
                      <div className="grid-item">
                        <select
                          className="form-control font-semibold text-medium"
                          style={{ color: "#4E4E4E" }}
                          name={`${day}_start_time`}
                          placeholder="Closes at"
                          onChange={onChange}
                          value={formData[day + "_start_time"]}
                        >
                          {timeDropDownOptions.map((time_option, i) => (
                            <Fragment key={i}>{time_option}</Fragment>
                          ))}
                        </select>
                      </div>
                      <div className="grid-item">
                        <select
                          className="form-control font-semibold text-medium"
                          style={{ color: "#4E4E4E" }}
                          name={`${day}_end_time`}
                          placeholder="Closes at"
                          onChange={onChange}
                          value={formData[day + "_end_time"]}
                        >
                          {timeDropDownOptions
                            .filter(
                              (time_option) =>
                                parseInt(time_option.props.value) > parseInt(formData[day + "_start_time"])
                            )
                            .map((time_option, i) => (
                              <Fragment key={i}>{time_option}</Fragment>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="d-flex justify-content-between">
              <input
                type="button"
                className="btn btn-primary bg-white border-0 text-dark font-weight-bold"
                style={{ padding: "10px 20px", fontSize: "15px" }}
                value="Back"
                data-tut="register_button"
                onClick={() => setStep(2)}
              />
              <input
                type="submit"
                className="btn btn-primary text-dark font-weight-bold"
                style={{ padding: "10px 20px", fontSize: "15px" }}
                value="NEXT STEP"
                data-tut="register_button"
              />
            </div>
            <p
              className="mb-5 mb-md-0 text-right skip-text"
              style={{ cursor: "pointer" }}
              onClick={() => setStep(4)}
            >
              Skip
            </p>
          </form>
        </div>
      </div>
      <div className="d-none d-lg-block" style={{ width: "30%" }}>
        <img src={Banner} className="img-fluid" alt="Banner" />
      </div>
    </div>
  );
};

export default Step3;
