import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getCategories } from "../../actions/company";
import {
  getAdminCompanyBookings,
  deleteAdminBusiness,
} from "../../actions/company";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import ReactPaginate from "react-paginate";
import Trash from "../../asssets/icons/trash.svg";
import Search from "../../asssets/icons/SearchL.svg";
// export default function superAdmin({
const SuperAdmin = ({
  getAdminCompanyBookings,
  deleteAdminBusiness,
  CT,
  getCategories,
  company: { adminBookings, totalAdminBusiness },
  loading,
  pageNumber,
}) => {
  const [currentPage, setcurrentPage] = useState(pageNumber ? pageNumber : 0);
  const [pageCount, setPageCount] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [loadingDynamic, setloadingDynamic] = useState(false);
  const [isSearch, setIsSearch] = useState([]);
  const [searchString, setIsSearchString] = useState("");

  const [pageNumberLimit, setPageNumberLimt] = useState(12);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(10);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);

  const getData = async () => {
    const slice = await getAdminCompanyBookings(currentPage, "");
  };
  useEffect(() => {
    getAdminCompanyBookings(
      currentPage === 0 ? currentPage : currentPage - 1,
      ""
    );
  }, []);

  useEffect(() => {
    getCategories();
    setPageCount(Math.ceil(totalAdminBusiness / itemsPerPage));
  }, [currentPage, totalAdminBusiness]);

  const onNext = () => {
    setloadingDynamic(true);
    setTimeout(() => {
      setloadingDynamic(false);
    }, 1000);
    window.scrollTo(0, 0);
    setcurrentPage(currentPage + 1);

    if (currentPage + 1 >= maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const onPrev = () => {
    setloadingDynamic(true);
    setTimeout(() => {
      setloadingDynamic(false);
    }, 1000);
    window.scrollTo(0, 0);
    setcurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit == 0) {
      setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  const onDelete = (id) => {
    deleteAdminBusiness(id);
    setloadingDynamic(true);
    setTimeout(() => {
      setloadingDynamic(false);
    }, 1000);
    getAdminCompanyBookings(
      currentPage === 0 ? currentPage : currentPage - 1,
      ""
    );
  };

  const onSearch = (e) => {
    setcurrentPage(0);
    const searchWord = e.target.value;
    if (searchWord === "") {
      setIsSearch([]);
      setIsSearchString("");
    } else {
      setIsSearch([searchWord]);
      setIsSearchString(searchWord);
    }
  };
  const onSearchSubmit = () => {
    getAdminCompanyBookings(
      currentPage === 0 ? currentPage : currentPage - 1,
      searchString
    );
  };
  const clearSearchBar = () => {
    setIsSearch([]);
    setIsSearchString("");
    setcurrentPage(0);
    getAdminCompanyBookings(0, "");
  };

  const pageNoClick = (i) => {
    setloadingDynamic(true);
    setTimeout(() => {
      setloadingDynamic(false);
    }, 1000);
    window.scrollTo(0, 0);
    setcurrentPage(i);
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setcurrentPage(selectedPage);
    getAdminCompanyBookings(selectedPage, searchString);
  };

  useEffect(() => {
    var prevelement = document.getElementsByClassName("previous");
    var nextelement = document.getElementsByClassName("next");
    if (pageCount === 1 && prevelement.length > 0 && nextelement.length > 0) {
      prevelement[0].classList?.add("disabledli");
      nextelement[0].classList?.add("disabledli");
    } else {
      if (prevelement[0]?.classList?.contains("disabledli")) {
        prevelement[0].classList.remove("disabledli");
      }
      if (nextelement[0]?.classList?.contains("disabledli")) {
        nextelement[0].classList.remove("disabledli");
      }
    }
  }, [pageCount]);
  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <div className="row p-3">
          <div className="col-md-12  tab-content">
            <div className="d-flex mb-4">
              <Link
                to={{
                  pathname: "/admin",
                  state: { show: true }, // Pass the additional state
                }}
                style={{ textDecoration: "none" }}
                className="bg-orange btn-orange border border-dark text-m font-semibold text-white border-r-42 px-20 py-10 mr-4"
              >
                Add Business
              </Link>
              <div className="search-bar ml-5 min-w-678">
                <form action="#" onSubmit={onSearchSubmit}>
                  <input
                    className="search-input form-control pl-30"
                    id="autocomplete"
                    placeholder="Search Businesses"
                    type="text"
                    value={searchString}
                    style={{ paddingLeft: "0.75rem" }}
                    onChange={onSearch}
                  ></input>
                </form>
                <div className="search-bar-clear">
                  <button>
                    {isSearch.length === 0 ? (
                      ""
                    ) : (
                      <i
                        className="fas fa-close mr-4"
                        onClick={clearSearchBar}
                      ></i>
                    )}
                  </button>
                </div>

                <button>
                  <img src={Search} alt="Search" onClick={onSearchSubmit} />
                </button>
              </div>
            </div>
            <div className="fade show">
              <div className=" setting-overflow">
                <div className="appointment-tbl">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th className=" py-10 text-sm font-bold">Name</th>
                        <th className=" py-10 text-sm font-bold">
                          Contact Info
                        </th>
                        <th className=" py-10 text-sm font-bold">Website</th>
                        <th className=" py-10 text-sm font-bold"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {adminBookings && adminBookings?.length > 0 ? (
                        adminBookings.map((comp, i) => (
                          <tr
                            className="booking-item-row"
                            key={i}
                            id={comp._id}
                          >
                            <td className="font-weight-bold text-sm py-10 text-aqua rounded-s-5">
                              {/* <Link
                                to={{
                                  pathname: `/superadmin/service/${comp._id}`,
                                  state: {
                                    companyDetails: comp,
                                    currentTab: CT,
                                    currentPage: currentPage,
                                  },
                                }}
                               
                              > */}
                              {comp.name}
                              {/* </Link> */}
                              <div className="text-xs text-dark font-normal">
                                {comp.location.street_address}, {comp.location.street_address_2}
                              </div>
                            </td>
                            <td className="py-10">{comp.phone}</td>
                            <td className="py-10">
                              <a
                                href={comp.website}
                                target="_blank"
                                className="text-orange"
                                style={{ textDecoration: "underline" }}
                              >
                                {comp.website}
                              </a>
                            </td>
                            <td className="py-10 rounded-e-5">
                              <span className="d-flex">
                                <Link
                                  to={{
                                    pathname: `/superadmin/service/${comp._id}`,
                                    state: {
                                      companyDetails: comp,
                                      currentTab: CT,
                                      currentPage: currentPage,
                                    },
                                  }}
                                  style={{
                                    textDecoration: "none",
                                  }}
                                  className="bg-orange btn-orange text-xs font-bold text-white p-badge border-4 mr-1"
                                >
                                  View
                                </Link>
                                <img
                                  src={Trash}
                                  alt="Trash"
                                  width={24}
                                  height={24}
                                  onClick={() => onDelete(comp._id)}
                                />
                              </span>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={5} style={{ textAlign: "center" }}>
                            No Data Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {adminBookings && (
        <div>
          {adminBookings?.length && !loading ? (
            <>
              <div className="App mt-4">
                <ReactPaginate
                  previousLabel={"prev"}
                  nextLabel={"next"}
                  breakClassName={"break-me"}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  forcePage={currentPage}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                  renderOnZeroPageCount={null}
                />
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};

SuperAdmin.propTypes = {
  getAdminCompanyBookings: PropTypes.func.isRequired,
  deleteAdminBusiness: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  company: state.company,
  loading: state.company.loading,
  pageNumber: state.company.currentPage,
});

export default connect(mapStateToProps, {
  getAdminCompanyBookings,
  deleteAdminBusiness,
  getCategories,
})(SuperAdmin);
