// Autocomplete.js
import React, { useEffect, useState } from "react";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
/* global google */
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
const AutoComplete = ({
  map,
  mapApi,
  addPlace,
  defaultAddress,
  setFilter,
  isFilter,
  placeholder,
  className,
}) => {
  const [address, setAddress] = useState("");

  const [toggle, setToggle] = useState(true);

  const options = {
    componentRestrictions: { country: ["ca", "us"] },
  };

  const [addressArray, setAddressArray] = useState([]);
  const enterEvent = async (e) => {
    geocodeByAddress(e)
      .then(async (results) => {
        getLatLng(results[0]).then((i) => {
          if (isFilter) {
            setFilter("");
          }
          addPlace(results[0]);
          setAddress(results[0].formatted_address);
          let viewPort = results[0]?.geometry.viewport;
          if (results.length > 0) {
            map?.fitBounds(viewPort);
            map?.setZoom(13);
          } else {
            map.setCenter(viewPort);
            map.setZoom(13);
          }
          setToggle(false);
          return true;
        });
      })
      .catch((error) => console.error("Error", error));
  };

  const onChange = (e) => {
    setAddress(e);
    setToggle(true);
  };

  useEffect(() => {
    if (defaultAddress) {
      setAddress(defaultAddress?.street_address);
      enterEvent(defaultAddress);
    }
  }, [defaultAddress]);

  const searchEnter = (e) => {
    if (e.key === "Enter") {
      setFilter("");
      const displaySuggestions = function (predictions, status) {
        geocodeByPlaceId(predictions[0]?.place_id)
          .then(async (results) => {
            let viewPort = results[0]?.geometry.viewport;
            if (results.length > 0) {
              map.fitBounds(viewPort);
              map.setZoom(13);
            } else {
              map.setCenter(viewPort);
              map.setZoom(13);
            }
            setAddress(results[0].formatted_address);
            setToggle(false);
            return true;
          })
          .catch((error) => console.log("error", error));
      };

      const service = new google.maps.places.AutocompleteService();
      let searchValue = e.target.value;
      service.getQueryPredictions({ input: searchValue }, displaySuggestions);
    }
  };

  const [userLat, setUserLat] = useState("");

  const [userLong, setUserLong] = useState("");

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setUserLat(position.coords.latitude);
      setUserLong(position.coords.longitude);
    });
  }, []);
  const location = useLocation();
  const { pathname } = location;
  return (
    <div
      className="form-group map-search-block d-flex justify-content-between align-items-center"
      data-tut="map_search"
    >
      <PlacesAutocomplete
        value={address}
        onChange={onChange}
        onSelect={enterEvent}
        searchOptions={options}
        onKeyDown={(e) => searchEnter(e)}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div style={{ width: "inherit" }}>
            <input
              {...getInputProps({
                placeholder: `${
                  placeholder ? placeholder : "Enter a location"
                } `,
                className: `${className} search-input form-control pac-target-input`,
                "aria-expanded": toggle,
              })}
              style={{ width: "inherit" }}
              autoFocus="autoFocus"
            />
            <div
              className="autocomplete-dropdown-container"
              style={{
                width: pathname === "/find" ? "750px" : "500px",
                display: !toggle ? "none" : "block",
              }}
            >
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: "#DFF9F8", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
      <span
        className="search_zoom"
        style={{ cursor: "pointer" }}
        onClick={() => map.panTo({ lat: userLat, lng: userLong })}
      >
        <i className="fa-solid fa-location-crosshairs"></i>
      </span>
    </div>
  );
};

export default AutoComplete;
