import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createProfile, getCurrentProfile } from "../../actions/profile";
import ToolTip from "../layout/ToolTip";
import moment from "moment";
import { awaitExpression } from "@babel/types";
import { Helmet } from "react-helmet";

const initialState = {
  firstName: "",
  lastName: "",
  prefix: "",
  suffix: "",
  birthday: "",
  phone: "",
  bio: "",
};

const ProfileForm = ({
  profile: { profile, loading },
  createProfile,
  getCurrentProfile,
  history,
}) => {
  const [formData, setFormData] = useState(initialState);
  const [error, setError] = useState();
  useEffect(() => {
    if (!profile) getCurrentProfile();
    if (!loading && profile) {
      const profileData = { ...initialState };
      for (const key in profile) {
        if (typeof profile[key] === "object") {
          for (const inner_key in profile[key]) {
            profileData[inner_key] = profile[key][inner_key];
          }
        }
        if (key in profileData) profileData[key] = profile[key];
      }

      if (Array.isArray(profileData.birthday))
        profileData.birthday = profileData.birthday.split("T")[0];
      setFormData(profileData);
    }
  }, [loading, getCurrentProfile, profile]);

  const { firstName, lastName, prefix, suffix, birthday, phone, bio } =
    formData;
  function clearPhoneNumber(value = "") {
    return (value = value.replace(/\D/g, ""));
    // return (value = value.replace(/^(\d{2})(\d{3})(\d{4})$/, "($1)-$2-$3"));
  }
  const onChange = (e) => {
    if (e.target.name === "firstName" || e.target.name === "lastName") {
      let reference = /^[A-Za-z]+$/;
      if (reference.test(e.target.value) || e.target.value === "") {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        }); // can be used for the onChange of all fields, automatically pulling the name of the field as the key
      }
    } else {
      setFormData({
        ...formData,
        [e.target.name]: clearPhoneNumber(e.target.value),
      });
    }
  };

  let defaultDate = moment(birthday).format("yyyy-MM-DD");

  const onSubmit = (e) => {
    e.preventDefault();
    createProfile(formData, history, profile ? true : false);
    //   .then((response) => {
    //  });
    // setFormData(profile)
    setFormData({
      firstName: profile.user.firstName,
      lastName: profile.user.lastName,
      // prefix: profile.prefix,
      suffix: profile.user.firstName,
      birthday: profile.birthday,
      phone: profile.user.phone,
    });
  };
  return (
    <Fragment>
      <Helmet>
        <title>Appointment Cake</title>
        <meta
          name="description"
          content="Whatever or whoever you’re looking for,
                   Appointment Cake has you covered."
        />
      </Helmet>
      <div className="mx-auto">
        <p className="text-lg w-fit font-weight-bold mb-0 mx-auto mb-4">
          Contact Information
        </p>

        {/* <div className="card-header">
          <h4 className="card-title">Contact Info </h4>
          <div>
            <ToolTip text={"Here you can update your latest contact info"} />
          </div>
        </div> */}

        <form className="px-2 px-md-0 form w-585" onSubmit={onSubmit}>
          <div className="bg-lightpeach border-20 p-10">
            <div className="form-row form-group mb-0 py-10 ">
              <div className="col-md-6">
                <input
                  required
                  type="text"
                  className="form-control h-43 py-10"
                  name="firstName"
                  placeholder="First Name"
                  maxLength="12"
                  value={firstName}
                  onChange={onChange}
                />
              </div>
              <div className="col-md-6">
                <input
                  required
                  type="text"
                  className="form-control h-43 py-10"
                  name="lastName"
                  placeholder="Last Name"
                  maxLength="12"
                  value={lastName}
                  onChange={onChange}
                />
              </div>
            </div>

            <div className="form-group mb-0 pb-10">
              <input
                required
                type="tel"
                className="form-control h-43 py-10"
                name="phone"
                placeholder="Phone Number"
                value={phone}
                minLength="10"
                maxLength="10"
                onChange={onChange}
                // disabled
              />
            </div>
          </div>
          <div className="text-center mt-1">
            <button
              type="submit"
              className="btn btn-primary w-50 mt-2 text-xs font-semibold py-1"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

ProfileForm.propTypes = {
  createProfile: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
});

export default connect(mapStateToProps, { createProfile, getCurrentProfile })(
  withRouter(ProfileForm)
); // need withRouter to pass history object
