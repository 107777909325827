import React, { useEffect, Fragment, useState } from "react";
import { withRouter, Redirect, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  createProfile,
  getCurrentProfile,
  deleteAccount,
} from "../../actions/profile";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { getBookings } from "../../actions/booking";
import ProfileForm from "../profile-forms/ProfileForm";
import EmailPasswordForm from "../profile-forms/EmailPasswordForm";
import PastBookings from "./PastBookings";
import Tour from "reactour";
import AddIntegration from "../profile/AddIntegration";
import { logout } from "../../actions/auth";
import { Helmet } from "react-helmet";
import { Dropdown } from "react-bootstrap";
import "./profile.css";
import WaitlistManager from "../waitlist/WaitlistManager";

const Profile = ({
  getCurrentProfile,
  getBookings,
  deleteAccount,
  auth: { user },
  profile: { profile, loading },
  booking: { bookings },
  logout,
}) => {
  useEffect(() => {
    getCurrentProfile();
    getBookings();
  }, [getCurrentProfile, getBookings, user]);

  const history = useHistory();

  const [isTourOpen, setisTourOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState("email-password");

  const closeTour = () => {
    setisTourOpen(false);
  };

  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);

  const handleSelect = (eventKey) => {
    setCurrentTab(eventKey);
  };

  const tabLabels = {
    "email-password": "Email / Password",
    "contact-info": "Contact Information",
    "appointment-history": "Appointment History",
    "waitlist-manager": "Waitlist Manager",
    "integrations": "Personal Calendar",
    "delete-account": "Delete Account",
    "signout": "Sign Out",
  };

  useEffect(() => {
    // Set the tab based on the URL hash
    const hash = window.location.hash.substring(1);
    if (hash) {
      setCurrentTab(hash);
    }
  }, []);

  return !loading && user === null ? (
    <Redirect to="/" />
  ) : (
    <Fragment>
      <Helmet>
        <title>Appointment Cake</title>
        <meta
          name="description"
          content="Whatever or whoever you’re looking for,
                   Appointment Cake has you covered."
        />
      </Helmet>
      <div className="buisness-manager-page header-bg profile-page ">
        <a
          href="/account"
          className="user-icon desktop-show btn btn-info max_width d-none"
        >
          <i className="fa-solid fa-user"></i> User
        </a>
        <button
          id="change-log"
          type="button"
          className="btn btn-info max_width d-none"
          onClick={() => setisTourOpen(true)}
        >
          <i className="fas fa-edit"></i>
          <span> Guide </span>{" "}
        </button>
        <Tour
          steps={steps}
          isOpen={isTourOpen}
          onRequestClose={closeTour}
          onAfterOpen={disableBody}
          onBeforeClose={enableBody}
        />
        <div className="row p-3">
          <div className="col mobile-dashboard-header">
            <a href="#" className="user-icon ">
              <i
                className="fa-solid fa-user"
                style={{ marginTop: "-9px", fontSize: "27px" }}
              ></i>
            </a>
          </div>
          <div className="col">
            <h2 className="desktop-show">
              {profile && profile.user && profile.user.firstName}{" "}
              {profile && profile.user && profile.user.lastName}
            </h2>
          </div>
        </div>
        <div className="row p-3">
          <div className="mb-3 ml-0 ml-md-4 d-lg-none mobile-menu-wrapper">
            <div className="px-2 mt-1">
              <Dropdown onSelect={handleSelect}>
                <Dropdown.Toggle variant="dark" id="dropdown-basic" className="mobile-dropdown-button">
                  {tabLabels[currentTab] || "Menu"}
                </Dropdown.Toggle>
                <Dropdown.Menu className="border border-dark dropdown-menu">
                  <Dropdown.Item eventKey="email-password" id="email-password-tab" aria-selected={currentTab === "email-password"}>
                    Email / Password
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="contact-info" id="contact-info-tab" aria-selected={currentTab === "contact-info"}>
                    Contact Information
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="appointment-history" id="appointment-history-tab" aria-selected={currentTab === "appointment-history"}>
                    Appointment History
                  </Dropdown.Item>
                  <Dropdown.Item
    eventKey="waitlist-manager"
    id="waitlist-manager-tab"
    aria-selected={currentTab === "waitlist-manager"}
    onClick={() => setCurrentTab("waitlist-manager")}
  >
    Waitlist Manager
  </Dropdown.Item>

                  <Dropdown.Item eventKey="integrations" id="integrations-tab" aria-selected={currentTab === "integrations"}>
                    Personal Calendar
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="delete-account" id="delete-account-tab" aria-selected={currentTab === "delete-account"}>
                    Delete Account
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="signout" id="signout-tab" aria-selected={currentTab === "signout"}>
                    Sign Out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className="card profile-card mb-3 ml-4 d-none d-lg-block" style={{ position: "sticky", top: "1em" }}>
  <div className="list-group list-group-flush border border-dark">
    <a
      href="#email-password"
      className={`list-group-item ${currentTab === "email-password" ? "active" : ""}`}
      onClick={() => setCurrentTab("email-password")}
    >
      Email / Password
    </a>
    <a
      href="#contact-info"
      className={`list-group-item ${currentTab === "contact-info" ? "active" : ""}`}
      onClick={() => setCurrentTab("contact-info")}
    >
      Contact Information
    </a>
    <a
      href="#appointment-history"
      className={`list-group-item ${currentTab === "appointment-history" ? "active" : ""}`}
      onClick={() => setCurrentTab("appointment-history")}
    >
      Appointment History
    </a>
    <a
      href="#waitlist-manager"
      className={`list-group-item ${currentTab === "waitlist-manager" ? "active" : ""}`}
      onClick={() => setCurrentTab("waitlist-manager")}
    >
      Waitlist Manager
    </a>
    <a
      href="#integrations"
      className={`list-group-item ${currentTab === "integrations" ? "active" : ""}`}
      onClick={() => setCurrentTab("integrations")}
    >
      Personal Calendar
    </a>
    <a
      href="#delete-account"
      className={`list-group-item ${currentTab === "delete-account" ? "active" : ""}`}
      onClick={() => setCurrentTab("delete-account")}
    >
      Delete Account
    </a>
    <a
      href="#signout"
      className={`list-group-item ${currentTab === "signout" ? "active" : ""}`}
      onClick={() => setCurrentTab("signout")}
    >
      Sign Out
    </a>
  </div>
</div>
          <div className="max-1200 mx-auto widget-col tab-content pb-5" id="accountTabs">
            {currentTab === "contact-info" && (
              <div className="tab-pane fade show active" id="contact-info" role="tabpanel">
                <ProfileForm />
              </div>
            )}
            {currentTab === "appointment-history" && (
              <div className="tab-pane fade show active" id="appointment-history" role="tabpanel">
                <PastBookings tab="appointment-history" />
              </div>
            )}
            {currentTab === "email-password" && (
              <div className="tab-pane fade show active" id="email-password" role="tabpanel">
                <EmailPasswordForm />
              </div>
            )}
            {currentTab === "waitlist-manager" && (
              <div className="tab-pane fade show active" id="waitlist-manager" role="tabpanel">
                <WaitlistManager />
              </div>
            )}
            {currentTab === "integrations" && (
              <div className="tab-pane fade show active" id="integrations" role="tabpanel">
                <AddIntegration />
              </div>
            )}
            {currentTab === "delete-account" && (
              <div className="tab-pane fade show active" id="delete-account" role="tabpanel">
                <div>
                  <p className="text-lg w-fit font-weight-bold mb-10 mx-auto">
                    Delete Account
                  </p>
                  <p className="text-medium font-medium text-center mb-4">
                    This will delete your account and all data associated with it. This cannot be undone.
                  </p>
                  <a className="btn-red p-10 font-semibold text-red mx-auto d-flex w-300 justify-content-center" href="#delete">
                    Delete Account
                  </a>
                </div>
              </div>
            )}
            <div id="delete" className="overlay delete_pop_overlay">
              <div id="pop_martop" className="popup popup_custom">
                <a className="close" href="#">
                  &times;
                </a>
                <p className="text-center">
                  Are you sure you want to delete <br />
                  your account ?
                </p>
                <div className="d-flex justify-content-center">
                  <a className="pop_cancle btn mar_top btn-primary pop_cancle" href="#" style={{ textDecoration: "none" }}>
                    Cancel
                  </a>
                  <button className="btn mar_top btn-primary pop_cancle" onClick={() => deleteAccount(history)}>
                    <i className="fa-solid"></i> Delete
                  </button>
                </div>
              </div>
            </div>
            {currentTab === "signout" && (
              <div className="tab-pane fade show active" id="delete-account" role="tabpanel">
                <p className="text-lg w-fit font-weight-bold mb-10 mx-auto">
                  Sign Out
                </p>
                <p className="text-2m font-medium text-center mb-4">
                  Confirm you want to sign out by clicking the button below
                </p>
                <button className="btn-red p-10 font-semibold text-red mx-auto d-flex w-300 justify-content-center bg-white" onClick={() => logout()}>
                  Sign Out
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const steps = [
  {
    selector: '[data-tut="email_tab"]',
    content: `By selection Email/Password tab, you can change the password of current logged user.`,
  },
  {
    selector: '[data-tut="service_tab"]',
    content: `By selecting Contact info tab, you will see all the contact information, you can edit them and save them by clicking on "Save contact info" button.`,
  },
  {
    selector: '[data-tut="inbox_tab"]',
    content: `By selecting appointments history tab, you will get all the appointments booked till date.`,
  },
  {
    selector: '[data-tut="settings_tab"]',
    content: `By selecting Calendar Connection tab, you can add the booking events to your calendar.`,
  },
  {
    selector: '[data-tut="delete_ac_tab"]',
    content: `By selection Delete Account tab, you can delete current account, once deleted you can not access it again.`,
  },
];

Profile.propTypes = {
  createProfile: PropTypes.func.isRequired,
  getBookings: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  booking: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
  booking: state.booking,
});

export default connect(mapStateToProps, {
  createProfile,
  getCurrentProfile,
  deleteAccount,
  getBookings,
  logout,
})(withRouter(Profile)); // need withRouter to pass history object
