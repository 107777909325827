import api from '../utils/api';
import { setAlert } from './alert';
import {
CLAIM,
// UPDATE_CLAIM,
TCLAIM,
REMOVE_CLAIM
} from './types';


// Add a claim

export const addClaim = (
	id,
	userid,
  ) => async dispatch => {
	try {
	//   const config = {
	// 	headers: {
	// 	  "Content-Type": "application/json"
	// 	}
	//   };
	  await api.post("/claim", {
		  company: id,
		  user : userid
	  });
  
	dispatch(setAlert( "Claim Registered", "success"));
	//   history.push("/admin")
	} catch (err) {
	  console.log(err);
	}
	};
	

// Get Claimed non transferred Data
export const setClaim = (currentPage) => async dispatch => {

	try {
		const res = await api.get(`/claim?limit=10&page=${currentPage}`);
		// const res = await api.get(`/claim`);
		dispatch({
			type: CLAIM,
			payload: res.data,
		});
		
	} catch (err) {
		// dispatch({
		// 	type: BOOKING_ERROR,
		// 	payload: { msg: err, status: 500 },
		// });
		console.log(err);
		 
	}
};

// Get Claimed transferred Data
export const setTransferredClaim = (currentPage) => async dispatch => {
	try {
		const res = await api.get(`/claim/transferred?limit=10&page=${currentPage}`);
		// const res = await api.get(`/claim`);
		dispatch({
			type: TCLAIM,
			payload: res.data,
		});
		
	} catch (err) {
		// dispatch({
		// 	type: BOOKING_ERROR,
		// 	payload: { msg: err, status: 500 },
		// });
		console.log(err);
	}
};


// transfer claim
// api/claim/transfer

// Get Claimed Data
export const transferClaim = (claimData) => async dispatch => {
	try {
		await api.put('/claim/transfer',claimData);
		dispatch({
			type: REMOVE_CLAIM,
			payload: claimData._id,
		});
		
	} catch (err) {
		// dispatch({
		// 	type: BOOKING_ERROR,
		// 	payload: { msg: err, status: 500 },
		// });
		console.log(err);
		
	}
};


// Delete a claim
export const deleteClaim = (claimId) => async dispatch => {
	try {
		await api.delete(`/claim/${claimId}`);
		
		dispatch({
			type: REMOVE_CLAIM,
			payload: claimId,
		});
		dispatch(setAlert('Claim Removed', 'success'));
	} catch (err) {
		console.log(err);
		// dispatch({
		// 	type: BOOKING_ERROR,
		// 	payload: {
		// 		msg: err.response.statusText,
		// 		status: err.response.status,
		// 	},
		// });
	}
};


// get all the claims of current user
//  /claim/me

export const getMyClaims = () => async dispatch => {
	try {
		const res = await api.get(`/claim/me`);
		dispatch({
			type: TCLAIM,
			payload: res.data,
		});
		
	} catch (err) {
		// dispatch({
		// 	type: BOOKING_ERROR,
		// 	payload: { msg: err, status: 500 },
		// });
		console.log(err);
	}
};