import {
  GET_BOOKING,
  BOOKING_ERROR,
  CLEAR_BOOKING,
  UPDATE_BOOKING,
  GET_BOOKINGS,
  GET_PAST_BOOKINGS,
  GET_FUTURE_BOOKINGS,
  ADD_COMMENT,
  REMOVE_COMMENT,
  CLEAR_HISTORY,
  GET_APPOINTMENTDETAIL
} from "../actions/types";

const initialState = {
  booking: null,
  bookings: [],
  loading: true,
  pastbookings: [],
  appointmentDetail: {},
  error: {},
};

export default function booking(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_APPOINTMENTDETAIL:
      return {
        ...state,
        appointmentDetail: payload,
      };
    case GET_BOOKING:
    case UPDATE_BOOKING:
      return {
        ...state,
        booking: payload,
        loading: false,
      };
    case GET_BOOKINGS:
      return {
        ...state,
        bookings: payload,
        loading: false,
      };
    case GET_PAST_BOOKINGS:
      return {
        ...state,
        pastbookings: payload,
        loading: false,
      };
      case GET_FUTURE_BOOKINGS:
      return {
        ...state,
        futurebookings: payload,
        loading: false,
      };
    case BOOKING_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        booking: null,
      };
    case CLEAR_BOOKING:
      return {
        ...state,
        booking: null,
        repos: [],
        bookings: [],
        pastbookings: [],
        loading: false,
      };
    case ADD_COMMENT:
      return {
        ...state,
        booking: { ...state.booking, comments: payload },
        loading: false,
      };
    case REMOVE_COMMENT:
      return {
        ...state,
        booking: {
          ...state.booking,
          comments: state.booking.comments.filter(
            (comment) => comment._id !== payload
          ),
        },
        loading: false,
      };
    case CLEAR_HISTORY:
      return {
        ...state,
        pastbookings: payload,
        loading: false,
      };
    default:
      return state;
  }
}
