import React from 'react'
import PropTypes from 'prop-types'

const ProfileItem = ({profile: {
    user: { _id, name },
    prefix,
    suffix,
}}) => {
    return (
        <div>
            {prefix} {suffix} {name}
        </div>
    )
}

ProfileItem.propTypes = {
    profile: PropTypes.object.isRequired,
}

export default ProfileItem
