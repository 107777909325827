import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import CompanyItem from "../companies/CompanyItem";
import { getCompanies } from "../../actions/company";
import { Helmet } from "react-helmet";

const Companies = ({ getCompanies, company: { companies, loading } }) => {
  useEffect(() => {
    getCompanies();
  }, []);
  return (
    <Fragment>
      <Helmet>
        <title>Appointment Cake</title>
        <meta
          name="description"
          content="Whatever or whoever you’re looking for,
                   Appointment Cake has you covered."
        />
      </Helmet>
      {loading ? (
        <Spinner />
      ) : (
        <Fragment>
          <h1 className="text-primary">Companies</h1>
          <p className="lead">
            <i className="fab fa-connectdevelop"></i> Browse Profiles
          </p>
          <div className="companies">
            {companies.length > 0 ? (
              companies.map((company) => (
                <CompanyItem key={company._id} company={company} />
              ))
            ) : (
              <h4>No Companies found...</h4>
            )}
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

Companies.propTypes = {
  getCompanies: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  company: state.company,
});

export default connect(mapStateToProps, { getCompanies })(Companies);
