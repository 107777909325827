import React, { Fragment, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  getPastBookings,
  getPastBookingsByBusiness,
  clearHistory,
  clearHistoryByBusiness,
} from "../../actions/booking";
import BookingItem from "../booking/BookingItem";
import Spinner from "../layout/Spinner";
// import ToolTip from "../layout/ToolTip";
import AppointmentHistory from "./AppointmentHistory";

const PastBookings = ({
  getPastBookings,
  getPastBookingsByBusiness,
  clearHistory,
  clearHistoryByBusiness,
  auth: { user },
  booking: { pastbookings, loading },
  tab,
}) => {
  const location = useLocation();
  const BusinessId = location?.state?.companyDetails?._id;
  const closeRef = useRef(null);

  const [businessId, setBusinessId] = useState(BusinessId);

  useEffect(() => {
    if (tab == "appointments") {
      getPastBookingsByBusiness(BusinessId);
      setBusinessId(BusinessId);
    } else if (tab == "appointment-history") {
      getPastBookings();
    }
  }, [getPastBookings, user]);

  const deleteHistory = () => {
    if (tab == "appointments") {
      clearHistoryByBusiness(businessId);
    } else if (tab == "appointment-history") {
      clearHistory();
    }
    closeRef.current.click();
  };

  return !loading && user === null ? (
    <Spinner />
  ) : (
    <Fragment>
      <h1 className="text-center font-bold">Appointments</h1>
      <div className="card my-4 border border-dark border-8">
        <div className="appointment-tbl">
          <table className="table table-striped mb-0">
            {tab == "appointment-history" || tab == "appointments" ? (
              <tbody>
                {pastbookings.length > 0 ? (
                  pastbookings.map((booking) => (
                    <AppointmentHistory key={booking._id} booking={booking} />
                  ))
                ) : (
                  <tr className="booking-item-row no-appointment">
                    <td
                      className="booking-date text-xs font-normal "
                      style={{ textAlign: "center" }}
                    >
                      No Appointment history
                    </td>
                  </tr>
                )}
              </tbody>
            ) : (
              <>
                <tbody>
                  {pastbookings.length > 0 ? (
                    pastbookings.map((booking) => (
                      <BookingItem key={booking._id} booking={booking} />
                    ))
                  ) : (
                    <tr className="booking-item-row no-appointment">
                      <td
                        className="booking-date text-xs font-normal no-appointment"
                        style={{ textAlign: "center" }}
                      >
                        No Appointment history
                      </td>
                    </tr>
                  )}
                </tbody>
              </>
            )}
          </table>
        </div>
      </div>
      <div className="btn-aqua text-white text-xs py-2 text-right w-fit float-right">
        Clear
      </div>

      {/* clear popup code */}
      <div id="clear" className="overlay delete_pop_overlay">
        <div id="pop_martop" className=" popup popup_custom">
          <a className="close" href="#" ref={closeRef}>
            &times;
          </a>
          <p>
            Are you sure you want to clear <br />
            Appointment History ?
          </p>

          <a className="pop_cancle" href="#">
            <i className="fa-solid"></i> Cancel
          </a>

          <button
            className="btn mar_top btn-primary pop_cancle"
            onClick={() => deleteHistory()}
          >
            <i className="fa-solid"></i> Delete
          </button>
        </div>
      </div>
      {/* clear popup code */}

      {/* <div id="clear" className="overlay delete_pop_overlay">
        <div className="popup popup_custom success_popup">
          <a className="close" href="#">
            &times;
          </a>

          <div className="success_popup_items">
            <h4>Success</h4>
            <p>
              You have successfully signed up <br />
              for a Feature Listing plan.{" "}
            </p>
            <p>Thank you for your business!</p>

            <div className="button">
              <button
                className="btn top btn-primary"
                // onClick={() => deleteAccount()}
              >
                <i className="fa-solid"></i> Back to Business
              </button>
            </div>
            {/* <a className="pop_cancle" href="#">
            <i className="fa-solid"></i> Cancel
          </a> 
          </div>
        </div>
      </div> */}
    </Fragment>
  );
};

PastBookings.propTypes = {
  getPastBookings: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  booking: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  booking: state.booking,
});

export default connect(mapStateToProps, {
  getPastBookings,
  getPastBookingsByBusiness,
  clearHistory,
  clearHistoryByBusiness,
})(PastBookings);
