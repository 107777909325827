import React from "react";
import { Route, Switch } from "react-router-dom";
import Login from "../auth/Login";
import Register from "../auth/Register";
import ForgotPassword from "../auth/ForgotPassword";
import ResetPassword from "../auth/ResetPassword";
import EmailSent from "../auth/EmailSent";
import Alert from "../layout/Alert";
import Dashboard from "../dashboard/Dashboard";
import Admin from "../admin/Admin";
import Companies from "../companies/Companies";
import Company from "../company/Company";
import ShareCompany from "../company/SharingCompany";
import Booking from "../booking/Booking";
import PrivateRoute from "../routing/PrivateRoute";
import ProfileForm from "../profile-forms/ProfileForm";
import Profile from "../profile/Profile";
import Profiles from "../profiles/Profiles";
import Find from "../find/Find";
import Messages from "../message/Messages";
import NotFound from "../layout/NotFound";
import Home from "../home/Home";
import SuperAdmin from "../superAdmin/SuperAdmin";
import CreateBusiness from "../superAdmin/CreateBusiness";
import UpdateBusiness from "../superAdmin/UpdateBusiness";
import BusinessService from "../superAdmin/BusinessService";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import CreateUserBusiness from "../userBusinesses/CreateUserBusiness";
// import UserServices from "../userBusinesses/UserServices";
import AllUserBusinesses from "../userBusinesses/AllUserBusinesses";
import EditUserBusiness from "../userBusinesses/EditUserBusiness";
import SuperadminList from "../superAdmin/SuperadminList";
import RegisterComp from "../auth/RegisterComp";
import Favorite from "../favorite/Favorite";
import AllBusiness from "../superAdmin/AllBusiness";
import AdminBusinessInfo from "../superAdmin/AdminBusinessInfo";
import SuccessPage from "../auth/ForgotSuccess";
// import SignupSuccess from "../auth/SignupSuccess";
import EditUserService from "../userBusinesses/EditUserService";
import DeleteAccountSuccess from "../auth/DeleteAccountSuccess";
import Step1 from "../auth/BusinessForms/Step1";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import AddBusinessService from "../superAdmin/AddBusinessService";
import WaitlistManager from "../waitlist/WaitlistManager";

const Routes = (props) => {
  const { pathname } = useLocation();
  return (
    <section
      className={`${pathname === "/find" ? "map-container" : " "} `}
      id="main-content"
    >
      <Alert />
      <Switch>
        <Route exact path="/register" component={RegisterComp} />

        <Route exact path="/create-account" component={Register} />

        <Route exact path="/business-register" component={Step1} />
        
        {/* <Route exact path="/business-register" component={BusinessForm} /> */}
        {/* <Route exact path="/signup-success" component={SignupSuccess} /> */}

        <Route exact path="/delete-account" component={DeleteAccountSuccess} />

        <Route exact path="/success-page" component={SuccessPage} />

        <Route exact path="/home" component={Home} />

        <Route exact path="/login" component={Login} />

        <Route exact path="/forgot" component={ForgotPassword} />

        <Route exact path="/reset/:token" component={ResetPassword} />

        <Route exact path="/email-sent" component={EmailSent} />

        <Route
          exact
          path="/find"
          component={() => <Find tour={props.tour} setTour={props.setTour} />}
        />

        <Route exact path="/profiles" component={Profiles} />

        <Route exact path="/companies" component={Companies} />

        <Route
          exact
          path="/company/:id"
          component={() => (
            <Company tour={props.tour} setTour={props.setTour} />
          )}
        />

        <Route exact path="/share/company/:id" component={ShareCompany} />

        <PrivateRoute exact path="/bookings/:id" component={Booking} />

        <PrivateRoute
          exact
          path="/dashboard"
          component={() => (
            <Dashboard tour={props.tour} setTour={props.setTour} />
          )}
        />
        <PrivateRoute exact path="/admin" component={AllUserBusinesses} />
        <PrivateRoute exact path="/favorite" component={Favorite} />
        <PrivateRoute
          exact
          path="/messages"
          component={() => (
            <Messages tour={props.tour} setTour={props.setTour} />
          )}
        />
        <PrivateRoute exact path="/edit-profile" component={ProfileForm} />

        <PrivateRoute exact path="/account" component={Profile} />

        <PrivateRoute exact path="/business" component={CreateUserBusiness} />

        <PrivateRoute exact path="/business/:id" component={EditUserBusiness} />

        {/* <PrivateRoute exact path='/business/service/:id' component={UserServices} /> */}

        <PrivateRoute exact path="/business/service/:id" component={Admin} />

        <PrivateRoute
          exact
          path="/edit-service/:id"
          component={EditUserService}
        />
        <PrivateRoute
          exact
          path="/add-service"
          component={AddBusinessService}
        />

        {props?.auth?.user?.isAdmin ? (
          <>
            <PrivateRoute
              exact
              path="/superadmin/list"
              component={SuperAdmin}
            />

            <PrivateRoute exact path="/superadmin" component={SuperadminList} />

            {/* All business listing for admin */}
            <PrivateRoute
              exact
              path="/superadmin/all-business"
              component={AllBusiness}
            />

            <PrivateRoute
              exact
              path="/superadmin/business"
              component={CreateBusiness}
            />

            <PrivateRoute
              exact
              path="/superadmin/update/:id"
              component={UpdateBusiness}
            />

            <PrivateRoute
              exact
              path="/superadmin/service/:id"
              component={BusinessService}
            />

            <PrivateRoute
              exact
              path="/superadmin/business-info/:id"
              component={AdminBusinessInfo}
            />
          </>
        ) : (
          ""
        )}
        <Route component={NotFound} />
        <h1>change og</h1>
      </Switch>
    </section>
  );
};

// export default Routes;
Routes.propTypes = {
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps)(Routes);
