import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
// import MapContainer from "../maps/MapContainer";
// import ToolTip from "../layout/ToolTip";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DetailTooltip from "../layout/DetailTooltip";
// import BusinessFormAutocomplete from "../maps/BusinessFormAutocomplete";
import { createBusiness, editBusiness } from "../../actions/company";
const initialState = {
  name: "",
  website: "",
  description: "",
  street_address: "",
  street_address_2: "",
  city: "",
  province: "",
  country: "",
  postal: "",
  email: "",
  phone: "",
  fax: "",
  lat: "",
  lng: "",
  facebook: "",
  twitter: "",
  linkedin: "",
  instagram: "",
  Sunday_open: false,
  Sunday_start_time: 540,
  Sunday_end_time: 1020,
  Monday_open: false,
  Monday_start_time: 540,
  Monday_end_time: 1020,
  Tuesday_open: false,
  Tuesday_start_time: 540,
  Tuesday_end_time: 1020,
  Wednesday_open: false,
  Wednesday_start_time: 540,
  Wednesday_end_time: 1020,
  Thursday_open: false,
  Thursday_start_time: 540,
  Thursday_end_time: 1020,
  Friday_open: false,
  Friday_start_time: 540,
  Friday_end_time: 1020,
  Saturday_open: false,
  Saturday_start_time: 540,
  Saturday_end_time: 1020,
  markers: [],
  geoLocation: "",
};

const BusinessHours = ({
  history,
  editBusiness,
  companyCurrent,
  setCompany,
  CompanyList,
}) => {
  const currentBusiness = CompanyList.find(
    (obj) => obj?._id == companyCurrent?._id
  );

  const [currentCompany, setCurrentCompany] = useState(currentBusiness);
  const [formData, setFormData] = useState(initialState);
  const [facebookError, setfacebookError] = useState("");
  const [linkedinError, setlinkedinError] = useState("");
  const [instagramError, setinstagramError] = useState("");
  const [weekDay, setWeekDay] = useState([]);
  const [formError, setFormError] = useState("");
  const d_names = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  useEffect(() => {
    if (currentCompany !== null) {
      const companyData = { ...initialState };
      for (const key in currentCompany) {
        if (key in companyData) companyData[key] = currentCompany[key];
      }

      for (const key in currentCompany.location) {
        if (key in companyData) companyData[key] = currentCompany.location[key];
      }

      for (const key in currentCompany.social) {
        if (key in companyData) companyData[key] = currentCompany.social[key];
      }

      for (const key in currentCompany.store_hours) {
        companyData[key + "_open"] = currentCompany.store_hours[key].open;
        companyData[key + "_start_time"] =
          currentCompany.store_hours[key].start_time;
        companyData[key + "_end_time"] =
          currentCompany.store_hours[key].end_time;

        companyData["markers"][0] = {
          text: currentCompany.name,
          lat: companyData["lat"],
          lng: companyData["lng"],
          company: currentCompany,
        };

        companyData["geoLocation"] = {
          type: "Point",
          coordinates: [companyData["lng"] * 1, companyData["lat"] * 1],
        };
      }

      setFormData(companyData);
    }
  }, []);

  // const handleToggle = i => {
  //   console.log("i : ",i)
  //   if (weekDay.includes(i)) {
  //     setWeekDay(weekDay.filter(elem => elem !== i));
  //   } else {
  //     setWeekDay([...weekDay, i]);
  //   }
  // };

  const {
    name,
    website,
    description,
    street_address,
    street_address_2,
    city,
    province,
    country,
    postal,
    email,
    phone,
    fax,
    lat,
    lng,
    facebook,
    instagram,
    linkedin,
    /*Sunday_open,
        Sunday_start_time,
        Sunday_end_time,
        Monday_open,
        Monday_start_time,
        Monday_end_time,
        Tuesday_open,
        Tuesday_start_time,
        Tuesday_end_time,
        Wednesday_open,
        Wednesday_start_time,
        Wednesday_end_time,
        Thursday__open,
        Thursday_start_time,
        Thursday_end_time,
        Friday_open,
        Friday_start_time,
        Friday_end_time,
        Saturday_open,
        Saturday_start_time,
        Saturday_end_time,*/
    markers,
  } = formData;
  let tempData;

  const timeDropDown = () => {
    var hours, minutes, ampm;
    const options = [];
    for (var i = 0; i <= 1440; i += 30) {
      hours = Math.floor(i / 60);
      minutes = i % 60;
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      ampm = hours % 24 < 12 ? "AM" : "PM";
      hours = hours % 12;
      if (hours === 0) {
        hours = 12;
      }
      options.push(
        <option key={i} value={i}>
          {hours + ":" + minutes + " " + ampm}
        </option>
      );
    }
    return options;
  };
  const timeDropDownOptions = timeDropDown();
  timeDropDownOptions.map((time) => {});

  const onChange = (e, lat) => {
    if (e.target.name === "facebook") {
      let reference = /^(https?:\/\/)?((w{3}\.)?)facebook.com\/.*/i;
      if (reference.test(e.target.value) || e.target.value === "") {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setfacebookError("");
      } else {
        setfacebookError("This is not a valid facebook link.");
      }
    }

    if (e.target.name === "linkedin") {
      let reference = /^(https?:\/\/)?((w{3}\.)?)linkedin.com\/.*/i;
      if (reference.test(e.target.value) || e.target.value === "") {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setlinkedinError("");
      } else {
        setlinkedinError("This is not a valid linkedin link.");
      }
    }

    if (e.target.name === "instagram") {
      let reference = /^(https?:\/\/)?((w{3}\.)?)instagram.com\/.*/i;
      if (reference.test(e.target.value) || e.target.value === "") {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setinstagramError("");
      } else {
        setinstagramError("This is not a valid instagram link.");
      }
    }

    if (e.target.name === "name") {
      let reference = /^[a-zA-Z\$&+,:;=?@#|'<>.-^*()%!]*$/;
      if (reference.test(e.target.value) || e.target.value === "") {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      }

      // }else{
      //   setFormData({ ...formData, [e.target.name]: e.target.value });
      // }
    } else if (e.target.name === "phone") {
      let reference = /^[0-9\b]+$/;
      if (reference.test(e.target.value) || e.target.value === "") {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      }
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    if (lat != null) {
      setFormData({ ...formData, lat: lat });
    }
  };

  const addressUpdate = (address, latitiude, lng) => {
    tempData = { ...formData };
    setFormData({
      ...formData,
      street_address: address[0] ? `${address[0].long_name}` : "",
      street_address_2: address[1] ? ` ${address[1].long_name}` : "",
      city: address[4] ? `${address[4].long_name}` : "",
      province: address[6] ? `${address[6].short_name}` : "",
      country: address[5] ? `${address[5].long_name}` : "",
      postal: address[8] ? `${address[8].short_name}` : "",
      lat: latitiude,
      lng: lng,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!facebookError && !instagramError && !linkedinError) {
      setFormError("");
      editBusiness(formData, history, false, currentCompany._id, true).then(
        (res) => {
          // history.push('/admin')
          window.scrollTo(0, 0);

          setCompany(res.name);
        }
      );
    } else {
      setFormError(
        "There is some errors with the form values, please check the form once."
      );
    }
  };

  return (
    <div>
      <section className="white-bg header-bg-edit bottom-wave-edit">
        <div className=" text-center d-flex justify-content-center align-items-center registration-page">
          <div className="buisness-hours-edit">
            {/* <div className="form-heading">
              <h4 className="logintitle">
                Business Information
                <DetailTooltip text="Business Information will be create a company" />
              </h4>
              <div className="logintitle">
                <span>Company Basics</span>
              </div>
            </div> */}
            {/* <div className="form-row form-group first-input"> */}
            {/* <div className="col-md-6">
                <input
                  className="form-control mb-3"
                  type="text"
                  placeholder="Company Name *"
                  name="name"
                  // value={name}
                  // onChange={onChange}
                  maxLength="15"
                  onChange={e => onChange(e)}
                  value={name}
                  required
                />
              </div>

              <div className="col-md-6">
                <input
                  className="form-control mb-3"
                  type="text"
                  placeholder="Website"
                  name="website"
                  value={website}
                  onChange={onChange}
                />
              </div>
              <div className="col-md-6">
                <input
                  className="form-control mb-3"
                  type="text"
                  placeholder="Brief Description / tagline"
                  name="description"
                  value={description}
                  onChange={onChange}
                />
              </div>

              <div className="col-md-6">
                <input
                  className="form-control mb-3"
                  type="email"
                  placeholder="Company Email *"
                  name="companyEmail"
                  value={email}
                  onChange={onChange}
                  required
                />
              </div>

              <div className="col-md-6">
                <input
                  className="form-control mb-3"
                  type="tel"
                  placeholder="Business Phone Number *"
                  name="companyPhone"
                  minLength="10"
                  maxLength="10"
                  value={phone}
                  onChange={onChange}
                  required
                />
              </div>

              <div className="col-md-6">
                <input
                  className="form-control mb-3"
                  type="text"
                  placeholder="Fax Number"
                  name="fax"
                  // minLength="6"
                  value={fax}
                  onChange={onChange}
                />
              </div>

              <div className="form-heading">
                <span>Social Media Links</span>
              </div>
              <div className="col-md-6">
                <input
                  className="form-control mb-3"
                  type="text"
                  placeholder="facebook.com/"
                  name="facebook"
                  value={facebook}
                  onChange={onChange}
                />
                <p style={{ color: "red" }}>
                  {facebookError ? facebookError : ""}
                </p>
              </div>

              <div className="col-md-6">
                <input
                  className="form-control mb-3"
                  type="text"
                  placeholder="linkedin.com/in/"
                  name="linkedin"
                  value={linkedin}
                  onChange={onChange}
                />
                <p style={{ color: "red" }}>
                  {linkedinError ? linkedinError : ""}
                </p>
              </div>

              <div className="col-md-6">
                <input
                  className="form-control mb-3"
                  type="text"
                  placeholder="instagram.com/"
                  name="instagram"
                  value={instagram}
                  onChange={onChange}
                />
                <p style={{ color: "red" }}>
                  {instagramError ? instagramError : ""}
                </p>
              </div>

              <div className="form-heading">
                <h4 className="logintitle">
                  <span>Location</span>
                </h4>
              </div>

              <div className="map-location">
                <MapContainer
                  mapMarkers={markers}
                  newMarker={false}
                  currentLat={currentCompany?.location?.lat}
                  currentLng={currentCompany?.location?.lng}
                  addressupdate={addressUpdate}
                  onChange={onChange}
                />
              </div> */}
            {/* <div className="map-location col-md-6">
                  <BusinessFormAutocomplete addressupdate={addressUpdate} />
                </div> */}

            {/* <div className="col-md-6">
                <input
                  className="form-control mb-3"
                  type="text"
                  placeholder="Street Address *"
                  name="street_address"
                  value={street_address}
                  onChange={onChange}
                  required
                />
              </div>
              <div className="col-md-6">
                <input
                  className="form-control mb-3"
                  type="text"
                  placeholder="Street Address2"
                  name="street_address_2"
                  value={street_address_2}
                  onChange={onChange}
                />
              </div>
              <div className="col-md-6">
                <input
                  className="form-control mb-3"
                  type="text"
                  placeholder="City *"
                  name="city"
                  value={city}
                  onChange={onChange}
                  required
                />
              </div>
              <div className="col-md-6">
                <input
                  className="form-control mb-3"
                  type="text"
                  placeholder="Province *"
                  name="province"
                  value={province}
                  onChange={onChange}
                  required
                />
              </div>
              <div className="col-md-6">
                <input
                  className="form-control mb-3"
                  type="text"
                  placeholder="Company country *"
                  name="country"
                  value={country}
                  onChange={onChange}
                  required
                />
              </div>
              <div className="col-md-6"> 
                <input
                  className="form-control mb-3"
                  type="text"
                  placeholder="Postal Code *"
                  name="postal"
                  value={postal}
                  onChange={onChange}
                  required
                />
              </div>*/}

            <div className="form-row form-group first-input">
              <div className="buisness-hours">
                <h4 className="logintitle">
                  Business Hours*
                  <DetailTooltip text="Set Business hours , for your company" />
                </h4>
                {d_names.map((day, i) => {
                  return (
                    <div className="grid-container" key={i}>
                      <label>{day}</label>
                      <label className="switch">
                        <input
                          type="checkbox"
                          name={`${day}_open`}
                          id={`${day}_open`}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              [day + "_open"]: !formData[day + "_open"],
                            });
                          }}
                          // onClick={() => handleToggle(i)}
                          checked={formData[day + "_open"]}
                        />
                        <span className="slider round"></span>
                      </label>
                      {/* {weekDay.includes(i) && ( */}
                      <div className="grid-col">
                        <div className="grid-item">
                          <select
                            className="form-control"
                            name={`${day}_start_time`}
                            placeholder="Closes at"
                            onChange={onChange}
                            value={formData[day + "_start_time"]}
                          >
                            {timeDropDownOptions.map((time_option, i) => (
                              <Fragment key={i}>{time_option}</Fragment>
                            ))}
                          </select>
                        </div>

                        <div className="grid-item">
                          <select
                            className="form-control"
                            name={`${day}_end_time`}
                            placeholder="Closes at"
                            onChange={onChange}
                            value={formData[day + "_end_time"]}
                          >
                            {timeDropDownOptions.map((time_option, i) => (
                              <Fragment key={i}>{time_option}</Fragment>
                            ))}
                          </select>
                        </div>
                      </div>
                      {/* // )} */}
                    </div>
                  );
                })}
              </div>
              <div className="card-body" style={{ marginBottom: "45px" }}>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={onSubmit}
                >
                  Save Company Info
                </button>
                <p
                  style={{
                    color: "red",
                    marginLeft: "10px",
                    marginTop: "10px",
                  }}
                >
                  {formError ? formError : ""}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ---------------------------- */}
    </div>
  );
};

BusinessHours.propTypes = {
  editBusiness: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  company: state.company,
  CompanyList: state.company.companylist,
  // markers: state.markers
});

export default connect(mapStateToProps, { editBusiness })(
  withRouter(BusinessHours)
); // need withRouter to pass history object
