import React from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux"; // Needed to intereact with State to check isAuthenticated
import PropTypes from "prop-types";
import LogoImage from "../../images/Appt-Cake-logo.png";
import Home from "../home/Home";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

export const Landing = ({ isAuthenticated }) => {
  // if (isAuthenticated) {
  // 	return <Redirect to='/dashboard' />;
  // }

  return (
    <section id="main-content" >
      {/* <div className=''>
				<img src={LogoImage} alt='Appointment Cake' id='welcome-logo' />
				<h1 className='text-secondary my-5'>
					<span style={{ fontWeight: 300 }}>welcome to </span>
					<br />
					Appointment Cake
				</h1>
				<p>
					<Link
						to='/register'
						className='btn btn-outline-primary btn-lg mr-2'
					>
						Register
					</Link>
					<Link
						to='/login'
						className='btn btn-outline-secondary btn-lg'
					>
						Login
					</Link>
				</p>
			</div> */}
      <Home />
    </section>
  );
};

Landing.propTypes = {
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(Landing);
