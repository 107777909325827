import React, { useMemo, useState, useEffect } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from "@stripe/react-stripe-js";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makePayment } from "../../actions/feature";
import Spinner from "../layout/Spinner";

import useResponsiveFontSize from "./useResponsiveFontSize";
import { setAlert } from "../../actions/alert";

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          // fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#9e2146"
        }
      }
    }),
    [fontSize]
  );

  return options;
};

const SplitForm = ({
  makePayment,
  payableamount,
  showCheckoutForm,
  company,
  func,
  success,
  setAlert
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const [payButton, setPayButton] = useState(showCheckoutForm);
  const [Error, setError] = useState();
  const [plan, setPlan] = useState();
  const [loadingDynamic, setloadingDynamic] = useState(false);

  // let plan = ''
  const generatePlan = () => {
    if (payableamount == 10) {
      setPlan("1m");
    } else if (payableamount == 20) {
      setPlan("2m");
    } else if (payableamount == 28) {
      setPlan("3m");
    } else if (payableamount == 50) {
      setPlan("6m");
    } else if (payableamount == 100) {
      setPlan("1year");
    }
    // return plan;
  };

  const handleSubmit = async event => {
    event.preventDefault();
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement)
    });

    if (error) {
      setloadingDynamic(false);
    } else {
      setloadingDynamic(true);
    }

    if (!error) {
      //send token to backend here
      try {
        setError("");
        const { id } = paymentMethod;
        const response = await makePayment({
          amount: payableamount,
          id: id,
          companyID: company._id,
          plan: plan
        });
        setPayButton(false);
        // func("");
        success(response.success);
        // console.log("response.success : ", response.success);
        setloadingDynamic(false);
        elements.getElement(CardNumberElement).clear();
        elements.getElement(CardExpiryElement).clear();
        elements.getElement(CardCvcElement).clear();
        if (response.data) {
          console.log("CheckoutForm.js 25 | payment successful!");
        }
      } catch (error) {
        setloadingDynamic(false);
        console.log("CheckoutForm.js 28 | ", error);
      }
    } else {
      setError(error.message);
      setAlert(error.message, "danger");
      // console.log("error.message : ", error);
    }
  };

  useEffect(() => {
    setPayButton(true);
    generatePlan();
  }, [payableamount]);

  return (
    <>
      {loadingDynamic ? (
        <Spinner />
      ) : (
        <form onSubmit={handleSubmit} className="DemoWrapper">
          <label>
            Card number
            <CardNumberElement options={options} />
          </label>
          <label>
            Expiration date
            <CardExpiryElement options={options} />
          </label>
          <label>
            CVC
            <CardCvcElement options={options} />
          </label>
          <button
            type="submit"
            className=" pay_In_btn"
            disabled={!stripe || payButton == false}
          >
            Pay
          </button>
          {Error ? <p className="errorMsg">{Error}</p> : ""}
        </form>
      )}
    </>
  );
};

// export default SplitForm;

SplitForm.protoTypes = {
  makePayment: PropTypes.func.isRequired
};

const mapStateToProps = state => ({});

export default connect(
  mapStateToProps,
  {
    makePayment,
    setAlert
  }
)(withRouter(SplitForm));
