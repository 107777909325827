import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getCompanyBookings } from "../../actions/company";
import moment from "moment";
import './companyServices.css';
import SunIcon from '../../asssets/icons/SunIcon.svg';
import MoonIcon from '../../asssets/icons/MoonIcon.svg';
import RisingSunIcon from '../../asssets/icons/RisingSunIcon.svg';
import Bell_White from '../../asssets/icons/Bell_White.svg';
import { showModal, hideModal, setCurrentCompany } from '../../actions/waitlistModal';
import WaitlistModal from '../waitlist/WaitlistModal';
import company from "../../reducers/company";

const ServiceButton = ({
  service,
  serviceIndex,
  selectedService,
  setItem,
  setSelectedServiceIndex,
  executeScrollForm,
  activeIndex,
  bookingItemTime,
  selectedDate,
  weekDay,
  storeHours,
  formatCurrentTime,
  currentTime,
  currentDay,
}) => {
  const activeItem = activeIndex === serviceIndex ? "bg-primary text-white" : "";
  const serviceStartTime = moment(selectedDate.selectedDate).add(service.start_time, "minutes");

  return (
    <button
      key={serviceIndex}
      disabled={
        formatCurrentTime >= serviceStartTime.format("HH:mm") &&
        currentDay === weekDay &&
        currentTime > selectedDate.selectedDate
          ? true
          : service.disabled
      }
      className={`card mb-2 w-100 text-left ${activeItem} ${service.disabled ? "bg-grey text-muted" : ""}`}
      onClick={() => {
        selectedService({
          ...service,
          start_time: bookingItemTime.toDate(),
        });
        setItem(serviceIndex);
        setSelectedServiceIndex(serviceIndex);
        executeScrollForm();
      }}
    >
      <div className="card-body">
        <h5 className="">{service.name}</h5>
        <small>
          {serviceStartTime.format("h:mm a")} -{" "}
          {serviceStartTime.add(service.service_duration, "minutes").format("h:mm a")}
        </small>
      </div>
    </button>
  );
};

const CompanyServices = ({
  company: { _id, services, store_hours },
  getCompanyBookings,
  booking: { bookings },
  selectedService,
  selectedDate,
  calendarEvent,
  isSharing,
  reference,
  executeScrollForm,
  activeIndexdefault,
  selectedDay,
  setSelectedServiceIndex,
  appointmentDetail,
  handleAddToWaitlist,
  showModal, hideModal, setCurrentCompany, waitlistModal
}) => {
  const [activeIndex, setItem] = useState(
    appointmentDetail?.selectedServiceIndex
      ? appointmentDetail?.selectedServiceIndex
      : -1
  );

  useEffect(() => {
    setItem(
      appointmentDetail?.selectedServiceIndex
        ? appointmentDetail?.selectedServiceIndex
        : -1
    );
  }, [selectedDate, activeIndexdefault]);

  useEffect(() => {
    getCompanyBookings(_id);
  }, [getCompanyBookings, _id]);

  const [serviceFilter, setServiceFilter] = useState("");
  const [filteredServices, setFilteredServices] = useState({
    morning: [],
    afternoon: [],
    evening: []
  });
  const [categoryAvailability, setCategoryAvailability] = useState({
    morningAvail: false,
    afternoonAvail: false,
    eveningAvail: false,
  });

  useEffect(() => {
    const week_day = moment(selectedDate.selectedDate).format("dddd");
    const start_time = store_hours[week_day]
      ? parseInt(store_hours[week_day].start_time)
      : 0;
    const end_time = parseInt(store_hours[week_day].end_time);
    let day_of_services = [];
    const now = moment();

    for (let service in services) {
      if (services[service].service_duration === null) continue;
      let duration = services[service].service_duration;
      let serviceName = services[service].name;

      if (serviceFilter === "all" || serviceFilter === "") {
        for (let t = start_time; t < end_time; t += duration) {
          if (!Array.isArray(day_of_services[t])) {
            day_of_services[t] = [];
          }

          let match = false;
          if (bookings.length > 0) {
            for (let bk in bookings) {
              if (
                moment(selectedDate.selectedDate)
                  .add(t, "minutes")
                  .format("YYYY-MM-DDTHH:mm") ===
                moment(bookings[bk].start_time).format("YYYY-MM-DDTHH:mm")
              ) {
                match = true;
                break;
              }
            }
          }

          const serviceStartTime = moment(selectedDate.selectedDate).add(t, "minutes");

          let serviceToAdd = { 
            ...services[service], 
            start_time: t, 
            disabled: match || now.isAfter(serviceStartTime)
          };

          if (t + services[service].service_duration < end_time) {
            day_of_services[t].push(serviceToAdd);
          }
        }
      } else if (serviceName === serviceFilter) {
        for (let t = start_time; t <= end_time; t += duration) {
          if (!Array.isArray(day_of_services[t])) {
            day_of_services[t] = [];
          }

          let match = false;
          if (bookings.length > 0) {
            for (let bk in bookings) {
              if (
                moment(selectedDate.selectedDate)
                  .add(t, "minutes")
                  .format("YYYY-MM-DDTHH:mm") ===
                moment(bookings[bk].start_time).format("YYYY-MM-DDTHH:mm")
              ) {
                match = true;
                break;
              }
            }
          }

          const serviceStartTime = moment(selectedDate.selectedDate).add(t, "minutes");

          let serviceToAdd = { 
            ...services[service], 
            start_time: t, 
            disabled: match || now.isAfter(serviceStartTime)
          };

          day_of_services[t].push(serviceToAdd);
        }
      }
    }

    // Categorize services by time of day
    const morning = [];
    const afternoon = [];
    const evening = [];
    let morningAvail = false;
    let afternoonAvail = false;
    let eveningAvail = false;

    day_of_services.flat().forEach((service) => {
      const serviceStartTime = moment().startOf('day').add(service.start_time, 'minutes');
      if (serviceStartTime.isBefore(moment().startOf('day').add(12, 'hours'))) {
        morning.push(service);
        if (!service.disabled) morningAvail = true;
      } else if (serviceStartTime.isBefore(moment().startOf('day').add(18, 'hours'))) {
        afternoon.push(service);
        if (!service.disabled) afternoonAvail = true;
      } else {
        evening.push(service);
        if (!service.disabled) eveningAvail = true;
      }
    });

    setFilteredServices({ morning, afternoon, evening });
    setCategoryAvailability({ morningAvail, afternoonAvail, eveningAvail });
  }, [serviceFilter, services, store_hours, selectedDate, bookings]);

  const handleFilterChange = (e) => {
    e.preventDefault();
    setServiceFilter(e.target.value);
  };

  const date = new Date();
  const d_names = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const d_key = selectedDay ? selectedDay : d_names[date.getDay()];
  const formatCurrentTime = moment().format("HH:mm");
  const currentTime = new Date();
  const currentDay = moment().format("dddd");

  const handleCloseModal = () => {
    hideModal();
  };



  return (
    <div className="fnd-srch-in" ref={reference}>
      <div className="search-filter-bar mb-4">
        <div className="input-group">
          <select
            className="custom-select"
            onChange={(e) => handleFilterChange(e)}
          >
            <option value={""} defaultValue>
              Filter By A Service
            </option>
            <option value="all">All</option>
            {services.length > 0
              ? services.map((service) => {
                  return (
                    <Fragment key={service._id}>
                      <option value={service.name}>{service.name}</option>
                    </Fragment>
                  );
                })
              : "No service found"}
          </select>
        </div>
      </div>

      <div style={{ maxHeight: "calc(100vh - 15em)", overflow: "auto" }}>
        {filteredServices.morning.length > 0 && (
          <div className="time-category"> 
                 
            <div className="vertical-label-container">
              <div className="vertical-label">
              <div className="time-icon-container">
                <img className='time-icon' src={RisingSunIcon} alt="SunIcon" />
              </div>
              Morning
              </div>
            </div>
            <div className="time-slot-color morning-color"></div>
            <div className={`time-slot-container service-list ${!categoryAvailability.morningAvail ? 'unavailable-category-bg' : ''}`}>
            {!categoryAvailability.morningAvail && <div className="waitlist-btn-container">
              <button 
              onClick={() => handleAddToWaitlist( {timeslot: 'morning', serviceName: serviceFilter})}
              className="service-waitlist-btn"><img style={{color:"white"}} src={Bell_White} className="mr-1" />Join Waitlist</button></div>}
              {filteredServices.morning.length > 0 ? (
                filteredServices.morning.map((service, index) => (
                  <ServiceButton
                    key={index}
                    service={service}
                    serviceIndex={index}
                    selectedService={selectedService}
                    setItem={setItem}
                    setSelectedServiceIndex={setSelectedServiceIndex}
                    executeScrollForm={executeScrollForm}
                    activeIndex={activeIndex}
                    bookingItemTime={moment(selectedDate.selectedDate).startOf('day').add(service.start_time, 'minutes')}
                    selectedDate={selectedDate}
                    weekDay={moment(selectedDate.selectedDate).format("dddd")}
                    storeHours={store_hours}
                    formatCurrentTime={formatCurrentTime}
                    currentTime={currentTime}
                    currentDay={currentDay}
                  />
                ))
              ) : (
                <p>No Morning Services Available</p>
              )}
            </div>
          </div>
        )}

        {filteredServices.afternoon.length > 0 && (
          <div className="time-category">
            <div className="vertical-label-container">
              <div className="vertical-label">
              <div className="time-icon-container">
                <img className='time-icon' src={SunIcon} alt="SunIcon" />
              </div>
                Afternoon</div>
            </div>
            <div className="time-slot-color afternoon-color"></div>
            <div className={`time-slot-container service-list ${!categoryAvailability.afternoonAvail ? 'unavailable-category-bg' : ''}`}>
            {!categoryAvailability.afternoonAvail && <div className="waitlist-btn-container">
              <button 
              onClick={() => handleAddToWaitlist({ timeSlot: 'afternoon', service_id: filteredServices.morning[0]._id })}
              className="service-waitlist-btn"><img style={{color:"white"}} src={Bell_White} className="mr-1" />Join Waitlist</button></div>}
              {filteredServices.afternoon.length > 0 ? (
                filteredServices.afternoon.map((service, index) => (
                  <ServiceButton
                    key={index}
                    service={service}
                    serviceIndex={index}
                    selectedService={selectedService}
                    setItem={setItem}
                    setSelectedServiceIndex={setSelectedServiceIndex}
                    executeScrollForm={executeScrollForm}
                    activeIndex={activeIndex}
                    bookingItemTime={moment(selectedDate.selectedDate).startOf('day').add(service.start_time, 'minutes')}
                    selectedDate={selectedDate}
                    weekDay={moment(selectedDate.selectedDate).format("dddd")}
                    storeHours={store_hours}
                    formatCurrentTime={formatCurrentTime}
                    currentTime={currentTime}
                    currentDay={currentDay}
                  />
                ))
              ) : (
                <p>No Afternoon Services Available</p>
              )}
            </div>
          </div>
        )}

        {filteredServices.evening.length > 0 && (
          <div className="time-category">
            <div className="vertical-label-container">
              <div className="vertical-label">
              <div className="time-icon-container">
                <img className='time-icon' src={MoonIcon} alt="SunIcon" />
              </div>
                Evening</div>
            </div>
            <div className="time-slot-color evening-color"></div>
            <div className={`time-slot-container service-list ${!categoryAvailability.eveningAvail ? 'unavailable-category-bg' : ''}`}>
            {!categoryAvailability.eveningAvail && <div className="waitlist-btn-container">
              <button 
              onClick={() => handleAddToWaitlist({ timeSlot: 'evening', service_id: filteredServices.morning[0]._id })}
              className="service-waitlist-btn"><img style={{color:"white"}} src={Bell_White} className="mr-1" />Join Waitlist</button></div>}
              {filteredServices.evening.length > 0 ? (
                filteredServices.evening.map((service, index) => (
                  <ServiceButton
                    key={index}
                    service={service}
                    serviceIndex={index}
                    selectedService={selectedService}
                    setItem={setItem}
                    setSelectedServiceIndex={setSelectedServiceIndex}
                    executeScrollForm={executeScrollForm}
                    activeIndex={activeIndex}
                    bookingItemTime={moment(selectedDate.selectedDate).startOf('day').add(service.start_time, 'minutes')}
                    selectedDate={selectedDate}
                    weekDay={moment(selectedDate.selectedDate).format("dddd")}
                    storeHours={store_hours}
                    formatCurrentTime={formatCurrentTime}
                    currentTime={currentTime}
                    currentDay={currentDay}
                  />
                ))
              ) : (
                <p>No Evening Services Available</p>
              )}
            </div>
          </div>
        )}
      </div>
      <WaitlistModal
        show={waitlistModal.showModal}
        onHide={handleCloseModal}
        currentCompany={waitlistModal.currentCompany}
      />
    </div>
  );
};

CompanyServices.propTypes = {
  getCompanyBookings: PropTypes.func.isRequired,
  booking: PropTypes.object.isRequired,
  // new props
  appointmentDetail: PropTypes.object,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  setCurrentCompany: PropTypes.func.isRequired,
  waitlistModal: PropTypes.object.isRequired,

};

const mapStateToProps = (state) => ({
  booking: state.booking,
  appointmentDetail: state.booking.appointmentDetail,
  waitlistModal: state.waitlistModal,
});

export default connect(mapStateToProps, { getCompanyBookings, showModal, hideModal, setCurrentCompany })(CompanyServices);
