import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col, Form } from 'react-bootstrap';
import api from '../../utils/api';
import SunIcon from '../../asssets/icons/SunIcon.svg';
import MoonIcon from '../../asssets/icons/MoonIcon.svg';
import RisingSunIcon from '../../asssets/icons/RisingSunIcon.svg';
import SunDarkIcon from '../../asssets/icons/SunDarkIcon.svg';
import MoonDarkIcon from '../../asssets/icons/MoonDarkIcon.svg';
import RisingSunDarkIcon from '../../asssets/icons/RisingSunDarkIcon.svg';
import './waitlistCard.css';

const WaitlistCard = ({ user, companyId, entries }) => {
  const [toggleState, setToggleState] = useState({
    Monday: { morning: false, afternoon: false, evening: false },
    Tuesday: { morning: false, afternoon: false, evening: false },
    Wednesday: { morning: false, afternoon: false, evening: false },
    Thursday: { morning: false, afternoon: false, evening: false },
    Friday: { morning: false, afternoon: false, evening: false },
    Saturday: { morning: false, afternoon: false, evening: false },
    Sunday: { morning: false, afternoon: false, evening: false },
  });
  const [duration, setDuration] = useState("2 Weeks");
  const [notifyByText, setNotifyByText] = useState(false);
  const [notifyByEmail, setNotifyByEmail] = useState(false);
  const [company, setCompany] = useState({});

  const userId = user?._id;

  useEffect(() => {
    const fetchExistingWaitlist = async () => {
      try {
        const response = await api.get(`/waitlist/user/${userId}/company/${companyId}`);
        const waitlistEntries = response.data;

        const newToggleState = { ...toggleState };
        waitlistEntries.forEach(entry => {
          newToggleState[entry.day][entry.period] = true;
        });

        setToggleState(newToggleState);

        if (waitlistEntries.length > 0) {
          setDuration(waitlistEntries[0].duration);
          setNotifyByText(waitlistEntries[0].preferences.notifyByText);
          setNotifyByEmail(waitlistEntries[0].preferences.notifyByEmail);
        }
      } catch (error) {
        console.error('Error fetching existing waitlist:', error);
      }
    };

    const fetchCompany = async () => {
      try {
        const response = await api.get(`/company/${companyId}`);
        setCompany(response.data);
      } catch (error) {
        console.error('Error fetching company:', error);
      }
    };

    fetchExistingWaitlist();
    fetchCompany();
  }, [userId, companyId]);

  const handleToggle = (day, period) => {
    setToggleState((prevState) => ({
      ...prevState,
      [day]: {
        ...prevState[day],
        [period]: !prevState[day][period],
      },
    }));
  };

  const renderToggleButton = (day, period, icon, darkIcon) => {
    return (
      <Button
        variant={toggleState[day][period] ? 'warning' : 'light'}
        onClick={() => handleToggle(day, period)}
        className="toggle-button"
      >
        <img src={toggleState[day][period] ? icon : darkIcon} alt={period} />
      </Button>
    );
  };

  const handleSave = async () => {
    const preferredTimes = [];
    const removedTimes = [];
    const existingTimes = entries.map(entry => `${entry.day}-${entry.period}`);

    Object.keys(toggleState).forEach(day => {
      Object.keys(toggleState[day]).forEach(period => {
        if (toggleState[day][period]) {
          if (!existingTimes.includes(`${day}-${period}`)) {
            preferredTimes.push({ day, period });
          }
        } else {
          if (existingTimes.includes(`${day}-${period}`)) {
            removedTimes.push({ day, period });
          }
        }
      });
    });

    const waitlistData = preferredTimes.map(time => ({
      company: companyId,
      user: userId,
      day: time.day,
      period: time.period,
      duration,
      preferences: { notifyByText, notifyByEmail },
      email: user?.email,
      phone: user?.phone
    }));

    try {
      const addResponses = await Promise.all(waitlistData.map(async (entry) => {
        const response = await api.post('/waitlist', entry);
        return response.data;
      }));

      console.log('All new entries have been successfully created:', addResponses);

      const removeResponses = await Promise.all(removedTimes.map(async (time) => {
        const response = await api.delete(`/waitlist/user/${userId}/company/${companyId}/day/${time.day}/period/${time.period}`);
        return response.data;
      }));

      console.log('All deselected entries have been successfully removed:', removeResponses);

      // Update existing entries only if preferences have changed
      const updateResponses = await Promise.all(entries.map(async (entry) => {
        if (entry.preferences.notifyByText !== notifyByText || entry.preferences.notifyByEmail !== notifyByEmail) {
          const response = await api.put(`/waitlist/${entry._id}`, {
            preferences: { notifyByText, notifyByEmail },
            email: user?.email,
            phone: user?.phone
          });
          return response.data;
        }
        return entry;
      }));

      console.log('Updated entries:', updateResponses);
    } catch (error) {
      console.error('Error updating waitlist:', error.response ? error.response.data : error.message);
    }
  };

  return (
    <div className="card mb-3 mx-3 justify-content-center">
      <div className="bg-aqua card-header">
        <h4>{company.name}</h4>
      </div>
      <div className="waitlist-card-body p-3">
        {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => (
          <Row className="bg-white p-1 mb-1 mx-2 align-items-center" key={day}>
            <Col>{day}</Col>
            <Row>
              <Col className="text-center">
                {renderToggleButton(day, 'morning', RisingSunIcon, RisingSunDarkIcon)}
              </Col>
              <Col className="text-center">
                {renderToggleButton(day, 'afternoon', SunIcon, SunDarkIcon)}
              </Col>
              <Col className="text-center">
                {renderToggleButton(day, 'evening', MoonIcon, MoonDarkIcon)}
              </Col>
            </Row>
          </Row>
        ))}
        <Form.Group as={Col} className="mb-3">
          <Form.Label column className='text-center font-weight-bold text-black-100'>
            Stay on the waitlist for:
          </Form.Label>
          <Col>
            <Form.Control
              as="select"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
            >
              <option>1 Week</option>
              <option>2 Weeks</option>
              <option>1 Month</option>
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Col>
            <Form.Check
              type="checkbox"
              label="Text Me"
              checked={notifyByText}
              onChange={(e) => setNotifyByText(e.target.checked)}
            />
          </Col>
          <Col>
            <Form.Check
              type="checkbox"
              label="Email Me"
              checked={notifyByEmail}
              onChange={(e) => setNotifyByEmail(e.target.checked)}
            />
          </Col>
        </Form.Group>
        <Button
          className={`btn-light-orange text-sm font-semibold py-2 px-4 ${!notifyByText && !notifyByEmail ? 'btn-secondary text-gray-500' : 'text-orange'}`}
          onClick={handleSave}
          disabled={!notifyByText && !notifyByEmail}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

WaitlistCard.propTypes = {
  user: PropTypes.object.isRequired,
  companyId: PropTypes.string.isRequired,
  entries: PropTypes.array.isRequired,
};

export default WaitlistCard;
