import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import alert from "./alert";
import modal from "./modal";
import auth from "./auth";
import profile from "./profile";
import company from "./company";
import booking from "./booking";
import message from "./message";
import map from "./map";
import formfields from "./formfields";
import plans from "./plans";
import feature from "./feature";
import waitlistModalReducer from "./waitlistModal"; //

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "profile", "company", "booking", "formfields"]
};

const rootReducer = combineReducers({
  alert,
  modal,
  auth,
  profile,
  company,
  booking,
  message,
  map,
  formfields,
  plans,
  feature,
  waitlistModal: waitlistModalReducer
});

export default persistReducer(persistConfig, rootReducer);
