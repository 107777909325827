import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMessages } from "../../actions/message";
import Tour from "reactour";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Spinner from "../layout/Spinner";
import UserIcon from "../../images/user-icon.png";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

const Messages = ({
  getMessages,
  auth: { user },
  message: { messages, loading_messages },
  tour,
  setTour,
}) => {
  const [isTourOpen, setisTourOpen] = useState(tour);
  const [tab, setTab] = useState(0);
  const closeTour = () => {
    setisTourOpen(false);
    setTour(false);
  };
  const [searchItem, setSearchItem] = useState("");
  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);

  useEffect(() => {
    getMessages();
  }, [getMessages]);

  const history = useHistory();

  const onSearchClear = () => {
    setSearchItem("");
  };

  const PersonalAccount = messages.filter((obj) => obj.section == "user");
  const BusinessAccount = messages.filter((obj) => obj.section == "business");
  const PersonalAccountData = PersonalAccount.filter((msg) => {
    if (searchItem == "") {
      return msg;
    } else if (msg.title.toLowerCase().includes(searchItem.toLowerCase())) {
      return msg;
    }
  });

  const BusinessAccountData = BusinessAccount.filter((msg) => {
    if (searchItem == "") {
      return msg;
    } else if (msg.title.toLowerCase().includes(searchItem.toLowerCase())) {
      return msg;
    }
  });
  const unReadPersonalData = PersonalAccountData.filter(
    (notification) => !notification.read
  );
  const unReadBusinessData = BusinessAccountData.filter(
    (notification) => !notification.read
  );

  return loading_messages && messages === null ? (
    <Spinner />
  ) : (
    <Fragment>
      <Helmet>
        <title>Appointment Cake</title>
        <meta
          name="description"
          content="Whatever or whoever you’re looking for,
                   Appointment Cake has you covered."
        />
      </Helmet>
      <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={closeTour}
        onAfterOpen={disableBody}
        onBeforeClose={enableBody}
      />
      <div className="notification-page">
        <div className="row header-bg">
          <div className="dashboard-header d-none">
            <a
              onClick={() => history.push("/account")}
              className="user-icon desktop-show btn btn-info max_width"
            >
              <i
                className="fa-solid fa-user"
                onClick={() => history.push("/account")}
              ></i>{" "}
              User{" "}
            </a>
            <button
              id="change-log"
              type="button"
              onClick={() => setisTourOpen(true)}
              className="btn btn-info max_width"
            >
              <i className="fas fa-edit"></i> <span> Guide </span>{" "}
            </button>
          </div>
          <div className="p-3 notification-upper">
            <div className="col mobile-dashboard-header desktop-show-search">
              <a
                onClick={() => {
                  history.push("/account");
                }}
                className="user-icon"
              >
                <img
                  src={UserIcon}
                  alt="User Icon"
                  style={{ marginLeft: "-20px", marginTop: "-5px" }}
                />
              </a>
              <div>
                <p className="text-lg w-fit font-weight-bold mb-0 mx-auto mb-4">
                  Notifications
                </p>
              </div>
              <div className="search-bar col-7 px-0 mx-auto">
                <input
                  className="form-control"
                  id="autocomplete"
                  placeholder="Search"
                  type="text"
                  value={searchItem}
                  style={{ paddingLeft: "0.75rem" }}
                  onChange={(event) => {
                    setSearchItem(event.target.value);
                  }}
                ></input>
                <div className="search-bar-clear">
                  <button>
                    {searchItem.length === 0 ? (
                      ""
                    ) : (
                      <i className="fas fa-close mr-4" onClick={onSearchClear}></i>
                    )}
                  </button>
                </div>
                <button className="bg-aqua" style={{ fontSize: "20px" }}>
                  <i className="fas fa-search"></i>
                </button>
              </div>
              <div className="notification-search d-none">
                <button>
                  <i className="fa-solid fa-magnifying-glass"></i>
                </button>
                <input
                  type="text"
                  value={searchItem}
                  placeholder="Search"
                  onChange={(event) => {
                    setSearchItem(event.target.value);
                  }}
                />
                {searchItem.length > 0 && (
                  <a href="#">
                    {" "}
                    <i className="fa fa-close" onClick={onSearchClear}>
                      {" "}
                    </i>{" "}
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* <div className="row p-3" data-tut="messages">
          <div className="col-md-12 widget-col">
            <div className="widget">
              <div className="card">
                <div className="list-group list-group-flush">
                  {messages.length > 0 ? (
                    <Fragment>
                      {messages.map((message, i) => (
                        <MessageItem message={message} key={i} />
                      ))}
                    </Fragment>
                  ) : (
                    <Fragment>
                      <div className="card-body">
                        <h4>No new messages</h4>
                      </div>
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* section */}

        {/* STATIC DATA */}
        <div className="p-3 pb-5 pb-md-0 mb-5 mb-md-0">
          <div className="row widget-col">
            <div className="widget col-lg-10 mb-4 mx-auto">
              <div className="d-flex">
                <p
                  className={`text-sm font-bold px-3 py-10 cursor-pointer ${
                    tab === 0 ? "border-b-orange" : null
                  }`}
                  onClick={() => setTab(0)}
                >
                  Personal
                  <span
                    className={`${
                      tab === 0
                        ? "bg-redOrange text-white"
                        : "bg-grey text-dark"
                    }  border-r-5 text-xs font-bold mx-1`}
                    style={{ padding: "2.5px 8px" }}
                  >
                    {unReadPersonalData?.length}
                  </span>
                </p>
                <p
                  className={`text-sm font-bold px-3 py-10 cursor-pointer ${
                    tab === 1 ? "border-b-orange" : null
                  }`}
                  onClick={() => setTab(1)}
                >
                  Business
                  <span
                    className={`${
                      tab === 1
                        ? "bg-redOrange text-white"
                        : "bg-grey text-dark"
                    }  border-r-5   text-xs font-bold mx-1`}
                    style={{ padding: "2.5px 8px" }}
                  >
                    {unReadBusinessData?.length}
                  </span>
                </p>
              </div>
              <div>
                <table className="table table-striped ">
                <tbody>
  {tab === 0 ? (
    PersonalAccountData?.length > 0 ? (
      PersonalAccount.filter((msg) => {
        if (searchItem == "") {
          return msg;
        } else if (
          msg.title
            .toLowerCase()
            .includes(searchItem.toLowerCase())
        ) {
          return msg;
        }
      }).map(
        (message, i) =>
          message.section == "user" && (
            <tr key={i}>
              <td className="border-0 py-10 text-sm font-medium">
                {moment(message.date_created).format(
                  "MMM DD h:mm a"
                )}
              </td>
              <td className="border-0 py-10 text-sm font-medium">
                <a
                  onClick={() =>
                    history.push({
                      pathname:
                        message?.type == "message"
                          ? "/account"
                          : `bookings/${message?.booking}`,
                      state: {
                        page: "notification",
                      },
                    })
                  }
                  className="cursor-pointer text-decoration-none text-dark"
                >
                  <strong>New Booking : </strong>
                  <span>
                    {message.title.slice("New Booking :".length)}
                  </span>
                </a>
              </td>
              <td className="border-0 py-10 text-sm font-medium d-none d-md-table-cell">
                <a
                  className=" float-right btn btn-blue rounded-circle mobile-top-arrow d-flex justify-content-center align-items-center"
                  style={{
                    height: "28px",
                    width: "28px",
                    fontSize: "12px",
                  }}
                  onClick={() =>
                    history.push({
                      pathname:
                        message?.type == "message"
                          ? "/account"
                          : `bookings/${message?.booking}`,
                      state: {
                        page: "notification",
                      },
                    })
                  }
                >
                  <i
                    className="fas fa-arrow-right"
                    aria-hidden="true"
                  ></i>
                </a>
              </td>
            </tr>
          )
      )
    ) : (
      <div>
        <p style={{ textAlign: "center" }}>
          {PersonalAccount.length > 0
            ? "No Data Found"
            : "No Personal Account"}
        </p>
      </div>
    )
  ) : BusinessAccountData.length > 0 ? (
    BusinessAccount.filter((msg) => {
      if (searchItem == "") {
        return msg;
      } else if (
        msg.title
          .toLowerCase()
          .includes(searchItem.toLowerCase())
      ) {
        return msg;
      }
    }).map(
      (message, i) =>
        message.section == "business" && (
          <tr key={i}>
            <td className="border-0 py-10 text-sm font-medium">
              {moment(message.date_created).format(
                "MMM DD h:mm a"
              )}
            </td>
            <td className="border-0 py-10 text-sm font-medium">
              <a
                onClick={() =>
                  history.push({
                    pathname:
                      message?.type == "message"
                        ? "/account"
                        : `bookings/${message?.booking}`,
                    state: {
                      page: "notification",
                    },
                  })
                }
                className="cursor-pointer text-decoration-none text-dark"
              >
                {message.title}
              </a>
            </td>
            <td className="border-0 py-10 text-sm font-medium">
              <a
                className=" float-right btn btn-blue rounded-circle mobile-top-arrow d-flex justify-content-center align-items-center"
                style={{
                  height: "28px",
                  width: "28px",
                  fontSize: "12px",
                }}
                onClick={() =>
                  history.push({
                    pathname:
                      message?.type == "message"
                        ? "/account"
                        : `bookings/${message?.booking}`,
                    state: {
                      page: "notification",
                    },
                  })
                }
              >
                <i
                  className="fas fa-arrow-right"
                  aria-hidden="true"
                ></i>
              </a>
            </td>
          </tr>
        )
    )
  ) : (
    <div>
      <p style={{ textAlign: "center" }}>
        {BusinessAccount.length > 0
          ? "No Data Found"
          : "No Business Account"}
      </p>
    </div>
  )}
</tbody>

                </table>
              </div>
            </div>
            <div className="widget col-lg-6 mb-4 d-none">
              <div className="card-header bg-aqua px-0">
                <h4>Business Account</h4>
              </div>
              <div className="card">
                <div className="card-body p-0">
                  <table className="table">
                    <tbody>
                      {BusinessAccountData.length > 0 ? (
                        BusinessAccount.filter((msg) => {
                          if (searchItem == "") {
                            return msg;
                          } else if (
                            msg.title
                              .toLowerCase()
                              .includes(searchItem.toLowerCase())
                          ) {
                            return msg;
                          }
                        }).map(
                          (message, i) =>
                            message.section == "business" && (
                              <tr key={i}>
                                <td>
                                  {moment(message.date_created).format(
                                    "MMM DD h:mm a"
                                  )}
                                </td>
                                <td>
                                  <span>{message.title}</span>
                                </td>
                                <td>
                                  <a
                                    className="btn btn-primary float-right rounded-circle"
                                    onClick={() =>
                                      history.push({
                                        pathname:
                                          message?.type == "message"
                                            ? "/account"
                                            : message?.type == "company"
                                            ? "/favorite"
                                            : `bookings/${message?.booking}`,
                                        state: {
                                          page: "notification",
                                        },
                                      })
                                    }
                                  >
                                    <i
                                      className="fas fa-arrow-right"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </td>
                              </tr>
                            )
                        )
                      ) : (
                        <div>
                          <p style={{ textAlign: "center" }}>
                            {BusinessAccount.length > 0
                              ? "No Data Found"
                              : "No Business Account"}
                          </p>
                        </div>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const steps = [
  {
    selector: '[data-tut="messages"]',
    content: `Messages related to booked appointments and services.`,
  },
];

Messages.protoTypes = {
  getMessages: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  message: state.message,
});

export default connect(mapStateToProps, {
  getMessages,
})(Messages);
