import api from "../utils/api";
import { setAlert } from "./alert";
import { $ } from "react-jquery-plugin";

import {
  GET_MESSAGE,
  MESSAGE_ERROR,
  CLEAR_MESSAGE,
  GET_MESSAGES,
  ADD_MESSAGE,
  REMOVE_MESSAGE
} from "./types";

// Get Messages
export const getMessages = () => async dispatch => {
  dispatch({ type: CLEAR_MESSAGE });

  try {
    const res = await api.get("/messages");

    dispatch({
      type: GET_MESSAGES,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: MESSAGE_ERROR,
      payload: { msg: err, status: 500 }
    });
  }
};

// Get Message by id
export const getMessageById = messageId => async dispatch => {
  try {
    const res = await api.get(`/messages/${messageId}`);

    dispatch({
      type: GET_MESSAGE,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: MESSAGE_ERROR,
      payload: { msg: err, status: 500 }
    });
  }
};

// Add or Update Message
export const addMessage = messageData => async dispatch => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    const res = await api.post("/messages", messageData, config);

    dispatch({
      type: ADD_MESSAGE,
      payload: res.data
    });
  } catch (err) {
    console.log(err);
    const errors = err ? err.response.data.errors : false;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: MESSAGE_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status
      }
    });
  }
};

// delete message
export const deleteMessage = id => async dispatch => {
  if (window.confirm("Are you sure you want to delete your message?")) {
    try {
      const res = await api.delete(`/messages/${id}`);

      $(`#${id}`).slideUp(500);

      dispatch({
        type: REMOVE_MESSAGE,
        payload: res.data
      });

      dispatch(setAlert("Message Deleted", "success"));
    } catch (err) {
      console.log(err);

      dispatch({
        type: MESSAGE_ERROR,
        payload: {
          msg: err.response.statusText,
          status: err.response.status
        }
      });
    }
  }
};
