export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const BUSINESS_REGISTER_SUCCESS = "BUSINESS_REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const UPDATE_USER = "UPDATE_USER";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";

export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILES = "GET_PROFILES";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const ACCOUNT_DELETED = "ACCOUNT_DELETED";

export const GET_COMPANY = "GET_COMPANY";
export const GET_COMPANY_LIST = "GET_COMPANY_LIST";
export const GET_BUSINESS = "GET_BUSINESS";
export const GET_COMPANIES = "GET_COMPANIES";
export const COMPANY_ERROR = "COMPANY_ERROR";
export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const CLEAR_COMPANY = "CLEAR_COMPANY";
export const DELETE_COMPANY = "DELETE_COMPANY";
export const UPDATE_LIKES = "UPDATE_LIKES";
export const GET_LIKED_COMPANIES = "GET_LIKED_COMPANIES";
export const UPDATE_USERSERVICE = "UPDATE_USERSERVICE";
export const GET_MAPDATA = "GET_MAPDATA";

export const SET_MODAL = "SET_MODAL";
export const REMOVE_MODAL = "REMOVE_MODAL";

export const GET_BOOKING = "GET_BOOKING";
export const COMPANY_BOOKINGS = "COMPANY_BOOKINGS";
export const GET_BOOKINGS = "GET_BOOKINGS";
export const BOOKING_ERROR = "BOOKING_ERROR";
export const UPDATE_BOOKING = "UPDATE_BOOKING";
export const CLEAR_BOOKING = "CLEAR_BOOKING";
export const DELETE_BOOKING = "DELETE_BOOKING";
export const ADD_COMMENT = "ADD_COMMENT";
export const REMOVE_COMMENT = "REMOVE_COMMENT";
export const CLEAR_HISTORY = "CLEAR_HISTORY";
export const GET_PAST_BOOKINGS = "GET_PAST_BOOKINGS";
export const GET_FUTURE_BOOKINGS = "GET_FUTURE_BOOKINGS";
export const ADMIN_BUSINESS = "ADMIN_BUSINESS";
export const ADMIN_COMPANY = "ADMIN_COMPANY";
export const GET_APPOINTMENTDETAIL = "GET_APPOINTMENTDETAIL";

export const LOAD_MAP = "LOAD_MAP";
export const GET_COOR = "GET_COOR";
export const MAP_ERROR = "MAP_ERROR";

export const GET_MESSAGE = "GET_MESSAGE";
export const MESSAGE_ERROR = "MESSAGE_ERROR";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const UPDATE_MESSAGE = "UPDATE_MESSAGE";
export const GET_MESSAGES = "GET_MESSAGES";
export const ADD_MESSAGE = "ADD_MESSAGE";
export const REMOVE_MESSAGE = "REMOVE_MESSAGE";

export const FORM_FIELDS = "FORM_FIELDS";

export const UPDATE_BSERVICE = "UPDATE_BSERVICE";
export const DELETE_BSERVICE = "DELETE_BSERVICE";
export const ADD_BSERVICE = "ADD_BSERVICE";
export const GET_COMPANY_LIST_UPDATE = "GET_COMPANY_LIST_UPDATE";
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";
export const CLAIM = "CLAIM";
export const UPDATE_CLAIM = "UPDATE_CLAIM";
export const DELETE_SASERVICE = "DELETE_SASERVICE";
export const TCLAIM = "TCLAIM";
export const REMOVE_CLAIM = "REMOVE_CLAIM";
export const MYCLAIMS = "MYCLAIMS";
export const PLANS = "PLANS";
export const INACTIVEPLANS = "INACTIVEPLANS";
export const FREEMONTHS = "FREEMONTHS";
export const ALLADMINBUSINESS = "ALLADMINBUSINESS";
export const ALLUSER = "ALLUSER";
export const USERERROR = "USERERROR";
export const EDITUSER = "EDITUSER";
export const DELETEUSER = "DELETEUSER";
export const CATEGORIES = "CATEGORIES";
export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";
export const PAGINATION_DATA = "PAGINATION_DATA";
