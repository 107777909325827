import React, { useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
//replace with new action we make
// import { login } from '../../actions/auth';
import Spinner from "../layout/Spinner";
// import Alert from "../layout/Alert";
import { setAlert } from "../../actions/alert";
import { resetPassword } from "../../actions/auth";
import { Helmet } from "react-helmet";

const ForgotPassword = ({
  resetPassword,
  isAuthenticated,
  loading,
  history
}) => {
  const [formData, setFormData] = useState({
    email: ""
  });

  const { email } = formData;

  const onChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    }); // can be used for the onChange of all fields, automatically pulling the name of the field as the key
  };

  const onSubmit = async e => {
    e.preventDefault();
    resetPassword(formData, history);
  };

  // const history = useHistory();

  // Redirect if logged in
  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }
  return loading ? (
    <Spinner />
  ) : (
    <>
      <Helmet>
        <title>Appointment Cake</title>
        <meta
          name="description"
          content="Whatever or whoever you’re looking for,
                   Appointment Cake has you covered."
        />
      </Helmet>
      <div className="edit-service">
        <button onClick={() => history.push("/login")}>
          <i className="fas fa-arrow-left"></i>
        </button>
        <div className="signup_header">
          <Link
            className="btn btn-secondary rounded-circle mobile-top-arrow"
            to="/login"
          >
            <i className="fas fa-arrow-left" aria-hidden="true"></i>
          </Link>
        </div>
      </div>
      <section className="header-bg landing text-center d-flex justify-content-center align-items-center">
        <div className="form_width">
          <h1 className="forget_text large text-primary">Forgot Password</h1>
          <p className="enter_email lead">
            Enter Your Email Asociated <br /> With Your Account
          </p>
          <form
            className="form"
            onSubmit={e => {
              onSubmit(e);
            }}
          >
            <div className="form-group">
              <input
                className="form-control"
                type="email"
                id="email"
                placeholder="Email Address"
                name="email"
                value={email}
                onChange={e => onChange(e)}
                required
              />
            </div>
            <input
              type="submit"
              className="btn btn-primary btn-block send_btn"
              value="Send Link"
            />
          </form>
          <p className="my-4 signup_btn">
            Don't have an Account? <Link to="/register">Signup</Link>
          </p>
        </div>
      </section>
    </>
  );
};

ForgotPassword.propTypes = {
  //login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  resetPassword: PropTypes.func
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(
  mapStateToProps,
  { setAlert, resetPassword }
)(ForgotPassword);
