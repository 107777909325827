import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  getFutureBookings,
  getFutureBookingsByBusiness,
} from "../../actions/booking";
import BookingItem from "../booking/BookingItem";
import Spinner from "../layout/Spinner";
import AppointmentHistory from "./AppointmentHistory";

const FutureBookings = ({
  getFutureBookings,
  getFutureBookingsByBusiness,
  auth: { user },
  booking: { futurebookings = [], loading },
  tab,
}) => {
  const location = useLocation();
  const BusinessId = location?.state?.companyDetails?._id;

  useEffect(() => {
    if (tab === "appointments" && BusinessId) {
      getFutureBookingsByBusiness(BusinessId);
    } else if (tab === "appointment-history") {
      getFutureBookings();
    }
  }, [getFutureBookings, getFutureBookingsByBusiness, user, tab, BusinessId]);

  return !loading && user === null ? (
    <Spinner />
  ) : (
    <Fragment>
      <h1 className="text-center font-bold">Future Appointments</h1>
      <div className="card my-4 border border-dark border-8">
        <div className="appointment-tbl">
          <table className="table table-striped mb-0">
            {tab === "appointment-history" || tab === "appointments" ? (
              <tbody>
                {futurebookings.length > 0 ? (
                  futurebookings.map((booking) => (
                    <AppointmentHistory key={booking._id} booking={booking} />
                  ))
                ) : (
                  <tr className="booking-item-row no-appointment">
                    <td
                      className="booking-date text-xs font-normal"
                      style={{ textAlign: "center" }}
                    >
                      No Future Appointments
                    </td>
                  </tr>
                )}
              </tbody>
            ) : (
              <tbody>
                {futurebookings.length > 0 ? (
                  futurebookings.map((booking) => (
                    <BookingItem key={booking._id} booking={booking} />
                  ))
                ) : (
                  <tr className="booking-item-row no-appointment">
                    <td
                      className="booking-date text-xs font-normal no-appointment"
                      style={{ textAlign: "center" }}
                    >
                      No Future history
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </Fragment>
  );
};

FutureBookings.propTypes = {
  getFutureBookings: PropTypes.func.isRequired,
  getFutureBookingsByBusiness: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  booking: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  booking: {
    ...state.booking,
    futurebookings:  state.booking.futurebookings || [],
  },
});

export default connect(mapStateToProps, {
  getFutureBookings,
  getFutureBookingsByBusiness,
})(FutureBookings);
