// formfields
import {
	FORM_FIELDS
} from '../actions/types';

const initialState = {
	formfields: [],
};

export default function formfields(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case FORM_FIELDS:
			return {
				formfields: payload
			};
		default:
			return state;
	}
}
