import { FREEMONTHS } from "../actions/types";

const initialState = {
  free_months: {}
};

export default function feature(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FREEMONTHS:
      return {
        ...state,
        free_months: payload
      };
    default:
      return state;
  }
}
