import React, { Fragment, useEffect, useState, useRef } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../../actions/auth";
import Logo from "../../images/Cake-Slice.png";
import Logo2 from "../../images/Appt-Cake-logo.png";
import Home from "../../asssets/icons/home.svg";
import SHome from "../../asssets/icons/selectedhome.svg";
import Calendar from "../../asssets/icons/calendar.svg";
import SCalendar from "../../asssets/icons/selectedcalendar.svg";
import Search from "../../asssets/icons/search.svg";
import SSearch from "../../asssets/icons/selectedsearch.svg";
import Bell from "../../asssets/icons/inactivebell.svg";
import SBell from "../../asssets/icons/selectedinactivebell.svg";
import Gear from "../../asssets/icons/gear.svg";
import SGear from "../../asssets/icons/selectedgear.svg";
import Case from "../../asssets/icons/case.svg";
import SCase from "../../asssets/icons/selectedcase.svg";

import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";


const Navbar = ({ auth: { isAuthenticated, loading }, logout, auth }) => {
  const route = useLocation();
  const pathname = route?.pathname;
  const [show, setShow] = useState(true);
  const [showOverlay, setShowOverlay] = useState(true);

  const navbarCollapse = useRef(null);

  const closeMenu = () => {
    if (navbarCollapse.current) {
      navbarCollapse.current.classList.remove('show');
    }
  };

  useEffect(() => {
    if (
      pathname.includes("service") ||
      pathname === "/" ||
      pathname === "/account" ||
      pathname === "/superadmin"
    ) {
      setShow(false);
    } else {
      setShow(true);
    }
    
    if (window.innerWidth <= 1024) {
      setShow(false);
      setShowOverlay(false);
    }
  }, [pathname]);
  const navLinkClassName = "nav-link d-flex align-items-center py-4 py-md-3 px-3 px-md-4";
  const authLinks = (
    <Fragment>
      <ul className="nav flex-lg-column my-auto justify-content-sm-evenly nav-tablet-flex-row">
        <li className="nav-item d-flex justify-content-center">
          {showOverlay ? (
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip id="nav-dashboard-tooltip">Dashboard</Tooltip>}
            >
              <Link
                to="/dashboard"
                onClick={closeMenu}
                className={`${pathname === "/dashboard" && "nav-active"} ${navLinkClassName}`}
              >
                {pathname === "/dashboard" ? (
                  <img src={SHome} alt="SHome" />
                ) : (
                  <img src={Home} alt="Home" />
                )}
                <p
                  className={`${show ? "d-block" : "d-none"} nav-title mb-0 ${
                    pathname === "/dashboard" && "font-weight-bold"
                  } `}
                >
                  Dashboard
                </p>
                <span className="nav-text">Dashboard</span>
              </Link>
            </OverlayTrigger>
          ) : (
            <Link
              to="/dashboard"
              onClick={closeMenu}
              className={`${pathname === "/dashboard" && "nav-active"} ${navLinkClassName}`}
            >
              {pathname === "/dashboard" ? (
                <img src={SHome} alt="SHome" />
              ) : (
                <img src={Home} alt="Home" />
              )}
              <p
                className={`${show ? "d-block" : "d-none"} nav-title mb-0 ${
                  pathname === "/dashboard" && "font-weight-bold"
                } `}
              >
                Dashboard
              </p>
              <span className="nav-text">Dashboard</span>
            </Link>
          )}
        </li>
        <li className="nav-item d-flex justify-content-center">
          {showOverlay ? (
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip id="nav-find-tooltip">Find Appointment</Tooltip>}
            >
              <Link
                to="/find"
                onClick={closeMenu}
                className={`${pathname === "/find" && "nav-active"} ${navLinkClassName}`}
              >
                {pathname === "/find" ? (
                  <img src={SSearch} alt="SSearch" />
                ) : (
                  <img src={Search} alt="Search" />
                )}
                <p
                  className={`${show ? "d-block" : "d-none"} nav-title mb-0 ${
                    pathname === "/find" && "font-weight-bold"
                  } `}
                >
                  Find Appointments
                </p>
                <span className="nav-text">Find Appointments</span>
              </Link>
            </OverlayTrigger>
          ) : (
            <Link
              to="/find"
              onClick={closeMenu}
              className={`${pathname === "/find" && "nav-active"} ${navLinkClassName}`}
            >
              {pathname === "/find" ? (
                <img src={SSearch} alt="SSearch" />
              ) : (
                <img src={Search} alt="Search" />
              )}
              <p
                className={`${show ? "d-block" : "d-none"} nav-title mb-0 ${
                  pathname === "/find" && "font-weight-bold"
                } `}
              >
                Find Appointments
              </p>
              <span className="nav-text">Find Appointments</span>
            </Link>
          )}
        </li>
        <li className="nav-item d-flex justify-content-center">
          {showOverlay ? (
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip id="nav-business-tooltip">Business Manager</Tooltip>}
            >
              <Link
                to="/admin"
                onClick={closeMenu}
                className={`${pathname === "/admin" && "nav-active"} ${navLinkClassName}`}
              >
                {pathname === "/admin" ? (
                  <img src={SCalendar} alt="SCalendar" />
                ) : (
                  <img src={Calendar} alt="Calendar" />
                )}
                <p
                  className={`${show ? "d-block" : "d-none"} nav-title mb-0 ${
                    pathname === "/admin" && "font-weight-bold"
                  } `}
                >
                  Business Manager
                </p>
                <span className="nav-text">Business Manager</span>
              </Link>
            </OverlayTrigger>
          ) : (
            <Link
              to="/admin"
              onClick={closeMenu}
              className={`${pathname === "/admin" && "nav-active"} ${navLinkClassName}`}
            >
              {pathname === "/admin" ? (
                <img src={SCalendar} alt="SCalendar" />
              ) : (
                <img src={Calendar} alt="Calendar" />
              )}
              <p
                className={`${show ? "d-block" : "d-none"} nav-title mb-0 ${
                  pathname === "/admin" && "font-weight-bold"
                } `}
              >
                Business Manager
              </p>
              <span className="nav-text">Business Manager</span>
            </Link>
          )}
        </li>
        <li className="nav-item d-flex justify-content-center">
          {showOverlay ? (
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip id="nav-messages-tooltip">Notifications</Tooltip>}
            >
              <Link
                to="/messages"
                onClick={closeMenu}
                className={`${pathname === "/messages" && "nav-active"} ${navLinkClassName}`}
              >
                {pathname === "/messages" ? (
                  <img src={SBell} alt="SBell" />
                ) : (
                  <img src={Bell} alt="Bell" />
                )}
                <p
                  className={`${show ? "d-block" : "d-none"} nav-title mb-0 ${
                    pathname === "/messages" && "font-weight-bold"
                  } `}
                >
                  Notifications
                </p>
                <span className="nav-text">Notifications</span>
              </Link>
            </OverlayTrigger>
          ) : (
            <Link
              to="/messages"
              onClick={closeMenu}
              className={`${pathname === "/messages" && "nav-active"} ${navLinkClassName}`}
            >
              {pathname === "/messages" ? (
                <img src={SBell} alt="SBell" />
              ) : (
                <img src={Bell} alt="Bell" />
              )}
              <p
                className={`${show ? "d-block" : "d-none"} nav-title mb-0 ${
                  pathname === "/messages" && "font-weight-bold"
                } `}
              >
                Notifications
              </p>
              <span className="nav-text">Notifications</span>
            </Link>
          )}
        </li>
        <li className="nav-item d-flex justify-content-center">
          {showOverlay ? (
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip id="nav-messages-tooltip">Profile Settings</Tooltip>}
            >
              <Link
                to="/account"
                onClick={closeMenu}
                className={`${pathname === "/account" && "nav-active"} ${navLinkClassName}`}
              >
                {pathname === "/account" ? (
                  <img src={SGear} alt="SGear" />
                ) : (
                  <img src={Gear} alt="Gear" />
                )}
                <p
                  className={`${show ? "d-block" : "d-none"} nav-title mb-0 ${
                    pathname === "/account" && "font-weight-bold"
                  } `}
                >
                  Profile Settings
                </p>
                <span className="nav-text">Profile Settings</span>
              </Link>
            </OverlayTrigger>
          ) : (
            <Link
              to="/account"
              onClick={closeMenu}
              className={`${pathname === "/account" && "nav-active"} ${navLinkClassName}`}
            >
              {pathname === "/account" ? (
                <img src={SGear} alt="SGear" />
              ) : (
                <img src={Gear} alt="Gear" />
              )}
              <p
                className={`${show ? "d-block" : "d-none"} nav-title mb-0 ${
                  pathname === "/account" && "font-weight-bold"
                } `}
              >
                Profile Settings
              </p>
                <span className="nav-text">Profile Settings</span>
              </Link>
            )}
        </li>
        {auth?.user?.isAdmin && (
          <li className="nav-item d-flex justify-content-center">
            {showOverlay ? (
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip id="nav-dashboard-tooltip">Admin</Tooltip>}
              >
                <Link
                  to="/superadmin"
                  onClick={closeMenu}
                  className={`${pathname === "/superadmin" && "nav-active"} ${navLinkClassName}`}
                >
                  {pathname === "/superadmin" ? (
                    <img src={SCase} alt="SCase" />
                  ) : (
                    <img src={Case} alt="Case" />
                  )}
                  <p
                    className={`${show ? "d-block" : "d-none"} nav-title mb-0 ${
                      pathname === "/superadmin" && "font-weight-bold"
                    } `}
                  >
                    Admin
                  </p>
                  <span className="nav-text">Admin</span>
                </Link>
              </OverlayTrigger>
            ) : (
              <Link
                to="/superadmin"
                onClick={closeMenu}
                className={`${pathname === "/superadmin" && "nav-active"} ${navLinkClassName}`}
              >
                {pathname === "/superadmin" ? (
                  <img src={SCase} alt="SCase" />
                ) : (
                  <img src={Case} alt="Case" />
                )}
                <p
                  className={`${show ? "d-block" : "d-none"} nav-title mb-0 ${
                    pathname === "/superadmin" && "font-weight-bold"
                  } `}
                >
                  Admin
                </p>
                <span className="nav-text">Admin</span>
              </Link>
            )}
          </li>
        )}
      </ul>
    </Fragment>
  );

  const guestLinks = (
    <ul className="nav flex-sm-column my-auto justify-content-sm-evenly align-items-center">
      <li className="nav-item">
        <Link to="/register" className="nav-link" onClick={closeMenu}>
          <i className="fas fa-user-plus"></i>
          <span className="nav-text">Register</span>
        </Link>
      </li>
      <li className="nav-item">
        <Link to="/login" className="nav-link" onClick={closeMenu}>
          <i className="fas fa-sign-in-alt"></i>
          <span className="nav-text">Login</span>
        </Link>
      </li>
    </ul>
  );
  

  return (
    <nav
    id="sidebar-nav"
    className={
      isAuthenticated
        ? "vertical-nav d-flex bg-white flex-column open mobile-nav-menu"
        : "vertical-nav d-flex bg-white flex-column open hideMenu"
    }
  >
    {/* bootstrap hamburger button
     <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <img class="hamburger-icon" src={SHamburger} alt="Hamburger Icon" />
    </button>
    {/* collapse div */}
  {/* <div class="collapse navbar-collapse" id="navbarSupportedContent" ref={navbarCollapse}> */}
    <Link to="/" className="navbar-brand">
      {pathname === "/" ||
      pathname === "/login" ||
      pathname === "/register" ||
      pathname === "/account" ||
      pathname.includes("service") ||
      pathname === "/superadmin" ||
      pathname === "/business-register" ||
      pathname === "/create-account" ? (
        <img src={Logo} alt="Appointment Cake" className="site-logo mx-3" />
      ) : (
        <img src={Logo2} alt="Appointment Cake" width={141} height={100} />
      )}
    </Link>
    {!loading && (
      <Fragment>{isAuthenticated ? authLinks : guestLinks}</Fragment>
    )}
    {pathname === "/" ||
    pathname === "/login" ||
    pathname === "/register" ||
    pathname === "/business-register" ||
    pathname === "/create-account" ? null : (
      <div className="mx-auto flex-column align-items-center d-none">
        <img
          src={auth?.user?.avatar}
          alt="user"
          className="rounded-circle border border-dark"
          style={{
            width: `${show ? "80px" : "40px"}`,
            height: `${show ? "80px" : "40px"}`,
            marginBottom: "12px",
          }}
        />
        {show ? (
          <p className="text-center user-name mb-5">
            {auth.user && auth.user.firstName}{" "}
            {auth.user && auth.user.lastName}
          </p>
        ) : null}
      </div>
    )}
    {/* end of div */}
    {/* </div>  */}
  </nav>
  );
};

Navbar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { logout })(Navbar);
