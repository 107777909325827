import React, { useState, useEffect } from 'react';
import api from '../../utils/api';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import WaitlistCard from './WaitlistCard'; // Ensure this path is correct

const WaitlistManager = ({ auth }) => {
  const [waitlists, setWaitlists] = useState([]);

  useEffect(() => {
    const fetchWaitlists = async () => {
      try {
        const response = await api.get(`/waitlist/user/${auth.user?._id}`);
        const waitlistData = response.data;

        
        const uniqueCompanyIds = [...new Set(waitlistData.map((waitlist) => waitlist.company))];

    
        const groupedWaitlists = uniqueCompanyIds.map((companyId) => ({
          companyId,
          entries: waitlistData.filter((entry) => entry.company === companyId),
        }));

        setWaitlists(groupedWaitlists);
      } catch (error) {
        console.error('Error fetching waitlist data:', error);
      
      }
    };

    if (auth.user) {
      fetchWaitlists();
    }
  }, [auth.user]);

  return (
    <div className="waitlist-manager">
      <h2 className="text-center mb-4">Waitlist Manager</h2>
      <div className='d-flex flex-wrap'>
      {waitlists.map((waitlist) => (
        <WaitlistCard key={waitlist.companyId} user={auth.user} companyId={waitlist.companyId} entries={waitlist.entries} />
      ))}
      </div>
    </div>
  );
};

WaitlistManager.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(WaitlistManager);
