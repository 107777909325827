import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, Redirect, useLocation, useHistory } from "react-router-dom";
import { setAlert } from "../../actions/alert";
import { register } from "../../actions/auth";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFaceParty } from "@fortawesome/pro-regular-svg-icons";
import { Helmet } from "react-helmet";

//import axios from 'axios';
// import ReactPhoneInput from "react-phone-input-2";
// import 'react-phone-number-input/style.css'
// import PhoneInput from 'react-phone-number-input'

const Register = ({ setAlert, register, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    password2: "",
  });
  // const [isTourOpen, setisTourOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);
  const history = useHistory();
  const refferar = useLocation();
  // const search = refferar.search;
  // const refferalUserId = new URLSearchParams(search).get("referrer");
  const refferalUserId = refferar?.state?.refferalUserId;

  // const [phone, setPhone] = useState()
  // const closeTour = () => {
  //   setisTourOpen(false);
  // };
  // const disableBody = target => disableBodyScroll(target);
  // const enableBody = target => enableBodyScroll(target);
  function clearNumber(value = "") {
    return (value = value.replace(/\D/g, ""));
    // return (value = value.replace(/^(\d{2})(\d{3})(\d{4})$/, "($1)-$2-$3"));
  }
  const { firstName, lastName, email, phone, password, password2 } = formData;
  const onChange = (e) => {
    if (e.target.name === "phone") {
      e.target.value = clearNumber(e.target.value);
    }
    if (e.target.name === "firstName" || e.target.name === "lastName") {
      let reference = /^[A-Za-z]+$/;
      if (reference.test(e.target.value) || e.target.value === "") {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        }); // can be used for the onChange of all fields, automatically pulling the name of the field as the key
      }
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      }); // can be used for the onChange of all fields, automatically pulling the name of the field as the key
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password !== password2) {
      // brought in with the /actions/alert, but then must export it with 'connect' at the bottom of the file
      // Would be props.setAlert, but we destructured at the top
      setAlert("Passwords do not match", "danger");
    } else {
      if (refferalUserId) {
        register(
          {
            firstName,
            lastName,
            email,
            phone,
            password,
            refferalUserId,
          },
          history
        );
      } else {
        register({ firstName, lastName, email, phone, password }, history);
      }
    }
  };

  // Redirect is authenticated
  // if (isAuthenticated) {
  //   return <Redirect to="/signup-success" />;
  // }

  useEffect(() => {
    if (isAuthenticated) {
      const path = history?.location?.state?.url
        ? '/find'
        : "/dashboard";
      history.push(path);
    }
  }, []);
  return (
    <>
      <Helmet>
        <title>Appointment Cake</title>
        <meta
          name="description"
          content="Whatever or whoever you’re looking for,
                   Appointment Cake has you covered."
        />
      </Helmet>
      <div className="edit-service">
        <button>
          <i className="fas fa-arrow-left" onClick={() => history.goBack()}></i>
        </button>
        <div className="signup_header d-none">
          <Link
            className="btn btn-secondary rounded-circle mobile-top-arrow"
            to="/register"
          >
            <i className="fas fa-arrow-left" aria-hidden="true"></i>
          </Link>
        </div>
      </div>
      <section
        className="landing text-center d-flex justify-content-center align-items-center white-bg header-bg signup-page"
        id="bottom-wave"
      >
        {/* <Tour
          steps={steps}
          isOpen={isTourOpen}
          onRequestClose={closeTour}
          onAfterOpen={disableBody}
          onBeforeClose={enableBody}
        /> */}

        {/* <button
          id="change-log"
          type="button"
          onClick={() => setisTourOpen(true)}
          className="btn btn-info max_width"
        >
          <i className="fas fa-edit"></i> <span> Guide </span>{" "}
        </button> */}
        <div className="form-outer register_fom_container set-signup-icon">
          <FontAwesomeIcon
            icon={faFaceParty}
            className="fa-4x"
            style={{ color: "#ffab68" }}
          />
          <h1 className="">Sign Up</h1>
          <div className="in-center">
            <p className="">Create a Personal Account</p>
          </div>

          <form
            className="form"
            action="create-profile.html"
            onSubmit={(e) => onSubmit(e)}
            data-tut="register_detail"
          >
            <div className="form-row form-group">
              <div className="col-md-6">
                <input
                  className="form-control mb-3"
                  type="text"
                  placeholder="First Name *"
                  name="firstName"
                  maxLength="50"
                  value={firstName}
                  onChange={(e) => onChange(e)}
                  required
                />
              </div>

              <div className="col-md-6">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Last Name *"
                  name="lastName"
                  maxLength="50"
                  value={lastName}
                  onChange={(e) => onChange(e)}
                  required
                />
              </div>
            </div>
            <div className="form-group">
              <input
                className="form-control"
                type="email"
                placeholder="Email Address *"
                name="email"
                value={email}
                onChange={(e) => onChange(e)}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Phone Number *"
                name="phone"
                pattern=".{0}|.{10,}"
                // maxLength="10"
                value={phone}
                onChange={(e) => onChange(e)}
                required
              />
              {/* <ReactPhoneInput
							inputExtraProps={{
								// name: "phone",
								required: true,
								autoFocus: true
							}}
							defaultCountry={"sg"}
							placeholder='Phone Number'
							// value={phone}
							// value={this.state.phone}
							// onChange={this.handleOnChange}
							/> */}

              {/* <PhoneInput
							className="form-control"
							defaultCountry="CA"
							placeholder="Enter phone number"
							value={phone}
							countries={["CA", "US"]}
							name='phone'
							onChange={setPhone} /> */}
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col-12 input-box">
                  {showPassword ? (
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Password"
                      name="password"
                      minLength="6"
                      maxLength="50"
                      value={password}
                      onChange={(e) => onChange(e)}
                      required
                    />
                  ) : (
                    <input
                      className="form-control"
                      type="password"
                      placeholder="Password"
                      name="password"
                      minLength="6"
                      maxLength="50"
                      value={password}
                      onChange={(e) => onChange(e)}
                      required
                    />
                  )}
                  {showPassword ? (
                    <i
                      className="far fa-eye"
                      onClick={() => setShowPassword(false)}
                    ></i>
                  ) : (
                    <i
                      className="far fa-eye-slash"
                      onClick={() => setShowPassword(true)}
                    ></i>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col-12 input-box">
                  {showConfPassword ? (
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Confirm Password"
                      name="password2"
                      minLength="6"
                      maxLength="50"
                      value={password2}
                      onChange={(e) => onChange(e)}
                      required
                    />
                  ) : (
                    <input
                      className="form-control"
                      type="password"
                      placeholder="Confirm Password"
                      name="password2"
                      minLength="6"
                      maxLength="50"
                      value={password2}
                      onChange={(e) => onChange(e)}
                      required
                    />
                  )}
                  {showConfPassword ? (
                    <i
                      className="far fa-eye"
                      onClick={() => setShowConfPassword(false)}
                    ></i>
                  ) : (
                    <i
                      className="far fa-eye-slash"
                      onClick={() => setShowConfPassword(true)}
                    ></i>
                  )}
                </div>
              </div>
            </div>
            <input
              type="submit"
              className="btn btn-primary btn-lg register_btn"
              value="Register"
              data-tut="register_button"
            />
          </form>
          <p className="my-3" data-tut="signup_link">
            Already have an account?{" "}
            <Link to="/login" className="font-weight">
              Sign In
            </Link>
          </p>

          <p>
            By creating an account you agree to our
            <br />{" "}
            <Link to="/login" className="font-weight">
              Terms of Service
            </Link>{" "}
            and{" "}
            <Link to="/login" className="font-weight">
              Privacy Policy
            </Link>
          </p>
        </div>
      </section>
    </>
  );
};

// const steps = [
//   {
//     selector: '[data-tut="register_detail"]',
//     content: `Add your personal details to create a new account.`
//   },
//   {
//     selector: '[data-tut="register_button"]',
//     content: `Click on register button to confirm registration.`
//   },
//   {
//     selector: '[data-tut="signup_link"]',
//     content: `If you already have an account click on "Sign up" link.`
//   }
// ];

Register.propTypes = {
  setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

// Use the connect function to pass our setAlert into props
export default connect(mapStateToProps, { setAlert, register })(Register);
