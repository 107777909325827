import React, { Fragment, useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import MapContainer from "../maps/MapContainer";
import ToolTip from "../layout/ToolTip";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createBusiness } from "../../actions/company";
import { Helmet } from "react-helmet";
import DetailTooltip from "../layout/DetailTooltip";
import { useRef } from "react";
import Select from "react-select";
const initialState = {
  name: "",
  website: "",
  description: "",
  street_address: "",
  street_address_2: "",
  city: "",
  province: "",
  country: "",
  postal: "",
  email: "",
  phone: "",
  fax: "",
  lat: "",
  lng: "",
  facebook: "",
  twitter: "",
  linkedin: "",
  instagram: "",
  Sunday_open: false,
  Sunday_start_time: 540,
  Sunday_end_time: 1020,
  Monday_open: true,
  Monday_start_time: 540,
  Monday_end_time: 1020,
  Tuesday_open: true,
  Tuesday_start_time: 540,
  Tuesday_end_time: 1020,
  Wednesday_open: true,
  Wednesday_start_time: 540,
  Wednesday_end_time: 1020,
  Thursday_open: true,
  Thursday_start_time: 540,
  Thursday_end_time: 1020,
  Friday_open: true,
  Friday_start_time: 540,
  Friday_end_time: 1020,
  Saturday_open: false,
  Saturday_start_time: 540,
  Saturday_end_time: 1020,
  markers: [],
  geoLocation: "",
  category: "",
  subcategory: "",
};

const CreateBusiness = ({ history, createBusiness, categoryList }) => {
  const [formData, setFormData] = useState(initialState);
  const [facebookError, setfacebookError] = useState("");
  const [weekDay, setWeekDay] = useState([1, 2, 3, 4, 5]);
  const [linkedinError, setlinkedinError] = useState("");
  const [instagramError, setinstagramError] = useState("");

  const d_names = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const {
    name,
    website,
    description,
    street_address,
    street_address_2,
    city,
    province,
    country,
    postal,
    email,
    phone,
    fax,
    lat,
    lng,
    facebook,
    instagram,
    linkedin,
    /*Sunday_open,
    Sunday_start_time,
    Sunday_end_time,
    Monday_open,
    Monday_start_time,
    Monday_end_time,
    Tuesday_open,
    Tuesday_start_time,
    Tuesday_end_time,
    Wednesday_open,
    Wednesday_start_time,
    Wednesday_end_time,
    Thursday__open,
    Thursday_start_time,
    Thursday_end_time,
    Friday_open,
    Friday_start_time,
    Friday_end_time,
    Saturday_open,
    Saturday_start_time,
    Saturday_end_time,*/
    markers,
  } = formData;
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [isSelected, setIsSelected] = useState({
    category: false,
    subcategory: false,
    location: false,
  });
  let tempData;
  const categoryRef = useRef(null);
  const locationRef = useRef(null);
  const [addressArray, setAddressArray] = useState([]);
  const timeDropDown = () => {
    var hours, minutes, ampm;
    const options = [];
    for (var i = 0; i <= 1440; i += 30) {
      hours = Math.floor(i / 60);
      minutes = i % 60;
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      ampm = hours % 24 < 12 ? "AM" : "PM";
      hours = hours % 12;
      if (hours === 0) {
        hours = 12;
      }
      options.push(
        <option key={i} value={i}>
          {hours + ":" + minutes + " " + ampm}
        </option>
      );
    }
    return options;
  };
  const timeDropDownOptions = timeDropDown();

  function clearNumber(value = "") {
    return value.replace(/\D+/g, "");
  }
  function clearPhoneNumber(value = "") {
    return value = value.replace(/\D/g, "");
    // return (value = value.replace(/^(\d{2})(\d{3})(\d{4})$/, "($1)-$2-$3"));
  }
  const onChange = (e, lat) => {
    if (e.target.name === "fax") {
      e.target.value = clearNumber(e.target.value);
    }
    if (e.target.name === "facebook") {
      let reference = /^(https?:\/\/)?((w{3}\.)?)facebook.com\/.*/i;
      if (reference.test(e.target.value) || e.target.value === "") {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setfacebookError("");
      } else {
        setfacebookError("This is not a valid facebook link.");
      }
    }

    if (e.target.name === "linkedin") {
      let reference = /^(https?:\/\/)?((w{3}\.)?)linkedin.com\/.*/i;
      if (reference.test(e.target.value) || e.target.value === "") {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setlinkedinError("");
      } else {
        setlinkedinError("This is not a valid linkedin link.");
      }
    }

    if (e.target.name === "instagram") {
      let reference = /^(https?:\/\/)?((w{3}\.)?)instagram.com\/.*/i;
      if (reference.test(e.target.value) || e.target.value === "") {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setinstagramError("");
      } else {
        setinstagramError("This is not a valid instagram link.");
      }
    }

    if (e.target.name === "name") {
      setFormData({ ...formData, [e.target.name]: e.target.value });
      // let reference = /^[ A-Za-z0-9 & #%()\[\]+\-'é®ã@:|]*$/;
      // if (reference.test(e.target.value) || e.target.value === "") {
      //   setFormData({ ...formData, [e.target.name]: e.target.value });
      // }

      // }else{
      //   setFormData({ ...formData, [e.target.name]: e.target.value });
      // }
    } else if (e.target.name === "phone") {
      setFormData({
        ...formData,
        [e.target.name]: clearPhoneNumber(e.target.value),
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    if (lat != null) {
      setFormData({ ...formData, lat: lat });
    }
  };

  const addressUpdate = (address, latitiude, lng) => {
    tempData = { ...formData };
    setIsSelected({
      ...isSelected,
      location: false,
    });
    address.length > 0 &&
      address.map((add, i) =>
        add.types[0] == "route"
          ? ((addressArray["street_address"] = add.long_name),
            setAddressArray([...addressArray]))
          : add.types[0] == "neighborhood"
          ? ((addressArray["street_address_2"] = add.long_name),
            setAddressArray([...addressArray]))
          : add.types[0] == "locality"
          ? ((addressArray["street_address_2"] = add.long_name),
            setAddressArray([...addressArray]))
          : add.types[0] == "administrative_area_level_2"
          ? ((addressArray["city"] = add.long_name),
            setAddressArray([...addressArray]))
          : add.types[0] == "administrative_area_level_1"
          ? ((addressArray["province"] = add.long_name),
            setAddressArray([...addressArray]))
          : add.types[0] == "country"
          ? ((addressArray["country"] = add.long_name),
            setAddressArray([...addressArray]))
          : add.types[0] == "postal_code_prefix"
          ? ((addressArray["postal"] = add.long_name),
            setAddressArray([...addressArray]))
          : ""
      );

    setFormData({
      ...formData,
      street_address: addressArray.street_address
        ? addressArray.street_address
        : "",
      street_address_2: addressArray.street_address_2
        ? addressArray.street_address_2
        : "",
      city: addressArray.city ? addressArray.city : "",
      province: addressArray.province ? addressArray.province : "",
      country: addressArray.country ? addressArray.country : "",
      postal: addressArray.postal ? addressArray.postal : "",
      lat: latitiude,
      lng: lng,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (
      !formData.category ||
      !formData.subcategory ||
      !formData.lat ||
      !formData.lng
    ) {
      formData.lat === "" &&
        formData.lng === "" &&
        locationRef.current.scrollIntoView();
      !formData.subcategory &&
        !formData.category &&
        categoryRef.current.scrollIntoView();
      setIsSelected({
        ...isSelected,
        category: !formData.category ? true : false,
        subcategory: !formData.subcategory ? true : false,
        location: !formData.lat || !formData.lng ? true : false,
      });
    }

    if (
      !formData.category ||
      !formData.subcategory ||
      !formData.lat ||
      !formData.lng
    ) {
      formData.lat === "" &&
        formData.lng === "" &&
        locationRef.current.scrollIntoView();
      !formData.subcategory &&
        !formData.category &&
        categoryRef.current.scrollIntoView();
      setIsSelected({
        ...isSelected,
        category: !formData.category ? true : false,
        subcategory: !formData.subcategory ? true : false,
        location: !formData.lat || !formData.lng ? true : false,
      });
    }
    if (
      !facebookError &&
      !instagramError &&
      !linkedinError &&
      formData.subcategory &&
      formData.category &&
      formData.lat &&
      formData.lng
    ) {
      createBusiness(formData, history, false);
    }
  };

  const handleToggle = (i) => {
    if (weekDay.includes(i)) {
      setWeekDay(weekDay.filter((elem) => elem !== i));
    } else {
      setWeekDay([...weekDay, i]);
    }
  };

  const onCategoryChange = (values) => {
    let dropdownfield = [{ label: "Category", value: "" }];

    categoryList?.map((element) => {
      if (element.category_name === values) {
        element?.sub_category_name.map((value) => {
          dropdownfield.push({
            label: value.text,
            value: value.text,
          });
        });
      }
    });

    setSubCategories(dropdownfield);
    setFormData({
      ...formData,
      category: values,
      subcategory: "",
    });
  };
  let subcategoryValue, filterValue;
  filterValue = categories.filter((i) => i.value === formData?.category);
  if (formData.category) {
    subcategoryValue = subCategories?.filter(
      (i) => i.value === formData?.subcategory
    );
  }
  useEffect(() => {
    let CategoryList = [{ label: "Category", value: "" }];

    categoryList?.map((element) => {
      CategoryList.push({
        label: element.category_name,
        value: element.category_name,
      });
    });

    setCategories(CategoryList);

    filterValue = categories.filter((i) => i.value === formData?.category);
    if (formData.category) {
      subcategoryValue = subCategories?.filter(
        (i) => i.value === formData?.subcategory
      );
    }
  }, [formData, categoryList]);
  return (
    <div>
      <Helmet>
        <title>Appointment Cake</title>
        <meta
          name="description"
          content="Whatever or whoever you’re looking for,
                   Appointment Cake has you covered."
        />
      </Helmet>

      <div className="edit-service">
        <button>
          <i
            className="fas fa-arrow-left"
            onClick={() => history.push("/superadmin")}
          ></i>
        </button>
        <div className="signup_header">
          <a
            className="btn btn-secondary rounded-circle mobile-top-arrow"
            href="/superadmin"
          >
            <i className="fas fa-arrow-left" aria-hidden="true"></i>
          </a>
        </div>
      </div>

      <form className=" " onSubmit={onSubmit}>
        <section className="white-bg header-bg ">
          <div className="landing text-center d-flex justify-content-center align-items-center registration-page">
            <div
              className="buisness-information-edit"
              style={{ marginTop: "-47px" }}
            >
              <div className="form-heading">
                <h4 className="logintitle">
                  Business Information
                  <DetailTooltip text="Business Information will be create a company" />
                </h4>
                <div className="logintitle" style={{ textAlign: "left" }}>
                  <span>Company Basics</span>
                </div>
              </div>
              <div className="form-row form-group first-input">
                <div className="col-md-6">
                  {/* <label htmlFor="company-name">Name</label> */}
                  <input
                    type="text"
                    className="form-control mb-3"
                    name="name"
                    placeholder="Company Name *"
                    id="company-name"
                    required
                    maxLength="50"
                    value={name}
                    onChange={onChange}
                  />
                </div>
                <div className="col-md-6">
                  {/* <label htmlFor="company-website">Website</label> */}
                  <input
                    type="text"
                    className="form-control mb-3"
                    name="website"
                    placeholder="Website"
                    id="company-website"
                    value={website}
                    onChange={onChange}
                  />
                </div>
                <div className="col-md-6">
                  {/* <label htmlFor="company-description">Description</label> */}
                  <input
                    type="text"
                    className="form-control mb-3"
                    name="description"
                    placeholder="Brief Description / tagline"
                    id="company-description"
                    value={description}
                    onChange={onChange}
                  />
                </div>
                <div className="col-md-6">
                  {/* <label htmlFor="company-email">Email</label> */}
                  <input
                    type="email"
                    className="form-control mb-3"
                    name="email"
                    placeholder="Company Email *"
                    id="company-email"
                    required
                    oninvalid="this.setCustomValidity('Please enter email address.')"
                    value={email}
                    onChange={onChange}
                  />
                </div>
                <div className="col-md-6">
                  {/* <label htmlFor="company-phone">Phone</label> */}
                  <input
                    type="tel"
                    className="form-control mb-3"
                    name="phone"
                    placeholder="Business Phone Number *"
                    id="company-phone"
                    required
                    pattern=".{0}|.{10,}"
                    // maxLength="10"
                    value={phone}
                    onChange={onChange}
                  />
                </div>
                <div className="col-md-6">
                  {/* <label htmlFor="company-fax">Fax</label> */}
                  <input
                    type="tel"
                    className="form-control mb-3"
                    name="fax"
                    minLength="10"
                    maxLength="50"
                    placeholder="Fax Number"
                    id="company-fax"
                    value={fax}
                    onChange={(e) => onChange(e)}
                  />
                </div>
                <div className="form-heading">
                  <span>Social Media Links</span>
                </div>

                <div className="col-md-6">
                  {/* <label htmlFor="company-fax">FaceBook</label> */}
                  <input
                    type="text"
                    className="form-control mb-3"
                    name="facebook"
                    placeholder="facebook.com/"
                    id="company-fax"
                    //   defaultValue={company?.social?.facebook}
                    onChange={onChange}
                  />
                  <p style={{ color: "red" }}>
                    {facebookError ? facebookError : ""}
                  </p>
                </div>
                <div className="col-md-6">
                  {/* <label htmlFor="company-fax">LinkedIn</label> */}
                  <input
                    type="text"
                    className="form-control mb-3"
                    name="linkedin"
                    placeholder="linkedin.com/in/"
                    id="company-fax"
                    //   defaultValue={company?.social?.linkedin}
                    onChange={onChange}
                  />
                  <p style={{ color: "red" }}>
                    {linkedinError ? linkedinError : ""}
                  </p>
                </div>
                <div className="col-md-6">
                  {/* <label htmlFor="company-fax">Instagram</label> */}
                  <input
                    type="tel"
                    className="form-control mb-3"
                    name="instagram"
                    placeholder="instagram.com/"
                    id="company-fax"
                    //   defaultValue={company?.social?.instagram}
                    onChange={onChange}
                  />
                  <p style={{ color: "red" }}>
                    {instagramError ? instagramError : ""}
                  </p>
                </div>
                <div className="col-md-12  row p-0 m-0" ref={categoryRef}>
                  <div className="col-md-6 px-1 ">
                    <div className="form-heading">
                      <span>Category*</span>
                    </div>
                    <Select
                      options={categories}
                      className="field-type"
                      styles={{
                        menu: (base) => ({ ...base, zIndex: 99999999 }),
                      }}
                      name="category"
                      defaultValue={filterValue && filterValue[0]}
                      value={filterValue && filterValue[0]}
                      onChange={(value) => {
                        onCategoryChange(value.value);
                        setIsSelected({
                          ...isSelected,
                          category: false,
                        });
                      }}
                    />
                    {isSelected.category && (
                      <label className="text-danger d-flex">
                        Please select category.
                      </label>
                    )}
                  </div>
                  {subCategories?.length > 0 && (
                    <div className="col-md-6 px-1 ">
                      <div className="form-heading">
                        <span>Subcategory*</span>
                      </div>
                      <Select
                        options={subCategories}
                        className="field-type"
                        styles={{
                          menu: (base) => ({ ...base, zIndex: 99999999 }),
                        }}
                        defaultValue={subcategoryValue && subcategoryValue[0]}
                        value={subcategoryValue && subcategoryValue[0]}
                        onChange={(value) => {
                          setFormData({
                            ...formData,
                            subcategory: value.value,
                          });
                          setIsSelected({
                            ...isSelected,
                            subcategory: false,
                          });
                        }}
                      />
                      {isSelected.subcategory && (
                        <label className="text-danger d-flex">
                          Please select subcategory.
                        </label>
                      )}
                    </div>
                  )}
                </div>
                <div className="form-heading" ref={locationRef}>
                  <span>Location*</span>
                </div>
                <div className="map-location ">
                  <MapContainer
                    mapMarkers={markers}
                    newMarker={false}
                    currentLat={formData.lat}
                    currentLng={formData.lng}
                    addressupdate={addressUpdate}
                    onChange={onChange}
                  />
                  {isSelected.location && (
                    <label className="text-danger d-flex">
                      Please select valid location.
                    </label>
                  )}
                </div>

                <div className="col-md-6">
                  {/* <label htmlFor="company-street_address">Street Address</label> */}
                  <input
                    type="text"
                    className="form-control mb-3"
                    name="street_address"
                    placeholder="Street Address *"
                    id="company-street_address"
                    maxLength="50"
                    value={street_address}
                    onChange={onChange}
                    required
                  />
                </div>
                <div className="col-md-6">
                  {/* <label htmlFor="company-street_address_2">
                  Street Address 2
                </label> */}
                  <input
                    type="text"
                    className="form-control mb-3"
                    name="street_address_2"
                    placeholder="Street Address 2"
                    maxLength="50"
                    id="company-street_address_2"
                    value={street_address_2}
                    onChange={onChange}
                  />
                </div>
                <div className="col-md-6">
                  {/* <label htmlFor="company-city">City</label> */}
                  <input
                    type="text"
                    className="form-control mb-3"
                    name="city"
                    maxLength="50"
                    placeholder="City *"
                    id="company-city"
                    value={city}
                    onChange={onChange}
                  />
                </div>
                <div className="col-md-6">
                  {/* <label htmlFor="company-province">Province</label> */}
                  <input
                    type="text"
                    className="form-control mb-3"
                    name="province"
                    placeholder="Province *"
                    maxLength="50"
                    id="company-province"
                    value={province}
                    onChange={onChange}
                    required
                  />
                </div>
                <div className="col-md-6">
                  {/* <label htmlFor="company-country">Country</label> */}
                  <input
                    type="text"
                    className="form-control mb-3"
                    name="country"
                    placeholder="Company Country *"
                    id="company-country"
                    maxLength="50"
                    value={country}
                    onChange={onChange}
                    required
                  />
                </div>
                <div className="col-md-6">
                  {/* <label htmlFor="company-postal">Postal</label> */}
                  <input
                    type="text"
                    className="form-control mb-3"
                    name="postal"
                    placeholder="Postal Code *"
                    maxLength="50"
                    id="company-postal"
                    value={postal}
                    onChange={onChange}
                    required
                  />
                </div>
                <input
                  type="hidden"
                  id="company-lat"
                  value={lat}
                  name="lat"
                  onChange={onChange}
                />
                <input
                  type="hidden"
                  id="company-lng"
                  value={lng}
                  name="lng"
                  onChange={onChange}
                />
              </div>
              {/* </div> */}
              <div className="buisness-hours add-business-hours">
                {/* <div className="card-header"> */}
                <h4 className="logintitle">
                  Business Hours*
                  <DetailTooltip text="Set Business hours , for your company" />
                </h4>
                {/* </div> */}
                {/* <div className="form-group"> */}
                {/* <div className="office-avl-tbl"> */}
                {/* <table className="table table-striped"> */}

                {d_names.map((day, i) => {
                  return (
                    <div className="grid-container" key={i}>
                      <label>{day}</label>
                      <label className="switch">
                        <input
                          type="checkbox"
                          name={`${day}_open`}
                          id={`${day}_open`}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              [day + "_open"]: !formData[day + "_open"],
                            });
                          }}
                          onClick={() => handleToggle(i)}
                          checked={weekDay.includes(i)}
                        />
                        <span className="slider round"></span>
                      </label>
                      {weekDay.includes(i) && (
                        <div className="grid-col">
                          <div className="grid-item">
                            <select
                              className="form-control"
                              name={`${day}_start_time`}
                              placeholder="Closes at"
                              onChange={onChange}
                              value={formData[day + "_start_time"]}
                            >
                              {timeDropDownOptions.map((time_option, i) => (
                                <Fragment key={i}>{time_option}</Fragment>
                              ))}
                            </select>
                          </div>

                          <div className="grid-item">
                            <select
                              className="form-control"
                              name={`${day}_end_time`}
                              placeholder="Closes at"
                              onChange={onChange}
                              value={formData[day + "_end_time"]}
                            >
                              {timeDropDownOptions.map((time_option, i) => (
                                <Fragment key={i}>{time_option}</Fragment>
                              ))}
                            </select>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}

                {/* </table> */}
              </div>
              {/* </div> */}
            </div>
            {/* <div className="card-body"> */}
            {/* <div className="card-body">
              <button type="submit" className="btn btn-primary">
                Save Company Info
              </button>
              <p
                style={{ color: "red", marginLeft: "10px", marginTop: "10px" }}
              >
                {formError ? formError : ""}
              </p>
            </div> */}
            {/* </div> */}
          </div>
          <div className="card-body in-center">
            <button type="submit" className="btn btn-primary">
              Save Company Info
            </button>
            
          </div>
        </section>
      </form>

      <div className="row">
        {/* <form
          className="form col-md-12 mb-4"
          onSubmit={onSubmit}
          autoComplete="new-password"
        >
          <div className="card mb-4">
            <div className="card-header">
              <div style={{ display: "flex", alignItems: "center" }}>
                <Link
                  to="/superadmin"
                  className="btn btn-secondary rounded-circle"
                >
                  <i className="fas fa-arrow-left"></i>
                </Link>
                <h4
                  className="card-title"
                  style={{ marginLeft: "20px", marginBottom: "unset" }}
                >
                  Business Info
                </h4>
              </div>
              <div>
                <ToolTip text={"Here you can add a new bussiness"} />
              </div>
            </div>
            <div className="card-body">
              <div className="form-group">
                <label htmlFor="company-name">Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Company Name *"
                  id="company-name"
                  required
                  maxLength="15"
                  value={name}
                  onChange={onChange}
                  autoComplete="new-password"
                />
              </div>
              <div className="form-group">
                <label htmlFor="company-website">Website</label>
                <input
                  type="text"
                  className="form-control"
                  name="website"
                  placeholder="Company website"
                  id="company-website"
                  value={website}
                  onChange={onChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="company-description">Description</label>
                <input
                  type="text"
                  className="form-control"
                  name="description"
                  placeholder="Company description"
                  id="company-description"
                  value={description}
                  onChange={onChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="company-email">Email</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="Company email"
                  id="company-email"
                  required
                  oninvalid="this.setCustomValidity('Please enter email address.')"
                  value={email}
                  onChange={onChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="company-phone">Phone</label>
                <input
                  type="tel"
                  className="form-control"
                  name="phone"
                  placeholder="Company phone"
                  id="company-phone"
                  required
                  value={phone}
                  onChange={onChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="company-fax">Fax</label>
                <input
                  type="tel"
                  className="form-control"
                  name="fax"
                  placeholder="Fax Number"
                  id="company-fax"
                  value={fax}
                  onChange={onChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="company-fax">FaceBook</label>
                <input
                  type="text"
                  className="form-control"
                  name="facebook"
                  placeholder="Facebook link"
                  id="company-fax"
                  onChange={onChange}
                />
                <p style={{ color: "red" }}>
                  {facebookError ? facebookError : ""}
                </p>
              </div>
              <div className="form-group">
                <label htmlFor="company-fax">LinkedIn</label>
                <input
                  type="text"
                  className="form-control"
                  name="linkedin"
                  placeholder="LinkedIn link"
                  id="company-fax"
                  onChange={onChange}
                />
                <p style={{ color: "red" }}>
                  {linkedinError ? linkedinError : ""}
                </p>
              </div>
              <div className="form-group">
                <label htmlFor="company-fax">Instagram</label>
                <input
                  type="tel"
                  className="form-control"
                  name="instagram"
                  placeholder="Instagram link"
                  id="company-fax"
                  onChange={onChange}
                />
                <p style={{ color: "red" }}>
                  {instagramError ? instagramError : ""}
                </p>
              </div>
              <hr />
              <MapContainer
                mapMarkers={markers}
                newMarker={false}
                currentLat={formData.lat}
                currentLng={formData.lng}
                addressupdate={addressUpdate}
                onChange={onChange}
              />
              <div className="form-group">
                <label htmlFor="company-street_address">Street Address</label>
                <input
                  type="text"
                  className="form-control"
                  name="street_address"
                  placeholder="Street Address"
                  id="company-street_address"
                  value={street_address}
                  onChange={onChange}
                  autoComplete="new-password"
                />
              </div>
              <div className="form-group">
                <label htmlFor="company-street_address_2">
                  Street Address 2
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="street_address_2"
                  placeholder="Street Address 2"
                  id="company-street_address_2"
                  value={street_address_2}
                  onChange={onChange}
                  autoComplete="new-password"
                />
              </div>
              <div className="form-group">
                <label htmlFor="company-city">City</label>
                <input
                  type="text"
                  className="form-control"
                  name="city"
                  placeholder="City"
                  id="company-city"
                  value={city}
                  onChange={onChange}
                  autoComplete="new-password"
                />
              </div>
              <div className="form-group">
                <label htmlFor="company-province">Province</label>
                <input
                  type="text"
                  className="form-control"
                  name="province"
                  placeholder="Company province"
                  id="company-province"
                  value={province}
                  onChange={onChange}
                  required
                  autoComplete="new-password"
                />
              </div>
              <div className="form-group">
                <label htmlFor="company-country">Country</label>
                <input
                  type="text"
                  className="form-control"
                  name="country"
                  placeholder="Company country"
                  id="company-country"
                  value={country}
                  onChange={onChange}
                  required
                  autoComplete="new-password"
                />
              </div>
              <div className="form-group">
                <label htmlFor="company-postal">Postal</label>
                <input
                  type="text"
                  className="form-control"
                  name="postal"
                  placeholder="Company postal"
                  id="company-postal"
                  value={postal}
                  onChange={onChange}
                  required
                  autoComplete="new-password"
                />
              </div>
              <input
                type="hidden"
                id="company-lat"
                value={lat}
                name="lat"
                onChange={onChange}
                autoComplete="new-password"
              />
              <input
                type="hidden"
                id="company-lng"
                value={lng}
                name="lng"
                onChange={onChange}
                autoComplete="new-password"
              />
            </div>
          </div>
          <div className="card mb-4">
            <div className="card-header">
              <h4 className="card-title">Office Availability</h4>
              <div>
                <ToolTip text={"Here you can change your office hours"} />
              </div>
            </div>
            <div className="form-group">
              <div className="office-avl-tbl">
                <table className="table table-striped">
                  <tbody>
                    {d_names.map((day, i) => (
                      <tr key={i}>
                        <th>{day}</th>
                        <td>
                          <div className="custom-switch custom-control">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name={`${day}_open`}
                              id={`${day}_open`}
                              onChange={e => {
                                setFormData({
                                  ...formData,
                                  [day + "_open"]: !formData[day + "_open"]
                                });
                              }}
                              checked={formData[day + "_open"]}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={`${day}_open`}
                            >
                              Open
                            </label>
                          </div>
                        </td>
                        <td>
                          <select
                            className="form-control"
                            name={`${day}_start_time`}
                            placeholder="Opens at"
                            onChange={onChange}
                            value={formData[day + "_start_time"]}
                          >
                            {timeDropDownOptions.map((time_option, i) => (
                              <Fragment key={i}>{time_option}</Fragment>
                            ))}
                          </select>
                        </td>
                        <td>
                          <select
                            className="form-control"
                            name={`${day}_end_time`}
                            placeholder="Closes at"
                            onChange={onChange}
                            value={formData[day + "_end_time"]}
                          >
                            {timeDropDownOptions.map((time_option, i) => (
                              <Fragment key={i}>{time_option}</Fragment>
                            ))}
                          </select>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <button type="submit" className="btn btn-primary">
                Save Company Info
              </button>
              <p
                style={{ color: "red", marginLeft: "10px", marginTop: "10px" }}
              >
                {formError ? formError : ""}
              </p>
            </div>
          </div>
        </form> */}
      </div>
    </div>
  );
};

CreateBusiness.propTypes = {
  createBusiness: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  company: state.company,
  categoryList: state.company.category,
  // markers: state.markers
});

export default connect(mapStateToProps, { createBusiness })(
  withRouter(CreateBusiness)
); // need withRouter to pass history object
