import api from '../utils/api';

import { LOAD_MAP, MAP_ERROR, GET_COOR } from './types';

// Get current users Company
export const getCompanyMap = () => async dispatch => {
	try {
		const res = await api.get('/company/');

		dispatch({
			type: LOAD_MAP,
			payload: res.data,
		});
	} catch (err) {
		dispatch({
			type: MAP_ERROR,
			payload: {
				msg: err.response.statusText,
				status: err.response.status,
			},
		});
	}
};

export const getCoordinates = (lat, lng, markers) => async dispatch => {
	dispatch({
		type: GET_COOR,
		payload: {
			lat: lat,
			lng: lng,
			markers: markers,
		},
	});
};
