import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import "./Home.css";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import HomeImage from "../../images/People-illustration.png";
import HomeAutocomplete from "./HomeAutocomplete";
import { Helmet } from "react-helmet";

export const Home = ({ isAuthenticated }) => {
  let history = useHistory();
  const [address, setAddress] = useState();

  useEffect(() => {
    document.getElementById("main-content").classList.add("section-bg");
    return function cleanup() {
      document.getElementById("main-content").classList.remove("section-bg");
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Appointment Cake</title>
        <meta
          name="description"
          content="Whatever or whoever you’re looking for,
                   Appointment Cake has you covered."
        />
      </Helmet>
      <div className="right-section">
        <div className="home-content">
          <div className="home-content-left">
            <h1>Try Appointment Cake Today!</h1>
            <h2>
              Getting you what you <br />
              want... <em>appointments.</em>
            </h2>
            {/* search bar */}
            <HomeAutocomplete history={history} />
            {isAuthenticated ? (
              <Link to="/dashboard" className="mt-4">Go to Dashboard</Link>
            ) : (
              <Link to="/login" className="mt-4">
                Login / Registration
              </Link>
            )}
          </div>
          <div className="home-content-right">
            <figure>
              <img alt="logo" src={HomeImage} />
            </figure>
          </div>
        </div>
      </div>
    </>
  );
};

Home.propTypes = {
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(Home);
