import React, { Fragment, useEffect, useState } from "react";
import Tooltip from "../layout/ToolTip";
import SuperAdmin from "./SuperAdmin";
import Claim from "./Claim";
import TransferredClaim from "./TransferredClaim";
import { getAllPlans } from "../../actions/feature";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FeaturedLisitng from "./FeaturedLisitng";
import InactiveFeaturedLisitng from "./InactiveFeaturedLisitng";
import { useLocation, useHistory } from "react-router-dom";
import Tour from "reactour";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import UserIcon from "../../images/user-icon.png";
import AllBusiness from "./AllBusiness";
import { Helmet } from "react-helmet";
import AllUser from "./AllUser";

const SuperadminList = ({ getAllPlans }) => {
  const location = useLocation();
  const tab = location?.state?.CurrentTab;
  const [currentTab, setCurrentTab] = useState(
    tab == undefined || null ? "allbsns" : tab
  );
  const [isTourOpen, setisTourOpen] = useState(false);

  const closeTour = () => {
    setisTourOpen(false);
  };

  useEffect(() => {
    getAllPlans();
  }, []);

  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);

  const history = useHistory();
  return (
    <div className="header-bg">
      <Helmet>
        <title>Appointment Cake</title>
        <meta
          name="description"
          content="Whatever or whoever you’re looking for,
                   Appointment Cake has you covered."
        />
      </Helmet>

      <div className="row">
        <div className="col-12 mb-5">
          <div className="dashboard-header d-none">
            <a
              onClick={() => history.push("/account")}
              className="user-icon desktop-show btn btn-info max_width"
            >
              <i className="fa-solid fa-user"></i> User
            </a>

            <button
              id="change-log"
              type="button"
              className="btn btn-info max_width"
              onClick={() => setisTourOpen(true)}
            >
              <i className="fas fa-edit"></i> <span> Guide </span>{" "}
            </button>
          </div>
        </div>
      </div>
      <div className="row p-3">
        <div className="col-md-3 mb-3" style={{ marginTop: "33px" }}>
          <Tour
            steps={steps}
            isOpen={isTourOpen}
            onRequestClose={closeTour}
            onAfterOpen={disableBody}
            onBeforeClose={enableBody}
          />
          <div className="card" style={{ position: "sticky", top: "1em" }}>
            <div
              className="list-group list-group-flush border border-dark"
              role="tablist"
            >
              <a
                href="#allbsns"
                id="appointments-tab"
                data-toggle="pill"
                role="tab"
                className={
                  currentTab == "allbsns" || tab == undefined
                    ? "list-group-item active"
                    : "list-group-item"
                }
                data-tut="business_list"
                onClick={() => setCurrentTab("allbsns")}
              >
                Business List
              </a>
              <a
                href="#appointments"
                id="Claims"
                data-toggle="pill"
                role="tab"
                className={
                  currentTab == "appointments"
                    ? "list-group-item active"
                    : "list-group-item"
                }
                data-tut="appointment_tab"
                onClick={() => setCurrentTab("appointments")}
              >
                Claims
              </a>
              <a
                href="#tclaims"
                id="tclaims"
                data-toggle="pill"
                role="tab"
                className={
                  currentTab == "tclaims"
                    ? "list-group-item active"
                    : "list-group-item"
                }
                data-tut="appointment_tab"
                onClick={() => setCurrentTab("tclaims")}
              >
                Transferred Claims
              </a>
              <a
                href="#allBusiness"
                id="allBusiness"
                data-toggle="pill"
                role="tab"
                className={
                  currentTab == "allbusiness"
                    ? "list-group-item active"
                    : "list-group-item"
                }
                data-tut="appointment_tab"
                onClick={() => setCurrentTab("allbusiness")}
              >
                All Business
              </a>
              <a
                href="#activeBuss"
                id="activeFeaturedBusiness"
                data-toggle="pill"
                role="tab"
                className={
                  currentTab == "activeBuss"
                    ? "list-group-item active"
                    : "list-group-item"
                }
                data-tut="appointment_tab"
                onClick={() => setCurrentTab("activeBuss")}
              >
                Featured Businesses
              </a>
              <a
                href="#inactiveBuss"
                id="inActiveFeaturedBusiness"
                data-toggle="pill"
                role="tab"
                className={
                  currentTab == "inactiveBuss"
                    ? "list-group-item active d-none"
                    : "list-group-item d-none"
                }
                data-tut="appointment_tab"
                onClick={() => setCurrentTab("inactiveBuss")}
              >
                InActive Featured Businesses
              </a>
              <a
                href="#users"
                id="users"
                data-toggle="pill"
                role="tab"
                className={
                  currentTab == "users"
                    ? "list-group-item active"
                    : "list-group-item"
                }
                data-tut="appointment_tab"
                onClick={() => setCurrentTab("users")}
              >
                Users
              </a>
            </div>
          </div>
        </div>
        {/* right Pannel */}
        <div className="col-md-9 widget-col" id="accountTabs">
          {currentTab == "allbsns" && (
            <div className={"tab-pane fade show"} id="allbsns1" role="tabpanel">
              <p className="text-lg w-fit font-weight-bold mb-0 mx-auto">
                Business List
              </p>
              <div>
                {/* <Tooltip text={"A list of all your businesses."} /> */}

                <div className="appointment-tbl tbl-noscroll">
                  <SuperAdmin CT="allbsns" />
                </div>
              </div>
            </div>
          )}
          {currentTab == "appointments" && (
            <div className={"tab-pane fade show"} id="allbsns1" role="tabpanel">
              <p className="text-lg w-fit font-weight-bold mb-0 mx-auto">
                Claims
              </p>

              <div className="appointment-tbl">
                <Claim CT="appointments" />
              </div>
            </div>
          )}
          {currentTab == "tclaims" && (
            <div className={"tab-pane fade show"} id="allbsns1" role="tabpanel">
              <div>
                <p className="text-lg w-fit font-weight-bold mb-0 mx-auto mb-4">
                  Transferred Claims
                </p>

                <div className="appointment-tbl">
                  <TransferredClaim CT="tclaims" />
                </div>
              </div>
            </div>
          )}
          {currentTab == "activeBuss" && (
            <div className={"tab-pane fade show"} id="allbsns1" role="tabpanel">
              <div>
                <p className="text-lg w-fit font-weight-bold mb-0 mx-auto mb-4">
                  Featured Businesses
                </p>

                <div className="appointment-tbl">
                  <FeaturedLisitng />
                </div>
              </div>
            </div>
          )}
          {currentTab == "inactiveBuss" && (
            <div className={"tab-pane fade show"} id="allbsns1" role="tabpanel">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">All the InActive plans</h4>
                  <div>
                    <Tooltip text={"A list of all the inactive Plans"} />
                  </div>
                </div>
                <div className="appointment-tbl">
                  <InactiveFeaturedLisitng />
                </div>
              </div>
            </div>
          )}
          {currentTab == "users" && (
            <div className={"tab-pane fade show"} id="allbsns1" role="tabpanel">
              <div>
                <p className="text-lg w-fit font-weight-bold mb-0 mx-auto mb-4">
                  Users
                </p>

                <div className="appointment-tbl">
                  <AllUser CT="users" />
                </div>
              </div>
            </div>
          )}
          {currentTab == "allbusiness" && (
            <div className={"tab-pane fade show"} id="allbsns1" role="tabpanel">
              <div className="">
                <p className="text-lg w-fit font-weight-bold mb-0 mx-auto">
                  All Business
                </p>
                <div className="appointment-tbl">
                  <AllBusiness CT="allbusiness" />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const steps = [
  {
    selector: "#appointments-tab",
    content: `By selecting Business List tab, You can add new business and can see the all business list.`,
  },
  {
    selector: "#Claims",
    content: `By selecting Claims tab, you can see the claims and also can transfer OR reject it.`,
  },
  {
    selector: "#tclaims",
    content: `By selecting Transfered Claims tab, you can see the list of transfered claimms.`,
  },
  {
    selector: "#activeFeaturedBusiness",
    content: `By selecting Active featured business tab, you can see the all active plans list.`,
  },
  {
    selector: "#inActiveFeaturedBusiness",
    content: `By selecting InActive featured business tab, you can see the all the inActive plans list and also can delete the plan.`,
  },
];

SuperadminList.propTypes = {
  getAllPlans: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  company: state.company,
});

export default connect(mapStateToProps, { getAllPlans })(SuperadminList);
