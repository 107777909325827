import React, { Fragment, useState, useEffect, useRef } from "react";
//import { Link, withRouter } from 'react-router-dom'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  editBusiness,
  addBusinessService,
  editSBusinesservice,
  editUserServices,
  addUserService,
  getCurrentCompany,
} from "../../actions/company";
import "../company/appService.css";
import DetailTooltip from "../layout/DetailTooltip";
import { options } from "../../utils/constant";
import { useGoogleLogin } from "react-google-login";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import './addBusinessService.css'

const AddBusinessService = ({
  getCurrentCompany,
  addBusinessService,
  editSBusinesservice,
  selectedService,
  history,
  formfields,
  isEdit,
  data,
  editUserServices,
  clearService,
  addUserService,
  categoryList,
  editBusiness,
  companyList,
  company: { company, companybookings, loading },
  auth: { user },
}) => {
  const clientId = process.env.REACT_APP_GOOGLECLIENTID;
  const currentCompany = useLocation();
  const calendarRef = useRef(null);
  // calendar
  const initialState = {
    name: "",
    website: "",
    description: "",
    street_address: "",
    street_address_2: "",
    city: "",
    province: "",
    country: "",
    postal: "",
    email: "",
    phone: "",
    fax: "",
    lat: "",
    lng: "",
    facebook: "",
    twitter: "",
    linkedin: "",
    instagram: "",
    Sunday_open: false,
    Sunday_start_time: 540,
    Sunday_end_time: 1020,
    Monday_open: false,
    Monday_start_time: 540,
    Monday_end_time: 1020,
    Tuesday_open: false,
    Tuesday_start_time: 540,
    Tuesday_end_time: 1020,
    Wednesday_open: false,
    Wednesday_start_time: 540,
    Wednesday_end_time: 1020,
    Thursday_open: false,
    Thursday_start_time: 540,
    Thursday_end_time: 1020,
    Friday_open: false,
    Friday_start_time: 540,
    Friday_end_time: 1020,
    Saturday_open: false,
    Saturday_start_time: 540,
    Saturday_end_time: 1020,
    googleAuthBusiness: undefined,
    markers: [],
    geoLocation: "",
    calendartype: "",
    calendarurl: "",
  };

  const [companyDetail, setCompanyDetail] = useState(initialState);

  const [companyCal, setCompanyCal] = useState(currentCompany?.state?.company);

  const [currentComp, setCurrentComp] = useState({});
  useEffect(() => {
    setCurrentComp(currentCompany?.state?.company);
    setCompanyCal(currentCompany?.state?.company);
  }, [company]);
  const [formData, setFormData] = useState({
    _id: null,
    name: "",
    description: "",
    service_duration: 15,
    price: "",
    book_online: false,
    book_site: false,
    book_site_link: "",
    call_to_book: false,
    intakeForm: [],
    company_id: currentCompany?.state?.company?._id,
  });

  const closeRef = useRef(null);

  const [selectedUserservice, setSelectedUserservice] =
    useState(selectedService);
  const [formError, setFormError] = useState({ calendar: "", method: "" });
  const {
    _id,
    name,
    description,
    service_duration,
    price,
    book_online,
    call_to_book,
    book_site,
    book_site_link,
    intakeForm,
  } = formData;

  const clearServices = () => {
    const formsData = {
      book_online: false,
      book_site: false,
      book_site_link: "",
      call_to_book: false,
      description: "",
      intakeForm: [],
      name: "",
      price: "",
      service_duration: 15,
      _id: "",
    };

    setFormData(formsData);

    clearService();
    setSelectedUserservice(null);
  };

  let fieldinitiaValues = [
    {
      field_category: "",
      field_type: "",
      is_required: false,
      field_name: "",
      field_label: "",
      online_fieldnames: [],
      selected: "",
      option: "",
      // formfield: "",
    },
  ];

  useEffect(() => {
    setSelectedUserservice(selectedService);
    selectedUserservice && setFormData(selectedUserservice);
    Edit_field(selectedService?.intakeForm);
    Type();
  }, [selectedUserservice, selectedService, formfields]);

  const [intakeField, setIntakeField] = useState([]);
  const [categories, setCategories] = useState([]);
  const [showCalandly, setShowCalandly] = useState(false);
  const [showJaneApp, setShowJaneApp] = useState(false);
  const [onlineField, setOnlineField] = useState(
    intakeForm ? intakeForm : fieldinitiaValues
  );
  const [editFields, setEditFields] = useState([]);

  // add module

  const addField = (e) => {
    setOnlineField([
      ...onlineField,
      {
        field_category: "",
        field_name: "",
        is_required: false,
        field_label: "",
        field_type: "",
        online_fieldnames: [],
        selected: "",
        option: "",
        // formfield: "",
      },
    ]);
    setIntakeField([
      ...intakeField,
      {
        field_category: "",
        field_name: "",
        is_required: false,
        field_label: "",
        field_type: "",
        online_fieldnames: [],
        selected: "",
        option: "",
        // formfield: "",
      },
    ]);
  };

  const deleteField = (i) => {
    let formValues = onlineField;
    formValues.splice(i, 1);
    setOnlineField([...formValues]);
    setIntakeField([...formValues]);
  };

  const onCategoryFieldChange = (e, i) => {
    let fields = [...onlineField];
    let field = { ...fields[i] };
    field.selected = "";
    field.field_category = e.target.value;
    fields[i] = field;
    setOnlineField(fields);
    Type(e.target.value, i);
  };

  const onFieldTypeChange = (e, i) => {
    let fields = [...onlineField];
    let field = { ...fields[i] };
    field.field_name = e.target.value;
    if (e.target.value === "") {
      field.field_label = "Label";
    }
    fields[i] = field;
    setOnlineField(fields);
    onlineflabel(e.target.value, i);
  };

  const onSubcategoryChange = (e, i) => {
    let fields = [...intakeField];
    let field = { ...fields[i] };
    field.field_name = e;
    // if (e.target.value === "") {
    //   field.field_label = "Label";
    // }
    fields[i] = field;
    setIntakeField(fields);
  };

  const onisReqChange = (e, i) => {
    let fields = [...intakeField];
    let field = { ...fields[i] };

    field.is_required = !field.is_required;
    fields[i] = field;
    setIntakeField(fields);
  };

  const Type = (values, i) => {
    let fields = [...onlineField];
    let field = { ...fields[i] };
    field.field_category = values;
    let dropdownfield = [{ name: "Select One", value: "" }];

    formfields.formfields.map((element, index) => {
      if (element.field_category === values) {
        dropdownfield.push({
          name: element.field_name,
          value: element.field_name,
          is_selected: false,
        });
      }
    });
    field.online_fieldnames = dropdownfield;
    fields[i] = field;
    setOnlineField(fields);
  };

  const onCategoryChange = (values, i) => {
    let fields = [...intakeField];
    let field = { ...fields[i] };
    field.field_category = values.value;
    field.formfield = values.id;

    let dropdownfield = [{ label: "Select One", value: "" }];

    categoryList?.map((element) => {
      if (element.category_name === values.value) {
        element?.sub_category_name.map((value) => {
          dropdownfield.push({
            label: value.text,
            value: value.text,
          });
        });
      }
    });
    field.online_fieldnames = dropdownfield;
    fields[i] = field;
    setIntakeField(fields);
  };

  const onlineflabel = (value, i) => {
    let fields = [...onlineField];
    let field = { ...fields[i] };
    field.field_name = value;
    field.selected = value;
    let options = [];
    formfields.formfields.map((element, index) => {
      if (element.field_name === value) {
        field.formfield = element._id;
        options = element.option;
        field.field_label = element.field_label;
        field.field_type = element.field_type;
      }
      field.option = options;
      fields[i] = field;
      setOnlineField(fields);
    });
  };

  // edit module

  const Edit_field = (value) => {
    let fields = [];
    if (value && value.length > 0) {
      fields = [...value];
    }
    fields.length > 0 &&
      fields.map((element, i) => {
        let field = { ...fields[i] };
        let values = field.field_category;
        field.field_category = values;
        let dropdownfield = [{ name: "Select One", value: "" }];

        formfields.formfields.map((element, index) => {
          if (element.field_category === values) {
            dropdownfield.push({
              name: element.field_name,
              value: element.field_name,
              is_selected: false,
            });
          }
        });
        field.online_fieldnames = dropdownfield;
        fields[i] = field;
      });
    setEditFields(fields);
  };

  const edit_deleteField = (i) => {
    let formValues = editFields;
    formValues.splice(i, 1);
    setEditFields([...formValues]);
  };

  const onedit_CategoryFieldChange = (e, i) => {
    let fields = [...editFields];
    let field = { ...fields[i] };
    field.selected = "";
    field.field_category = e.target.value;
    fields[i] = field;
    setEditFields(fields);
    edit_Type(e.target.value, i);
  };

  const onedit_isReqChange = (e, i) => {
    let fields = [...editFields];
    let field = { ...fields[i] };

    field.is_required = !field.is_required;
    fields[i] = field;
    setEditFields(fields);
  };

  const oneditFieldTypeChange = (e, i, id) => {
    let fields = [...editFields];
    let field = { ...fields[i] };
    field.field_name = e.target.value;
    field.formfield = id;
    if (e.target.value === "") {
      field.field_label = "Label";
    }
    fields[i] = field;
    setEditFields(fields);
    edit_onlineflabel(e.target.value, i);
  };

  const edit_Type = (values, i) => {
    let fields = [...editFields];
    let field = { ...fields[i] };
    field.field_category = values;
    let dropdownfield = [{ name: "Select One", value: "" }];

    formfields.formfields.map((element, index) => {
      if (element.field_category === values) {
        dropdownfield.push({
          name: element.field_name,
          value: element.field_name,
          is_selected: false,
        });
      }
    });
    field.online_fieldnames = dropdownfield;
    fields[i] = field;
    setEditFields(fields);
  };

  const edit_onlineflabel = (value, i) => {
    let fields = [...editFields];
    let field = { ...fields[i] };
    field.field_name = value;
    field.selected = value;
    let options = [];
    formfields.formfields.map((element, index) => {
      if (element.field_name === value) {
        options = element.option;
        field.formfield = element._id;
        field.field_label = element.field_label;
        field.field_type = element.field_type;
      }
      field.option = options;
      fields[i] = field;
      setEditFields(fields);
    });
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let intakeForm = [];
    if (
      companyDetail?.googleAuthBusiness === null &&
      companyDetail?.calendarurl === ""
    ) {
      setFormError({
        ...formError,
        calendar: "Please integrate calendar for bookings!",
      });
    } else if (
      !formData.book_online ||
      !formData.book_site ||
      !formData.call_to_book
    ) {
      setFormError({
        ...formError,
        method: "Please select atleast one contact method!",
        calendar: "",
      });
    } else {
      setFormError({
        ...formError,
        method: "",
        calendar: "",
      });
    }
    if (intakeField != null && formData.book_online) {
      intakeField.map((element, index) =>
        intakeForm.push({
          field_category: element.field_category,
          field_type: "text",
          is_required: element.is_required,
          field_name: element.field_name,
          field_label: element.field_label,
          // formfield: element.formfield,
          // option: element.option,
        })
      );
    }
    if (editFields != null && formData.book_online) {
      editFields.map((element, index) =>
        intakeForm.push({
          field_category: element.field_category,
          field_type: element.field_type,
          is_required: element.is_required,
          field_name: element.field_name,
          field_label: element.field_label,
          // formfield: element.formfield,
          option: element.option,
        })
      );
    }

    let submitFormData = formData;
    submitFormData = { ...submitFormData, intakeForm };
    console.log("submitFormData======", submitFormData);
    submitFormData.company_id = currentCompany?.state?.company?._id;
    if (submitFormData._id !== null) {
      if (currentCompany?.state?.company.is_admin) {
        editSBusinesservice(submitFormData, history);
      } else {
        editUserServices(submitFormData, history);
      }
      setEditFields([]);
      clearService();
      history.goBack();
      closeRef.current.click();
    } else {
      editBusiness(
        companyDetail,
        history,
        false,
        currentCompany?.state?.company._id,
        false
      ).then((res) => {
        if (res) {
          getCurrentCompany();
        }
      });

      if (currentCompany?.state?.company?.is_admin) {
        addBusinessService(submitFormData, history);
      } else {
        addUserService(submitFormData, history);
      }
      // history.goBack();
      history.push({
        pathname: `/business/service/${currentCompany?.state?.company._id}`,
        state: {
          companyDetails: currentCompany?.state?.company,
          settingProp: "allbsns",
        },
      });
      clearService();
      closeRef.current.click();
    }
  };

  const onClose = () => {
    setCurrentComp(company);
    setCompanyCal(company);
    setShowJaneApp(false);
    setShowCalandly(false);
    setFormError({ calendar: "", method: "" });
    setFormData({
      _id: null,
      name: "",
      description: "",
      service_duration: "",
      price: "",
      book_online: false,
      book_site: false,
      book_site_link: "",
      call_to_book: false,
      intakeForm: [],
      company_id: currentCompany?.state?.company._id,
    });
    setOnlineField([]);
    setIntakeField([]);
  };

  useEffect(() => {
    let CategoryList = [{ label: "Select One", value: "" }];

    categoryList?.map((element) => {
      CategoryList.push({
        id: element._id,
        label: element.category_name,
        value: element.category_name,
      });
    });

    setCategories(CategoryList);
  }, [categoryList]);

  const onSuccess = (res) => {
    // closeRef.current.click();
    companyDetail.googleAuthBusiness = res.tokenObj;
    companyDetail.googleRefreshTokenBusiness = "";
    companyDetail.googleUserBusiness = {
      name: res.profileObj.name,
      email: res.profileObj.email,
    };

    setCompanyDetail(companyDetail);
    setCurrentComp(companyDetail);
    setFormError({ ...formError, calendar: "" });
    editBusiness(companyDetail, history, false, companyCal._id, false);
  };

  const onFailure = (res) => {
    console.log("Login failed: res:", res);
  };
  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
    issignedIn: true,
    accessType: "offline",
    scope:
      "profile email https://www.googleapis.com/auth/admin.directory.resource.calendar",
  });

  const onDisconnect = () => {
    const updatedgoogleUserBusiness = {
      name: null,
      email: null,
    };

    setCompanyDetail({
      ...companyDetail,
      googleAuthBusiness: null,
      googleRefreshTokenBusiness: null,
      googleUserBusiness: updatedgoogleUserBusiness,
      calendarurl: "",
      calendartype: "",
    });

    setCurrentComp({
      ...currentComp,
      googleAuthBusiness: null,
      googleRefreshTokenBusiness: null,
      googleUserBusiness: updatedgoogleUserBusiness,
      calendarurl: "",
      calendartype: "",
    });
  };

  useEffect(() => {
    companyDetail?.googleUserBusiness?.email &&
      setFormError({ ...formError, calendar: "" });

    (formData.book_online || formData.book_site || formData.call_to_book) &&
      setFormError({ ...formError, method: "" });
  }, [companyDetail?.googleUserBusiness?.email, formData]);

  useEffect(() => {
    if (companyCal !== null && companyCal !== undefined) {
      const companyData = { ...initialState };
      for (const key in companyCal) {
        if (key in companyData) companyData[key] = companyCal[key];
      }

      for (const key in companyCal.location) {
        if (key in companyData) companyData[key] = companyCal.location[key];
      }

      for (const key in companyCal.social) {
        if (key in companyData) companyData[key] = companyCal.social[key];
      }

      for (const key in companyCal.store_hours) {
        companyData[key + "_open"] = companyCal.store_hours[key].open;

        companyData[key + "_start_time"] =
          companyCal.store_hours[key].start_time;

        companyData[key + "_end_time"] = companyCal.store_hours[key].end_time;

        companyData["markers"][0] = {
          text: companyCal.name,
          lat: companyData["lat"],
          lng: companyData["lng"],
          company: companyCal,
        };

        companyData["geoLocation"] = {
          type: "Point",
          coordinates: [companyData["lng"] * 1, companyData["lat"] * 1],
        };

        companyData["googleUserBusiness"] = {
          email: companyCal?.googleUserBusiness?.email,
          name: companyCal?.googleUserBusiness?.name,
        };
      }

      setCompanyDetail(companyData);
    }
  }, [company]);

  return (
    <Fragment>
      <Helmet>
        <title>Appointment Cake</title>
        <meta
          name="description"
          content="Whatever or whoever you’re looking for,
                   Appointment Cake has you covered."
        />
      </Helmet>
      <div
        className="card edit_service_main_container spacing"
        id="add-service"
        style={{ marginBottom: "40px" }}
      ></div>
      <div className="edit-service">
        <div className="edit_header set-align-edit-service">
          <a
            className="btn btn-blue rounded-circle mobile-top-arrow edit-service-top-arrow d-flex justify-content-center align-items-center"
            onClick={() => history.goBack()}
          >
            <i className="fas fa-arrow-left" aria-hidden="true"></i>
          </a>
          <p className="text-medium font-bold ml-2 mb-0">Add Service</p>
        </div>
      </div>
      <div className="add-service-container">
        <h1
          className="text-center font-bold text-dark m-0 mb-1"
          style={{ height: "88px" }}
        >
          Add Service
        </h1>

        <form
          className="form d-flex flex-column align-items-center mt-2"
          onSubmit={(e) => onSubmit(e)}
          data-tut="register_detail"
        >
          <div className="w-50 bg-lightpeach py-20 px-40 border-20 mb-2 mx-2 mx-lg-0">
            <div className="row mb-20">
              <div className="col-sm-12">
                <input
                  className="w-100 form-control font-medium text-medium h-43"
                  id="service-name"
                  type="text"
                  placeholder="Service Name*"
                  name="name"
                  required
                  value={name}
                  onChange={(e) => onChange(e)}
                />
              </div>
            </div>
            <div className="row mb-20">
              <div className="col-sm-12">
                <textarea
                  type="text"
                  className="form-control w-100 font-medium text-medium"
                  name="description"
                  placeholder="Service Description*"
                  id="service-description"
                  value={description}
                  required
                  onChange={(e) => onChange(e)}
                />
              </div>
            </div>
            <div className="row mb-20">
              <div className="col-md-6">
                <input
                  id="service-price"
                  className="w-100 form-control font-medium text-medium h-43"
                  type="text"
                  value={price}
                  placeholder="Service Price"
                  name="price"
                  // onChange={(e) => onChange(e)}
                  onChange={(e) => {
                    const inputVal = e.target.value;
                    const regex = /^[0-9]*$/;
                    if (regex.test(inputVal) || inputVal === "") {
                      onChange(e);
                    }
                  }}
                />
              </div>
              <div className="col-md-6 mt-3 mt-md-0">
                <select
                  style={{ fontWeight: "200" }}
                  className="form-control font-medium text-medium drop-arrow business-service-select h-43"
                  name="service_duration"
                  placeholder="Duration"
                  id="service-service_duration"
                  value={service_duration}
                  onChange={(e) => onChange(e)}
                >
                  <option value="15" className="font-medium text-medium">
                    15 minutes
                  </option>
                  <option value="30" className="font-medium text-medium">
                    30 minutes
                  </option>
                  <option value="45" className="font-medium text-medium">
                    45 minutes
                  </option>
                  <option value="60" className="font-medium text-medium">
                    60 minutes
                  </option>
                  <option value="90" className="font-medium text-medium">
                    90 minutes
                  </option>
                  <option value="120" className="font-medium text-medium">
                    120 minutes
                  </option>
                </select>
              </div>
            </div>
            <>
              <p className="form-title m-0 text-left">
                How would you like to be booked?
              </p>
              <div className="flex-column gap-1 mt-3">
                <div className="contact-method-col">
                  <select
                    id="booking-method-select"
                    className="form-control drop-arrow-booking h-43"
                    name="booking_method"
                    value={formData.booking_method}
                    onChange={(e) => {
                      const selectedMethod = e.target.value;
                      if (selectedMethod === "call_to_book") {
                        setFormData({
                          ...formData,
                          booking_method: selectedMethod,
                          call_to_book: !formData?.call_to_book,
                          book_online: false,
                          book_site: false,
                          book_site_link: "",
                        });
                      } else if (selectedMethod === "book_site") {
                        setFormData({
                          ...formData,
                          book_site: !formData.book_site,
                          booking_method: selectedMethod,
                          book_online: false,
                          call_to_book: false,
                        });
                      }
                    }}
                  >
                    <option value="">Select Booking Method</option>
                    <option value="call_to_book">Call to book</option>
                    <option value="book_site">Book on another site</option>
                  </select>
                </div>

                {formData.book_site && (
                  <>
                    <div className="http_bg mt-3">
                      <div className="col-sm-12 text-muted">
                        <p className="htt_disc">
                          Enter the URL you would like to redirect users to when
                          <br />
                          booking for this service.
                        </p>
                      </div>
                      <div className="col-sm-12">
                        {/* <label htmlFor="book-site-link"></label> */}
                        <input
                          type="url"
                          className="form-control http_field"
                          name="book_site_link"
                          placeholder="https://"
                          id="book-site-link"
                          required={book_site}
                          value={book_site_link ? book_site_link : ""}
                          onChange={(e) => onChange(e)}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          </div>
          {!isEdit && (
            <>
              <div
                className="contact-method add_ser_contact_method d-none"
                ref={calendarRef}
              >
                <span>
                  Booking Calendar*
                  <DetailTooltip text="Integrate your Google Calendar and automatically have the time blocked off in Google and Appointment Cake." />
                </span>
                <p style={{ color: "red", fontSize: "12px" }}>
                  {formError.calendar ? formError.calendar : ""}
                </p>
              </div>
              {(currentCompany?.state?.company?.googleUserBusiness?.email ===
                undefined ||
                currentCompany?.state?.company?.googleUserBusiness?.email ===
                  null ||
                currentCompany?.state?.company?.googleUserBusiness?.email ===
                  "") &&
              (currentCompany?.state?.company?.calendarurl === null ||
                currentCompany?.state?.company?.calendarurl === undefined ||
                currentCompany?.state?.company?.calendarurl === " " ||
                currentCompany?.state?.company?.calendarurl === "") ? (
                <div className="service-calendar">
                  <input
                    type="button"
                    className="btn btn-primary btn-lg create-service-btn px-3 mt-1"
                    value="Google Calendar"
                    onClick={signIn}
                    style={{ width: " -webkit-fill-available" }}
                  />
                  <input
                    type="button"
                    className="btn btn-primary btn-lg create-service-btn px-3 mt-1"
                    value="Calendly"
                    data-tut="register_button"
                    onClick={() => {
                      if (companyDetail.calendartype === "calandly")
                        window.open(companyDetail.calendarurl);
                      else {
                        setShowCalandly(true);
                        setShowJaneApp(false);
                      }
                    }}
                    style={{ width: " -webkit-fill-available" }}
                  />
                  {showCalandly && (
                    <input
                      type="url"
                      style={{ width: " -webkit-fill-available" }}
                      className="form-control  my-1"
                      placeholder="Enter Calendly URL"
                      onChange={(e) =>
                        setCompanyDetail({
                          ...companyDetail,
                          calendartype: "calandly",
                          calendarurl: e.target.value,
                        })
                      }
                    />
                  )}
                  <input
                    type="button"
                    className="btn btn-primary btn-lg create-service-btn px-3 mt-1"
                    value="JaneApp"
                    data-tut="register_button"
                    onClick={() => {
                      if (currentComp.calendartype === "janeapp")
                        window.open(currentComp.calendarurl);
                      else {
                        setShowCalandly(false);
                        setShowJaneApp(true);
                      }
                    }}
                    style={{ width: " -webkit-fill-available" }}
                  />
                  {showJaneApp && (
                    <input
                      type="url"
                      className="form-control my-1"
                      placeholder="Enter JaneApp URL "
                      onChange={(e) =>
                        setCompanyDetail({
                          ...companyDetail,
                          calendartype: "janeapp",
                          calendarurl: e.target.value,
                        })
                      }
                      style={{ width: " -webkit-fill-available" }}
                    />
                  )}
                </div>
              ) : (
                <>
                  <div className="google_calender px-4 d-none">
                    <p>
                      {currentComp?.googleUserBusiness?.email
                        ? "Google Calendar"
                        : currentComp.calendartype === "calandly"
                        ? "Calendly"
                        : currentComp.calendartype === "janeapp"
                        ? "JaneApp"
                        : ""}
                    </p>

                    <h
                      className="calendar-email"
                      style={{ wordBreak: " break-word" }}
                    >
                      (
                      {currentComp?.googleUserBusiness?.email
                        ? currentComp?.googleUserBusiness?.email
                        : currentComp?.calendarurl
                        ? currentComp?.calendarurl
                        : ""}
                      )
                    </h>
                    <i
                      className="fa-solid fa-close ml-5"
                      style={{
                        fontSize: "15px",
                        cursor: "pointer",
                        color: "red",
                      }}
                      onClick={() => {
                        onDisconnect();
                      }}
                    ></i>
                    {/* <div className="btn">
                          <i
                            className="fa-solid fa-link calender_icon"
                            onClick={() => {
                              onDisconnect();
                            }}
                          ></i>
                        </div> */}
                  </div>
                </>
              )}
            </>
          )}

          <div className="contact-method add_ser_contact_method d-none">
            <span>
              Booking Method*
              <DetailTooltip text="Choose how you want customers to book with you!" />
            </span>
            <p style={{ color: "red", fontSize: "12px" }}>
              {formError.method ? formError.method : ""}
            </p>
          </div>
          <div className="contact-method-col switch_add_ser d-none">
            <label className="switch">
              <input
                type="checkbox"
                name="typeof_booking"
                id="service-book_online"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    call_to_book: false,
                    book_online: !formData?.book_online,
                    book_site: false,
                  });
                }}
                checked={book_online}
              />
              <span className="slider round"></span>
            </label>
            <label htmlFor="service-book_online">
              Book Through Integrated Calendar
            </label>
          </div>

          {book_online && (
            <>
              {/* <p className="bookonline-text">Intake From</p> */}
              {editFields !== null
                ? editFields.length > 0 &&
                  editFields.map((element, index) => {
                    let filterValue = options.filter(
                      (i) => i.value === element?.field_category
                    );
                    return (
                      <div
                        key={index}
                        className="card bookonlinemodule-wrapper d-none"
                      >
                        <div className="card-header bg-aqua bookonline-header">
                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 mb-sm-0 mb-2 text-muted online-fields">
                              <input
                                className=""
                                id="field_label"
                                type="text"
                                placeholder="Field label"
                                name="field_label"
                                required
                                defaultValue={element.field_label}
                                onChange={(e) => {
                                  var value = e.target.value.replace(" ", "_");
                                  let fields = [...editFields];
                                  let field = { ...fields[index] };
                                  field.selected = "";
                                  field.field_name = value;
                                  field.field_label = e.target.value;
                                  field.field_type = "text";
                                  fields[index] = field;
                                  setEditFields(fields);
                                }}
                              />
                            </div>
                            <div className="col-lg-5 col-md-5 col-sm-5 mt-2 mt-lg-0 col-6">
                              <div className="check-box-control">
                                <input
                                  type="checkbox"
                                  className="form-check-input intake_checkbox_add_ser"
                                  id={index}
                                  checked={element.is_required}
                                  onClick={(e) => {
                                    onedit_isReqChange(e, index);
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={index}
                                >
                                  Required ?
                                </label>
                              </div>
                            </div>
                            <div className="col-lg-1 col-md-1 col-sm-1 mt-lg-0 mt-2 col-6">
                              <button
                                type="button"
                                className="btn btn-danger float-right deleteBtn"
                                onClick={() => edit_deleteField(index)}
                              >
                                -
                              </button>
                            </div>
                          </div>
                        </div>
                        <ul className="list-group list-group-flush border-bottom">
                          <li className="list-group-item online-fields">
                            <div className="row">
                              <div className="col-md-12 field-label">
                                <label>{element.field_label}</label>
                              </div>
                              <div className="col-md-12">
                                {element.field_type === "text" ? (
                                  <input
                                    disabled
                                    type={element.field_type}
                                    placeholder={element.field_label}
                                  ></input>
                                ) : element.field_type === "checkbox" ? (
                                  <ul>
                                    {element &&
                                      element.option &&
                                      element.option.map((element, index) => (
                                        <li>
                                          <div className="form-check">
                                            <label className="form-check-label lable-service">
                                              <input
                                                disabled
                                                id="checkbox"
                                                type="checkbox"
                                                className="form-check-input"
                                                name={element.value}
                                                value={element.value}
                                              ></input>
                                              {element.lable}
                                            </label>
                                          </div>
                                        </li>
                                      ))}
                                  </ul>
                                ) : element.field_type === "radio" ? (
                                  element.option ? (
                                    element &&
                                    element.option &&
                                    element.option.map((element, i) => (
                                      <div className="form-check">
                                        <label className="form-check-label lable-service">
                                          <input
                                            disabled
                                            type="radio"
                                            className="form-check-input"
                                            name={index}
                                            value={element.value}
                                          ></input>
                                          {element.lable}
                                        </label>
                                      </div>
                                    ))
                                  ) : (
                                    element &&
                                    element.option &&
                                    element.option.map((element, i) => (
                                      <div className="form-check">
                                        <label className="form-check-label lable-service">
                                          <input
                                            disabled
                                            type="radio"
                                            className="form-check-input"
                                            name={index}
                                            value={element.value}
                                          ></input>
                                          {element.lable}
                                        </label>
                                      </div>
                                    ))
                                  )
                                ) : element.field_type === "file" ? (
                                  <button
                                    disabled
                                    type="button"
                                    className="attached-comment btn btn-info"
                                  >
                                    <i className="fas fa-paperclip"></i> Upload
                                  </button>
                                ) : element.field_type === "textarea" ? (
                                  <textarea
                                    id="bookingNotes"
                                    name="text"
                                    className="form-control"
                                    placeholder={element.field_label}
                                  ></textarea>
                                ) : element.field_type === "slider" ? (
                                  <>
                                    <input
                                      disabled
                                      className="slider"
                                      type="range"
                                      list="tickmarks"
                                    />
                                    <datalist id="tickmarks">
                                      {element &&
                                        element.option &&
                                        element.option.map((el, i) => (
                                          <option value={i + 1}>{i + 1}</option>
                                        ))}
                                    </datalist>
                                  </>
                                ) : element.field_type === "dropdown" ? (
                                  <select
                                    className="form-control"
                                    name="field_category"
                                    value={element.field_category}
                                    option={element.option}
                                    onChange={(e) =>
                                      onCategoryFieldChange(e, index)
                                    }
                                  ></select>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    );
                  })
                : []}

              <div className="bookonline-button-wrapper">
                <div className="ser_pop_create_intake_form">
                  <span>Additional questions you want to ask the client:</span>
                  <a onClick={(e) => addField(e)}>Add Field +</a>
                </div>
              </div>

              {intakeField !== null
                ? intakeField.length > 0 &&
                  intakeField.map((element, index) => {
                    return (
                      <div
                        key={index}
                        className="card bookonlinemodule-wrapper"
                      >
                        <div className="card-header bg-aqua bookonline-header">
                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 mb-sm-0 mb-2">
                              {/* <Select
                                    options={categories}
                                    className="field-type"
                                    styles={{
                                      menu: (base) => ({
                                        ...base,
                                        zIndex: 99999999,
                                      }),
                                    }}
                                    name="category"
                                    defaultValue={categories[0]}
                                    onChange={(value) => {
                                      let fields = [...intakeField];
                                      let field = { ...fields[index] };
                                      field.selected = "";
                                      field.field_category = value.value;
                                      field.formfield = value.id;
                                      fields[index] = field;
                                      setIntakeField(fields);
                                      onCategoryChange(value, index);
                                    }}
                                  /> */}
                              <input
                                className=""
                                id="field_label"
                                type="text"
                                placeholder="Field label"
                                name="field_label"
                                required
                                onChange={(e) => {
                                  var value = e.target.value.replace(" ", "_");
                                  let fields = [...intakeField];
                                  let field = { ...fields[index] };
                                  field.selected = "";
                                  field.field_name = value;
                                  field.field_label = e.target.value;
                                  field.field_type = "text";
                                  fields[index] = field;
                                  setIntakeField(fields);
                                }}
                              />
                            </div>

                            {/* <div className="col-lg-4 col-md-6 col-sm-6   online-fields">
                                  {element.online_fieldnames.length > 0 && (
                                    <Select
                                      options={element.online_fieldnames}
                                      className="field-type"
                                      styles={{
                                        menu: (base) => ({
                                          ...base,
                                          zIndex: 99999999,
                                        }),
                                      }}
                                      defaultValue={
                                        element?.online_fieldnames[0]
                                      }
                                      onChange={(value) => {
                                        onSubcategoryChange(value.value, index);
                                      }}
                                    />
                                  )}
                                </div> */}
                            <div className="col-lg-5 col-md-4 col-sm-4 mt-2 mt-lg-0 col-4">
                              <div className="check-box-control req-chk">
                                <label
                                  className="form-check-label requiredInput"
                                  htmlFor={index}
                                >
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id={index}
                                    onClick={(e) => {
                                      onisReqChange(e, index);
                                    }}
                                  />
                                  Required ?
                                </label>
                              </div>
                            </div>
                            <div className="col-lg-1 col-md-2 col-sm-2 mt-lg-0 mt-2 col-6">
                              <div className="col-ms-2">
                                <button
                                  type="button"
                                  className="btn btn-danger float-right deleteBtn"
                                  onClick={() => deleteField(index)}
                                >
                                  -
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <ul className="list-group list-group-flush border-bottom">
                          <li className="list-group-item online-fields">
                            <div className="row flex_column">
                              <div className="col-lg-12  col-md-6 com-sm-12 field-label">
                                <label className="lable-service">
                                  {element.field_label}
                                </label>
                              </div>
                              <div className="col-lg-12 col-md-6 com-sm-12 pain-scale-slider">
                                {element.field_type === "text" ? (
                                  <input
                                    disabled
                                    type={element.field_type}
                                    placeholder={element.field_label}
                                  ></input>
                                ) : element.field_type === "checkbox" ? (
                                  <ul>
                                    {element &&
                                      element.option &&
                                      element.option.map((element, index) => (
                                        <li>
                                          <div className="form-check">
                                            <label className="form-check-label lable-service">
                                              <input
                                                disabled
                                                id="checkbox"
                                                type="checkbox"
                                                className="form-check-input"
                                                name={element.value}
                                                value={element.value}
                                              ></input>
                                              {element.lable}
                                            </label>
                                          </div>
                                        </li>
                                      ))}
                                  </ul>
                                ) : element.field_type === "radio" ? (
                                  element &&
                                  element.option &&
                                  element.option.map((element, i) => (
                                    <div className="form-check">
                                      <label className="form-check-label lable-service">
                                        <input
                                          disabled
                                          type="radio"
                                          className="form-check-input"
                                          name={index}
                                          value={element.value}
                                        ></input>
                                        {element.lable}
                                      </label>
                                    </div>
                                  ))
                                ) : element.field_type === "file" ? (
                                  <button
                                    disabled
                                    type="button"
                                    className="attached-comment btn btn-info"
                                  >
                                    <i className="fas fa-paperclip"></i> Upload
                                  </button>
                                ) : element.field_type === "textarea" ? (
                                  <textarea
                                    id="bookingNotes"
                                    name="text"
                                    className="form-control"
                                    disabled
                                    placeholder={element.field_label}
                                  ></textarea>
                                ) : element.field_type === "slider" ? (
                                  <>
                                    <input
                                      disabled
                                      className="slider"
                                      type="range"
                                      list="tickmarks"
                                    />
                                    <datalist id="tickmarks">
                                      {element &&
                                        element.option &&
                                        element.option.map((el, i) => (
                                          <option>{i + 1}</option>
                                        ))}
                                    </datalist>
                                  </>
                                ) : element.field_type === "dropdown" ? (
                                  <select
                                    className="form-control"
                                    name="field_category"
                                    value={element.field_category}
                                    option={element.option}
                                    onChange={(e) =>
                                      onCategoryFieldChange(e, index)
                                    }
                                  ></select>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    );
                  })
                : []}
            </>
          )}

          {/* <div className="ser_pop_create_intake_form">
                <span>Create Intake Inform:</span>
                <a href="">Add Field +</a>
              </div> */}

          <div className="contact-method-col switch_add_ser d-none">
            <label className="switch">
              <input
                type="checkbox"
                name="typeof_booking"
                id="service-call_to_book"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    call_to_book: !formData?.call_to_book,
                    book_online: false,
                    book_site: false,
                  });
                }}
                checked={call_to_book}
              />
              <span className="slider round"></span>
            </label>
            <label htmlFor="service-call_to_book"> Call to Book </label>
          </div>

          <div className="contact-method-col switch_add_ser d-none">
            <label className="switch">
              <input
                type="checkbox"
                name="typeof_booking"
                id="service-book_site"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    call_to_book: false,
                    book_online: false,
                    book_site: !formData.book_site,
                    book_site_link: e.target.checked
                      ? formData.book_site_link
                      : "",
                  });
                }}
                checked={book_site}
              />
              <span className="slider round"></span>
            </label>
            <label htmlFor="service-book_site">
              Bookable From Another Site
            </label>
          </div>
          <div className="row d-none">
            {formData.book_site && (
              <>
                <div className="http_bg">
                  <div className="col-sm-12 text-muted">
                    <p className="htt_disc">
                      Enter the URL you would like to redirect users to when
                      <br />
                      booking for this service.
                    </p>
                  </div>
                  <div className="col-sm-12">
                    {/* <label htmlFor="book-site-link"></label> */}
                    <input
                      type="url"
                      className="form-control http_field"
                      name="book_site_link"
                      placeholder="https://"
                      id="book-site-link"
                      required={book_site}
                      value={book_site_link ? book_site_link : ""}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                </div>
              </>
            )}
          </div>

          <button
            type="submit"
            className="btn btn-primary btn-lg mt-4 create_btn w-md-25 mx-auto text-xs font-semibold mb-3 mb-md-0"
            data-tut="register_button"
          >
            Save Changes
          </button>
        </form>
        {/* </div> */}
        {/* </div> */}
      </div>
    </Fragment>
  );
};

AddBusinessService.propTypes = {
  addBusinessService: PropTypes.func.isRequired,
  editSBusinesservice: PropTypes.func.isRequired,
  form_fields: PropTypes.func,
  editUserServices: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  formfields: state.formfields,
  categoryList: state.company.category,
  companyList: state.company.companylist,
  auth: state.auth,
  company: state.company,
});

export default connect(mapStateToProps, {
  addBusinessService,
  editSBusinesservice,
  editUserServices,
  addUserService,
  editBusiness,
  getCurrentCompany,
})(AddBusinessService);
