import React, { useEffect, useState } from "react";
import { setClaim, transferClaim, deleteClaim } from "../../actions/claim";
import Tooltip from "../layout/ToolTip";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Pagination from "react-bootstrap/Pagination";
import Spinner from "../layout/Spinner";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";

const Claim = ({
  setClaim,
  claimList,
  transferClaim,
  totalClaims,
  deleteClaim,
  CT,
}) => {
  const [currentPage, setcurrentPage] = useState(0);
  const [loadingDynamic, setloadingDynamic] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  useEffect(() => {
    setloadingDynamic(true);
    setTimeout(() => {
      setloadingDynamic(false);
    }, 1000);
  }, []);

  useEffect(() => {
    setClaim(currentPage);
  }, []);

  useEffect(() => {
    setPageCount(Math.ceil(totalClaims / 10));
    setcurrentPage(0);
  }, []);

  const transferComapny = (claim) => {
    if (window.confirm("Are you sure? This can NOT be undone!")) {
      transferClaim(claim);
    }
    setClaim(currentPage);
  };
  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setcurrentPage(selectedPage);
    setClaim(selectedPage);
  };
  const onNext = () => {
    setloadingDynamic(true);
    setTimeout(() => {
      setloadingDynamic(false);
    }, 1000);
    window.scrollTo(0, 0);
    setcurrentPage(currentPage + 1);
  };

  const onPrev = () => {
    setloadingDynamic(true);
    setTimeout(() => {
      setloadingDynamic(false);
    }, 1000);
    window.scrollTo(0, 0);
    setcurrentPage(currentPage - 1);
  };

  const pageNoClick = (i) => {
    setloadingDynamic(true);
    setTimeout(() => {
      setloadingDynamic(false);
    }, 1000);
    window.scrollTo(0, 0);
    setcurrentPage(i);
  };

  const rejectClaim = (claim) => {
    setloadingDynamic(true);
    deleteClaim(claim._id);
    setTimeout(() => {
      setloadingDynamic(false);
    }, 1000);
  };

  return (
    <div>
      {loadingDynamic ? (
        <Spinner />
      ) : (
        <div className="appointment-tbl" style={{ overflow: "auto" }}>
          <table className="table table-striped">
            <thead>
              <tr>
                <th className=" py-10 text-sm font-bold">Company Info</th>
                <th className=" py-10 text-sm font-bold">Claimer Info</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {claimList && claimList.length > 0 ? (
                claimList.map((claim, i) => (
                  <tr className="booking-item-row" id={i}>
                    <td className="rounded-s-5">
                      {/* <Link
                          to={{
                            pathname: `/superadmin/service/${claim.company._id}`,
                            state: { companyDetails: claim.company, currentTab: CT }
                          }}
                          className="font-weight-bold"
                        > */}
                      <span className="font-weight-bold text-sm py-10 text-aqua">
                        {claim?.company?.name}
                      </span>
                      {/* </Link> */}
                      <br />
                      <span className="text-xs text-dark font-normal">
                        {claim?.company?.email}
                      </span>
                      <br />
                      <span className="text-xs text-dark font-normal">
                        {claim?.company?.phone}
                      </span>
                    </td>
                    <td>
                      <span className="font-weight-bold text-sm py-10 text-aqua">
                        {claim?.user?.firstName} {claim?.user?.lastName}
                      </span>
                      <br />
                      <span className="text-xs text-dark font-normal">
                        {claim?.user?.email}
                      </span>
                      <br />
                      <span className="text-xs text-dark font-normal">
                        {claim?.user?.phone}
                      </span>
                    </td>
                    <td
                      className="booking-date rounded-e-5 text-right"
                      style={{ verticalAlign: "inherit" }}
                    >
                      {claim?.is_transfered ? (
                        <button
                          className="btn btn-primary disabledBtn"
                          disabled
                        >
                          Transferred
                        </button>
                      ) : (
                        <button
                          className="py-1 px-4 border-4 bg-green text-white text-xs font-bold border-0 mr-2 p-badge"
                          onClick={() => transferComapny(claim)}
                        >
                          Transfer
                        </button>
                      )}

                      <button
                        className="py-1 px-4 border-4 bg-red text-white text-xs font-bold border-0 p-badge"
                        onClick={() => rejectClaim(claim)}
                      >
                        Reject
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="booking-item-row">
                  <td className="booking-date">No claims found</td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="App mt-4">
            <ReactPaginate
              previousLabel={"prev"}
              nextLabel={"next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              forcePage={currentPage}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
          {/* <Pagination
              style={{
                marginTop: "10px",
                paddingBottom: "50px",
                marginLeft: "10px"
              }}
            >
              <Pagination.Prev
                disabled={currentPage == 0 || claimList ?.length == 0}
                onClick={onPrev}
              />
              {claimList &&
                [...Array(totalClaims)].map((data, i) => {
                  if (i < Math.ceil(totalClaims / 10)) {
                    return (
                      <Pagination.Item
                        active={currentPage == i}
                        onClick={() => pageNoClick(i)}
                      >
                        {i + 1}
                      </Pagination.Item>
                    );
                  }
                })}
              <Pagination.Next
                disabled={
                  currentPage == Math.ceil(totalClaims / 10) - 1 ||
                    claimList ?.length == 0
              }
                onClick={onNext}
              />
            </Pagination> */}
        </div>
      )}
    </div>
  );
};

Claim.propTypes = {
  setClaim: PropTypes.object.isRequired,
  claimList: PropTypes.object.isRequired,
  transferClaim: PropTypes.object.isRequired,
  deleteClaim: PropTypes.object.isRequired,
  totalClaims: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  claimList: state.company.claimsList,
  totalClaims: state.company.totalClaims,
  // auth: state.auth,
});

export default connect(mapStateToProps, {
  setClaim,
  transferClaim,
  deleteClaim,
})(Claim);
