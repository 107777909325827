import api from "../utils/api";
import { setAlert } from "./alert";

import {
  GET_COMPANY_LIST_UPDATE,
  PLANS,
  INACTIVEPLANS,
  FREEMONTHS,
} from "./types";

export const generatePrivateKey = (amount) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await api.post(
      "/feature/create-payment-intent",
      amount,
      config
    );
    return res.data;

    //   history.push("/admin")
  } catch (err) {
    console.log(err);
  }
};

export const makePayment = (amount, id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await api.post("/feature/stripe/charge", amount, id, config);
    // dispatch(setAlert(res.data.message));
    dispatch({
      type: GET_COMPANY_LIST_UPDATE,
      payload: res.data.company,
    });
    return res.data;
    //   history.push("/admin")
  } catch (err) {
    dispatch(setAlert(err.message, "danger"));
    // console.log("err : ", err);
  }
};

// get lists of  by id
// feature/plans

export const getPlans = (id) => async (dispatch) => {
  try {
    const res = await api.get(`/feature/plans/${id}`);

    dispatch({
      type: GET_COMPANY_LIST_UPDATE,
      payload: res.data[0],
    });
    return res;
  } catch (err) {
    // dispatch({
    //   type: COMPANY_ERROR,
    //   payload: { msg: err, status: 500 }
    // });
    console.log("err", err);
  }
};

// all the featured companies

export const getAllPlans = (search) => async (dispatch) => {
  try {
    const res = await api.get(`/feature/allPlans?search=${search}`);

    dispatch({
      type: PLANS,
      payload: res.data,
    });

    return res;
  } catch (err) {
    // dispatch({
    //   type: COMPANY_ERROR,
    //   payload: { msg: err, status: 500 }
    // });
    console.log("err", err);
  }
};

// get inactive plans for admin
// /inavtive

export const getAllInactivePlans = (search) => async (dispatch) => {
  try {
    const res = await api.get(`/feature/inavtive?search=${search}`);

    dispatch({
      type: INACTIVEPLANS,
      payload: res.data,
    });

    return res;
  } catch (err) {
    // dispatch({
    //   type: COMPANY_ERROR,
    //   payload: { msg: err, status: 500 }
    // });
    console.log("err", err);
  }
};

// get inactive plans for admin
// /plans/inavtive

export const cancelPlan = (id) => async (dispatch) => {
  try {
    // const res = await api.get(`/feature/plans/currentinactive`);

    const res = await api.post("/feature/plans/currentinactive", id);

    dispatch({
      type: GET_COMPANY_LIST_UPDATE,
      payload: res.data.company,
    });

    return res;
  } catch (err) {
    // dispatch({
    //   type: COMPANY_ERROR,
    //   payload: { msg: err, status: 500 }
    // });
    console.log("err", err);
  }
};

// renew expired plans
// /renew

export const renewPlan = (amount, id, plan) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await api.post("/feature/renew", amount, id, config, plan);
    dispatch(setAlert(res.data.message));
    dispatch({
      type: GET_COMPANY_LIST_UPDATE,
      payload: res.data.company,
    });

    dispatch({
      type: FREEMONTHS,
      payload: res.data,
    });

    return res.data;
    //   history.push("/admin")
  } catch (err) {
    console.log(err);
  }
};
