import React  from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import LogoImage from '../../images/Appt-Cake-logo.png';

const EmailSent = ({history}) => {

  return (
    <section className="landing text-center d-flex justify-content-center align-items-center">
      <div>
        <h1 className="large text-primary">Our Email Is On The Way !</h1>
        <img alt="logo" src={LogoImage}/>
        <p className="my-4">
          Back To <Link to="/login">Login</Link>
        </p>
      </div>
    </section>
  );
};

EmailSent.propTypes = {
  history: PropTypes.object,
};


export default EmailSent;
