import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { getCoordinates } from "../../actions/map";
import GoogleMapReact from "google-map-react";
import AutoComplete from "./Autocomplete";
import PropTypes from "prop-types";
import { getCompanies } from "../../actions/company";
import Marker from "./Marker";
import { useHistory } from "react-router-dom";
import { GET_MAPDATA } from "../../actions/types";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

let localAddressRef = "";
const MapContainer = ({
  setIsLoading,
  getCompanies,
  company: { companies, loading },
  mapMarkers,
  newMarker,
  addressupdate,
  currentLat,
  currentLng,
  centerData,
  companiesData,
  filter,
  defaultAddress,
  companyList,
  onHover,
  onLeave,
  companyFull,
  setFilter,
}) => {
  var url_string = window.location.href; //window.location.href
  var url = new URL(url_string);
  const dispatch = useDispatch();
  const location = useLocation();
  const [mapData, setMapData] = useState({
    mapApiLoaded: false,
    mapInstance: null,
    mapApi: null,
    geoCoder: null,
    places: [],
    center: {
      lat: null,
      lng: null,
    },
    zoom: url.searchParams.get("zoom")
      ? parseFloat(url.searchParams.get("zoom"))
      : 13,
    address: "",
    draggable: true,
    markers: [],
    lat: null,
    lng: null,
  });
  const OPTIONS = {
    minZoom: 12,
    maxZoom: 16,
    disableDoubleClickZoom: true,
  };

  const refMap = useRef(null);

  let {
    mapApiLoaded,
    mapInstance,
    mapApi,
    geoCoder,
    places,
    center,
    zoom,
    address,
    draggable,
    markers,
    lat,
    lng,
  } = mapData;

  // const { companies  } = useSelector((state) => state.company)
  useEffect(() => {
    getCoordinates(lat, lng, markers);

    const newMarkers = markers?.length === 0 ? mapMarkers : markers;
    setMapData({
      mapApiLoaded: mapApiLoaded ? mapApiLoaded : false,
      mapInstance: !mapInstance ? null : mapInstance,
      mapApi: !mapApi ? null : mapApi,
      geoCoder: !geoCoder ? null : geoCoder,
      places: !places ? [] : places,
      center: !center ? [0, 0] : center,
      zoom: !zoom ? 13 : zoom,
      address: !address ? "" : address,
      draggable: !draggable ? true : draggable,
      markers: !newMarkers ? [] : newMarkers,
      lat: !lat ? null : lat,
      lng: !lng ? null : lng,
    });
  }, [
    address,
    center,
    draggable,
    geoCoder,
    markers,
    mapApi,
    mapApiLoaded,
    mapInstance,
    places,
    zoom,
    lat,
    lng,
    mapMarkers,
  ]);

  // var radiusCircle;
  const [miles, setMiles] = useState();
  const [radiusCust, setradiusCust] = useState(15000);
  const [initialMap, setInitialMap] = useState();
  const [initialMaps, setInitialMaps] = useState();
  const [radiusCircle, setradiusCircle] = useState();
  const [filteredCompanyList, setFilteredCompanyList] = useState([]);
  const [mapLoaded, setMapLoaded] = useState(false);
  // const [filtersvalue,setFilter]=useState("")
  let history = useHistory();
  const handleBoundsChanged = () => {
    const mapCenter = refMap.current.getCenter(); //get map center
    // mapData.center = mapCenter
  };
  useEffect(() => {
    setFilteredCompanyList(companyList);
  }, [companyList]);

  useEffect(() => {
    setTimeout(() => {
      setMapData({
        ...mapData,
        center: [currentLat, currentLng],
        lat: currentLat,
        lng: currentLng,
      });
      // setCurrentLocation()
    }, 1000);
  }, [currentLng]);

  useEffect(() => {
    mapData.markers = [];
    mapData.filteredMarkers = [];
    companies?.map((companyValue) => {
      mapData.markers.push({
        lat: companyValue.location.lat,
        lng: companyValue.location.lng,
        company: companyValue,
        show: false,
        text: companyValue.name,
      });
      setMapData({
        ...mapData,
        markers: [...mapData.markers],
      });
    });
  }, [companies, companyFull, filteredCompanyList, companyList]);

  const onMarkerInteraction = (childKey, childProps, mouse) => {
    setMapData({
      ...mapData,
      draggable: false,
      lat: mouse.lat,
      lng: mouse.lng,
    });
  };

  const onMarkerInteractionMouseUp = (childKey, childProps, mouse) => {
    setMapData({ ...mapData, draggable: true });
    // _generateAddress();
  };

  // const onChildClickCallback = (key) => {
  //   console.log('key---',key)
  //   markers[key].show = !markers[key].show;
  //   for (let marker in markers) {
  //     if (marker !== key) {
  //       markers[marker].show = false;
  //     }
  //   }
  // };

  const onChildClickCallback = (key) => {
    const markerValue = markers[key - 1].show;

    const markerValueChange = !markerValue;
    markers[key - 1].show = markerValueChange;
    for (let marker in markers) {
      const customKey = key - 1;
      if (marker != customKey) {
        markers[marker].show = false;
      }
    }
  };
  var params = new URLSearchParams(window.location.search);

  const _onChange = ({ center, zoom }) => {
    setMapData({
      ...mapData,
      center: center,
      zoom: zoom,
    });
    const currentSearch = new URLSearchParams(location.search);
    
     
    const query = {};
    for (let entry of params.entries()) {
      query[entry[0]] = entry[1];
    }
    // filter
  
    if(center.lat != currentSearch.get('lat') && center.lng != currentSearch.get('lng') && center.miles != currentSearch.get('miles') ){
    
      history.push({
      pathname: "/find",
      search:
        "?" +
        new URLSearchParams({
          ...query,
          filter: filter,
          lat: center.lat || query.lat,
          lng: center.lng || query.lng,
          zoom: zoom,
        }).toString(),
      state: {
        address: localAddressRef,
      },
    });
  }
    // window.history.replaceState( {} , '', `?lat=${center.lat}&lng=${center.lng}&zoom=${zoom}` );
    // window.history.replaceState( {} , '', `?lng=${center.lng}`);
    // window.history.replaceState( {} , '', `?zoom=${zoom}`);
    let latitude = center ? center.lat : "";
    const metersPerPixel =
      (156543.03392 * Math.cos((latitude * Math.PI) / 180)) / Math.pow(2, zoom);
    let radius = 1;
    // radius = metersPerPixel * 400
    if (window.innerWidth <= 414) {
      radius = metersPerPixel * 150;
    } else if (window.innerWidth <= 786) {
      radius = metersPerPixel * 230;
    } else if (window.innerWidth <= 1200) {
      radius = metersPerPixel * 310;
    } else {
      radius = metersPerPixel * 400;
    }

    // const radius = Math.pow((20 - zoom), 4)
    setradiusCust(radius);
    setMiles(Math.ceil(radius));
    const milesCust = Math.ceil(radius);
    getCompanies({
      latitude: center.lat,
      longitude: center.lng,
      miles: milesCust,
    });
    
    
    if (initialMap != undefined)
      createCircel(initialMap, initialMaps, center, radius);
  };

  const _onClick = (value) => {
    setMapData({
      ...mapData,
      lat: value.lat,
      lng: value.lng,
      markers: [
        {
          lat: value.lat,
          lng: value.lng,
        },
      ],
    });
    if (typeof newMarker === "function" && newMarker !== false) {
      newMarker({
        lat: value.lat,
        lng: value.lng,
      });
    }
  };

  const addPlace = (place) => {
    localAddressRef = place?.formatted_address;
    const payload = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
      address: place?.formatted_address,
    };
    dispatch({
      type: GET_MAPDATA,
      payload: payload,
    });
    setMapData({
      ...mapData,
      places: [place],
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
      markers: [
        {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        },
      ],
      center: {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      },
    });
    addressupdate &&
      addressupdate(
        place.address_components,
        place.geometry.location.lat(),
        place.geometry.location.lng()
      );
  };

  const _generateAddress = () => {
    if (mapApi !== null) {
      const geocoder = new mapApi.Geocoder();

      geocoder.geocode(
        { location: { lat: lat, lng: lng } },
        (results, status) => {
          if (status === "OK") {
            if (results[0]) {
              setMapData({
                ...mapData,
                zoom: 13,
                address: results[0].formatted_address,
              });
            } else {
              window.alert("No results found");
            }
          } else {
            window.alert("Geocoder failed due to: " + status);
          }
        }
      );
    }
  };

  const apiHasLoaded = (map, maps) => {
    setInitialMap(map);
    setInitialMaps(maps);
    setMapData({
      ...mapData,
      mapApiLoaded: true,
      mapInstance: map,
      mapApi: maps,
    });
    setradiusCircle(
      new maps.Circle({
        strokeColor: "#ffa264",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#ffa264",
        fillOpacity: 0.2,
        map,
        center: { lat: mapData.lat, lng: mapData.lng },
        radius: radiusCust,
      })
    );
    _generateAddress();
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  const createCircel = (map, maps, center, radius) => {
    if (radiusCircle && radiusCircle.setMap) {
      radiusCircle.setMap(null);
    }
    setradiusCircle(
      new maps.Circle({
        strokeColor: "#ffa264",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#ffa264",
        fillOpacity: 0.3,
        map,
        center: { lat: center.lat, lng: center.lng },
        radius: radius,
      })
    );
  };

  // marker hover
  const onMarkerHover = (company) => {
    onHover(company);
  };

  // marker hover leave
  const onMarkerHoverLeave = (company) => {
    onLeave(company);
  };

  return (
    <div style={{ position: "relative" }}>
      {mapApiLoaded && (
        <div>
          <AutoComplete
            defaultAddress={defaultAddress}
            map={mapInstance}
            mapApi={mapApi}
            addPlace={addPlace}
            setFilter={setFilter}
            isFilter={true}
          />
        </div>
      )}
      <div
        style={{
          height: "100vh",
          minHeight: "400px",
          width: "100%",
          position: "relative",
        }}
        id="MapContainer"
        data-tut="map_container"
      >
        <GoogleMapReact
          options={OPTIONS}
          ref={refMap}
          bootstrapURLKeys={{
            key: "AIzaSyDMrbBYCBv7WyV77RaI6OBbfK4gDNYNiCw",
            libraries: ["places", "geometry"],
          }}
          center={center}
          defaultCenter={center}
          defaultZoom={zoom}
          zoom={zoom}
          draggable={draggable}
          scrollwheel={false}
          onChange={_onChange}
          onChildMouseDown={onMarkerInteraction}
          onChildMouseUp={onMarkerInteractionMouseUp}
          onChildMouseMove={onMarkerInteraction}
          onChildClick={onChildClickCallback}
          onBoundsChanged={handleBoundsChanged}
          onClick={newMarker !== false ? _onClick : null}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => apiHasLoaded(map, maps)}
          // options={{  disableDoubleClickZoom: true}}
        >
          <Marker
            key={0}
            lat={center?.lat}
            lng={center?.lng}
            isdefault={true}
            show={false}
          />
          {companies &&
            companies?.length > 0 &&
            mapData?.markers?.map((marker, index) => {
              return marker?.company ? (
                <Marker
                  isdefault={false}
                  key={index + 1}
                  company={marker?.company}
                  lat={marker?.lat}
                  lng={marker?.lng}
                  text={marker?.text}
                  show={marker?.show}
                  onMarkerHover={onMarkerHover}
                  onMarkerHoverLeave={onMarkerHoverLeave}
                  isFiltered={
                    filteredCompanyList?.find(
                      (service) => service?._id == marker?.company?._id
                    )
                      ? false
                      : true
                  }
                />
              ) : (
                ""
              );
            })}
        </GoogleMapReact>
      </div>
    </div>
  );
};

MapContainer.propTypes = {
  getCoordinates: PropTypes.func,
  markers: PropTypes.array,
  getCompanies: PropTypes.func.isRequired,
  company: PropTypes.object,
  setFilter: PropTypes?.setFilter,
};

const mapStateToProps = (state) => ({
  companiesData: state.companies,
  company: state.company,
});
export default connect(mapStateToProps, { getCoordinates, getCompanies })(
  MapContainer
);
