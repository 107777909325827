export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const SET_CURRENT_COMPANY = 'SET_CURRENT_COMPANY';

export const showModal = () => {
  console.log("Dispatching SHOW_MODAL"); // Add this line
  return {
    type: SHOW_MODAL,
  };
};

export const hideModal = () => ({
  type: HIDE_MODAL,
});

export const setCurrentCompany = (company) => ({
  type: SET_CURRENT_COMPANY,
  payload: company,
});
