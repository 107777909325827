import React, { Fragment, useEffect, useState } from "react";
import { withRouter, useHistory, useLocation } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import moment from "moment";
import Tour from "reactour";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import CommentForm from "../booking/CommentForm";
import CommentItem from "../booking/CommentItem";
import { getBookingById, deleteBooking } from "../../actions/booking";
import { Helmet } from "react-helmet";
import "./booking.css";
// import UserIcon from "../../images/user-icon.png";

const Booking = ({
  booking: { booking, loading },
  getBookingById,
  auth,
  match,
  deleteBooking
}) => {
  let User = auth.user;
  let history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [text, setText] = useState("");
  useEffect(() => {
    getBookingById(match.params.id, history);
  }, [auth.user._id]);

  const [isTourOpen, setisTourOpen] = useState(false);
  const closeTour = () => {
    setisTourOpen(false);
  };
  const disableBody = target => disableBodyScroll(target);
  const enableBody = target => enableBodyScroll(target);

  const location = useLocation();

  return loading || booking === null ? (
    <Spinner />
  ) : (
    <Fragment>
      <Helmet>
        <title>Appointment Cake</title>
        <meta
          name="description"
          content="Whatever or whoever you’re looking for,
                   Appointment Cake has you covered."
        />
      </Helmet>
      <a
        onClick={() => {
          history.push("/account");
        }}
        className="user-icon desktop-show btn btn-info max_width d-none"
      >
        <i className="fa-solid fa-user"></i> User{" "}
      </a>
      <button
        id="change-log"
        type="button"
        className="btn btn-info max_width d-none"
        onClick={() => setisTourOpen(true)}
      >
        <i className="fas fa-edit"></i>
        <span> Guide </span>{" "}
      </button>
      <Tour
        steps={steps}
        scrollSmooth
        isOpen={isTourOpen}
        onRequestClose={closeTour}
        onAfterOpen={disableBody}
        onBeforeClose={enableBody}
      />
      <div className="booking-detail-page">
        <div className="row p-3 header-bg" style={{ height: "100vh" }}>
          <div
            className="col-12 booking-detail-margin break_hed mb-5"
            data-tut="booked_service_header"
          >
            <button
              onClick={() =>
                location.state?.page == "notification"
                  ? history.push("/messages")
                  : history.goBack()
              }
              className="btn btn-secondary rounded-circle float-left mr-4 mr-in mobile-top-arrow"
            >
              <i
                className="fas fa-arrow-left"
                onClick={() =>
                  location.state?.page == "notification"
                    ? history.push("/messages")
                    : history.goBack()
                }
              ></i>
            </button>
            <h2>
              {booking?.service?.name} Booking at{" "}
              {moment(booking?.start_time).format("h:mm a, MMM Do")}
            </h2>
          </div>
          <div className="col-md-12 col-sm-12">
            <div className="row">
              <div className="col-lg-4">
                <div
                  className="card booking-card mb-4 book-serv"
                  data-tut="booked_service_detail"
                >
                  <div className="card-header booking-card-header" >
                    <h4>Booking Details</h4>
                  </div>
                  <div className="card-body">
                    <h5>
                      <strong>{booking?.service?.name}</strong> at{" "}
                      {booking?.company?.name}
                    </h5>
                    <p>
                      <strong>Full Name:</strong>
                      {" " + User?.firstName + " " + User?.lastName}
                      <br />
                      <strong>Phone:</strong>
                      {" " + User?.phone}
                      <br />
                      <strong>Email:</strong>
                      {" " + User?.email}
                      <br />
                      <strong>Date:</strong>{" "}
                      {moment(booking?.start_time).format("dddd, D, MMMM")}
                      <br />
                      <strong>Time:</strong>{" "}
                      {moment(booking.start_time).format("h:mm a")} to{" "}
                      {moment(booking.start_time)
                        .add(booking.duration, "minutes")
                        .format("h:mm a")}
                      <br />
                      <strong>Duration:</strong> {booking.duration} Minutes
                    </p>
                    <p>{booking?.service?.description}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card booking-card mb-4" data-tut="booked_service_location">
                  <div className="card-header booking-card-header">
                    <h4>Company Location</h4>
                  </div>
                  <div className="card-body">
                    <p>
                      {booking?.company?.location.street_address}
                      <br />
                      {booking?.company?.location.city},{" "}
                      {booking?.company?.location.province}
                      <br />
                      {booking?.company?.location.postal}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card booking-card mb-4" data-tut="booked_service_formdetail">
                  <div className="card-header booking-card-header">
                    <h4>Booking Details Values</h4>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">Message</h5>
                    <div className="from-sent">
                      <strong>From :</strong> {booking?.company?.name}
                    </div>
                    <div className="from-sent">
                      <strong>Sent On :</strong> 2:00 PM March 21, 2022
                    </div>
                    <div className="form-sent">
                      {booking &&
                        booking.service &&
                        booking.service.intakeForm &&
                        booking.service.intakeForm.map((fields, i) =>
                          fields.value && fields.field_type === "file" ? (
                            <>
                              <strong>{fields.field_label} :</strong>
                              <div
                                style={{
                                  display: "flex",
                                  marginBottom: 10
                                }}
                              >
                                {fields.value &&
                                  fields.value.map((ele, i) => (
                                    <>
                                      <img
                                        className="commentImage-priview"
                                        src={ele}
                                        style={{
                                          width: "100px",
                                          height: "100px"
                                        }}
                                      />
                                    </>
                                  ))}
                              </div>
                            </>
                          ) : fields.field_type === "slider" ? (
                            <p key={i}>
                              <strong>{fields.field_label} :</strong>{" "}
                              {fields.value ? fields.value : "0"}
                            </p>
                          ) : fields.value ? (
                            <p key={i} className="lable-service">
                              <strong>{fields.field_label} :</strong>{" "}
                              {fields.value.toString()}
                            </p>
                          ) : (
                            " "
                          )
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="card booking-card mb-4 book-service-comment"
              data-tut="booked_service_comment"
            >
              <div className="card-header booking-card-header">
                <h4>Comments</h4>
              </div>
              <div className="card-body">
                {booking?.comments?.map(comment => (
                  <CommentItem
                    key={comment._id}
                    comment={comment}
                    bookingId={booking._id}
                  />
                ))}
                <CommentForm
                  postId={booking._id}
                  company={booking?.company?.name}
                />
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            {(booking.user._id === auth.user._id ||
              booking.company.user._id === auth.user._id) &&
              moment(booking.start_time).toDate() > moment().toDate() && (
                <div className="cancel-paddingbtm">
                  <div className="card booking-card mb-4" data-tut="booked_service_cancel">
                    <button
                      onClick={() => {
                        setIsOpen(true);
                      }}
                      type="button"
                      className="btn btn-sm btn-danger"
                    >
                      Delete Appointment
                    </button>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>

      <Modal
        show={isOpen}
        onHide={() => {
          setIsOpen(false);
          setText("");
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Cancel </Modal.Title>
        </Modal.Header>
        <form
          className="form my-1"
          onSubmit={e => {
            e.preventDefault();
            deleteBooking(booking._id, history, text, booking);
            setIsOpen(false);
            setText("");
          }}
        >
          <Modal.Body>
            <div className="card-header booking-card-header">
              <h4>Booking Details</h4>
            </div>
            <div className="card-body">
              <h5>
                <strong>{booking?.service?.name}</strong> at{" "}
                {booking?.company?.name}
              </h5>
              <p>
                <strong>Date:</strong>{" "}
                {moment(booking?.start_time).format("dddd, D, MMMM")}
                <br />
                <strong>Time:</strong>{" "}
                {moment(booking.start_time).format("h:mm a")} to{" "}
                {moment(booking.start_time)
                  .add(booking.duration, "minutes")
                  .format("h:mm a")}
                <br />
                <strong>Duration:</strong> {booking.duration} Minutes
              </p>
              <p>{booking?.service?.description}</p>
            </div>
            <Form.Group>
              <Form.Label>Reason: </Form.Label>
              <Form.Control
                type="text"
                onChange={e => setText(e.target.value)}
                required
                value={text}
                placeholder="Please enter the reason"
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" className="btn btn-danger">
              Cancel Appt.
            </Button>
            <Button
              variant="secondary"
              type="button"
              onClick={() => {
                setIsOpen(false);
                setText("");
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </Fragment>
  );
};

const steps = [
  {
    selector: '[data-tut="booked_service_header"]',
    content: `Booked service name with booking time.`
  },
  {
    selector: '[data-tut="booked_service_detail"]',
    content: `Booked service details such as time, duration etc..`
  },
  {
    selector: '[data-tut="booked_service_formdetail"]',
    content: `Values of the form which is filled by customer.`
  },
  {
    selector: '[data-tut="booked_service_comment"]',
    content: `Comment section for the service.`
  },
  {
    selector: '[data-tut="booked_service_location"]',
    content: `Location details for the company.`
  }
  // {
  //   selector: '[data-tut="booked_service_cancel"]',
  //   content: `Button to delete the booked appointment.`
  // }
];

Booking.protoTypes = {
  getBookingById: PropTypes.func.isRequired,
  booking: PropTypes.object.isRequired,
  auh: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  booking: state.booking,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { deleteBooking, getBookingById }
)(withRouter(Booking));
