import React, { Fragment } from 'react';

export const Spinner = () => {
  return (
    <Fragment>
      <div className='d-flex justify-content-center align-items-center h-100 backdrop'>
        <div className='spinner-border text-primary' role='status'>
          <span className='sr-only'>Loading...</span>
        </div>
      </div>
    </Fragment>
  );
};
export default Spinner;
