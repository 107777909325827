import React, { Fragment, useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Spinner from "../layout/Spinner";
import CompanyItem from "../companies/CompanyItem";
import { getCategories, getCompanies } from "../../actions/company";
import Tour from "reactour";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import MapComponent from "../maps/MapComponent";
import { useLocation, useHistory } from "react-router-dom";
import GoogleAd from "./GoogleAd";
import Select from "react-select";
import { Helmet } from "react-helmet";
import { options } from "../../utils/constant";
import { GET_APPOINTMENTDETAIL } from "../../actions/types";
// import UserIcon from "../../images/user-icon.png";

const Find = ({
  getCompanies,
  getCategories,
  company: { companies, loading, MapData },
  tour,
  myclaims,
  formfields,
  categoryList,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  let history = useHistory();
  //set category list
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [dropdownList, setDropdownList] = useState([]);
  const [address, setAddress] = useState("");
  const [services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filterToggle, setFilterToggle] = useState(false);
  const [mapData, setMapData] = useState({
    latitude: null,
    longitude: null,
    miles: null,
  });

  //create a state that we can filter through
  const [companiesList, setCompaniesList] = useState(companies);
  const [companiesHoverList, setCompaniesHoverList] = useState([]);

  const [isTourOpen, setisTourOpen] = useState(tour);
  //create a state for the search input
  const [filter, setFilter] = useState("");
  const [filterDropDown, setFilterDropDown] = useState("");

  const closeTour = () => {
    setisTourOpen(false);
  };
  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);

  useEffect(() => {
    setTimeout(() => {
      const section = document.querySelector(".isMarkerHover");
      section?.scrollIntoView({ behavior: "smooth" });
    }, 500);
    setFilter(params.get("search"));
  }, [companiesList]);

  useEffect(() => {
    const search = location.search;
    const name = new URLSearchParams(search).get("filter");
    const searchParam = new URLSearchParams(search).get("search");
    setFilter(searchParam == "null" ? "" : searchParam);
    setFilterDropDown(name);
  }, []);

  // exclude column list from filter
  const excludeColumns = [
    "social",
    "store_hours",
    "_id",
    "user",
    "website",
    "email",
    "phone",
    "likes",
    "likes",
    "reviews",
    "date_created",
  ];

  const markers = [];
  for (let company in companies) {
    if (
      companies[company].location.hasOwnProperty("lat") &&
      companies[company].location.hasOwnProperty("lng")
    ) {
      markers.push({
        lat: companies[company].location.lat,
        lng: companies[company].location.lng,
        company: companies[company],
        text: companies[company].name,
        show: true,
      });
    }
  }

  var url_string = window.location.href; //window.location.href
  var url = new URL(url_string);

  const setCurrentLocation = () => {
    if (location?.state?.detail) {
      setMapData({
        ...mapData,
        latitude: location.state.detail.lat,
        longitude: location.state.detail.lng,
        miles: 15000 / 1000,
      });
    } else if (url.searchParams.get("lat") != undefined) {
      const searchParams = new URLSearchParams(location.search);
      const filter = searchParams.get("filter");
      const lat = searchParams.get("lat");
      const lng = searchParams.get("lng");
      const zoom = searchParams.get("zoom");
      setMapData({
        ...mapData,
        latitude: parseFloat(lat),
        longitude: parseFloat(lng),
        miles: parseFloat(zoom),
      });
    } else if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setMapData({
          ...mapData,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          miles: url.searchParams.get("zoom"),
        });
      });
    }
  };

  useEffect(() => {
    setAddress(MapData?.address);
  }, [MapData]);
  useEffect(() => {
    setTimeout(() => {
      setCurrentLocation();
    }, 1000);
    // getCategories();
  }, []);
  useEffect(() => {
    if (categoryList.length === 0) {
      getCategories();
    }
    dispatch({
      type: GET_APPOINTMENTDETAIL,
      payload: "",
    });
  }, []);

  var url_string = window.location.href; //window.location.href
  var params = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (filter && companies?.length > 0) {
      filterDataByName(filter);
    } else {
      setCompaniesList(companies);
    }
    let servicesArray = [];
    let categoryArray = [];
    if (companies?.length > 0) {
      companies.map((company) => {
        company.services.map((service) => {
          servicesArray.push(service.name);
          return;
        });
        let unique = [...new Set(servicesArray)];
        setServices(unique);
        return;
      });
      companies.map((company) => {
        categoryArray.push(company.category);
      });
      let filterCategory = [];
      categoryList?.map((element) => {
        if (categoryArray.includes(element.category_name))
          filterCategory.push(element);
      });

      let CategoryList = [
        { label: "Select Category", value: "Select Category" },
      ];

      filterCategory?.map((element) => {
        CategoryList.push({
          label: element.category_name,
          value: element.category_name,
        });
      });
      setCategories(CategoryList);
    }
  }, [companies, filter]);

  const onChangeText = (e) => {
    const { value } = e.target;
    setFilter(value);
  };
  const onSearchSubmit = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const { value } = e.target;

      filterDataByName(value);
      history.push({
        pathname: "/find",
        search:
          "?" +
          new URLSearchParams({
            search: value,
            // filter: params.get("filter"),
            cat: params.get("cat"),
            subcategory: params.get("subcategory"),
            lat: mapData.latitude,
            lng: mapData.longitude,
            zoom: mapData.miles,
          }).toString(),
      });
    }
  };

  const handleFilterChange = async (e) => {
    if (e.target.value === "Filter By A Service") {
      setFilterDropDown("");
      history.push({
        pathname: "/find",
        search:
          "?" +
          new URLSearchParams({
            search: params?.get("search") ?? "",
            lat: mapData.latitude,
            lng: mapData.longitude,
            zoom: mapData.miles,
          }).toString(),
      });
      setCompaniesList(companies);
    } else {
      setFilterDropDown(e.target.value);
      history.push({
        pathname: "/find",
        search:
          "?" +
          new URLSearchParams({
            search: params.get("search") ?? "",
            // filter: e.target.value,
            lat: mapData.latitude,
            lng: mapData.longitude,
            zoom: mapData.miles,
          }).toString(),
      });
      const result = await companies.filter((company) =>
        company.services.find((service) =>
          service.name.includes(e.target.value)
        )
      );
      setCompaniesList(result);
    }
  };

  const resetFilter = async (e) => {
    history.push({
      pathname: "/find",
      search:
        "?" +
        new URLSearchParams({
          lat: mapData.latitude,
          lng: mapData.longitude,
          zoom: mapData.miles,
        }).toString(),
    });
    setCategory("Select Category");
    setDropdownList([]);
    setCompaniesList(companies);
    setFilterToggle(false);
    // setServices([]);
    setFilterDropDown("");
    setFilter("");
    let servicesArray = [];
    companies.map((company) => {
      company.services.map((service) => {
        servicesArray.push(service.name);
        return;
      });
      let unique = [...new Set(servicesArray)];
      setServices(unique);
      return;
    });
  };
  // filter records by search text
  const filterDataByName = (value) => {
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === "") {
      setCompaniesList(companies);
      let servicesArray = [];
      companies.map((company) => {
        company.services.map((service) => {
          servicesArray.push(service.name);
          return;
        });
        let unique = [...new Set(servicesArray)];
        setServices(unique);
        return;
      });
    } else {
      // company filter
      const filteredData = companies.filter((item) => {
        return Object.keys(item).some((key) =>
          excludeColumns.includes(key)
            ? false
            : item[key]?.toString().toLowerCase().includes(lowercasedValue)
        );
      });

      // service filter
      const result = companies.filter((company) =>
        company.services.find((service) =>
          service.name?.toString().toLowerCase().includes(lowercasedValue)
        )
      );

      // const uniqueCompany = [...new Set([...filteredData, ...result])];

      const uniqueCompany = [...new Set([...filteredData])];

      setCompaniesList(uniqueCompany);
      let servicesArray = [];
      uniqueCompany.map((company) => {
        company.services.map((service) => {
          servicesArray.push(service.name);
          return;
        });
      });
      let unique = [...new Set(servicesArray)];
      setServices(unique);
      // setCompaniesHoverList(uniqueCompany)
    }
  };

  // company list add hover
  const onHover = (company) => {
    let companyIndex = companiesList.indexOf(company);
    companiesList[companyIndex].isHover = true;
    setCompaniesList([...companiesList]);
  };

  // company list remove hover
  const onLeave = (company) => {
    let companyIndex = companiesList.indexOf(company);
    companiesList[companyIndex].isHover = false;
    setCompaniesList([...companiesList]);
  };

  const clearSearchBar = () => {
    history.push({
      pathname: "/find",
      search:
        "?" +
        new URLSearchParams({
          lat: mapData.latitude,
          lng: mapData.longitude,
          zoom: mapData.miles,
        }).toString(),
    });
    setCompaniesList(companies);
    setFilter("");
  };
  const onCategoryChange = async (values) => {
    let dropdownfield = [{ label: "Select Subcategory", value: "" }];
    categoryList?.map((element) => {
      if (element.category_name === values) {
        element?.sub_category_name.map((value) => {
          dropdownfield.push({
            label: value.text,
            value: value.text,
          });
        });
      }
    });

    history.push({
      pathname: "/find",
      search:
        "?" +
        new URLSearchParams({
          search: params.get("search") ?? "",
          // filter: e.target.value,
          cat: values,
          lat: mapData.latitude,
          lng: mapData.longitude,
          zoom: mapData.miles,
        }).toString(),
    });

    const result = await companies.filter(
      (company) => company.category === values
    );
    setCompaniesList(result);
    setDropdownList(dropdownfield);
    setCategory(values);
    setSubcategory("");
  };

  const onSubcategoryChange = async (values) => {
    setSubcategory(values);
    history.push({
      pathname: "/find",
      search:
        "?" +
        new URLSearchParams({
          search: params.get("search") ?? "",
          // filter: e.target.value,
          cat: params.get("cat"),
          subcategory: values,
          lat: mapData.latitude,
          lng: mapData.longitude,
          zoom: mapData.miles,
        }).toString(),
    });
    const result = await companies.filter(
      (company) => company.subcategory === values
    );

    setCompaniesList(result);
  };

  useEffect(() => {
    if (categoryList?.length) {
      const category = params.get("cat");
      const subcategory = params.get("subcategory");
      if (category !== "null" && category) {
        setCategory(category);
        let dropdownfield = [{ label: "Select Subcategory", value: "" }];
        categoryList?.map((element) => {
          if (element.category_name === category) {
            element?.sub_category_name.map((value) => {
              dropdownfield.push({
                label: value.text,
                value: value.text,
              });
            });
          }
        });
        const result = companies.filter(
          (company) => company.category === category
        );
        setCompaniesList(result);
        setDropdownList(dropdownfield);
      }
      if (subcategory !== "null" && subcategory) {
        setSubcategory(subcategory);

        const result = companies.filter(
          (company) => company.subcategory === subcategory
        );
        setCompaniesList(result);
      }
    }
  }, [categoryList, companies]);

  const filterValue = useMemo(
    () => categories.filter((i) => i.value === category),
    [categories, category]
  );

  const subcategoryValue = useMemo(
    () => dropdownList?.filter((i) => i.value === subcategory),
    [dropdownList, subcategory]
  );
  return (
    <Fragment>
      {isLoading && (
        <div>
          <Spinner />
        </div>
      )}

      <>
        <Helmet>
          <title>Appointment Cake</title>
          <meta
            name="description"
            content="Whatever or whoever you’re looking for,
                   Appointment Cake has you covered."
          />
        </Helmet>
        <div className="h-100 company-listin-outer google_map find-bg">
          <div className="user-icon">
            <i
              onClick={() => history.push("/account")}
              className="fa-solid fa-user d-none"
              style={{ fontSize: "25px" }}
            ></i>
          </div>
          <a
            onClick={() => history.push("/account")}
            className="user-icon desktop-show btn btn-info max_width d-none"
          >
            <i className="fa-solid fa-user"></i> User{" "}
          </a>
          <button
            id="change-log"
            type="button"
            onClick={() => setisTourOpen(true)}
            className="btn btn-info max_width d-none"
          >
            <i className="fas fa-edit"></i> <span> Guide </span>{" "}
          </button>

          <Tour
            steps={steps}
            isOpen={isTourOpen}
            onRequestClose={closeTour}
            onAfterOpen={disableBody}
            onBeforeClose={enableBody}
          />
          <div className="text-orange mt-3 mx-3">
            <h3>Search</h3>
          </div>
          <div
            className="revese-in map_section map_main_container"
            style={{
              paddingBottom: "50px",
            }}
          >
            <div className="col-md-4 bg-light" id="companyListCol">
              {/* {filterToggle ? ( */}
              <div className="row mb-2">
                <Fragment>
                  <div className="search-filter-bar col-lg-9 col-9">
                    <Select
                      options={categories}
                      className="field-type"
                      styles={{
                        menu: (base) => ({ ...base, zIndex: 99999999 }),
                      }}
                      name="category"
                      defaultValue={
                        filterValue.length > 0
                          ? filterValue[0]
                          : [
                              {
                                label: "Select Category",
                                value: "Select Category",
                              },
                            ]
                      }
                      value={
                        filterValue.length > 0
                          ? filterValue[0]
                          : [
                              {
                                label: "Select Category",
                                value: "Select Category",
                              },
                            ]
                      }
                      onChange={(value) => {
                        onCategoryChange(value.value);
                      }}
                    />
                    {/* <div className="input-group">
                    <select
                      className="custom-select"
                      onChange={(e) => handleFilterChange(e)}
                    >
                      <option defaultValue>Filter By A Service</option>
                      {services?.map((service) => {
                        return (
                          <option
                            key={service}
                            value={service}
                            selected={filterDropDown == service}
                          >
                            {service}
                          </option>
                        );
                      })}
                    </select>
                  </div> */}
                  </div>
                  <div className="col-lg-3 col-3 px-1">
                    <button
                      onClick={(e) => resetFilter()}
                      type="button"
                      className="btn btn-primary"
                    >
                      Clear
                    </button>
                  </div>
                  {dropdownList.length > 0 && filterValue.length > 0 && (
                    <div className="search-filter-bar col-lg-9 ">
                      <Select
                        options={dropdownList}
                        className="field-type"
                        styles={{
                          menu: (base) => ({ ...base, zIndex: 99999999 }),
                        }}
                        name="subcategory"
                        defaultValue={subcategoryValue && subcategoryValue[0]}
                        value={subcategoryValue && subcategoryValue[0]}
                        onChange={(value) => {
                          onSubcategoryChange(value.value);
                        }}
                      />
                    </div>
                  )}
                </Fragment>
              </div>
              {/* ) : null} */}
              <div
                className="search-filter-bar mb-2 d-flex justify-content-between"
                data-tut="search_business"
                style={{ position: "relative", paddingBottom: "0px" }}
              >
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Businesses"
                    onChange={(e) => onChangeText(e)}
                    defaultValue={filter}
                    style={{ marginTop: "2px", textOverflow: "ellipsis" }}
                    onKeyDown={(e) => onSearchSubmit(e)}
                  />
                  {/* <form onSubmit={onSearchSubmit}>
                    <input
                      type="text"
                      className="search-input form-control"
                      placeholder="Search Businesses"
                      value={filter}
                      style={{ marginTop: "2px", textOverflow: "ellipsis" }}
                      onChange={(e) => onChange(e)}
                    />
                  </form> */}
                  {/* {filter ? (
                  <i className="fas fa-close" onClick={clearSearchBar}></i>
                ) : (
                  ""
                )} */}
                </div>
              </div>
              <div className="company-list p-3" data-tut="company_list">
                {companiesList?.length > 0 ? (
                  companiesList?.length > 5 ? (
                    companiesList?.map((company, i) =>
                      (i != 0 && i % 5 == 0) || i == 4 ? (
                        i == 5 ? (
                          <CompanyItem
                            key={company._id}
                            company={company}
                            onHover={onHover}
                            onLeave={onLeave}
                            showService={true}
                            claimed={
                              myclaims &&
                              myclaims?.some((element) => {
                                if (element.company == company._id) {
                                  return true;
                                } else {
                                  return false;
                                }
                              })
                            }
                          />
                        ) : i == 4 ? (
                          <>
                            <CompanyItem
                              key={company._id}
                              company={company}
                              onHover={onHover}
                              onLeave={onLeave}
                              showService={true}
                              claimed={
                                myclaims &&
                                myclaims?.some((element) => {
                                  if (element.company == company._id) {
                                    return true;
                                  } else {
                                    return false;
                                  }
                                })
                              }
                            />
                            <GoogleAd
                              slot="8729257860"
                              timeout={1000}
                              classNames="page-bottom"
                            />
                          </>
                        ) : (
                          i != 5 &&
                          i != 4 && (
                            <>
                              <GoogleAd
                                slot="8729257860"
                                timeout={1000}
                                classNames="page-bottom"
                              />

                              <CompanyItem
                                key={company._id}
                                company={company}
                                onHover={onHover}
                                onLeave={onLeave}
                                showService={true}
                                claimed={
                                  myclaims &&
                                  myclaims?.some((element) => {
                                    if (element.company == company._id) {
                                      return true;
                                    } else {
                                      return false;
                                    }
                                  })
                                }
                              />
                            </>
                          )
                        )
                      ) : (
                        <CompanyItem
                          key={company._id}
                          company={company}
                          onHover={onHover}
                          onLeave={onLeave}
                          showService={true}
                          claimed={
                            myclaims &&
                            myclaims?.some((element) => {
                              if (element.company == company._id) {
                                return true;
                              } else {
                                return false;
                              }
                            })
                          }
                        />
                      )
                    )
                  ) : (
                    companiesList?.map((company, i) =>
                      i == companiesList.length - 1 ? (
                        <>
                          <CompanyItem
                            key={company._id}
                            company={company}
                            onHover={onHover}
                            onLeave={onLeave}
                            showService={true}
                            claimed={
                              myclaims &&
                              myclaims?.some((element) => {
                                if (element.company == company._id) {
                                  return true;
                                } else {
                                  return false;
                                }
                              })
                            }
                          />

                          <GoogleAd
                            slot="8729257860"
                            timeout={1000}
                            classNames="page-bottom"
                          />
                        </>
                      ) : (
                        <CompanyItem
                          key={company._id}
                          company={company}
                          onHover={onHover}
                          onLeave={onLeave}
                          showService={true}
                          claimed={
                            myclaims &&
                            myclaims?.some((element) => {
                              if (element.company == company._id) {
                                return true;
                              } else {
                                return false;
                              }
                            })
                          }
                        />
                      )
                    )
                  )
                ) : (
                  <div className="d-flex justify-content-center align-items-center empty-company h-100">
                    {loading ? (
                      <Spinner />
                    ) : (
                      <h3>No services available at this location.</h3>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div
              className="col-md-8 d-flex bg-dark"
              id="mapSearchCol"
              // data-tut="map_container"
            >
              {/* <MapContainer
									mapMarkers={markers}
									newMarker={false}
									currentLat={mapData.latitude}
									currentLng={mapData.longitude}
								/> */}

              <MapComponent
                setIsLoading={setIsLoading}
                mapMarkers={markers}
                newMarker={false}
                currentLat={mapData.latitude}
                currentLng={mapData.longitude}
                companyList={companiesList}
                onHover={onHover}
                onLeave={onLeave}
                defaultAddress={address}
                filter={filterDropDown}
                setFilter={setFilter}
              />
            </div>
          </div>
        </div>
      </>
    </Fragment>
  );
};

const steps = [
  {
    selector: '[data-tut="search_business"]',
    content: `Search bar for the companies`,
  },
  {
    selector: '[data-tut="company_list"]',
    content: `Lists of companies from current location.`,
  },
  {
    selector: '[data-tut="map_search"]',
    content: `You can search for the location in maps from here.`,
  },
  {
    selector: '[data-tut="map_container"]',
    content: `Map that display markers at current location.`,
  },
];

Find.propTypes = {
  getCompanies: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool,
  myclaims: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  company: state.company,
  isAuthenticated: state.auth.isAuthenticated,
  myclaims: state.company.myclaims,
  formfields: state.formfields,
  categoryList: state.company.category,
});

export default connect(mapStateToProps, { getCompanies, getCategories })(Find);
