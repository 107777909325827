import React, { Fragment, useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import MapContainer from "../maps/MapContainer";
import ToolTip from "../layout/ToolTip";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createBusiness, editBusiness } from "../../actions/company";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import DetailTooltip from "../layout/DetailTooltip";
import { useRef } from "react";
import Select from "react-select";
const initialState = {
  name: "",
  website: "",
  description: "",
  street_address: "",
  street_address_2: "",
  city: "",
  province: "",
  country: "",
  postal: "",
  email: "",
  phone: "",
  fax: "",
  lat: "",
  lng: "",
  facebook: "",
  twitter: "",
  linkedin: "",
  instagram: "",
  Sunday_open: false,
  Sunday_start_time: 540,
  Sunday_end_time: 1020,
  Monday_open: false,
  Monday_start_time: 540,
  Monday_end_time: 1020,
  Tuesday_open: false,
  Tuesday_start_time: 540,
  Tuesday_end_time: 1020,
  Wednesday_open: false,
  Wednesday_start_time: 540,
  Wednesday_end_time: 1020,
  Thursday_open: false,
  Thursday_start_time: 540,
  Thursday_end_time: 1020,
  Friday_open: false,
  Friday_start_time: 540,
  Friday_end_time: 1020,
  Saturday_open: false,
  Saturday_start_time: 540,
  Saturday_end_time: 1020,
  markers: [],
  geoLocation: "",
  category: "",
  subcategory: "",
};

const UpdateBusiness = ({ history, editBusiness, comp, categoryList }) => {
  const company = useLocation();
  const [currentCompany, setCurrentCompany] = useState(comp);
  const [formData, setFormData] = useState(initialState);
  const [facebookError, setfacebookError] = useState("");
  const [linkedinError, setlinkedinError] = useState("");
  const [instagramError, setinstagramError] = useState("");
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [isSelected, setIsSelected] = useState({
    category: false,
    subcategory: false,
    location: false,
  });

  const categoryRef = useRef(null);
  const locationRef = useRef(null);
  const d_names = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  useEffect(() => {
    if (currentCompany !== null) {
      const companyData = { ...initialState };
      for (const key in currentCompany) {
        if (key in companyData) companyData[key] = currentCompany[key];
      }

      for (const key in currentCompany?.location) {
        if (key in companyData)
          companyData[key] = currentCompany?.location[key];
      }

      for (const key in currentCompany?.social) {
        if (key in companyData) companyData[key] = currentCompany?.social[key];
      }

      for (const key in currentCompany?.store_hours) {
        companyData[key + "_open"] = currentCompany?.store_hours[key].open;
        companyData[key + "_start_time"] =
          currentCompany?.store_hours[key].start_time;
        companyData[key + "_end_time"] =
          currentCompany?.store_hours[key].end_time;

        companyData["markers"][0] = {
          text: currentCompany?.name,
          lat: companyData["lat"],
          lng: companyData["lng"],
          company: currentCompany,
        };

        companyData["geoLocation"] = {
          type: "Point",
          coordinates: [companyData["lng"] * 1, companyData["lat"] * 1],
        };
      }

      setFormData(companyData);
    }
  }, []);
 
  const {
    name,
    website,
    description,
    street_address,
    street_address_2,
    city,
    province,
    country,
    postal,
    email,
    phone,
    fax,
    lat,
    lng,
    category,
    subcategory,
    facebook,
    instagram,
    linkedin,
    /*Sunday_open,
    Sunday_start_time,
    Sunday_end_time,
    Monday_open,
    Monday_start_time,
    Monday_end_time,
    Tuesday_open,
    Tuesday_start_time,
    Tuesday_end_time,
    Wednesday_open,
    Wednesday_start_time,
    Wednesday_end_time,
    Thursday__open,
    Thursday_start_time,
    Thursday_end_time,
    Friday_open,
    Friday_start_time,
    Friday_end_time,
    Saturday_open,
    Saturday_start_time,
    Saturday_end_time,*/
    markers,
  } = formData;
  let tempData;

  const timeDropDown = () => {
    var hours, minutes, ampm;
    const options = [];
    for (var i = 0; i <= 1440; i += 30) {
      hours = Math.floor(i / 60);
      minutes = i % 60;
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      ampm = hours % 24 < 12 ? "AM" : "PM";
      hours = hours % 12;
      if (hours === 0) {
        hours = 12;
      }
      options.push(
        <option key={i} value={i}>
          {hours + ":" + minutes + " " + ampm}
        </option>
      );
    }
    return options;
  };
  const timeDropDownOptions = timeDropDown();
  function clearNumber(value = "") {
    return value.replace(/\D+/g, "");
  }
  const onChange = (e, lat) => {
    if (e.target.name === "facebook") {
      if (e.target.name === "fax") {
        e.target.value = clearNumber(e.target.value);
      }
      let reference = /^(https?:\/\/)?((w{3}\.)?)facebook.com\/.*/i;
      if (reference.test(e.target.value) || e.target.value === "") {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setfacebookError("");
      } else {
        setfacebookError("This is not a valid facebook link.");
      }
    }

    if (e.target.name === "linkedin") {
      let reference = /^(https?:\/\/)?((w{3}\.)?)linkedin.com\/.*/i;
      if (reference.test(e.target.value) || e.target.value === "") {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setlinkedinError("");
      } else {
        setlinkedinError("This is not a valid linkedin link.");
      }
    }

    if (e.target.name === "instagram") {
      let reference = /^(https?:\/\/)?((w{3}\.)?)instagram.com\/.*/i;
      if (reference.test(e.target.value) || e.target.value === "") {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setinstagramError("");
      } else {
        setinstagramError("This is not a valid instagram link.");
      }
    }

    if (e.target.name === "name") {
      let reference = /^[a-zA-Z\$&+,:;=?@#|'<>.-^*()%!]*$/;
      if (reference.test(e.target.value) || e.target.value === "") {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      }
    } else if (e.target.name === "phone") {
      let reference = /^[0-9\b]+$/;
      if (reference.test(e.target.value) || e.target.value === "") {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      }
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    if (lat != null) {
      setFormData({ ...formData, lat: lat });
    }
  };

  const addressUpdate = (address, latitiude, lng) => {
    tempData = { ...formData };
    setIsSelected({
      ...isSelected,
      location: false,
    });
    setFormData({
      ...formData,
      street_address: address[0] ? `${address[0].long_name}` : "",
      street_address_2: address[1] ? ` ${address[1].long_name}` : "",
      city: address[4] ? `${address[4].long_name}` : "",
      province: address[6] ? `${address[6].short_name}` : "",
      country: address[5] ? `${address[5].long_name}` : "",
      postal: address[8] ? `${address[8].short_name}` : "",
      lat: latitiude,
      lng: lng,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (
      !formData.category ||
      !formData.subcategory ||
      !formData.lat ||
      !formData.lng
    ) {
      formData.lat === "" &&
        formData.lng === "" &&
        locationRef.current.scrollIntoView();
      !formData.subcategory &&
        !formData.category &&
        categoryRef.current.scrollIntoView();
      setIsSelected({
        ...isSelected,
        category: !formData.category ? true : false,
        subcategory: !formData.subcategory ? true : false,
        location: !formData.lat || !formData.lng ? true : false,
      });
    }

    if (
      !formData.category ||
      !formData.subcategory ||
      !formData.lat ||
      !formData.lng
    ) {
      formData.lat === "" &&
        formData.lng === "" &&
        locationRef.current.scrollIntoView();
      !formData.subcategory &&
        !formData.category &&
        categoryRef.current.scrollIntoView();
      setIsSelected({
        ...isSelected,
        category: !formData.category ? true : false,
        subcategory: !formData.subcategory ? true : false,
        location: !formData.lat || !formData.lng ? true : false,
      });
    }

    if (
      !facebookError &&
      !instagramError &&
      !linkedinError &&
      formData.subcategory &&
      formData.category &&
      formData.lat &&
      formData.lng
    ) {
      editBusiness(formData, history, false, currentCompany?._id, true);
    }
  };

  const onCategoryChange = (values) => {
    let dropdownfield = [{ label: "Category", value: "" }];

    categoryList?.map((element) => {
      if (element.category_name === values) {
        element?.sub_category_name.map((value) => {
          dropdownfield.push({
            label: value.text,
            value: value.text,
          });
        });
      }
    });

    setSubCategories(dropdownfield);
    setFormData({
      ...formData,
      category: values,
      subcategory: "",
    });
  };
  let subcategoryValue, filterValue;

  filterValue = categories.filter((i) => i.value === formData?.category);
  if (formData.category) {
    subcategoryValue = subCategories?.filter(
      (i) => i.value === formData?.subcategory
    );
  }

  useEffect(() => {
    let CategoryList = [{ label: "Category", value: "" }];

    categoryList?.map((element) => {
      CategoryList.push({
        label: element.category_name,
        value: element.category_name,
      });
    });

    setCategories(CategoryList);

    let dropdownfield = [{ label: "Category", value: "" }];
    categoryList?.map((element) => {
      if (element.category_name === formData.category) {
        element?.sub_category_name.map((value) => {
          dropdownfield.push({
            label: value.text,
            value: value.text,
          });
        });
      }
    });
    setSubCategories(dropdownfield);

    filterValue = categories?.filter(
      (i) => i.category_name === formData?.category
    );
    if (formData.category) {
      subcategoryValue = subCategories?.filter(
        (i) => i.value === formData?.subcategory
      );
    }
  }, [formData, categoryList]);
  return (
    <div>
      <Helmet>
        <title>Appointment Cake</title>
        <meta
          name="description"
          content="Whatever or whoever you’re looking for,
                   Appointment Cake has you covered."
        />
      </Helmet>
      <form className=" " onSubmit={onSubmit}>
        <section className="white-bg header-bg-edit bottom-wave-edit">
          <div className="text-center d-flex justify-content-center align-items-center registration-page">
            {/* <form className="form col-lg-12" onSubmit={onSubmit}> */}
            <div className="buisness-information-edit">
              <div className="form-heading">
                {/* <div className="logintitle"> */}
                {/* <Link
                to="/superadmin"
                className="btn btn-secondary rounded-circle"
              >
                <i className="fas fa-arrow-left"></i>
              </Link> */}
                <h4 className="logintitle">
                  Business Information
                  <DetailTooltip text="Here you can edit your bussiness" />
                </h4>
                <div className="logintitle">
                  <span>Company Basics</span>
                </div>
                {/* </div> */}
                {/* <div>
                <ToolTip text={"Here you can edit your bussiness"} />
              </div> */}
              </div>
              <div className="form-row form-group first-input">
                <div className="col-md-6">
                  {/* <label htmlFor="company-name">Name</label> */}
                  <input
                    type="text"
                    className="form-control mb-3"
                    name="name"
                    placeholder="Company Name *"
                    id="company-name"
                    required
                    maxLength="50"
                    value={name}
                    onChange={onChange}
                  />
                </div>

                <div className="col-md-6">
                  {/* <label htmlFor="company-website">Website</label> */}
                  <input
                    type="text"
                    className="form-control mb-3"
                    name="website"
                    placeholder="Website"
                    id="company-website"
                    value={website}
                    onChange={onChange}
                  />
                </div>
                <div className="col-md-6">
                  {/* <label htmlFor="company-description">Description</label> */}
                  <input
                    type="text"
                    className="form-control mb-3"
                    name="description"
                    placeholder="Brief Description / tagline"
                    id="company-description"
                    value={description}
                    onChange={onChange}
                  />
                </div>
                <div className="col-md-6">
                  {/* <label htmlFor="company-email">Email</label> */}
                  <input
                    type="email"
                    className="form-control mb-3"
                    name="email"
                    placeholder="Company Email *"
                    id="company-email"
                    required
                    oninvalid="this.setCustomValidity('Please enter email address.')"
                    value={email}
                    onChange={onChange}
                  />
                </div>
                <div className="col-md-6">
                  {/* <label htmlFor="company-phone">Phone</label> */}
                  <input
                    type="tel"
                    className="form-control mb-3"
                    name="phone"
                    placeholder="Business Phone Number *"
                    id="company-phone"
                    required
                    // minLength="10"
                    // maxLength="10"
                    value={phone}
                    onChange={onChange}
                  />
                </div>
                <div className="col-md-6">
                  {/* <label htmlFor="company-fax">Fax</label> */}
                  <input
                    type="text"
                    className="form-control mb-3"
                    name="fax"
                    placeholder="Fax Number"
                    id="company-fax"
                    minLength="10"
                    maxLength="10"
                    value={fax}
                    onChange={onChange}
                  />
                </div>

                <div className="form-heading">
                  <span>Social Media Links</span>
                </div>
                <div className="col-md-6">
                  {/* <label htmlFor="company-fax">FaceBook</label> */}
                  <input
                    type="text"
                    className="form-control mb-3"
                    name="facebook"
                    placeholder="facebook.com/"
                    id="company-fax"
                    defaultValue={currentCompany?.social?.facebook}
                    onChange={onChange}
                  />
                  <p style={{ color: "red" }}>
                    {facebookError ? facebookError : ""}
                  </p>
                </div>
                <div className="col-md-6">
                  {/* <label htmlFor="company-fax">LinkedIn</label> */}
                  <input
                    type="text"
                    className="form-control mb-3"
                    name="linkedin"
                    placeholder="linkedin.com/in/"
                    id="company-fax"
                    defaultValue={currentCompany?.social?.linkedin}
                    onChange={onChange}
                  />
                  <p style={{ color: "red" }}>
                    {linkedinError ? linkedinError : ""}
                  </p>
                </div>
                <div className="col-md-6">
                  {/* <label htmlFor="company-fax">Instagram</label> */}
                  <input
                    type="text"
                    className="form-control mb-3"
                    name="instagram"
                    placeholder="instagram.com/"
                    id="company-fax"
                    defaultValue={currentCompany?.social?.instagram}
                    onChange={onChange}
                  />
                  <p style={{ color: "red" }}>
                    {instagramError ? instagramError : ""}
                  </p>
                </div>
                <div className="col-md-12  row p-0 m-0" ref={categoryRef}>
                  <div className="col-md-6 px-1 ">
                    <div className="form-heading">
                      <span>Category*</span>
                    </div>
                    <Select
                      options={categories}
                      className="field-type"
                      styles={{
                        menu: (base) => ({ ...base, zIndex: 99999999 }),
                      }}
                      name="category"
                      defaultValue={filterValue && filterValue[0]}
                      value={filterValue && filterValue[0]}
                      onChange={(value) => {
                        onCategoryChange(value.value);
                        setIsSelected({
                          ...isSelected,
                          category: false,
                        });
                      }}
                    />
                    {isSelected.category && (
                      <label className="text-danger d-flex">
                        Please select category.
                      </label>
                    )}
                  </div>

                  {subCategories?.length > 0 && (
                    <div className="col-md-6 px-1 ">
                      <div className="form-heading">
                        <span>Subcategory*</span>
                      </div>
                      <Select
                        options={subCategories}
                        className="field-type"
                        styles={{
                          menu: (base) => ({ ...base, zIndex: 99999999 }),
                        }}
                        defaultValue={subcategoryValue && subcategoryValue[0]}
                        value={subcategoryValue && subcategoryValue[0]}
                        onChange={(value) => {
                          setFormData({
                            ...formData,
                            subcategory: value.value,
                          });
                          setIsSelected({
                            ...isSelected,
                            subcategory: false,
                          });
                        }}
                      />
                      {isSelected.subcategory && (
                        <label className="text-danger d-flex">
                          Please select subcategory.
                        </label>
                      )}
                    </div>
                  )}
                </div>
                <div className="form-heading">
                  <h4 className="logintitle margin-bottom mb-1">
                    <span>Location*</span>
                  </h4>
                </div>
                <div className="map-location d-none">
                  <MapContainer
                    mapMarkers={markers}
                    newMarker={false}
                    currentLat={currentCompany?.location.lat}
                    currentLng={currentCompany?.location.lng}
                    addressupdate={addressUpdate}
                    onChange={onChange}
                  />
                  {isSelected.location && (
                    <label className="text-danger d-flex">
                      Please select valid location.
                    </label>
                  )}
                </div>
                <div className="col-md-6">
                  {/* <label htmlFor="company-street_address">Street Address</label> */}
                  <input
                    type="text"
                    className="form-control mb-3"
                    name="street_address"
                    placeholder="Street Address *"
                    id="company-street_address"
                    value={street_address}
                    onChange={onChange}
                  />
                </div>
                <div className="col-md-6">
                  {/* <label htmlFor="company-street_address_2">
                  Street Address 2
                </label> */}
                  <input
                    type="text"
                    className="form-control mb-3"
                    name="street_address_2"
                    placeholder="Street Address2"
                    id="company-street_address_2"
                    value={street_address_2}
                    onChange={onChange}
                  />
                </div>
                <div className="col-md-6">
                  {/* <label htmlFor="company-city">City</label> */}
                  <input
                    type="text"
                    className="form-control mb-3"
                    name="city"
                    placeholder="City *"
                    id="company-city"
                    value={city}
                    onChange={onChange}
                  />
                </div>
                <div className="col-md-6">
                  {/* <label htmlFor="company-province">Province</label> */}
                  <input
                    type="text"
                    className="form-control mb-3"
                    name="province"
                    placeholder="Province *"
                    id="company-province"
                    value={province}
                    onChange={onChange}
                    required
                  />
                </div>
                <div className="col-md-6">
                  {/* <label htmlFor="company-country">Country</label> */}
                  <input
                    type="text"
                    className="form-control mb-3"
                    name="country"
                    placeholder="Company Country *"
                    id="company-country"
                    value={country}
                    onChange={onChange}
                    required
                  />
                </div>
                <div className="col-md-6">
                  {/* <label htmlFor="company-postal">Postal</label> */}
                  <input
                    type="text"
                    className="form-control mb-3"
                    name="postal"
                    placeholder="Postal Code *"
                    id="company-postal"
                    maxLength="50"
                    value={postal}
                    onChange={onChange}
                    required
                  />
                </div>
                <input
                  type="hidden"
                  id="company-lat"
                  value={lat}
                  name="lat"
                  onChange={onChange}
                />
                <input
                  type="hidden"
                  id="company-lng"
                  value={lng}
                  name="lng"
                  onChange={onChange}
                />
              </div>
              <div className="buisness-hours add-business-hours d-none">
                <h4 className="logintitle">
                  Business Hours*
                  <DetailTooltip text="Set Business hours , for your company" />
                </h4>
                {d_names.map((day, i) => {
                  return (
                    <div className="grid-container" key={i}>
                      <label>{day}</label>
                      {/* <td> */}
                      <label className="switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          name={`${day}_open`}
                          id={`${day}_open`}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              [day + "_open"]: !formData[day + "_open"],
                            });
                          }}
                          checked={formData[day + "_open"]}
                        />
                        <span className="slider round"></span>
                        {/* <label
                          className="custom-control-label"
                          htmlFor={`${day}_open`}
                        >
                          Open
                        </label> */}
                      </label>
                      {/* </td> */}
                      <div className="grid-col">
                        <div className="grid-item">
                          <select
                            className="form-control"
                            name={`${day}_start_time`}
                            placeholder="Opens at"
                            onChange={onChange}
                            value={formData[day + "_start_time"]}
                          >
                            {timeDropDownOptions.map((time_option, i) => (
                              <Fragment key={i}>{time_option}</Fragment>
                            ))}
                          </select>
                        </div>
                        <div className="grid-item">
                          <select
                            className="form-control"
                            name={`${day}_end_time`}
                            placeholder="Closes at"
                            onChange={onChange}
                            value={formData[day + "_end_time"]}
                          >
                            {timeDropDownOptions.map((time_option, i) => (
                              <Fragment key={i}>{time_option}</Fragment>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="card-body">
                <button type="submit" className="btn btn-primary">
                  Save Company Info
                </button>
              </div>
            </div>
            {/* <div className="card mb-4">
            <div className="card-header">
              <h4 className="card-title">Office Availability</h4>
              <div>
                <ToolTip text={"Here you can change your office hours"} />
              </div>
            </div>
            <div className="form-group">
              <div className="office-avl-tbl">
                <table className="table table-striped">
                  <tbody>
                    {d_names.map((day, i) => (
                      <tr key={i}>
                        <th>{day}</th>
                        <td>
                          <div className="custom-switch custom-control">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name={`${day}_open`}
                              id={`${day}_open`}
                              onChange={e => {
                                setFormData({
                                  ...formData,
                                  [day + "_open"]: !formData[day + "_open"]
                                });
                              }}
                              checked={formData[day + "_open"]}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={`${day}_open`}
                            >
                              Open
                            </label>
                          </div>
                        </td>
                        <td>
                          <select
                            className="form-control"
                            name={`${day}_start_time`}
                            placeholder="Opens at"
                            onChange={onChange}
                            value={formData[day + "_start_time"]}
                          >
                            {timeDropDownOptions.map((time_option, i) => (
                              <Fragment key={i}>{time_option}</Fragment>
                            ))}
                          </select>
                        </td>
                        <td>
                          <select
                            className="form-control"
                            name={`${day}_end_time`}
                            placeholder="Closes at"
                            onChange={onChange}
                            value={formData[day + "_end_time"]}
                          >
                            {timeDropDownOptions.map((time_option, i) => (
                              <Fragment key={i}>{time_option}</Fragment>
                            ))}
                          </select>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div> */}
            {/* <div className="card-body"> */}

            {/* </div> */}
            {/* </form> */}
          </div>
        </section>
      </form>
    </div>
  );
};

UpdateBusiness.propTypes = {
  editBusiness: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  company: state.company,
  categoryList: state.company.category,
  // markers: state.markers
});

export default connect(mapStateToProps, { editBusiness })(
  withRouter(UpdateBusiness)
); // need withRouter to pass history object
