import React, { Fragment, useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { withRouter, Link, useHistory } from "react-router-dom";
import moment from "moment-timezone";
import { connect } from "react-redux";
import Service from "../company/Service";
import AddBusinessService from "../superAdmin/AddBusinessService";
import { deleteBusinessService, editBusiness } from "../../actions/company";
import Gear from "../../asssets/icons/whitegear.svg";
import Trash from "../../asssets/icons/trash.svg";
import PropTypes from "prop-types";
import {
  addGoogleAuthCode,
  addGoogleAuthCodeBusiness,
  // removeGoogleAuthCodeBusiness
} from "../../actions/auth";
import { useGoogleLogin } from "react-google-login";

const UserServices = ({
  auth,
  deleteBusinessService,
  companyBusiness,
  auth: { user },
  addGoogleAuthCode,
  addGoogleAuthCodeBusiness,
  // removeGoogleAuthCodeBusiness,
  editBusiness,
  companyCurrent,
}) => {
  var GoogleAuth;

  var gapi = window.gapi;
  const CLIENT_ID = process.env.REACT_APP_GOOGLECLIENTID;
  const API_KEY = process.env.REACT_APP_GOOGLEAPIKEY;
  const DISCOVERY_DOCS = [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
  ];
  const SCOPES = "https://www.googleapis.com/auth/calendar.events";
  // const currentCompany = useLocation();
  let history = useHistory();
  const closeRef = useRef(null);
  const [company, setCompany] = useState(companyCurrent);

  const [serviceToDelete, setServiceToDelete] = useState(null);
  const [serviceData, setService] = useState({
    service: {
      _id: null,
      name: null,
      book_online: null,
      book_site: null,
      book_site_link: null,
      call_to_book: null,
      description: null,
      price: null,
      service_duration: 15,
      intakeForm: [],
    },
  });

  const listUpcomingEvents = () => {
    gapi.client.calendar.events
      .list({
        calendarId: "primary",
        timeMin: new Date().toISOString(),
        showDeleted: false,
        singleEvents: true,
        maxResults: 10,
        orderBy: "startTime",
      })
      .then(function (response) {
        var events = response.result.items;
      });
  };

  const clearService = () => {
    const serviceData = {
      service: {
        _id: null,
        name: "",
        book_online: false,
        call_to_book: false,
        book_site: false,
        book_site_link: "",
        description: "",
        price: "",
        service_duration: "",
        intakeForm: [],
      },
    };
    setService(serviceData);
  };
  const d_names = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const date = new Date();
  const today = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    0,
    0,
    0,
    0
  );
  const d_key = d_names[date.getDay()];
  const [selectedService, setSelectedService] = useState();
  const initialState = {
    name: "",
    website: "",
    description: "",
    street_address: "",
    street_address_2: "",
    city: "",
    province: "",
    country: "",
    postal: "",
    email: "",
    phone: "",
    fax: "",
    lat: "",
    lng: "",
    facebook: "",
    twitter: "",
    linkedin: "",
    instagram: "",
    Sunday_open: false,
    Sunday_start_time: 540,
    Sunday_end_time: 1020,
    Monday_open: false,
    Monday_start_time: 540,
    Monday_end_time: 1020,
    Tuesday_open: false,
    Tuesday_start_time: 540,
    Tuesday_end_time: 1020,
    Wednesday_open: false,
    Wednesday_start_time: 540,
    Wednesday_end_time: 1020,
    Thursday_open: false,
    Thursday_start_time: 540,
    Thursday_end_time: 1020,
    Friday_open: false,
    Friday_start_time: 540,
    Friday_end_time: 1020,
    Saturday_open: false,
    Saturday_start_time: 540,
    Saturday_end_time: 1020,
    markers: [],
    geoLocation: "",
  };
  const [companyDetail, setCompanyDetail] = useState(initialState);
  const location = useLocation();

  useEffect(() => {
    setCompany(companyCurrent);
  }, [companyCurrent]);
  useEffect(() => {
    // company
    //   ? companyBusiness &&
    //     company &&
    //     setAllService(companyBusiness?.find((item) => item._id == company?._id))
    //   : history.push("/admin");
  }, [companyBusiness, location]);

  useEffect(() => {
    if (company !== null) {
      const companyData = { ...initialState };
      for (const key in company) {
        if (key in companyData) companyData[key] = company[key];
      }

      for (const key in company.location) {
        if (key in companyData) companyData[key] = company.location[key];
      }

      for (const key in company.social) {
        if (key in companyData) companyData[key] = company.social[key];
      }

      for (const key in company.store_hours) {
        companyData[key + "_open"] = company.store_hours[key].open;
        companyData[key + "_start_time"] = company.store_hours[key].start_time;
        companyData[key + "_end_time"] = company.store_hours[key].end_time;

        companyData["markers"][0] = {
          text: company.name,
          lat: companyData["lat"],
          lng: companyData["lng"],
          company: company,
        };

        companyData["geoLocation"] = {
          type: "Point",
          coordinates: [companyData["lng"] * 1, companyData["lat"] * 1],
        };
      }

      // setFormData(companyData);
      setCompanyDetail(companyData);
    }
  }, []);

  // const onConnect = () => {
  //   gapi.load("client:auth2", () => {
  //     gapi.client.init({
  //       apiKey: API_KEY,
  //       clientId: CLIENT_ID,
  //       discoveryDocs: DISCOVERY_DOCS,
  //       scope: SCOPES
  //     });

  //     gapi.client.init({
  //       apiKey: API_KEY,
  //       clientId: CLIENT_ID,
  //       discoveryDocs: DISCOVERY_DOCS,
  //       scope: SCOPES
  //     });

  //     gapi.client.load("calendar", "v3", () =>
  //       console.log("calendar connected")
  //     );

  //     GoogleAuth = gapi.auth2.getAuthInstance();
  //     GoogleAuth.grantOfflineAccess({
  //       access_type: "offline",
  //       prompt: "consent"
  //     })
  //       .then(authResult => {
  //         if (authResult["code"]) {
  //           let googleLoginUser = gapi.auth2
  //             .getAuthInstance()
  //             .currentUser.get()
  //             .getBasicProfile(true);
  //           // console.log('googleLoginUser',gapi.auth2.currentUser.get().getBasicProfile().getName())
  //           companyDetail.googleRefreshTokenBusiness = authResult["code"];
  //           companyDetail.googleAuthBusiness = gapi.auth2
  //             .getAuthInstance()
  //             .currentUser.get()
  //             .getAuthResponse(true);

  //           companyDetail.googleUserBusiness = {
  //             name: googleLoginUser.getName(),
  //             email: googleLoginUser.getEmail()
  //           };
  //           setCompanyDetail(companyDetail);

  //           editBusiness(companyDetail, history, false, company._id);
  //         }
  //         listUpcomingEvents();
  //       })
  //       .catch(err => {
  //         console.log("err--", err);
  //       });
  //   });
  //   // signIn();
  // };

  const onDisconnect = () => {
    companyDetail.googleRefreshTokenBusiness = null;
    companyDetail.googleAuthBusiness = null;
    companyDetail.googleUserBusiness = {
      name: null,
      email: null,
    };
    setCompanyDetail(companyDetail);
    editBusiness(companyDetail, history, false, company._id, true);
  };

  const clientId = CLIENT_ID;

  const onSuccess = (res) => {
    companyDetail.googleAuthBusiness = res.tokenObj;
    companyDetail.googleRefreshTokenBusiness = "";
    companyDetail.googleUserBusiness = {
      name: res.Du.tf,
      email: res.Du.tv,
    };
    setCompanyDetail(companyDetail);

    editBusiness(companyDetail, history, false, company._id, true);
    listUpcomingEvents();
  };
  const onFailure = (res) => {
    console.log("Login failed: res:", res);
  };
  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
    issignedIn: true,
    accessType: "offline",
    scope:
      "profile email https://www.googleapis.com/auth/admin.directory.resource.calendar",
  });
  return (
    <>
      {company && (
        <>
          {/* <div className="row p-4 business-service-header">
            <div className="px-4">
              {auth.isAuthenticated && auth.user._id && (
                <Link to="/admin" className="btn btn-secondary rounded-circle">
                  <i className="fas fa-arrow-left"></i>
                </Link>
              )}
            </div>
            <div className="col">
              <h3 className="company-title" style={{ fontSize: "20px" }}>
                {company.name}
                {company.social.facebook ? (
                  <a
                    className="marginLeft btn"
                    target="_blank"
                    href={company.social.facebook}
                  >
                    <i className="fab fa-facebook-f text-primary"></i>
                  </a>
                ) : (
                  ""
                )}
                {company.social.instagram ? (
                  <a
                    className="marginLeft btn"
                    target="_blank"
                    href={company.social.instagram}
                  >
                    <i className="fab fa-instagram text-primary"></i>
                  </a>
                ) : (
                  ""
                )}
                {company.social.linkedin ? (
                  <a
                    className="marginLeft btn"
                    target="_blank"
                    href={company.social.linkedin}
                  >
                    <i className="fab fa-linkedin-in text-primary"></i>
                  </a>
                ) : (
                  ""
                )}
              </h3>
              <p>
                {company.description && (
                  <Fragment>{company.description}</Fragment>
                )}
                <br />
                <span className="text-muted">
                  {company.store_hours[d_key].open ? (
                    <Fragment>
                      <span className="text-success">
                        Open{" "}
                        {moment(new Date(today))
                          .add(company.store_hours[d_key].start_time, "minutes")
                          .format("h:mm a")}{" "}
                        -{" "}
                        {moment(new Date(today))
                          .add(company.store_hours[d_key].end_time, "minutes")
                          .format("h:mm a")}
                      </span>
                    </Fragment>
                  ) : (
                    <span className="badge badge-danger">Closed today</span>
                  )}
                </span>{" "}
                | {company.location.street_address} | {company.phone}{" "}
              </p>
            </div>
          </div> */}
          {/* service listing  */}
          <div className="row">
            <div className="col-lg-12">
              {/* calander int */}
              {/* <div className="card mb-4">
                <div className="card-header">
                  <h4 className="card-title">Integrations</h4>
                  <div>
                    <Tooltip
                      text={
                        "Integrate with apps like Google Calender, Calendly to sync up all your systems"
                      }
                    />
                  </div>
                </div>
                <div className="card-body">
                  {company.googleAuthBusiness === undefined ||
                  company.googleAuthBusiness === null ? (
                    <button className="btn btn-primary" onClick={signIn}>
                      Google Calender
                    </button>
                  ) : null}
                  {company.googleAuthBusiness !== undefined &&
                  company.googleAuthBusiness !== null ? (
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item container">
                        <div className="d-flex w-100 justify-content-between row">
                          <div className="col-md-10">
                            <h6>Google Calendar</h6>
                            {company.googleUserBusiness &&
                              company.googleUserBusiness.email &&
                              `Connected user: ${company.googleUserBusiness.email}`}
                          </div>

                          <div
                            className="col-md-10"
                            style={{ marginTop: "10px" }}
                          >
                            <button
                              className="btn btn-warning"
                              style={{ height: "unset" }}
                              onClick={() => {
                                onDisconnect();
                              }}
                            >
                              <i className="fa fa-unlink"></i> Disconnect
                            </button>
                          </div>
                        </div>
                      </li>
                    </ul>
                  ) : null}
                  {/* <button onClick={signIn} className="button">
                    <span className="buttonText">Sign in with Google</span>
                  </button> */}
              <div className="d-flex justify-content-center align-items-center mb-4 flex-column flex-md-row">
                <h1 className="text-center font-bold ">Services</h1>
                <div className="text-white border-r-42 btn-aqua bg-aqua border border-dark font-semibold w-fit py-10 px-20 position-md-absolute right-0 cursor-pointer mr-3">
                  <a
                    style={{ color: "white", textDecoration: "none" }}
                    onClick={() => {
                      history.push({
                        pathname: `/add-service`,
                        state: {
                          company: company,
                        },
                      });
                    }}
                  >
                    Add Service
                  </a>
                </div>
              </div>
              <div id="delete-pop" className="overlay">
                <div className="pop-up w-50 bg-white text-dark text-sm border-r-5 p-3">
                  <a className="close" href="#" ref={closeRef}>
                    &times;
                  </a>
                  <p className="text-center">
                    Are you sure you want to delete this service ?
                  </p>
                  <div className="d-flex w-100 justify-content-center">
                    <a
                      className="pop_cancle btn mar_top btn-primary pop_cancle"
                      href="#"
                      style={{ textDecoration: "none" }}
                    >
                      Cancel
                    </a>

                    <a
                      className="pop_cancle btn mar_top btn-primary pop_cancle"
                      href="#"
                      style={{ textDecoration: "none" }}
                      onClick={() => {
                        deleteBusinessService(serviceToDelete, company._id);
                      }}
                    >
                      Delete
                    </a>
                  </div>
                </div>
              </div>
              {companyCurrent && companyCurrent?.services?.length > 0 ? (
                <>
                  {companyCurrent &&
                    companyCurrent?.services?.map((serv, i) => (
                      <div
                        className="row flex-column border-8 border-grey m-2"
                        key={serv._id}
                      >
                        <div className="row px-4 justify-content-between align-items-center py-3 stack-on-mobile">
                          <div className="d-flex flex-column col-5">
                            <div
                              className="text-sm font-medium"
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                width: "300px",
                              }}
                            >
                              {serv.name}

                            </div>
                            <div
                              className="text-xs font-normal mt-md-2"
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                width: "300px",
                              }}
                            >
                              {serv.description}
                            </div>
                          </div>
                          <div className="d-flex flex-column col-md-2 col-sm-12">
                            <div className="text-sm font-medium mt-2 mt-md-0">Price</div>
                            <div className="text-xs font-normal mt-md-2">
                              $ {parseFloat(serv.price).toFixed(2)}
                            </div>
                          </div>
                          <div className="d-flex flex-column col-md-2 col-sm-12">
                            <div className="text-sm font-medium mt-2 mt-md-0">Time</div>
                            <div className="text-xs font-normal mt-md-2">
                              {serv.service_duration} minutes
                            </div>
                          </div>
                          <div className="d-flex mt-2 mt-md-0 justify-content-start justify-content-sm-end align-items-center col-lg-3 col-md-5 col-sm-12" >
                            <Link
                              to={{
                                pathname: `/edit-service/${serv._id}`,
                                state: {
                                  id: serv._id,
                                  company: company,
                                }
                              }}
                              className="bg-orange btn-orange rounded-r-160 text-xs font-semibold text-white py-10 px-4"
                              style={{
                                textDecoration: "none",
                                display: "inline-flex", 
                                alignItems: "center",
                                width: 'fit-content'
                              }}
                              onClick={() => setSelectedService(serv)}
                            >
                              <img src={Gear} alt="gear" className="mr-2" />
                              Edit Service
                            </Link>

                            <a
                              href="#delete-pop"
                              onClick={() => {
                                setServiceToDelete(serv._id);
                              }}
                              className="cursor-pointer ml-3"
                            >
                              <img src={Trash} alt="Trash" />
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                </>
              ) : (
                <h5 className="text-center">No Services Available!</h5>
              )}

              <div className="add-btn d-none">
                <a href="#add-service">
                  <button>Add +</button>
                </a>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

UserServices.propTypes = {
  addGoogleAuthCode: PropTypes.func.isRequired,
  removeGoogleAuthCode: PropTypes.func,
  auth: PropTypes.object.isRequired,
  // removeGoogleAuthCodeBusiness: PropTypes.func.isRequired,
  addGoogleAuthCodeBusiness: PropTypes.func.isRequired,
  editBusiness: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  companyBusiness: state.company.companylist,
});

export default connect(mapStateToProps, {
  deleteBusinessService,
  addGoogleAuthCode,
  addGoogleAuthCodeBusiness,
  // removeGoogleAuthCodeBusiness,
  editBusiness,
})(withRouter(UserServices));
