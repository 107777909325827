import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect ,dispatch} from "react-redux";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
import { GET_MAPDATA } from "../../actions/types";

/* global google */

class HomeAutocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.autocompleteInput = React.createRef();
    this.autocomplete = null;
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
    this.locateMe = this.locateMe.bind(this);
    this.state = { errorLog: "", address: "" };
    this.onChangeVlaue = this.onChangeVlaue.bind(this);
  }

  options = {
    // restrict your search to a specific type of result
    // types: ['(regions)'],

    // restrict your search to a specific country, or an array of countries
    componentRestrictions: { country: ["ca", "us"] },
  };

  componentDidMount() {
    this.autocomplete = new google.maps.places.Autocomplete(
      this.autocompleteInput.current,
      this.options
    );

    this.autocomplete.addListener("place_changed", this.handlePlaceChanged);
  }

  locateMe() {
    this.props.history.push({
      pathname: "/find",
    });
  }
  async handlePlaceChanged() {
    var place = this.autocomplete.getPlace();
    var lat = place?.geometry?.location.lat();
    var lng = place?.geometry?.location.lng();
   
    if (lat) {
      this.setState({
        errorLog: "",
      });
      const payload = {
        lat: lat,
        lng: lng,
        address: place.formatted_address,
      };
      this.props.getMapData(payload)
      this.props.history.push({
        pathname: "/find",
        state: { detail: { lat, lng }, address: place.formatted_address },
      });
    } else if (lng == undefined) {
      var props = this.props;
      // var error = false;
      var eventThis = this;
      const displaySuggestions = function (predictions, status) {
        if (predictions == null) {
          eventThis.setState({
            errorLog: "Invalid location",
          });
          return false;
        }
        geocodeByPlaceId(predictions[0]?.place_id)
          .then(async (results) => {
            lat = results[0].geometry.location.lat();
            lng = results[0].geometry.location.lng();
            place = results[0].formatted_address;
            props.history.push({
              pathname: "/find",
              state: {
                detail: { lat: lat, lng: lng },
                address: place,
              },
            });
            return true;
          })
          .catch((error) => {
            eventThis.setState({
              errorLog: "Invalid location",
            });

            return false;
          });

        // if (
        //   lat
        // ) {
        //   props.history.push({
        //       pathname: "/find",
        //       state: {
        //         detail: { lat, lng },
        //         address: place,
        //       },
        //     });
        //   return;
        // }

        predictions.forEach((prediction) => {
          const li = document.createElement("li");
          li.appendChild(document.createTextNode(prediction.description));
          document.getElementById("results").appendChild(li);
        });
      };
      const service = new google.maps.places.AutocompleteService();
      let searchValue = this.state.address;
      service.getQueryPredictions({ input: searchValue }, displaySuggestions);
      // this.props.history.push({
      //   pathname: "/find",
      //   state: {
      //     detail: { lat, lng },
      //     address: place,
      //   },
      // });
    }
  }

  onChangeVlaue(e) {
    if (e.target.value == "") {
      this.setState({
        errorLog: "",
      });
    } else {
      this.setState({
        address: e.target.value,
      });
    }
  }

  render() {
    return (
      <div className="search-bar">
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>Locate Me</Tooltip>}
        >
          <a
            onClick={this.locateMe}
            className="locate-btn"
            style={{ cursor: "pointer" }}
          >
            <i className="fa fa-location-arrow" aria-hidden="true"></i>
          </a>
        </OverlayTrigger>
        <input
          style={{ paddingLeft: "50px" }}
          className="search-input form-control"
          ref={this.autocompleteInput}
          id="autocomplete"
          placeholder="Enter your address"
          type="text"
          onChange={this.onChangeVlaue}
        ></input>
        {this.state.errorLog ? (
          <p style={{ color: "red" }}>{this.state.errorLog}</p>
        ) : (
          ""
        )}
        <button onClick={this.handlePlaceChanged}>
          <i className="fas fa-search"></i>
        </button>
      </div>
    );
  }
}

HomeAutocomplete.propTypes = {
 
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => {
  return {
    getMapData: (payload) => dispatch({ type: GET_MAPDATA, payload: payload }),
  };
};
export default connect(mapStateToProps,mapDispatchToProps)(
  HomeAutocomplete
);
