import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { useLocation } from "react-router-dom";
import ReactTooltip from "react-bootstrap/Tooltip";
import Tooltip from "../layout/ToolTip";
import Moment from "react-moment";
import { withRouter, Link, useHistory } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Button from "react-bootstrap/Button";
import { renewPlan } from "../../actions/feature";
import { Helmet } from "react-helmet";

const AdminBusinessInfo = ({
  auth,
  companyBusiness,
  renewPlan,
  CompanyList
}) => {
  const currentCompany = useLocation();
  const [company, setCompany] = useState(currentCompany?.state?.companyDetails);
  // const [featuredList, setfeaturedList] = useState(
  //   currentCompany?.state?.companyDetails?.featuredPlan
  // );

  const FeaturedList = CompanyList.find(obj => obj?._id == company?._id);
  const activePlans = FeaturedList?.featuredPlan.filter(
    element => element.status == "active" || element.status == "pending"
  );

  const d_names = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ];
  const date = new Date();
  const today = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    0,
    0,
    0,
    0
  );
  const d_key = d_names[date.getDay()];

  const AddMonthToolTip = ({ text }) => {
    return (
      <OverlayTrigger
        key={"left"}
        placement={"bottom"}
        overlay={<ReactTooltip id={`tooltip-${"bottom"}`}>{text}</ReactTooltip>}
      >
        <Button variant="outline-secondary">Add Free Month</Button>
      </OverlayTrigger>
    );
  };

  const onFreeMonthSubmit = () => {
    const response = renewPlan({
      amount: 0,
      userId: company?.user?._id,
      companyID: company._id,
      plan: "1m",
      isReferalMonthFromAdmin: true
    });
  };

  const Title = company?.services[0]?.name
    ? `${company?.name} | ${company?.services[0]?.name} | Appointment Cake`
    : `${company?.name} | Appointment Cake`;

  return (
    <>
      <Helmet>
        <title>{Title}</title>
        <meta
          name="description"
          content={
            company?.description
              ? `${company?.description} | ${company?.name} | Appointment Cake`
              : `${company?.name} | Appointment Cake`
          }
        />
      </Helmet>
      <div className="row p-4 business-service-header">
        <div className="px-4">
          {auth.isAuthenticated && auth.user._id && (
            <Link
              to={{
                pathname: "/superadmin",
                state: { CurrentTab: currentCompany?.state?.currentTab }
              }}
              className="btn btn-secondary rounded-circle"
            >
              <i className="fas fa-arrow-left"></i>
            </Link>
          )}
        </div>
        <div className="col">
          <h3 className="company-title" style={{ fontSize: "20px" }}>
            {company.name}
            {company.social.facebook ? (
              <a
                className="marginLeft btn"
                target="_blank"
                href={company.social.facebook}
              >
                <i className="fab fa-facebook-f text-primary"></i>
              </a>
            ) : (
              ""
            )}
            {company.social.instagram ? (
              <a
                className="marginLeft btn"
                target="_blank"
                href={company.social.instagram}
              >
                <i className="fab fa-instagram text-primary"></i>
              </a>
            ) : (
              ""
            )}
            {company.social.linkedin ? (
              <a
                className="marginLeft btn"
                target="_blank"
                href={company.social.linkedin}
              >
                <i className="fab fa-linkedin-in text-primary"></i>
              </a>
            ) : (
              ""
            )}
          </h3>
          <p>
            {company.description && <Fragment>{company.description}</Fragment>}
            <br />
            <span className="text-muted">
              {company.store_hours[d_key].open ? (
                <Fragment>
                  <span className="text-success">
                    Open{" "}
                    {moment(new Date(today))
                      .add(company.store_hours[d_key].start_time, "minutes")
                      .format("h:mm a")}{" "}
                    -{" "}
                    {moment(new Date(today))
                      .add(company.store_hours[d_key].end_time, "minutes")
                      .format("h:mm a")}
                  </span>
                </Fragment>
              ) : (
                <span className="badge badge-danger">Closed today</span>
              )}
            </span>{" "}
            | {company.location.street_address} | {company.phone}{" "}
          </p>
        </div>

        <div className="col business-info">
          <h3 className="company-title" style={{ fontSize: "20px" }}>
            User Information
          </h3>
          <p>
            {company?.description && (
              <Fragment>{`${company?.user?.firstName} ${" "} ${
                company?.user?.lastName
              } `}</Fragment>
            )}
            <br />
            <span className="text-muted">{company?.user?.email}</span>
            <br />
            <span className="text-muted">{company?.user?.phone}</span>
            <br />
            <span className="text-muted">
              <div
                className="header-right"
                style={{ marginTop: "10px", marginLeft: "-2px" }}
                onClick={() => onFreeMonthSubmit()}
              >
                <AddMonthToolTip
                  text={"Free month credit to this business user"}
                />
              </div>
            </span>
          </p>
        </div>

        {/* This is for active plan talbe */}
        <div className="card mb-4 col-12 mt-4">
          <div className="card-header">
            <h4 className="card-title">Active Plans</h4>
            <div className="header-right">
              <Tooltip text={"Active Plans"} />
            </div>
          </div>
          <div className="card-body" style={{ overflowX: "auto" }}>
            <table className="table table-striped featured-table featured_custom">
              <thead>
                <tr>
                  <th>Plan Duration</th>
                  <th>Start</th>
                  <th>End</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {activePlans?.length > 0 ? (
                  activePlans?.map((plan, id) => (
                    <tr className="booking-item-row" id={id}>
                      <td className="booking-date custom_class">
                        {plan.plan_name
                          ? plan.plan_name.includes("m")
                            ? plan.plan_name == "1m"
                              ? plan.plan_name.slice(0, -1).concat(" - Month")
                              : plan.plan_name.slice(0, -1).concat(" - Months")
                            : "1 Year"
                          : "1 Year"}

                        {/* add a status badge here */}
                        {plan.status == "pending" ? (
                          <td className="booking-date badge badge-warning">
                            {plan.status}
                          </td>
                        ) : (
                          <td className="booking-date badge badge-success">
                            {plan.status}
                          </td>
                        )}
                      </td>
                      <td className="booking-date">
                        <Moment format="MMM DD, yyyy" date={plan.start_date} />
                      </td>
                      <td className="booking-date">
                        <Moment format="MMM DD, yyyy" date={plan.end_date} />
                      </td>
                      <td className="booking-date"> ${plan.amount}</td>
                    </tr>
                  ))
                ) : (
                  <tr className="booking-item-row">
                    <td
                      className="booking-date"
                      colSpan="5"
                      style={{ textAlign: "center" }}
                    >
                      No Plans Available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  companyBusiness: state.company,
  CompanyList: state.company.companylist
});

export default connect(
  mapStateToProps,
  { renewPlan }
)(withRouter(AdminBusinessInfo));
