import {
  COMPANY_BOOKINGS,
  GET_LIKED_COMPANIES,
  GET_COMPANY,
  // GET_BUSINESS,
  COMPANY_ERROR,
  CLEAR_COMPANY,
  UPDATE_COMPANY,
  GET_COMPANIES,
  UPDATE_LIKES,
  ADMIN_BUSINESS,
  UPDATE_BSERVICE,
  ADD_BSERVICE,
  DELETE_BSERVICE,
  GET_COMPANY_LIST,
  UPDATE_USERSERVICE,
  GET_COMPANY_LIST_UPDATE,
  UPDATE_NOTIFICATION,
  CLAIM,
  UPDATE_CLAIM,
  DELETE_SASERVICE,
  TCLAIM,
  REMOVE_CLAIM,
  MYCLAIMS,
  ALLADMINBUSINESS,
  CATEGORIES,
  GET_MAPDATA,
  START_LOADING,
  STOP_LOADING,
  PAGINATION_DATA,
  ALLUSER,
  EDITUSER,
  DELETEUSER,
  USERERROR,
} from "../actions/types";

const initialState = {
  company: null,
  companies: [],
  liked: [],
  companybookings: [],
  error: {},
  category: [],
  MapData: {},
  loading: false,
  currentPage: "",
  userlist:[]
};

export default function company(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case PAGINATION_DATA:
      return {
        ...state,
        currentPage: payload,
      };
    case GET_MAPDATA:
      return {
        ...state,
        MapData: payload,
      };
    case GET_COMPANY:
    case UPDATE_COMPANY:
      return {
        ...state,
        company: payload,
        loading: false,
      };
    case GET_COMPANY_LIST:
    case ALLADMINBUSINESS:
      return {
        ...state,
        companylist: payload.data,
        totalUserCompanies: payload.total,
        loading: false,
      };
    case ALLUSER:
      return {
        ...state,
        userlist: payload.data,
        totalUsers: payload.total,
        loading: false,
      };
    case EDITUSER:
      return {
        ...state,
        loading: true,
      };
    case DELETEUSER:
      return {
        ...state,
        loading: true,
      };
      case USERERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case GET_COMPANY_LIST_UPDATE:
      return {
        ...state,
        companylist: state.companylist.map((x, i) =>
          x._id == payload._id ? (x = payload) : (x = x)
        ),
        loading: false,
      };
    case GET_COMPANIES:
      return {
        ...state,
        companies: payload,
        loading: false,
      };
    case GET_LIKED_COMPANIES:
      return {
        ...state,
        liked: payload,
        loading: false,
      };
    case COMPANY_BOOKINGS:
      return {
        ...state,
        companybookings: payload,
        loading: false,
      };
    case COMPANY_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        company: null,
      };
    case CLEAR_COMPANY:
      return {
        ...state,
        company: null,
        companybookings: [],
        loading: true,
      };
    case UPDATE_LIKES:
      return {
        ...state,
        company:
          state.company._id === payload._id
            ? { ...state.company, likes: payload.likes }
            : state.company,
        loading: false,
      };
    case UPDATE_NOTIFICATION:
      return {
        ...state,
        company:
          state.company._id === payload._id
            ? { ...state.company, notified: payload.notified }
            : state.company,
        loading: false,
      };
    case ADMIN_BUSINESS:
      return {
        ...state,
        adminBookings: payload.data,
        totalAdminBusiness: payload.total,
        loading: false,
      };
    case UPDATE_BSERVICE:
      return {
        ...state,
        adminBookings: state.adminBookings.map((x, i) =>
          x._id == payload._id ? (x = payload) : (x = x)
        ),
        loading: false,
      };
    case ADD_BSERVICE:
      return {
        ...state,
        adminBookings: state.adminBookings.map((x, i) =>
          x._id == payload._id ? (x = payload) : (x = x)
        ),
        loading: false,
      };
    case DELETE_BSERVICE:
      return {
        ...state,
        companylist: state.companylist.map((x, i) =>
          x._id == payload._id ? (x = payload) : (x = x)
        ),
        loading: false,
      };

    case DELETE_SASERVICE:
      return {
        ...state,
        adminBookings: state.adminBookings.map((x, i) =>
          x._id == payload._id ? (x = payload) : (x = x)
        ),
        loading: false,
      };
    case UPDATE_USERSERVICE:
      return {
        ...state,
        companylist: state.companylist.map((x, i) =>
          x._id == payload._id ? (x = payload) : (x = x)
        ),
        loading: false,
      };
    case CLAIM:
      return {
        ...state,
        claimsList: payload.data,
        totalClaims: payload.total,
        loading: false,
      };
    case TCLAIM:
      return {
        ...state,
        TransferredClaimList: payload.data,
        totalTransferredClaims: payload.total,
        loading: false,
      };
    case UPDATE_CLAIM:
      return {
        ...state,
        claimsList: state.claimsList.map((x, i) =>
          x._id == payload._id ? (x = payload) : (x = x)
        ),
        loading: false,
      };
    case REMOVE_CLAIM:
      return {
        ...state,
        claimsList: state.claimsList.filter((claim) => claim._id !== payload),
        loading: false,
      };
    case MYCLAIMS:
      return {
        ...state,
        myclaims: payload,
        loading: false,
      };
    case CATEGORIES:
      return {
        ...state,
        category: payload,
      };
    default:
      return state;
  }
}
