import React, { useEffect, useRef, useState } from "react";
import Spinner from "../layout/Spinner";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { allUser, deleteUser, editUser } from "../../actions/company";
import ReactPaginate from "react-paginate";
import { Helmet } from "react-helmet";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Trash from "../../asssets/icons/trash.svg";
import Search from "../../asssets/icons/SearchL.svg";
const AllUser = ({
  getAllUser,
  loading,
  userlist,
  totalUsers,
  DeleteUser,
  EditUser,
}) => {
  const [isSearch, setIsSearch] = useState([]);
  const [currentPage, setcurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [searchString, setIsSearchString] = useState("");
  const [endDate, setEndDate] = useState(null);
  const [date, setDate] = useState(null);
  const [start_Date, setStart_Date] = useState("");
  const [end_Date, setEnd_Date] = useState("");
  const [userToDelete, setUserToDelete] = useState(null);
  const [formData, setFormData] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });

  const closeRef = useRef(null);
  useEffect(() => {
    getAllUser(currentPage, searchString, start_Date, end_Date);
  }, []);
  useEffect(() => {
    setPageCount(Math.ceil(totalUsers / itemsPerPage));
  }, [currentPage, totalUsers]);
  const onSearch = (e) => {
    setcurrentPage(0);

    const searchWord = e.target.value;
    if (searchWord == "") {
      setIsSearch([]);
      setIsSearchString("");
    } else {
      setIsSearch([searchWord]);
      setIsSearchString(searchWord);
    }
  };
  const onSearchSubmit = () => {
    getAllUser(
      currentPage === 0 ? currentPage : currentPage - 1,
      searchString,
      start_Date,
      end_Date
    );
  };

  const clearSearchBar = () => {
    setIsSearch([]);
    setIsSearchString("");
    setcurrentPage(0);
    getAllUser(0, "", start_Date, end_Date);
  };
  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };
  const onDateChange = (dates) => {
    const [start, end] = dates;
    setDate(start);
    setEndDate(end);

    let startDate;
    let endDate;
    startDate = moment(start).format("yyyy-MM-DD");
    endDate = moment(end).format("yyyy-MM-DD");
    if (startDate !== "Invalid date" && endDate !== "Invalid date") {
      setStart_Date(startDate);
      setEnd_Date(endDate);
      getAllUser(
        currentPage === 0 ? currentPage : currentPage - 1,
        searchString,
        startDate,
        endDate
      );
    }
  };
  const clearFilter = () => {
    setDate(null);
    setEndDate(null);
    setcurrentPage(0);
    setEnd_Date("");
    setStart_Date("");
    getAllUser(0, searchString, "", "");
  };
  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setcurrentPage(selectedPage);

    getAllUser(selectedPage, searchString, start_Date, end_Date);
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    EditUser(formData);
    getAllUser(
      currentPage === 0 ? currentPage : currentPage - 1,
      searchString,
      start_Date,
      end_Date
    );
  };

  function clearNumber(value = "") {
    return (value = value.replace(/\D/g, ""));
  }
  const { firstName, lastName, email, phone } = formData;
  const onChange = (e) => {
    if (e.target.name === "phone") {
      e.target.value = clearNumber(e.target.value);
    }
    if (e.target.name === "firstName" || e.target.name === "lastName") {
      let reference = /^[A-Za-z]+$/;
      if (reference.test(e.target.value) || e.target.value === "") {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      }
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };
  const onDelete = (id) => {
    DeleteUser(id);
    getAllUser(
      currentPage === 0 ? currentPage : currentPage - 1,
      searchString,
      start_Date,
      end_Date
    );
  };
  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <div className="row p-3">
          <div id="edit-pop" className="overlay">
            <div className="popup">
              <a className="close" href="#" ref={closeRef}>
                &times;
              </a>
              <h2>Edit User Detail</h2>

              <div>
                <form
                  className="form mt-4"
                  action="create-profile.html"
                  onSubmit={(e) => onSubmit(e)}
                  data-tut="register_detail"
                >
                  <div className="form-row form-group">
                    <label>First Name *</label>
                    <input
                      className="form-control mb-2"
                      type="text"
                      placeholder="First Name *"
                      name="firstName"
                      maxLength="50"
                      value={firstName}
                      onChange={(e) => onChange(e)}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Last Name *</label>
                    <input
                      className="form-control mb-2"
                      type="text"
                      placeholder="Last Name *"
                      name="lastName"
                      maxLength="50"
                      value={lastName}
                      onChange={(e) => onChange(e)}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Email Address *</label>
                    <input
                      className="form-control mb-2"
                      type="email"
                      placeholder="Email Address *"
                      name="email"
                      value={email}
                      disabled
                    />
                  </div>
                  <div className="form-group">
                    <label>Phone Number *</label>
                    <input
                      type="text"
                      className="form-control mb-2"
                      placeholder="Phone Number *"
                      name="phone"
                      pattern=".{0}|.{10,}"
                      // maxLength="10"
                      value={phone}
                      onChange={(e) => onChange(e)}
                      required
                    />
                  </div>

                  <input
                    type="submit"
                    className="btn btn-primary btn-sm py-2 px-4"
                    value="Save"
                    data-tut="register_button"
                  />
                </form>
              </div>
            </div>
          </div>
          <div id="delete-pop" className="overlay">
            <div className="pop-up w-50 bg-white text-dark text-sm border-r-5 p-3">
              <a className="close" href="#" ref={closeRef}>
                &times;
              </a>
              <p className="text-center">
                Are you sure you want to delete this user ?
              </p>
              <div className="d-flex w-100 justify-content-center">
                <a
                  className="pop_cancle btn mar_top btn-primary pop_cancle"
                  href="#"
                  style={{ textDecoration: "none" }}
                >
                  Cancel
                </a>

                <button
                  className="btn mar_top btn-primary pop_cancle"
                  onClick={() => {
                    onDelete(userToDelete);
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
          <Helmet>
            <title>Appointment Cake</title>
            <meta
              name="description"
              content="Whatever or whoever you’re looking for,
                   Appointment Cake has you covered."
            />
          </Helmet>

          <div className="col-md-12 widget-col tab-content" id="accountTabs">
            <div className="row col-md-12 ">
              <div className="search-bar col-md-8">
                <form action="#" onSubmit={onSearchSubmit}>
                  <input
                    className="search-input form-control pl-30"
                    id="autocomplete"
                    placeholder="Search"
                    type="text"
                    value={searchString}
                    onChange={onSearch}
                  ></input>
                </form>
                <div className="search-bar-clear mr-3">
                  <button>
                    {isSearch.length === 0 ? (
                      ""
                    ) : (
                      <i className="fas fa-close mr-4" onClick={clearSearchBar}></i>
                    )}
                  </button>
                </div>

                <button>
                  <img src={Search} alt="Search" onClick={onSearchSubmit} />
                </button>
              </div>
              <div className="datepicker col-md-4 mt-1">
                <div className="relative">
                  <DatePicker
                    name="date"
                    placeholderText="Select Date Range"
                    selected={date}
                    startDate={date}
                    endDate={endDate}
                    onChangeRaw={handleDateChangeRaw}
                    onChange={onDateChange}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    fixedHeight
                    scrollableYearDropdown
                    dateFormat="dd-MM-yyyy"
                    selectsRange
                    customInput={
                      <input
                        value={`${date} - ${endDate}`}
                        className="calendar-input"
                      />
                    }
                  />
                  <button
                    className="calendar-btn "
                    style={{
                      height: "48px",
                      position: "absolute",
                      right: "0%",
                      top: "-7%",
                    }}
                  >
                    {date ? (
                      <i className="fas fa-close" onClick={clearFilter}></i>
                    ) : (
                      <i className="fas fa-calendar"></i>
                    )}
                  </button>
                </div>
              </div>
            </div>

            <div className="fade show">
              <div className=" setting-overflow">
                <div className="appointment-tbl">
                  <table className="table table-striped custom_table">
                    <thead>
                      <tr>
                        <th className="py-10 text-sm font-bold">Name</th>
                        <th className="py-10 text-sm font-bold">Email</th>
                        <th className="py-10 text-sm font-bold">
                          Contact Info
                        </th>
                        <th className="py-10 text-sm font-bold">Date</th>
                        <th className="py-10 text-sm font-bold">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userlist && userlist?.length > 0 ? (
                        userlist?.map((user, i) => (
                          <tr
                            className="booking-item-row"
                            key={i}
                            id={user._id}
                          >
                            <td className="booking-service-name">
                              {user.firstName} {user.lastName}
                            </td>
                            <td className="booking-service-name">
                              {user.email}
                            </td>
                            <td className="user_num">{user.phone}</td>

                            <td className="booking-notes">
                              <a
                                // href={user.website}
                                target="_blank"
                                // className="btn btn-primary"
                              >
                                {/* {moment(user.date).format("DD-MM-yyyy")} */}
                                {moment.utc(user.date).format("DD-MM-yyyy")}
                              </a>
                            </td>
                            <td className="booking-notes">
                              <div className="d-flex align-items-center">
                                <div className="edit-btn mr-2">
                                  <a
                                    href="#edit-pop"
                                    onClick={() =>
                                      setFormData({
                                        id: user._id,
                                        firstName: user.firstName,
                                        lastName: user.lastName,
                                        email: user.email,
                                        phone: user.phone,
                                      })
                                    }
                                  >
                                    <button>Edit</button>
                                  </a>
                                </div>
                                {/* <a
                                  className="btn btn-danger"
                                  href="#delete"
                                  onClick={() => onDelete(user._id)}
                                >
                                  Delete
                                </a> */}
                                <a
                                  href="#delete-pop"
                                  onClick={() => {
                                    setUserToDelete(user._id);
                                  }}
                                  className="cursor-pointer"
                                >
                                  <img
                                    src={Trash}
                                    alt="Trash"
                                    width={24}
                                    height={24}
                                    // onClick={() => onDelete(user._id)}
                                  />
                                </a>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={5} style={{ textAlign: "center" }}>
                            No Data Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {userlist && (
        <div>
          {userlist?.length && !loading ? (
            <>
              <div className="App d-flex justify-content-between align-items-center">
                <ReactPaginate
                  previousLabel={"prev"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  forcePage={currentPage}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
                <div className="mr-4">
                  {currentPage > 0 ? currentPage * 10 - 9 : 1} -{" "}
                  {currentPage > 0
                    ? pageCount === currentPage
                      ? totalUsers
                      : currentPage * 10
                    : 10}{" "}
                  out of {totalUsers}
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};

AllUser.propTypes = {
  auth: PropTypes.object.isRequired,
  userlist: PropTypes.array.isRequired,
  totalUsers: PropTypes.number.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  // Map your action functions to props
  return {
    getAllUser: (page, search, startDate, endDate) =>
      dispatch(allUser(page, search, startDate, endDate)),
    DeleteUser: (id) => dispatch(deleteUser(id)),
    EditUser: (formData) => dispatch(editUser(formData)),
  };
};
const mapStateToProps = (state) => ({
  userlist: state.company.userlist,
  totalUsers: state.company.totalUsers,
  loading: state.company.loading,
});

export default connect(mapStateToProps, mapDispatchToProps)(AllUser);
