import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  getCurrentCompanyList,
  deleteUserBusiness,
} from "../../actions/company";
import PropTypes from "prop-types";
import Spinner from "../layout/Spinner";
import { connect } from "react-redux";
import Pagination from "react-bootstrap/Pagination";
import ToolTip from "../layout/ToolTip";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import UserIcon from "../../images/user-icon.png";
import Gear from "../../asssets/icons/whitegear.svg";
import Search from "../../asssets/icons/SearchL.svg";
import { Helmet } from "react-helmet";
import CreateUserBusiness from "./CreateUserBusiness";
import { getCategories } from "../../actions/company";
import { useLocation } from "react-router-dom";
const AllUserBusinesses = ({
  getCurrentCompanyList,
  companyList,
  totalUserCompanies,
  history,
  deleteUserBusiness,
  User,
  FreeMonths,
  getCategories,
}) => {
  const location = useLocation();
  const [loadingDynamic, setloadingDynamic] = useState(false);
  const [currentPage, setcurrentPage] = useState(0);
  const [show, setShow] = useState(
    location?.state?.show ? location?.state?.show : false
  );
  const [isSearch, setIsSearch] = useState([]);
  const [searchString, setIsSearchString] = useState("");

  var totalAdminBusiness = totalUserCompanies;
  const ToolTipp = ({ text }) => {
    return (
      <OverlayTrigger
        key={"left"}
        placement={"bottom"}
        overlay={<Tooltip id={`tooltip-${"bottom"}`}>{text}</Tooltip>}
      >
        {/* <Button variant="outline-secondary free_month_btn">{`Free Months : ${
          FreeMonths?.free_months || FreeMonths?.free_months == 0
            ? FreeMonths?.free_months
            : User?.free_months
        }`}</Button> */}
        <button
          className="btn-light-orange text-m font-semibold text-orange px-2 px-md-3 py-2"
          // onClick={() => history.push(`/business/service/${comp._id}`)}
        >
          {`Free Months : ${
            FreeMonths?.free_months || FreeMonths?.free_months == 0
              ? FreeMonths?.free_months
              : User?.free_months
          }`}
        </button>
      </OverlayTrigger>
    );
  };
  const getCompanyList = () => {
    setloadingDynamic(true);
    setTimeout(() => {
      setloadingDynamic(false);
    }, 1000);
    getCurrentCompanyList(currentPage, "");
  };
  useEffect(() => {
    getCategories();
    setloadingDynamic(true);
    setTimeout(() => {
      setloadingDynamic(false);
    }, 1000);
    getCurrentCompanyList(currentPage, "");
  }, [currentPage]);

  const onNext = () => {
    setloadingDynamic(true);
    setTimeout(() => {
      setloadingDynamic(false);
    }, 1000);
    window.scrollTo(0, 0);
    setcurrentPage(currentPage + 1);
  };

  const onPrev = () => {
    setloadingDynamic(true);
    setTimeout(() => {
      setloadingDynamic(false);
    }, 1000);
    window.scrollTo(0, 0);
    setcurrentPage(currentPage - 1);
  };

  const onDelete = (id) => {
    deleteUserBusiness(id);
    setloadingDynamic(true);
    setTimeout(() => {
      setloadingDynamic(false);
    }, 1000);
    getCurrentCompanyList(currentPage, "");
  };

  const onSearch = (e) => {
    getCurrentCompanyList(currentPage, e.target.value);
    const searchWord = e.target.value;
    if (searchWord === "") {
      setIsSearch([]);
      setIsSearchString("");
    } else {
      setIsSearch([searchWord]);
      setIsSearchString(searchWord);
    }
  };

  const clearSearchBar = () => {
    setIsSearch([]);
    setIsSearchString("");
    getCurrentCompanyList(currentPage, "");
  };

  const pageNoClick = (i) => {
    setloadingDynamic(true);
    setTimeout(() => {
      setloadingDynamic(false);
    }, 1000);
    window.scrollTo(0, 0);
    setcurrentPage(i);
  };
  return (
    <>
      {loadingDynamic ? (
        <Spinner />
      ) : (
        <div className="find-bg max-1200 mx-auto px-3 px-md-5">
          <Helmet>
            <title>Appointment Cake</title>
            <meta
              name="description"
              content="Whatever or whoever you’re looking for,
                   Appointment Cake has you covered."
            />
          </Helmet>
          <div className="dashboard-header justify-content-center">
            <p className="text-lg w-fit font-weight-bold mb-4 ">
              Business Manager
            </p>
            {/* <img src={LogoImage} alt='' /> */}
            <a
              onClick={() => history.push("/account")}
              className="user-icon desktop-show btn btn-info max_width d-none"
            >
              <i
                className="fa-solid fa-user"
                onClick={() => history.push("/account")}
              ></i>
              User
            </a>
            <div className="col mobile-dashboard-header d-none">
              <a
                onClick={() => {
                  history.push("/account");
                }}
                className="user-icon"
              >
                <img
                  src={UserIcon}
                  alt="User Icon"
                  style={{ marginLeft: "-17px", marginTop: "-11px" }}
                />
              </a>
            </div>
            <div className="d-none">
              <ToolTip text={"A list of Businesses"} />
            </div>
          </div>

          <div className="appointment-tbl mb-5">
            <div className="row flex w-100 justify-content-between align-items-center mx-auto">
              <div>
                <Link
                  // to={`/business`}
                  className="d-none d-lg-inline-block bg-orange btn-orange border border-dark text-m font-semibold text-white border-r-42 px-2 px-md-3 py-2"
                  style={{ textDecoration: "none" }}
                  onClick={() => setShow(true)}
                >
                  Add Business
                </Link>
              </div>

              <div className="search-bar col-md-7 px-0 min-w-678">
                <input
                  className="form-control"
                  id="autocomplete"
                  placeholder="Search Businesses"
                  type="text"
                  value={searchString}
                  style={{ paddingLeft: "30px" }}
                  onChange={onSearch}
                ></input>
                <div className="search-bar-clear ">
                  <button>
                    {isSearch.length === 0 ? (
                      ""
                    ) : (
                      <i
                        className="fas fa-close mr-4"
                        onClick={clearSearchBar}
                      ></i>
                    )}
                  </button>
                </div>
                <button className="bg-aqua">
                  <img src={Search} alt="Search" />
                </button>
              </div>
              <div>
                <Link
                  // to={`/business`}
                  className="mt-3 d-inline-block d-lg-none bg-orange btn-orange border border-dark text-m font-semibold text-white border-r-42 px-2 py-2"
                  style={{ textDecoration: "none" }}
                  onClick={() => setShow(true)}
                >
                  Add Business
                </Link>
              </div>

              <div className="mt-3 mt-md-0">
                <ToolTipp
                  text={"you can use 1 free month once for a single business"}
                />
              </div>
            </div>
            {companyList && companyList?.length > 0 ? (
              companyList.map((comp, i) => {
                return (
                  <div
                    className="row flex-column border-8 border-grey mx-1 mt-4"
                    key={i}
                  >
                    <p className="text-medium bg-aqua text-white px-4 card-head font-semibold m-0 text-center">
                      {comp.name ?? "No Name Available"}
                    </p>
                    <div className="row d-flex px-4 justify-content-between align-items-center py-4">
                      {/* <div className="d-flex flex-column col-sm-3 align-self-start">
                        <div className="text-sm font-medium hide-on-mobile">Name</div>
                        <div
                          className="text-sm text-mobile-sm text font-normal mt-2 text-center text-md-left"
                          style={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {comp.name} 
                          
                        </div>
                      </div> */}
                      <div className="d-flex flex-column col-sm-3 align-self-start">
                        <div className="text-sm  font-medium hide-on-mobile">Address</div>
                        <div className="text-xs font-normal mt-2 text-center text-md-left">
                          {comp?.location?.street_address}
                          {/* {" "} */}
                          {/* {comp?.location?.street_address_2} */}
                          {/* {" "} */}
                          {comp?.location?.street_address_2? `${ " " + comp?.location?.street_address_2}`: ""}
                          {comp?.location?.city
                            ? `${ ", " + comp?.location?.city + ", "}`
                            : ""}
                          {comp?.location?.province
                            ? `${comp?.location?.province}`
                            : ""}
                          {/* {comp?.location?.country} */}
                        </div>
                      </div>
                      <div className="d-flex flex-column col-sm-3 align-self-start">
                        <div className="text-sm font-medium hide-on-mobile" >Phone Number</div>
                        <div className="text-xs font-normal mt-2 text-center text-md-left">
                          {comp?.phone}
                        </div>
                      </div>
                      <div className="col-sm-3 text-right mt-4 mt-md-0">
                        <Link
                           className="bg-orange btn-orange text-xs font-semibold text-white py-2 px-4 rounded-r-160 "
                          to={{
                            pathname: `/business/service/${comp._id}`,
                            state: { companyDetails: comp },
                          }}
                          style={{
                            textDecoration: "none",
                          }}
                        >
                          <img src={Gear} alt="gear" className="mr-2" />{" "}
                          Business Settings
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="text-lg font-bold text-center my-5">No Data!</div>
            )}
            {show ? (
              <CreateUserBusiness
                setShow={setShow}
                getCompanyList={getCompanyList}
              />
            ) : null}
            {companyList && companyList?.length > 10 ? (
              <Pagination style={{ marginTop: "10px", paddingBottom: "50px" }}>
                <Pagination.Prev
                  disabled={currentPage == 0 || companyList?.length == 0}
                  onClick={onPrev}
                />
                {companyList &&
                  [...Array(totalAdminBusiness)].map((data, i) => {
                    if (i < Math.ceil(totalAdminBusiness / 10)) {
                      return (
                        <Pagination.Item
                          key={i}
                          active={currentPage == i}
                          onClick={() => pageNoClick(i)}
                        >
                          {i + 1}
                        </Pagination.Item>
                      );
                    }
                  })}
                <Pagination.Next
                  disabled={
                    currentPage == Math.ceil(totalAdminBusiness / 10) - 1 ||
                    companyList?.length == 0
                  }
                  onClick={onNext}
                />
              </Pagination>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </>
  );
};

AllUserBusinesses.propTypes = {
  getCurrentCompanyList: PropTypes.func.isRequired,
  deleteUserBusiness: PropTypes.func.isRequired,
  companyList: PropTypes.array,
  getCategories: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  company: state.company,
  // markers: state.markers
  companyList: state.company.companylist,
  totalUserCompanies: state.company.totalUserCompanies,
  User: state.auth.user,
  FreeMonths: state.feature.free_months.userFreeMonths,
});

export default connect(mapStateToProps, {
  getCurrentCompanyList,
  deleteUserBusiness,
  getCategories,
})(withRouter(AllUserBusinesses)); // need withRouter to pass history object
