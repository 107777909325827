import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { hideModal } from '../../actions/waitlistModal.js';
import './waitlistModal.css';
import SunIcon from '../../asssets/icons/SunIcon.svg';
import MoonIcon from '../../asssets/icons/MoonIcon.svg';
import RisingSunIcon from '../../asssets/icons/RisingSunIcon.svg';
import SunDarkIcon from '../../asssets/icons/SunDarkIcon.svg';
import MoonDarkIcon from '../../asssets/icons/MoonDarkIcon.svg';
import RisingSunDarkIcon from '../../asssets/icons/RisingSunDarkIcon.svg';
import api from '../../utils/api';
import { sendWaitlistSignupEmails } from '../../actions/booking.js';

const WaitlistModal = ({ show, onHide, currentCompany }) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);

  const initialToggleState = {
    Monday: { morning: false, afternoon: false, evening: false },
    Tuesday: { morning: false, afternoon: false, evening: false },
    Wednesday: { morning: false, afternoon: false, evening: false },
    Thursday: { morning: false, afternoon: false, evening: false },
    Friday: { morning: false, afternoon: false, evening: false },
    Saturday: { morning: false, afternoon: false, evening: false },
    Sunday: { morning: false, afternoon: false, evening: false },
  };

  const [toggleState, setToggleState] = useState(initialToggleState);
  const [duration, setDuration] = useState("2 Weeks");
  const [notifyByText, setNotifyByText] = useState(false);
  const [notifyByEmail, setNotifyByEmail] = useState(false);
  const [existingEntries, setExistingEntries] = useState([]);

  useEffect(() => {
    if (show) {
      fetchExistingWaitlist();
    }
  }, [show]);

  const fetchExistingWaitlist = async () => {
    try {
      const response = await api.get(`/waitlist/user/${user?._id}/company/${currentCompany?._id}`);
      const waitlistEntries = response.data;

      const newToggleState = { ...initialToggleState };
      waitlistEntries.forEach(entry => {
        newToggleState[entry.day][entry.period] = true;
      });

      setToggleState(newToggleState);
      setExistingEntries(waitlistEntries);

      if (waitlistEntries.length > 0) {
        setDuration(waitlistEntries[0].duration);
        setNotifyByText(waitlistEntries[0].preferences.notifyByText);
        setNotifyByEmail(waitlistEntries[0].preferences.notifyByEmail);
      }

    } catch (error) {
      console.error('Error fetching existing waitlist:', error);
    }
  };

  const handleClose = () => {
    dispatch(hideModal());
    onHide();
  };

  const handleToggle = (day, period) => {
    setToggleState((prevState) => ({
      ...prevState,
      [day]: {
        ...prevState[day],
        [period]: !prevState[day][period],
      },
    }));
  };

  const renderToggleButton = (day, period, icon, darkIcon) => {
    return (
      <Button
        variant={toggleState[day][period] ? 'warning' : 'light'}
        onClick={() => handleToggle(day, period)}
        className="toggle-button"
      >
        <img src={toggleState[day][period] ? icon : darkIcon} alt={period} />
      </Button>
    );
  };

  const handleSubmit = async () => {
    const preferredTimes = [];
    const removedTimes = [];
    const existingTimes = existingEntries.map(entry => `${entry.day}-${entry.period}`);

    Object.keys(toggleState).forEach(day => {
      Object.keys(toggleState[day]).forEach(period => {
        if (toggleState[day][period]) {
          if (!existingTimes.includes(`${day}-${period}`)) {
            preferredTimes.push({ day, period });
          }
        } else {
          if (existingTimes.includes(`${day}-${period}`)) {
            removedTimes.push({ day, period });
          }
        }
      });
    });

    const waitlistData = preferredTimes.map(time => ({
      company: currentCompany?._id,
      user: user?._id,
      day: time.day,
      period: time.period,
      duration,
      preferences: { notifyByText, notifyByEmail },
      email: user?.email, 
      phone: user?.phone
    }));

    try {
       // Update existing entries only if preferences have changed
    const updateResponses = await Promise.all(existingEntries.map(async (entry) => {
      if (entry.preferences.notifyByText !== notifyByText || entry.preferences.notifyByEmail !== notifyByEmail) {
        const response = await api.put(`/waitlist/${entry._id}`, {
          preferences: { notifyByText, notifyByEmail },
          email: user?.email,
          phone: user?.phone
        });
        //send user email, user first and last name and company name and compnay email to /booking/waitlist-signup-email
        return response.data;
      }
      return entry;
    }));

      const addResponses = await Promise.all(waitlistData.map(async (entry) => {
        const response = await api.post('/waitlist', entry);
        return response.data;
      }));

    

      const removeResponses = await Promise.all(removedTimes.map(async (time) => {
        const response = await api.delete(`/waitlist/user/${user?._id}/company/${currentCompany?._id}/day/${time.day}/period/${time.period}`);
        return response.data;
      }));


    //     // Send the email with user details and company info
    // await api.post('/booking/waitlist-signup-email', {
    //   firstName: user.firstName,
    //   lastName: user.lastName,
    //   userEmail: user.email,
    //   companyName: currentCompany.name,
    //   companyEmail: currentCompany.email
    // });

     // Dispatch the action to send the email with user details and company info
     sendWaitlistSignupEmails({
      firstName: user?.firstName,
      lastName: user?.lastName,
      userEmail: user?.email,
      companyName: currentCompany?.name,
      companyEmail: currentCompany?.email
    });

      handleClose();

    } catch (error) {
      console.error('Error sending data:', error.response ? error.response.data : error.message);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered className='wlm-container'>
      <Modal.Header closeButton className='wlm-header justify-content-center'>
        <Modal.Title className='wlm-title'>{currentCompany?.name || 'Waitlist Signup'}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='wlm-body'>
        <div className='px-3'>
          <h5 className='text-center'>Preferred time of appointment?</h5>
          <Row className="mb-3 text-center">
            <Col>
              <div className="wlm-time-slot wlm-prefered-time-container">
                <img className='time-icon' src={RisingSunDarkIcon} alt="Morning" />
                12am-12pm
              </div>
            </Col>
            <Col>
              <div className="wlm-time-slot wlm-prefered-time-container">
                <img className='time-icon' src={SunDarkIcon} alt="Afternoon" />
                12pm-5pm
              </div>
            </Col>
            <Col>
              <div className="wlm-time-slot wlm-prefered-time-container">
                <img className='time-icon' src={MoonDarkIcon} alt="Evening" />
                5pm-12am
              </div>
            </Col>
          </Row>
        </div>
        {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => (
          <Row className="bg-white p-1 mb-1 mx-2 align-items-center" key={day}>
            <Col>{day}</Col>
            <Row>
              <Col className="text-center">
                {renderToggleButton(day, 'morning', RisingSunIcon, RisingSunDarkIcon)}
              </Col>
              <Col className="text-center">
                {renderToggleButton(day, 'afternoon', SunIcon, SunDarkIcon)}
              </Col>
              <Col className="text-center">
                {renderToggleButton(day, 'evening', MoonIcon, MoonDarkIcon)}
              </Col>
            </Row>
          </Row>
        ))}
        <Form.Group as={Col} className="mb-3">
          <Form.Label column className='text-center font-weight-bold text-black-100'>
            Stay on the waitlist for:
          </Form.Label>
          <Col>
            <Form.Control
              as="select"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
            >
              <option>1 Week</option>
              <option>2 Weeks</option>
              <option>1 Month</option>
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Col>
            <Form.Check
              type="checkbox"
              label="Text Me"
              checked={notifyByText}
              onChange={(e) => setNotifyByText(e.target.checked)}
            />
          </Col>
          <Col>
            <Form.Check
              type="checkbox"
              label="Email Me"
              checked={notifyByEmail}
              onChange={(e) => setNotifyByEmail(e.target.checked)}
            />
          </Col>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer className='wlm-footer'>
        <Button 
           variant={(!notifyByEmail && !notifyByText) ? "secondary" : "primary"} 
          onClick={handleSubmit} 
          disabled={!notifyByEmail && !notifyByText}
        >
          Save Waitlist
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WaitlistModal;
