import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Button from 'react-bootstrap/Button';

const ToolTip = ({ text }) => {
  return (
    <OverlayTrigger
      key={'left'}
      placement={'bottom'}
      overlay={<Tooltip id={`tooltip-${'bottom'}`}>{text}</Tooltip>}
    >
      <Button variant='outline-secondary'>?</Button>
    </OverlayTrigger>
  );
};

ToolTip.propTypes = {
  text: PropTypes.string.isRequired,
};

export default ToolTip;
