import React, { Fragment, useEffect, useState, useRef } from "react";
import { withRouter, useHistory, useLocation, Link } from "react-router-dom";
import PropTypes from "prop-types";
import Alert from "../../../src/asssets/icons/alertorange.png";
import Save from "../../../src/asssets/icons/savegrey.png";
import { connect, useDispatch } from "react-redux";
import {
  getCurrentCompany,
  getFormFields,
  getCompanyBookings,
  getCategories,
} from "../../actions/company";
import Calendar from "../../asssets/icons/googlecal.png";
import Calandly from "../../asssets/icons/calandly.png";
import JaneApp from "../../asssets/icons/janeapp.png";
import Trash from "../../asssets/icons/trash.svg";
import {
  addGoogleAuthCode,
  removeGoogleAuthCode,
  addGoogleAuthCodeBusiness,
  removeGoogleAuthCodeBusiness,
} from "../../actions/auth";
import Spinner from "../layout/Spinner";
// import CompanyBookings from "../company/CompanyBookings";
import Tooltip from "../layout/ToolTip";
import Tour from "reactour";
import { getMessages } from "../../actions/message";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import EditUserBusiness from "../userBusinesses/EditUserBusiness";
import UserServices from "../userBusinesses/UserServices";
import { editBusiness, deleteUserBusiness } from "../../actions/company";
import { useGoogleLogin } from "react-google-login";
import FeaturedForm from "./FeaturedForm";
import Moment from "react-moment";
// import moment from "moment";
// import { getBookings } from "../../actions/booking";
// import UserIcon from "../../images/user-icon.png";
import PastBookings from "../profile/PastBookings";
import FutureBookings from "../profile/FutureBookings";
import BusinessHours from "../userBusinesses/BusinessHours";
import { Helmet } from "react-helmet";
import Arrow from "../../asssets/icons/keyboard_arrow_down.svg";
import { Dropdown } from "react-bootstrap";
import './admin.css';


const Admin = ({
  getCurrentCompany,
  getFormFields,
  getCategories,
  // getBookings,
  addGoogleAuthCodeBusiness,
  auth: { user },
  booking: { bookings },
  company: { company, companybookings, loading },
  getMessages,
  message: { messages, loading_messages },
  editBusiness,
  companyList,
  getCompanyBookings,
  deleteUserBusiness,
}) => {
  const [serviceData, setService] = useState({
    service: {
      _id: null,
      name: null,
      book_online: null,
      book_site: null,
      book_site_link: null,
      call_to_book: null,
      description: null,
      price: null,
      service_duration: null,
      intakeForm: [],
    },
  });
  const dispatch = useDispatch();
  const currentCompany = useLocation();
  const [onEdit, setOnEdit] = useState(null);
  // const [isConnected, setConnected] = useState(false);
  const { service } = serviceData;
  const setCurrentService = (selectedService) => {
    setService({ service: { ...selectedService } });
  };

  const [currentTab, setCurrentTab] = useState(
    currentCompany?.state?.settingProp
      ? currentCompany?.state?.settingProp
      : "appointments"
  );
  const [companyCal, setCompanyCal] = useState(
    currentCompany?.state?.companyDetails
  );
  const [activePlans, setActivePlans] = useState(
    companyCal?.featuredPlan.filter((element) => element.status == "inactive")
  );
  const [isTourOpen, setisTourOpen] = useState(false);
  const [companyName, setcompanyName] = useState();
  const [showCalandly, setShowCalandly] = useState(false);
  const [showJaneApp, setShowJaneApp] = useState(false);
  const [currentComp, setCurrentComp] = useState({});
  console.log("currentComp", currentComp);
  const closeRef = useRef(null);
  // mobile menu dropdown
  const handleSelect = (eventKey) => {
    setCurrentTab(eventKey);
  };
  const tabLabels = {
    appointments: "Appointments",
    allbsns: "Services",
    businesshours: "Business Hours",
    calendar: "Booking Calendars",
    settings: "Business Information",
    feature: "Feature Listing",
    inactive: "InActive Feature Plans",
    "delete-company": "Delete Business"
  };
  // const [companyDetail, setCompanyDetail] = useState({
  //   firstName: "",
  //   lastName: "",
  //   email: "",
  //   password: "",
  //   password2: ""
  // });
  const closeTour = () => {
    setisTourOpen(false);
  };
  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);
  // calendar
  const initialState = {
    name: "",
    website: "",
    description: "",
    street_address: "",
    street_address_2: "",
    city: "",
    province: "",
    country: "",
    postal: "",
    email: "",
    phone: "",
    fax: "",
    lat: "",
    lng: "",
    facebook: "",
    twitter: "",
    linkedin: "",
    instagram: "",
    Sunday_open: false,
    Sunday_start_time: 540,
    Sunday_end_time: 1020,
    Monday_open: false,
    Monday_start_time: 540,
    Monday_end_time: 1020,
    Tuesday_open: false,
    Tuesday_start_time: 540,
    Tuesday_end_time: 1020,
    Wednesday_open: false,
    Wednesday_start_time: 540,
    Wednesday_end_time: 1020,
    Thursday_open: false,
    Thursday_start_time: 540,
    Thursday_end_time: 1020,
    Friday_open: false,
    Friday_start_time: 540,
    Friday_end_time: 1020,
    Saturday_open: false,
    Saturday_start_time: 540,
    Saturday_end_time: 1020,
    markers: [],
    geoLocation: "",
    calendartype: undefined,
    calendarurl: undefined,
    googleAuthBusiness: {},
    googleUserBusiness: {},
  };
  const [companyDetail, setCompanyDetail] = useState(initialState);
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    // setCompanyDetail({
    //   ...companyDetail,
    //   calendartype: currentCompany?.state?.companyDetails?.calendartype,
    //   calendarurl: currentCompany?.state?.companyDetails?.calendarurl,
    //   googleAuthBusiness:
    //     currentCompany?.state?.companyDetails?.googleAuthBusiness,
    //   googleUserBusiness:
    //     currentCompany?.state?.companyDetails?.googleUserBusiness,
    // });
    setCompanyDetail(company);
    setCurrentComp({
      ...currentComp,
      calendartype: currentCompany?.state?.companyDetails?.calendartype || "",
      calendarurl: currentCompany?.state?.companyDetails?.calendarurl || "",
      street_address:
        currentCompany?.state?.companyDetails?.location?.street_address,
      street_address_2:
        currentCompany?.state?.companyDetails?.location?.street_address_2,
      country: currentCompany?.state?.companyDetails?.location?.country,
      lat: currentCompany?.state?.companyDetails?.location?.lat,
      lng: currentCompany?.state?.companyDetails?.location?.lng,
      city: currentCompany?.state?.companyDetails?.location?.city,
      province: currentCompany?.state?.companyDetails?.location?.province,
      postal: currentCompany?.state?.companyDetails?.location?.postal,
      Sunday_open:
        currentCompany?.state?.companyDetails?.store_hours?.Sunday?.open ||
        false,
      Sunday_start_time:
        currentCompany?.state?.companyDetails?.store_hours?.Sunday
          ?.start_time || 540,
      Sunday_end_time:
        currentCompany?.state?.companyDetails?.store_hours?.Sunday?.end_time ||
        1020,
      Monday_open:
        currentCompany?.state?.companyDetails?.store_hours?.Monday?.open ||
        false,
      Monday_start_time:
        currentCompany?.state?.companyDetails?.store_hours?.Monday
          ?.start_time || 540,
      Monday_end_time:
        currentCompany?.state?.companyDetails?.store_hours?.Monday?.end_time ||
        1020,
      Tuesday_open:
        currentCompany?.state?.companyDetails?.store_hours?.Tuesday?.open ||
        false,
      Tuesday_start_time:
        currentCompany?.state?.companyDetails?.store_hours?.Tuesday
          ?.start_time || 540,
      Tuesday_end_time:
        currentCompany?.state?.companyDetails?.store_hours?.Tuesday?.end_time ||
        1020,
      Wednesday_open:
        currentCompany?.state?.companyDetails?.store_hours?.Wednesday?.open ||
        false,
      Wednesday_start_time:
        currentCompany?.state?.companyDetails?.store_hours?.Wednesday
          ?.start_time || 540,
      Wednesday_end_time:
        currentCompany?.state?.companyDetails?.store_hours?.Wednesday
          ?.end_time || 1020,
      Thursday_open:
        currentCompany?.state?.companyDetails?.store_hours?.Thursday?.open ||
        false,
      Thursday_start_time:
        currentCompany?.state?.companyDetails?.store_hours?.Thursday
          ?.start_time || 540,
      Thursday_end_time:
        currentCompany?.state?.companyDetails?.store_hours?.Thursday
          ?.end_time || 1020,
      Friday_open:
        currentCompany?.state?.companyDetails?.store_hours?.Friday?.open ||
        false,
      Friday_start_time:
        currentCompany?.state?.companyDetails?.store_hours?.Friday
          ?.start_time || 540,
      Friday_end_time:
        currentCompany?.state?.companyDetails?.store_hours?.Friday?.end_time ||
        1020,
      Saturday_open:
        currentCompany?.state?.companyDetails?.store_hours?.Saturday?.open ||
        false,
      Saturday_start_time:
        currentCompany?.state?.companyDetails?.store_hours?.Saturday
          ?.start_time || 540,
      Saturday_end_time:
        currentCompany?.state?.companyDetails?.store_hours?.Saturday
          ?.end_time || 1020,
    });

    if (currentCompany?.state?.companyDetails?.calendartype === "calandly") {
      setShowCalandly(true);
    } else if (
      currentCompany?.state?.companyDetails?.calendartype === "janeapp"
    ) {
      setShowJaneApp(true);
    } else {
      setShowCalandly(false);
      setShowJaneApp(false);
    }
  }, [company]);
  console.log("currentComp =>>>>", currentComp);
  console.log("currentCompany =>>>>", currentCompany);
  // const clearService = () => {
  //   const serviceData = {
  //     service: {
  //       _id: null,
  //       name: "",
  //       book_online: false,
  //       call_to_book: false,
  //       book_site: false,
  //       book_site_link: "",
  //       description: "",
  //       price: "",
  //       service_duration: "",
  //       intakeForm: []
  //     }
  //   };
  //   setService(serviceData);
  // };

  useEffect(() => {
    getCurrentCompany();
    getFormFields();
    getCategories();
    getMessages();
    serviceData["_id"] = service._id;
    serviceData["service_duration"] = service.service_duration;
    //serviceData['service'] = service;
    serviceData["text"] = service.description;
    serviceData["name"] = service.name;
    serviceData["price"] = service.price;
    serviceData["call_to_book"] = service.call_to_book;
    serviceData["book_online"] = service.book_online;
    serviceData["book_site"] = service.book_site;
    serviceData["book_site_link"] = service.book_site_link;
    serviceData["intakeForm"] = service.intakeForm ? service.intakeForm : [];

    setService({ service: serviceData });
  }, [
    getCurrentCompany,
    service._id,
    service.book_online,
    service.book_site,
    service.book_site_link,
    service.call_to_book,
    service.price,
    service.name,
    service.description,
    service.service_duration,
    companyCal,
  ]);
  var gapi = window.gapi;
  /* 
    Update with your own Client Id and Api key 
  */
  var GoogleAuth;
  const CLIENT_ID = process.env.REACT_APP_GOOGLECLIENTID;
  const API_KEY = process.env.REACT_APP_GOOGLEAPIKEY;
  const DISCOVERY_DOCS = [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
  ];
  const SCOPES = "https://www.googleapis.com/auth/calendar.events";
  let history = useHistory();
  const handleClick = () => {
    gapi.load("client:auth2", () => {
      gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      });

      gapi.client.load("calendar", "v3", () =>
        console.log("calendar connected")
      );

      GoogleAuth = gapi.auth2.getAuthInstance();
      GoogleAuth.grantOfflineAccess({
        access_type: "offline",
        prompt: "consent",
      })
        .then((authResult) => {
          if (authResult["code"]) {
            let googleLoginUser = gapi.auth2
              .getAuthInstance()
              .currentUser.get()
              .getBasicProfile(true);
            addGoogleAuthCodeBusiness({
              googleRefreshTokenBusiness: authResult["code"],
              googleAuthBusiness: gapi.auth2
                .getAuthInstance()
                .currentUser.get()
                .getAuthResponse(true),
              googleUserBusiness: {
                name: googleLoginUser.getName(),
                email: googleLoginUser.getEmail(),
              },
              user: user,
            });
          }
          listUpcomingEvents();
        })
        .catch((err) => {
          console.log("err--", err);
        });
    });
  };
  const listUpcomingEvents = () => {
    gapi.client.calendar.events
      .list({
        calendarId: "primary",
        timeMin: new Date().toISOString(),
        showDeleted: false,
        singleEvents: true,
        maxResults: 10,
        orderBy: "startTime",
      })
      .then(function (response) {
        var events = response.result.items;
      });
  };
  // useEffect(() => {
  //   if (companyCal !== null && companyCal !== undefined) {
  //     getCompanyBookings(companyCal?._id);
  //     const companyData = { ...initialState };
  //     for (const key in companyCal) {
  //       if (key in companyData) companyData[key] = companyCal[key];
  //     }

  //     for (const key in companyCal.location) {
  //       if (key in companyData) companyData[key] = companyCal.location[key];
  //     }

  //     for (const key in companyCal.social) {
  //       if (key in companyData) companyData[key] = companyCal.social[key];
  //     }

  //     for (const key in companyCal.store_hours) {
  //       companyData[key + "_open"] = companyCal.store_hours[key].open;
  //       companyData[key + "_start_time"] =
  //         companyCal.store_hours[key].start_time;
  //       companyData[key + "_end_time"] = companyCal.store_hours[key].end_time;

  //       companyData["markers"][0] = {
  //         text: companyCal.name,
  //         lat: companyData["lat"],
  //         lng: companyData["lng"],
  //         company: companyCal,
  //       };

  //       companyData["geoLocation"] = {
  //         type: "Point",
  //         coordinates: [companyData["lng"] * 1, companyData["lat"] * 1],
  //       };
  //     }

  //     // setCompanyDetail(companyData);
  //     setCompanyDetail(companyData);
  //   } else {
  //     history.push("/admin");
  //   }
  // }, []);

  const func = (data) => {
    setcompanyName(data);
  };

  const onDisconnect = () => {
    companyDetail.googleRefreshTokenBusiness = null;
    companyDetail.googleAuthBusiness = null;
    companyDetail.googleUserBusiness = {
      name: null,
      email: null,
    };
    currentComp.googleRefreshTokenBusiness = null;
    currentComp.googleAuthBusiness = null;
    currentComp.googleUserBusiness = {
      name: null,
      email: null,
    };
    setCompanyDetail(companyDetail);
console.log("currentComp =>>> Disconnect", currentComp);
    editBusiness(currentComp, history, false, currentComp._id, true).then(
      (res) => {
        if (res) getCurrentCompany();
      }
    );
  };

  const clientId = CLIENT_ID;

  const onSuccess = (res) => {
    const token = res.tokenObj;
    closeRef.current.click();
    companyDetail.googleAuthBusiness = res.tokenObj;
    companyDetail.googleRefreshTokenBusiness = "";
    companyDetail.googleUserBusiness = {
      name: res.profileObj.name,
      email: res.profileObj.email,
    };
    companyDetail.calendartype = "";
    companyDetail.calendarurl = "";
    currentComp.googleAuthBusiness = res.tokenObj;
    currentComp.googleRefreshTokenBusiness = "";
    currentComp.googleUserBusiness = {
      name: res.profileObj.name,
      email: res.profileObj.email,
    };
    currentComp.calendartype = "";
    currentComp.calendarurl = "";
    editBusiness(currentComp, history, false, currentComp._id, true).then(
      (res) => {
        if (res) getCurrentCompany();
      }
    );
    // listUpcomingEvents();
  };
  const onFailure = (res) => {
    console.log("Login failed: res:", res);
  };

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
    issignedIn: true,
    accessType: "offline",
    scope:
      "profile email https://www.googleapis.com/auth/admin.directory.resource.calendar",
  });

  const d_names = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const timeDropDown = () => {
    var hours, minutes, ampm;
    const options = [];
    for (var i = 0; i <= 1440; i += 30) {
      hours = Math.floor(i / 60);
      minutes = i % 60;
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      ampm = hours % 24 < 12 ? "AM" : "PM";
      hours = hours % 12;
      if (hours === 0) {
        hours = 12;
      }
      options.push(
        <option key={i} value={i}>
          {hours + ":" + minutes + " " + ampm}
        </option>
      );
    }
    return options;
  };

  const timeDropDownOptions = timeDropDown();

  const location = useLocation();

  const Title =
    currentComp?.services && currentComp?.services[0]?.name
      ? `${currentComp?.name} | ${currentComp?.services[0]?.name} | Appointment Cake`
      : `${currentComp?.name} | Appointment Cake`;

  useEffect(() => {
    const cid = history.location.pathname.split("/")[3];
    const company = companyList.filter((e) => e._id === cid);
    setCurrentComp(company[0]);
  }, [history.location.pathname, companyList]);

  const onSelect = (cid) => {
    const company = companyList.filter((e) => e._id === cid);
    setCurrentComp(company[0]);
  };
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const options = companyList;

  const toggleDropdowns = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };
  useEffect(() => {
    setSelectedOption(currentComp?.name);
  }, [currentComp]);
  return loading && company === null ? (
    <Spinner />
  ) : (
    <Fragment>
      <Helmet>
        <title>{Title}</title>
        <meta
          name="description"
          content={
            currentComp?.description
              ? `${currentComp?.description} | ${currentComp?.name} | Appointment Cake`
              : `${currentComp?.name} | Appointment Cake`
          }
        />
      </Helmet>
      {/* <a
        onClick={() => history.push("/account")}
        className="user-icon desktop-show btn btn-info max_width"
      >
        <i className="fa-solid fa-user"></i> User
      </a>
      <button
        id="change-log"
        type="button"
        className="btn btn-info max_width"
        onClick={() => setisTourOpen(true)}
      >
        <i className="fas fa-edit"></i> <span> Guide </span>{" "}
      </button>
      <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={closeTour}
        onAfterOpen={disableBody}
        onBeforeClose={enableBody}
      /> */}
      <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={closeTour}
        onAfterOpen={disableBody}
        onBeforeClose={enableBody}
      />
      <div className="col dashboard-header d-none">
        <a
          onClick={() => history.push("/account")}
          className="user-icon desktop-show btn btn-info max_width "
        >
          <i className="fa-solid fa-user"></i> User
        </a>
        <button
          id="change-log"
          type="button"
          onClick={() => setisTourOpen(true)}
          className="btn btn-info max_width"
        >
          <i className="fas fa-edit"></i> <span> Guide </span>{" "}
        </button>
      </div>
      <div className="buisness-manager-page header-bg white-bg">
        <div className="row p-3 position-relative" style={{zIndex:3000}}>
          <div
            className="col"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="mobile-dahboard-header pl-4 pl-md-4">
              {currentCompany?.state?.page == "Dashboard" ? (
                <Link
                  to={"/dashboard"}
                  className="btn btn-blue rounded-circle mobile-top-arrow d-flex justify-content-center align-items-center"
                >
                  <i className="fas fa-arrow-left"></i>
                </Link>
              ) : (
                <Link
                  to={"/admin"}
                  className="btn btn-blue rounded-circle mobile-top-arrow d-flex justify-content-center align-items-center"
                >
                  <i className="fas fa-arrow-left"></i>
                </Link>
              )}
              <p className="text-medium font-bold ml-2 mb-0 d-none d-md-block">
                Business Manager
                {/* {companyName === undefined
                  ? currentComp?.name
                  : companyName}{" "} */}
              </p>
              <img src={Arrow} alt="arrow" className="mx-2 d-none d-md-block" />

              <select
                className="border-2 border-dark p-2 border-4 w-auto text-m font-bold comp-select d-none"
                onChange={(e) => {
                  onSelect(e.target.value);
                  history.push({
                    pathname: `/business/service/${e.target.value}`,
                  });
                  const company = companyList.filter(
                    (n) => n._id === e.target.value
                  );
                  setCurrentComp(company[0]);
                }}
                onFocus={toggleDropdown}
                onBlur={toggleDropdown}
              >
                {companyList?.map((e, i) => {
                  return (
                    <option
                      value={e?._id}
                      selected={currentComp?._id === e?._id}
                    >
                      {e.name}
                    </option>
                  );
                })}
              </select>

              <div className="custom-select-container">
                <div
                  className={`select-box ${isOpen ? "open" : ""}`}
                  onClick={toggleDropdowns}
                >
                  <div className="selected-option">
                    {selectedOption || "Select an option"}
                  </div>
                  <i class="fas fa-chevron-down arrow text-dark"></i>
                </div>
                {isOpen && (
                  <div className="options-container">
                    {options.map((option, index) => (
                      <div
                        key={index}
                        className={`option ${
                          option.name === selectedOption ? "selected-one" : ""
                        }`}
                        onClick={() => {
                          handleOptionClick(option.name);
                          history.push({
                            pathname: `/business/service/${option._id}`,
                          });
                          const company = companyList.filter(
                            (n) => n._id === option._id
                          );
                          setCurrentComp(company[0]);
                        }}
                      >
                        {option.name}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row p-3 top-gap">
          <div className="col-12">
            <div
              // className="mobile-show mobile-top-header"
               className="d-none d-md-block mobile-top-header"
              style={{ marginLeft: "30px" }}
            >
              <h4>
                {companyName == undefined ? currentComp?.name : companyName}{" "}
              </h4>
              <p>
                <i>
                  {companyCal?.location?.street_address} <br />{" "}
                  {companyCal?.location?.street_address_2}
                </i>
              </p>
            </div>
          </div>
          {/* Mobile menu Dropdown */}
          <div className="mb-3 d-lg-none mobile-menu-wrapper">
    <div className="px-2 mt-1">
      <Dropdown onSelect={handleSelect}>
        <Dropdown.Toggle variant="dark" id="dropdown-basic" className="mobile-dropdown-button">
          {tabLabels[currentTab] || "Menu"}
        </Dropdown.Toggle>

        <Dropdown.Menu className="border border-dark dropdown-menu">
          <Dropdown.Item eventKey="appointments" id="appointment" aria-selected={currentTab === "appointments"}>
            Appointments
          </Dropdown.Item>
          <Dropdown.Item eventKey="allbsns" id="allbsns" aria-selected={currentTab === "allbsns"}>
            Services
          </Dropdown.Item>
          <Dropdown.Item eventKey="businesshours" id="businesshours" className="d-none" aria-selected={currentTab === "businesshours"}>
            Business Hours
          </Dropdown.Item>
          <Dropdown.Item eventKey="calendar" id="calendar" aria-selected={currentTab === "calendar"}>
            Booking Calendars
          </Dropdown.Item>
          <Dropdown.Item eventKey="settings" id="setting" aria-selected={currentTab === "settings"}>
            Business Information
          </Dropdown.Item>
          <Dropdown.Item eventKey="feature" id="featureListing" aria-selected={currentTab === "feature"}>
            Feature Listing
          </Dropdown.Item>
          <Dropdown.Item eventKey="inactive" id="InactiveFeaturePlans" className="d-none" aria-selected={currentTab === "inactive"}>
            InActive Feature Plans
          </Dropdown.Item>
          <Dropdown.Item eventKey="delete-company" id="InactiveFeaturePlans">
            Delete Business
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  </div>
          <div className="col-md-3 mb-3 d-none d-lg-block">
            <div className="pr-4" style={{ position: "sticky", top: "1em" }}>
              <div
                className="list-group list-group-flush  border border-dark"
                role="tablist"
              >
                <a
                  href="#appointments"
                  id="appointment"
                  data-toggle="pill"
                  role="tab"
                  className={
                    currentTab === "appointments"
                      ? " list-group-item active"
                      : " list-group-item "
                  }
                  data-tut="appointments"
                  onClick={() => setCurrentTab("appointments")}
                >
                  Appointments
                </a>
                <a
                  href="#allbsns"
                  id="allbsns"
                  data-toggle="pill"
                  role="tab"
                  className={
                    currentTab === "allbsns"
                      ? " list-group-item active"
                      : " list-group-item "
                  }
                  // className={
                  //   currentCompany?.state?.settingProp !== "settings"
                  //     ? "list-group-item "
                  //     : "list-group-item"
                  // }
                  data-tut="services"
                  onClick={() => setCurrentTab("allbsns")}
                >
                  Services
                </a>
                <a
                  href="#businesshours"
                  id="businesshours"
                  data-toggle="pill"
                  role="tab"
                  className="list-group-item d-none"
                  data-tut="business-hours"
                  onClick={() => setCurrentTab("businesshours")}
                >
                  Business Hours
                </a>

                <a
                  href="#calendar"
                  id="calendar"
                  data-toggle="pill"
                  role="tab"
                  className="list-group-item"
                  data-tut="calendar"
                  onClick={() => setCurrentTab("calendar")}
                >
                  Booking Calendars
                </a>
                <a
                  href="#settings"
                  id="setting"
                  data-toggle="pill"
                  role="tab"
                  className={
                    currentTab == "settings"
                      ? "list-group-item active"
                      : "list-group-item"
                  }
                  data-tut="settings"
                  onClick={() => setCurrentTab("settings")}
                >
                  Business Information
                </a>
                <a
                  href="#feature"
                  id="featureListing"
                  data-toggle="pill"
                  role="tab"
                  className="list-group-item"
                  data-tut="feature"
                  onClick={() => setCurrentTab("feature")}
                >
                  Feature Listing
                </a>
                <a
                  href="#inactive"
                  id="InactiveFeaturePlans"
                  data-toggle="pill"
                  role="tab"
                  className="list-group-item d-none"
                  data-tut="inactive"
                  onClick={() => setCurrentTab("inactive")}
                >
                  InActive Feature Plans
                </a>
                <a
                  href="#inactive"
                  id="InactiveFeaturePlans"
                  data-toggle="pill"
                  role="tab"
                  className="list-group-item"
                  data-tut="delete-business"
                  onClick={() => setCurrentTab("delete-company")}
                >
                  Delete Business
                </a>
              </div>
            </div>
          </div>
          <div
            className="col-md-9 widget-col calender-tab tab-content max-1200"
            id="accountTabs"
          >
            {currentTab == "allbsns" && (
              <div
                className={
                  company !== null
                    ? "tab-pane fade show active"
                    : "tab-pane fade show"
                }
                id="allbsns1"
                role="tabpanel"
              >
                {currentComp ? (
                  <UserServices
                    companyCurrent={currentComp}
                    setCurrentTab={setCurrentTab}
                  />
                ) : (
                  ""
                )}
              </div>
            )}
           

           {currentTab == "calendar" && (
    <div className="mb-4">
        <div className="">
            <div className="">
                <p className="text-lg w-fit font-weight-bold mb-0 mx-auto mb-10">
                    Booking Calendars
                </p>
                <p className="text-medium w-fit font-medium mb-0 mx-auto mb-1">
                    Connect your calendar to have your schedules booked in no
                    time!
                </p>
                <p className="w-fit font-medium mb-0 mx-auto mb-1">
                    Only 1 calendar integration is permitted at one time to
                    avoid overbooking
                </p>
            </div>
            <div className="card-body d-none">
                <ul className="list-group list-group-flush">
                    <li className="list-group-item p-0">
                        <div className="google_calender">
                            <p>
                                {currentComp &&
                                currentComp?.googleUserBusiness?.email !== undefined &&
                                currentComp?.googleUserBusiness?.email !== null
                                    ? "Google Calendar"
                                    : "No integrations"}
                            </p>
                            {(currentComp &&
                                currentComp?.googleUserBusiness?.email !== undefined &&
                                currentComp?.googleUserBusiness?.email !== null) ? (
                                <div className="plusbtnclass">
                                    <a href="#calendar-pop">
                                        <i
                                            className="fa-solid fa-pen"
                                            style={{
                                                fontSize: "15px",
                                                cursor: "pointer",
                                            }}
                                        ></i>
                                    </a>
                                </div>
                            ) : null}
                        </div>
                        {currentComp &&
                        currentComp?.googleUserBusiness?.email !== undefined &&
                        currentComp?.googleUserBusiness?.email !== null ? (
                            <h className="calendar-email">
                                ({currentComp?.googleUserBusiness?.email})
                            </h>
                        ) : (
                            ""
                        )}
                    </li>
                </ul>
            </div>
        </div>
        <div className="d-flex mt-4 justify-content-center flex-column flex-md-row align-items-center">
            <div className="border border-dark p-4 d-flex w-fit border-8">
                <img src={Calendar} alt="Calendar" width={60} height={60} />
                <div className="d-flex flex-column ml-3">
                    <p className="text-sm font-medium mb-1">
                        Google Calendar
                    </p>
                    {currentComp?.googleUserBusiness?.email !== undefined &&
                    currentComp?.googleUserBusiness?.email !== null ? (
                        <div className="d-flex mr-1">
                            <p>{currentComp?.googleUserBusiness?.email}</p>
                            <img
                                src={Trash}
                                alt="Trash"
                                width={24}
                                height={24}
                                className="cursor-pointer"
                                onClick={() => {
                                    onDisconnect();
                                }}
                            />
                        </div>
                    ) : (
                        <input
                            type="submit"
                            className="btn bg-orange text-white text-xs font-semibold py-0 px-5"
                            value="Connect"
                            data-toggle={`${showModal ? "modal" : undefined}`}
                            data-target="#myModal"
                            onClick={() => {
                                if (
                                    currentComp?.googleUserBusiness?.email
                                ) {
                                    setShowModal(true);
                                } else {
                    
                                    signIn();
                                }
                            }}
                        />
                    )}
                </div>
            </div>
        </div>

        <div
            className="modal fade"
            id="myModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div
                className="modal-dialog modal-dialog-centered"
                role="document"
            >
                <div className="modal-content">
                    <div className="modal-body d-flex flex-column justify-content-center align-items-center">
                        <img src={Alert} alt="Alert" width={85} height={85} />
                        <div className="text-xxl font-bold ">Are you sure?</div>
                        <div className="text-xl font-normal mt-2 w-75 text-center">
                            Linking a new calendar will remove the current
                            calendar
                        </div>
                    </div>
                    <div className="d-flex justify-content-center gap-20 mb-4">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                            onClick={() => {
                                setShowModal(false);
                                currentComp.googleRefreshTokenBusiness = null;
                                currentComp.googleAuthBusiness = null;
                                currentComp.googleUserBusiness = {
                                    name: null,
                                    email: null,
                                };
                                currentComp.calendartype = "";
                                currentComp.calendarurl = "";
                                editBusiness(
                                    currentComp,
                                    history,
                                    false,
                                    currentComp._id,
                                    true
                                ).then((res) => {
                                    if (res) getCurrentCompany();
                                });
                            }}
                        >
                            Replace calendar
                        </button>
                        <button
                            type="button"
                            data-dismiss="modal"
                            className="bg-red btn text-white"
                            onClick={() => setShowModal(false)}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div id="calendar-pop" className="overlay">
            <div className="popup">
                <a className="close" href="#" ref={closeRef}>
                    &times;
                </a>
                <h2>Sync Calendar</h2>

                <div className="calender_pop_btn_container align-items-center">
                    <input
                        type="submit"
                        className="btn btn-primary btn-lg create-service-btn"
                        value="Google Calendar"
                        data-tut="register_button"
                        onClick={() => {
                            setCompanyDetail({
                                ...companyDetail,
                                calendartype: undefined,
                                calendarurl: undefined,
                            });
                            setCurrentComp({
                                ...currentComp,
                                calendartype: undefined,
                                calendarurl: undefined,
                            });
                            signIn();
                            setShowCalandly(false);
                            setShowJaneApp(false);
                        }}
                        disabled={currentComp?.googleUserBusiness?.email}
                    />
                    {currentComp &&
                        currentComp?.googleUserBusiness?.email !== undefined &&
                        currentComp?.googleUserBusiness?.email !== null && (
                            <div className="d-flex align-items-center mt-2">
                                <div className="mr-1">
                                    ({currentComp?.googleUserBusiness?.email})sssss
                                </div>

                                <i
                                    className="fa-solid fa-close ml-5"
                                    style={{
                                        fontSize: "15px",
                                        cursor: "pointer",
                                        color: "red",
                                    }}
                                    onClick={() => {
                                        onDisconnect();
                                    }}
                                ></i>
                            </div>
                        )}
                </div>
            </div>
        </div>

        <div className="mt-4 text-right d-none">
            <a href="#calendar-pop">
                <button
                    style={{
                        border: "none",
                        background:
                            companyDetail?.calendarurl !== undefined &&
                            companyDetail?.calendarurl !== null &&
                            currentComp?.googleUserBusiness?.email !== null
                                ? "#808080"
                                : "#25d2c3",
                        borderRadius: " 10px",
                        color: "#fff",
                        padding: "10.5px 28.5px",
                    }}
                    disabled={
                        companyDetail?.calendarurl !== undefined &&
                        companyDetail?.calendarurl !== null &&
                        currentComp?.googleUserBusiness?.email !== null
                    }
                >
                    Add +
                </button>
            </a>
        </div>
    </div>
)}

           
           
            {currentTab == "appointments" && (
              <div
                className={
                  company !== null
                    ? "tab-pane fade show active"
                    : "tab-pane fade show"
                }
                id="appointments"
                role="tabpanel"
              >
                {/* <div className="card"> */}
                {/* <div className="card-header">
                    <h4 className="card-title">Appointments</h4>
                    <div>
                      <Tooltip text={"A list of your appoinments"} />
                    </div>
                  </div> */}
                <FutureBookings tab='appointments'/>
                <PastBookings tab="appointments" />

                {/* <div className="appointment-tbl buisness-table"> */}
                {/* <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Service</th>
                          <th>Client</th>
                          <th>Messages</th>
                        </tr>
                      </thead>
                      <tbody>{company !== null && <CompanyBookings />}</tbody>
                    </table> */}
                {/* <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Service</th>
                          <th>Client</th>
                        </tr>
                      </thead>

                      <tbody>
                        {companybookings &&
                          companybookings.length > 0 &&
                          companybookings.map(booking => (
                            <tr>
                              <td>
                                <Moment
                                  format="ddd, MMM Do"
                                  date={booking.start_date}
                                />
                              </td>
                              <td>
                                <span> */}
                {/* {currentComp.services.map(ser => ser.name)} */}
                {/* {booking.service?.name}
                                </span>
                              </td>
                              <td>
                                <i
                                  className="fa-regular fa-memo-circle-info"
                                  onClick={() => {
                                    history.push(`/bookings/${booking?._id}`);
                                  }}
                                ></i>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>*/}
              </div>
            )}
            {currentTab == "settings" && (
              <div
                className={
                  company !== null
                    ? "tab-pane fade show active"
                    : "tab-pane fade show"
                }
                id="settings"
                role="tabpanel"
              >
                {companyCal ? (
                  <EditUserBusiness
                    companyCurrent={companyCal}
                    setCompany={func}
                  />
                ) : (
                  ""
                )}
              </div>
            )}
            {currentTab == "feature" && (
              <div
                className={
                  company !== null
                    ? "tab-pane fade show active"
                    : "tab-pane fade show"
                }
                id="feature1"
                role="tabpanel"
              >
                {companyCal ? <FeaturedForm company={companyCal} /> : ""}
              </div>
            )}
            {currentTab == "inactive" && (
              // {/* inactive plans */}
              <div className="feature-listing inActive_plane">
                <div>
                  <p className="text-lg w-fit font-weight-bold mb-0 mx-auto mb-4">
                    InActive Plans
                  </p>
                  <div
                    className="card-body p-0 border border-r-5"
                    style={{ overflowX: "auto" }}
                  >
                    <table className="table table-striped featured-table mb-0 py-10">
                      <thead>
                        <tr>
                          <th className="text-sm font-medium px-10 h-fit pt-10 pb-0">
                            Plan Duration
                          </th>
                          <th className="text-sm font-medium px-10 h-fit pt-10 pb-0">
                            Start
                          </th>
                          <th className="text-sm font-medium px-10 h-fit pt-10 pb-0">
                            End
                          </th>
                          <th className="text-sm font-medium px-10 h-fit pt-10 pb-0">
                            Amount
                          </th>
                        </tr>
                      </thead>
                      {/* <th>Status</th> */}

                      <tbody>
                        {activePlans?.length !== 0 ? (
                          activePlans?.map((plan, id) => (
                            <tr className="booking-item-row" id={id} key={id}>
                              <td className="text-xs font-normal px-10 pb-10 pt-1">
                                {plan.plan_name
                                  ? plan.plan_name.includes("m")
                                    ? plan.plan_name
                                        .slice(0, -1)
                                        .concat(" Month")
                                    : "1 Year"
                                  : "1 Year"}
                              </td>
                              <td className="text-xs font-normal px-10 pb-10 pt-1">
                                <Moment
                                  format="Do MMM yyyy"
                                  date={plan.start_date}
                                />
                              </td>
                              <td className="text-xs font-normal px-10 pb-10 pt-1">
                                <Moment
                                  format="Do MMM yyyy"
                                  date={plan.end_date}
                                />
                              </td>

                              <td className="text-xs font-normal px-10 pb-10 pt-1">
                                ${plan.amount}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr className="booking-item-row">
                            <td
                              className="text-center w-100 text-sm"
                              colSpan={4}
                            >
                              No Inactive Plan
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
            {currentTab == "businesshours" && (
              <div
                className={
                  company !== null
                    ? "tab-pane fade show active"
                    : "tab-pane fade show"
                }
                id="settings"
                role="tabpanel"
              >
                {companyCal ? (
                  <BusinessHours
                    companyCurrent={companyCal}
                    setCompany={func}
                  />
                ) : (
                  ""
                )}
              </div>
            )}
            {currentTab == "delete-company" && (
              <div
                className="tab-pane fade show active"
                id="delete-account"
                role="tabpanel"
              >
                <div className="" id="custom_header">
                  <p className="text-lg w-fit font-weight-bold mb-0 mx-auto mb-4">
                    Delete Business
                  </p>
                  <div>
                    <p className="text-medium font-medium text-center mb-4">
                      This will delete your company and all data associated with
                      it. This cannot be undone.
                    </p>
                    <a
                      className="btn-red p-10 font-semibold text-red mx-auto d-flex w-25 justify-content-center"
                      style={{ textDecoration: "none" }}
                      href="#delete"
                    >
                      Delete Business
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* delete popup code */}
      <div id="delete" className="overlay delete_pop_overlay">
        <div id="pop_martop" className="popup popup_custom">
          <a className="close" href="#" ref={closeRef}>
            &times;
          </a>
          <p className="text-center">
            Are you sure you want to delete <br />
            your Business ?
          </p>
          <div className="d-flex justify-content-center">
            <a
              className="pop_cancle btn-light-aqua"
              href="#"
              style={{ textDecoration: "none" }}
            >
              <i className="fa-solid"></i> Cancel
            </a>

            <button
              className="btn mar_top btn-primary pop_cancle"
              onClick={() => {
                deleteUserBusiness(companyCal._id, history);
                closeRef.current.click();
              }}
            >
              <i className="fa-solid"></i> Delete
            </button>
          </div>
        </div>
      </div>
      {/* delete popup code */}
    </Fragment>
  );
};

const steps = [
  {
    selector: '[data-tut="appointments"]',
    content: `By selecting Appointments tab, one can see all the list of appointments booked.`,
  },
  {
    selector: '[data-tut="services"]',
    content: `By selecting Services tab, You can get the list of all the services created by you and also you can add or edit it.`,
  },
  {
    selector: '[data-tut="business-hours"]',
    content: `By selecting Business Hours tab, You can edit the business hours.`,
  },
  {
    selector: '[data-tut="calendar"]',
    content: `By selecting Calendar Integration tab, you can connect the calendar to your business.`,
  },
  {
    selector: '[data-tut="settings"]',
    content: `By selecting Business Information tab, one edit the information about your business.`,
  },
  {
    selector: '[data-tut="feature"]',
    content: `By selecting Feature Listing tab, you can purchase new plan.`,
  },
  {
    selector: '[data-tut="inactive"]',
    content: `By selecting InActive Feature Plans tab, you can see your inactive plans.`,
  },
  {
    selector: '[data-tut="delete-business"]',
    content: `By selecting Delete Business, you can delete your business.`,
  },
];

Admin.protoTypes = {
  getCurrentCompany: PropTypes.func.isRequired,
  getFormFields: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  service: PropTypes.object,
  form_fields: PropTypes.object,
  auth: PropTypes.object.isRequired,
  serviceData: PropTypes.object,
  addGoogleAuthCode: PropTypes.func.isRequired,
  removeGoogleAuthCode: PropTypes.func,
  addGoogleAuthCodeBusiness: PropTypes.func.isRequired,
  removeGoogleAuthCodeBusiness: PropTypes.func,
  message: PropTypes.object.isRequired,
  editBusiness: PropTypes.func.isRequired,
  getCompanyBookings: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  company: state.company,
  service: state.service,
  form_fields: state.form_fields,
  serviceData: state.serviceData,
  message: state.message,
  booking: state.booking,
  companyList: state.company.companylist,
});

export default connect(mapStateToProps, {
  getCurrentCompany,
  getFormFields,
  getCategories,
  addGoogleAuthCode,
  removeGoogleAuthCode,
  addGoogleAuthCodeBusiness,
  removeGoogleAuthCodeBusiness,
  getMessages,
  editBusiness,
  getCompanyBookings,
  deleteUserBusiness,
})(withRouter(Admin));
